import { JOB_TEMPLATES_SORT_OPTIONS, JOB_TEMPLATE_STATUS, Locals_all } from '@learned/constants';
import FileSaver from 'file-saver';

import type {
  ICreateServerRoute,
  IDeleteServerRoute,
  IServerRoute,
  IUpdateServerRoute,
} from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { ICreateJobTemplate, IMultiLangString, IUpdateJobTemplate } from '@learned/types';

export function createJobTemplateSuperAdmin(data: ICreateJobTemplate) {
  return cloudRequest(serverRoutes.jobTemplates.createSuperAdmin as IServerRoute, {}, data);
}

export function updateJobTemplateSuperAdmin(jobTemplateId: string, data: IUpdateJobTemplate) {
  return cloudRequest(
    (serverRoutes.jobTemplates.updateSuperAdmin as IUpdateServerRoute)(jobTemplateId),
    {},
    data,
  );
}

export function addAliasJobTemplateSuperAdmin(
  jobTemplateId: string,
  data: { alias: IMultiLangString; jobTemplatePendingId: string },
) {
  return cloudRequest(
    (serverRoutes.jobTemplates.addAliasSuperAdmin as IUpdateServerRoute)(jobTemplateId),
    {},
    data,
  );
}

export function getJobTemplateSuperAdmin(jobTemplateId: string) {
  return cloudRequest(
    (serverRoutes.jobTemplates.getSuperAdmin as ICreateServerRoute)(jobTemplateId),
  );
}

export function getJobTemplatesSuperAdmin(
  filters: { search?: string; statuses?: JOB_TEMPLATE_STATUS[]; isFilterCompetencies?: boolean },
  options: { skip: number; limit: number; sortBy: JOB_TEMPLATES_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      statuses: filters.statuses,
      isFilterCompetencies: filters.isFilterCompetencies,
    },
    options: {
      skip: options.skip,
      limit: options.limit,
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(serverRoutes.jobTemplates.listSuperAdmin as IServerRoute, {}, body);
}

export function archiveJobTemplatesSuperAdmin(jobTemplateIds: string[]) {
  return cloudRequest(
    serverRoutes.jobTemplates.archiveSuperAdmin as IServerRoute,
    {},
    {
      jobTemplateIds,
    },
  );
}

export function downloadJobTemplatesCSVSuperAdmin(
  filters: { search?: string; statuses?: JOB_TEMPLATE_STATUS[] },
  options: { sortBy: JOB_TEMPLATES_SORT_OPTIONS },
) {
  const body = {
    filters: {
      search: filters.search,
      statuses: filters.statuses,
    },
    options: {
      sortBy: options.sortBy,
    },
  };
  return cloudRequest(
    serverRoutes.jobTemplates.downloadJobTemplatesCSVSuperAdmin as IServerRoute,
    {},
    body,
  ).then((response) => {
    FileSaver.saveAs(response, 'job_templates.csv');
  });
}

export function getJobTemplate(
  jobTemplateId: string,
  body?: {
    options?: {
      populate?: string[];
    };
  },
) {
  return cloudRequest(
    (serverRoutes.jobTemplates.getJobTemplate as ICreateServerRoute)(jobTemplateId),
    {},
    body,
  );
}

export function getJobTemplateNames(
  filters: { search: string; statuses?: JOB_TEMPLATE_STATUS[] },
  options: {
    skip: number;
    limit: number;
    projection: Record<string, number>;
  },
  locale: string,
) {
  const body = {
    filters: {
      search: filters.search,
      statuses: filters.statuses,
    },
    options,
    locale,
  };

  return cloudRequest(
    serverRoutes.jobTemplates.getJobTemplateNames as IServerRoute,
    {},
    body,
    // @ts-ignore
    { noLogin: true },
  );
}

export function deleteJobTemplateCoverImage(jobTemplateId: string) {
  return cloudRequest(
    (serverRoutes.jobTemplates.deleteJobTemplateCoverImage as IDeleteServerRoute)(jobTemplateId),
  );
}

/*
  Generate a Job Template data
  body: { name: string; locale: Locals_all[] }
 */
export function generateJobTemplate(name: string, locales: Locals_all[]) {
  return cloudRequest(
    serverRoutes.jobTemplates.generateJobTemplate as IServerRoute,
    {},
    {
      name,
      locales,
    },
  );
}

/*
  Generate Skills data for a certain category
  categoryId: string
  body: { name: string; locales: Locals_all[], existingSkillNames: string[] }
 */
export function generateSkillTempForJobTempCategory(
  categoryId: string,
  body: {
    name: string;
    locales: Locals_all[];
    existingSkillNames?: string[];
  },
) {
  return cloudRequest(
    (serverRoutes.jobTemplates.generateSkillTempForJobTempCategory as ICreateServerRoute)(
      categoryId,
    ),
    {},
    body,
  );
}
