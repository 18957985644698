import { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, NEXT_STEP_TYPES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { confirm } from '~/components/Modals/ConfirmationModal/confirm';

import useBoolState from '~/hooks/useBoolState';
import { getRelevantSteps } from '~/services/nextSteps';
import { deleteUserReviewAttachment, uploadUserReviewAttachment } from '~/services/userReviews';

import type { IReviewDashboardUserForm, IReviewNextStep } from '../types';
import type { UseFormReturn } from 'react-hook-form';

const useAttachments = ({
  userReviewId,
  formMethods,
}: {
  userReviewId?: string;
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
}) => {
  const { i18n } = useLingui();
  const isVisible = useBoolState(false);
  const [nextSteps, setNextSteps] = useState<IReviewNextStep[]>([]);
  const [editStepId, setEditStepId] = useState('');
  const { setValue, watch } = formMethods;

  const fetchNextSteps = async () => {
    const res = await getRelevantSteps({ targetId: userReviewId, type: NEXT_STEP_TYPES.REVIEW });
    setNextSteps(res.data.currentConvSteps);
  };

  useEffect(() => {
    fetchNextSteps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpload = async (file?: File) => {
    if (file && userReviewId) {
      const uploadedFile = await uploadUserReviewAttachment(file, userReviewId);

      setValue('attachments', [...watch('attachments'), uploadedFile.data]);
    }
  };

  const onDelete = async (fileId: string) => {
    if (userReviewId) {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete attachment?`),
        description: i18n._(t`Are you sure? This action cannot be undone`),
      });
      if (isConfirmed) {
        await deleteUserReviewAttachment(fileId, userReviewId);
      }

      setValue(
        'attachments',
        watch('attachments').filter(({ id }) => id !== fileId),
      );
    }
  };

  return {
    isVisible,
    editStepId,
    setEditStepId,
    onUpload,
    onDelete,
    nextSteps,
  };
};

export { useAttachments };
