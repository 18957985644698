import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, CONFIRMATION_MODAL_TYPE, ROLES } from '@learned/constants';
import { IReviewTemplateOld } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import { ICONS } from '~/components/Icon';
import { ConfirmationModal } from '~/components/Modals/ConfirmationModal';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import { LearnedTemplatesModal } from '~/pages/Conversations/components/LearnedTemplatesModal';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';
import { CreationInProcessModal } from '~/pages/Surveys/creationInProcessModal';

import { createColumns, SORT_OPTIONS } from './columns';

import { IReviewThemesStatus, THEME_STATUS } from '~/constants/reviewsThemes';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import {
  createTemplateNew,
  copyReviewTemplate,
  deleteMultipleTemplates,
  getTemplatesNew,
} from '~/services/reviewTemplates';

import type { IReviewTemplate } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_DEVELOPMENT_TEMPLATES;

const initialFilters = {
  search: '',
  status: [],
  sortBy: SORT_OPTIONS.CREATED_LATER_SOON,
  pagination: DEFAULT_PAGINATION,
};

const TemplatesTab = () => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const history = useHistory();
  const user = useSelector(getUser);
  const { addToast } = useToasts();
  const [items, setItems] = useState<IReviewTemplate[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const $isLearnedTemplatesShowing = useBoolState(false);
  const [isDeleteTemplateModalVisible, setIsDeleteTemplateModalVisible] = useState(false);
  const [isMultiDeleteTemplateModalVisible, setIsMultiDeleteTemplateModalVisible] = useState(false);
  const { isShowFilters: _isShowFilters, ...debCurrentFilters } = useDebounce(currentFilters, 300); // isShowFilters does not affect on reFetch
  const [isCreateTemplateLoading, setIsCreateTemplateLoading] = useState(false);
  // learned templates (for import)
  const [learnedTemplates] = useState([]);

  // redirect if no permission
  useEffect(() => {
    if (!user.isAdmin) {
      history.push(routes.HOME);
    }
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    const {
      data: { reviewTemplates: items, total },
    } = await getTemplatesNew(
      {
        search: currentFilters.search,
        status: currentFilters.status.map(({ key }: { key: string }) => key),
      },
      {
        skip: currentFilters.pagination.skip,
        limit: currentFilters.pagination.limit,
        sortBy: currentFilters.sortBy,
      },
    );

    setItems(Object.values(items));
    setTotalCount(total);
    setIsLoading(false);
  };

  const forceFetch = fetchData;

  // change filters fetch
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const navigateToEditFlow = (id: string, isCreatingNew = false) => {
    history.push(
      routes.REVIEW_TEMPLATE_UPDATE.build(
        {
          role: ROLES.ADMIN,
          companyId: undefined,
          teamId: undefined,
        },
        {
          isBackPath: true,
          // @ts-ignore
          reviewTemplateId: id,
          ...(isCreatingNew && { query: { isCreatingNew } }),
        },
      ),
    );
  };

  const createTemplate = async () => {
    setIsCreateTemplateLoading(true);
    const response = await createTemplateNew();
    const reviewTemplate = response.data[API_RETURN_FIELDS.REVIEW_TEMPLATE];
    setIsCreateTemplateLoading(false);

    navigateToEditFlow(reviewTemplate.id, true);
  };

  const actionButton = {
    label: t`Create new`,
    onClick: createTemplate,
  };

  const secondaryButton = {
    label: t`Import template(s)`,
    onClick: $isLearnedTemplatesShowing.on,
    icon: ICONS.IMPORT,
  };

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) => {
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      }));
    },

    // @ts-ignore
    onChangeFilter: (key, value) => {
      setCurrentFilters({ ...currentFilters, pagination: DEFAULT_PAGINATION, [key]: value });
    },
    resetFilters: () => setCurrentFilters(initialFilters),
    filterCount: currentFilters.status.length ? 1 : undefined,
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path = routes.REVIEW_TEMPLATE_VIEW.build(
        { companyId: undefined, teamId: undefined, role: ROLES.USER },
        // @ts-ignore
        { reviewTemplateId: item.id, isBackPath: true },
      );
      history.push(path as string);
    },
  };

  const onDuplicate = async (item: IReviewTemplateOld) => {
    await copyReviewTemplate(item.id);

    // confirm toast
    addToast({
      title: i18n._(t`Template duplicated!`),
      subtitle: i18n._(t`You can find your template in the company templates`),
      type: TOAST_TYPES.INFO,
    });

    // re-fetch items
    await forceFetch();
  };

  const onDelete = async () => {
    if (selectedItems.length > 0) {
      await deleteMultipleTemplates(selectedItems);

      // confirm toast
      addToast({
        title: i18n._(selectedItems.length > 1 ? t`Templates deleted!` : t`Template deleted!`),
        subtitle: i18n._(
          selectedItems.length > 1
            ? t`Your templates has been deleted from your company templates`
            : t`Your template has been deleted from your company templates`,
        ),
        type: TOAST_TYPES.INFO,
      });

      // re-fetch items
      await forceFetch();

      // uncheck selectedItems
      setSelectedItems([]);
    }
  };

  const createMenuItems = (item: IReviewTemplateOld) => {
    return [
      {
        label: i18n._(t`Edit`),
        action: () => navigateToEditFlow(item.id),
        icon: ICONS.EDIT_PENCIL,
      },
      {
        label: i18n._(t`Duplicate`),
        action: () => onDuplicate(item),
        icon: ICONS.DUPLICATE,
      },
      {
        label: i18n._(t`Delete`),
        action: () => {
          setSelectedItems([item.id]);
          setIsDeleteTemplateModalVisible(true);
        },
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const isAllSelected = items.every((item) => {
    return selectedItems.includes(item.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : items.map((item) => item.id);
    setSelectedItems(itemsToSelect);
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IReviewTemplateOld) => onSelectItem(item.id),
    isItemChecked: (item: IReviewTemplateOld) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onDelete: () => setIsMultiDeleteTemplateModalVisible(true),
  };

  return (
    <Wrapper>
      {isCreateTemplateLoading && (
        <CreationInProcessModal title={i18n._(t`Creating a new template…`)} />
      )}
      <TableList
        data={items}
        columns={createColumns(getMultiLangString)}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect,
        }}
        placeholderProps={{
          noResultText: i18n._(t`This search did not produce any results.`),
          emptyStateText: i18n._(t`No templates yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        secondaryButton={secondaryButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length || !!currentFilters.status.length,
          resetFilters: () => setCurrentFilters(initialFilters),
          isToggleHideFilterVisible: true,
          filterComponents: (
            <Dropdown
              placeholder={i18n._(t`Status`)}
              selectedItems={currentFilters.status}
              items={THEME_STATUS}
              onChange={(selectedItems?: IReviewThemesStatus[]) => {
                setCurrentFilters({
                  ...currentFilters,
                  pagination: DEFAULT_PAGINATION,
                  status: selectedItems,
                });
              }}
              stringifyItem={(item) => item.translated(i18n)}
              isSingleSelect={false}
            />
          ),
        }}
      />
      {$isLearnedTemplatesShowing.value && (
        <LearnedTemplatesModal
          templates={learnedTemplates}
          onClose={$isLearnedTemplatesShowing.off}
          duplicateTemplate={onDuplicate}
        />
      )}
      {(isDeleteTemplateModalVisible || isMultiDeleteTemplateModalVisible) && (
        <ConfirmationModal
          type={CONFIRMATION_MODAL_TYPE.DELETE}
          onClose={() => {
            setIsDeleteTemplateModalVisible(false);
            setIsMultiDeleteTemplateModalVisible(false);
          }}
          title={
            isMultiDeleteTemplateModalVisible
              ? i18n._(t`Delete templates?`)
              : i18n._(t`Delete template?`)
          }
          description={
            isMultiDeleteTemplateModalVisible
              ? i18n._(
                  t`Are you sure you want to delete these templates? This action cannot be undone.`,
                )
              : i18n._(
                  t`Are you sure you want to delete this template? This action cannot be undone.`,
                )
          }
          onSubmit={onDelete}
        />
      )}
    </Wrapper>
  );
};

export { TemplatesTab };
