import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import FocusWithHeightLayout from '~/layouts/FocusWithHeightLayout';
import { SurveyTaskView } from '~/pages/SurveyTaskView';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('surveyTasks', {
  routesPrivate: {
    user: [
      <PrivateRoute
        layout={FocusWithHeightLayout}
        key={routes.SURVEY_TASK.routePath(ROLES.USER)}
        exact
        path={routes.SURVEY_TASK.routePath(ROLES.USER)}
        component={SurveyTaskView}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
