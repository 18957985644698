import { ROLES } from '@learned/constants';

import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'profile';

export const PROFILE = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(`my-${routeName}`)}`,
);

export const CAREER_PROFILE = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(`career/my-${routeName}`)}`,
);

export const PROFILE_SETTINGS = new LinkConstructor([ROLES.USER, ROLES.ADMIN], () =>
  pageOverview(`${routeName}-settings`),
);
