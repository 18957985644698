import React, { useState } from 'react';

import { REVIEW_RATING_STATUS } from '@learned/constants';
import { IReviewRating } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  IconWrapper,
  Row,
  UserContainer,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { AvatarWrapper } from '../Avatar';
import { NotSharedLabel } from '../NotSharedLabel';

type TextRating = IReviewRating & { id?: string; reviewType: string; isOpened: boolean };

function TextAnswers({
  question,
}: {
  question: IQuestionDefaultData & {
    reviewRatings?: (IReviewRating & { reviewType: string })[];
  };
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [answers, setAnswers] = useState<TextRating[]>(
    question.reviewRatings?.map((answer, index) => {
      return {
        ...answer,
        isOpened: question.reviewRatings && question.reviewRatings.length > 0 ? index === 0 : true,
      };
    }) || [],
  );

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  return (
    <>
      {answers?.map((rating, index) => {
        return (
          <Row key={index}>
            <AnswerContainer clickable onClick={() => onClick(index)} $answered={!!rating?.answer}>
              <UserContainer>
                <Icon
                  transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                  size={ICON_SIZES.MEDIUM}
                  icon={ICONS.NEXT}
                  color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
                />
                <div>
                  <AvatarWrapper
                    key={index}
                    id={rating.createdBy?.id}
                    answer={rating?.answer as string}
                    email={rating.createdBy?.email}
                    reviewType={rating.reviewType}
                  />
                </div>
              </UserContainer>
              <IconWrapper $answered={!!rating?.answer}>
                {rating.status === REVIEW_RATING_STATUS.CALIBRATE && (
                  <ToolTip
                    key={index}
                    placement={TOOLTIP_PLACEMENTS.TOP}
                    tooltip={i18n._(t`This input is not yet shared with the employee`)}
                  >
                    <NotSharedLabel />
                  </ToolTip>
                )}
                <Icon icon={ICONS.TEXT_ANSWER} size={ICON_SIZES.LARGE} />
              </IconWrapper>
            </AnswerContainer>
            {rating.isOpened && (
              <AnswerWrapper>
                {rating?.answer ? (
                  <Answer html={getMultiLangString((rating?.answer as string) || '')} />
                ) : (
                  <AnswerPlaceholder>
                    {rating?.answer === null ? (
                      <Trans>Answered this question with N/A</Trans>
                    ) : (
                      <Trans>No answer provided yet</Trans>
                    )}
                  </AnswerPlaceholder>
                )}
              </AnswerWrapper>
            )}
          </Row>
        );
      })}
    </>
  );
}

export { TextAnswers };
