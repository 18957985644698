import styled from 'styled-components';

import { SearchField } from '~/components/Text';

import { COLORS } from '~/styles';

const HeatmapCtr = styled.div`
  box-sizing: border-box;

  & th span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10ch;
  }

  & th {
    position: relative;
  }
`;

const NormalCellCtr = styled.div<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  height: 56px;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
`;

const NormalCell = styled.div<{ mWidth: string }>`
  height: auto;
  padding: 0 20px;
  box-sizing: border-box;
  width: ${({ mWidth }) => mWidth};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  .cell-content-wrapper {
    display: flex;
  }

  .text-content-wrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .cell-dimension-section {
    display: flex;
    width: 100%;
    align-items: center;

    padding: 7px 10px 7px 0;
    overflow: hidden;
  }

  .cell-hover-with-color {
    &:hover {
      background-color: ${COLORS.HOVER};
    }
  }
`;

const FilterCtr = styled.div`
  width: 100%;
`;

const FilterBtnCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-bottom: 20px;
  & > .icon {
    cursor: pointer;
  }
`;

const SearchCtr = styled.div`
  flex: auto;
  display: flex;
  align-items: center;
  gap: 10px;
  & > .active:hover {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
  & > .active {
    border-color: ${COLORS.COMPANY};
    color: ${COLORS.COMPANY};
  }
`;

const DropDownCtr = styled.div`
  position: relative;
`;

const EmptyCell = styled.div`
  background: ${COLORS.WHITE};
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
`;

const SearchFieldWrapper = styled(SearchField)`
  height: 32px;
  width: 320px;
  font-size: 14px;
  font-weight: 600;
  & input {
    border-radius: 10rem;
  }
`;

const BodyCtr = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 500px;
  position: relative;
  flex-direction: column;
  gap: 10px;
`;
const NotAvailableCtr = styled.div`
  color: ${COLORS.INACTIVE};
`;

const NoDataTextContainer = styled.span`
  color: ${COLORS.PLACEHOLDERS};
`;

const Nester = styled.span<{ nestLevel: number }>`
  padding-left: ${({ nestLevel }) => nestLevel * 30 + 'px'};
`;

export {
  SearchFieldWrapper,
  EmptyCell,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  NormalCell,
  NormalCellCtr,
  HeatmapCtr,
  DropDownCtr,
  BodyCtr,
  Nester,
  NotAvailableCtr,
  NoDataTextContainer,
};
