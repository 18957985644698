import React from 'react';

import { REVIEW_RATING_TYPE } from '@learned/constants';
import moment from 'moment';

import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import { ModalDimensionsRow } from './DimensionsStrip.design';

import { TTimeFrame } from '../../types';

const getReviewerTypeText = (reviewer: REVIEW_RATING_TYPE): string => {
  switch (reviewer) {
    case REVIEW_RATING_TYPE.COACH:
      return 'Coach';
    case REVIEW_RATING_TYPE.SELF:
      return 'Self';
    case REVIEW_RATING_TYPE.PEER:
      return 'Peer';
    default:
      return '';
  }
};

interface IDimensionDisplayProps {
  primaryDimensionName?: string;
  secondaryDimensionName?: string;
  additionalDimensionNames?: string[];
  reviewer?: REVIEW_RATING_TYPE;
  measureName?: string;
  timeFrame: TTimeFrame;
}

const DimensionsStrip: React.FC<IDimensionDisplayProps> = ({
  primaryDimensionName,
  secondaryDimensionName,
  additionalDimensionNames = [],
  measureName,
  reviewer,
  timeFrame,
}) => {
  const MAX_DIMENSION_LENGTH = 15;
  const dimensions = [primaryDimensionName, secondaryDimensionName, ...additionalDimensionNames]
    .map((name) => name?.trim())
    .filter((name) => name && name !== '');

  return (
    <ModalDimensionsRow>
      {dimensions.map((dimension, i) => {
        const separator =
          i + 1 < dimensions.length ? (
            <span> &nbsp;&gt;&nbsp; </span>
          ) : (
            <span> &nbsp;/&nbsp; </span>
          );
        if (dimension) {
          return (
            <React.Fragment key={dimension}>
              <Tooltip disabled={false} tooltip={dimension} size={TOOLTIP_SIZES.BIG}>
                <span>
                  {dimension.length > MAX_DIMENSION_LENGTH
                    ? `${dimension.slice(0, MAX_DIMENSION_LENGTH)}...`
                    : dimension}

                  {separator}
                </span>
              </Tooltip>
            </React.Fragment>
          );
        }
        return null;
      })}
      {measureName || (
        <>
          [{moment(timeFrame.start, 'YYYY-MM-DD').format('DD-MM-YYYY')} -&nbsp;
          {moment(timeFrame.end, 'YYYY-MM-DD').format('DD-MM-YYYY')}]
        </>
      )}
      {reviewer && (
        <>
          &nbsp;/&nbsp;
          <span>{getReviewerTypeText(reviewer)}</span>
        </>
      )}
    </ModalDimensionsRow>
  );
};

export default DimensionsStrip;
