import styled from 'styled-components';

import { Icon } from '~/components/Icon';
import Tooltip from '~/components/Tooltip';

import { ETrend } from './Card';

import { COLORS } from '~/styles';

const CardContainer = styled.div`
  height: 229px; // matching with the circular progress card height
  background: ${COLORS.WHITE};
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

const ChartContainer = styled(CardContainer)`
  flex: none;
  flex-basis: 30%;
`;

const WaveCtr = styled.div`
  border-radius: 6px;
  box-sizing: border-box;
  margin: 0 10px 10px 10px;
  position: relative;
`;

const LoaderCtr = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  max-width: 300px;
  margin: auto;
  gap: 15px;
  flex-direction: column;
`;

const Title = styled.div`
  color: ${COLORS.SUBTEXT};
  box-sizing: border-box;
  padding: 20px 20px 0 20px;
`;

const BarContainer = styled(CardContainer)`
  flex: auto;
`;

const BarCtr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  flex-basis: 30%;
`;

const BarData = styled.div<{ oppositeGradient: boolean }>`
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 12px;
  position: relative;
  overflow: hidden;
  isolation: isolate;
  cursor: default;
  &:nth-child(1) > .bgCtr {
    opacity: ${({ oppositeGradient }) => (oppositeGradient ? '0.05' : '0.25')};
  }
  &:nth-child(2) > .bgCtr {
    opacity: 0.15;
  }
  &:nth-child(3) > .bgCtr {
    opacity: ${({ oppositeGradient }) => (oppositeGradient ? '0.25' : '0.05')};
  }
`;

const BackgroundCtr = styled.div<{ bColor: string }>`
  z-index: -1;
  position: absolute;
  inset: 0;
  background: ${({ bColor }) => bColor};
`;

const BarTitle = styled.div`
  color: ${COLORS.TEXT_HOVER};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 22ch;
`;

const Value = styled.div<{ trend?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ trend }) => (trend ? 'space-between' : 'flex-end')};
  min-width: 70px;
  cursor: pointer;
`;

const IconCtr = styled.div<{ trend?: ETrend }>`
  display: flex;
  align-items: center;
  transform: ${({ trend }) => (trend === ETrend.UP ? 'rotate(270deg)' : 'rotate(90deg)')};
  color: ${({ trend }) => (trend === ETrend.UP ? COLORS.ACCENT_SUCCESS : COLORS.ACCENT_ERROR)};
`;

const CustomIcon = styled(Icon)`
  align-self: flex-end;
`;

const TooltipWrapper = styled(Tooltip)`
  z-index: 1;
  display: block;
`;

const AverageCtr = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 7px;
`;

const Average = styled.div`
  color: ${COLORS.TEXT_HOVER};
  font-size: 27px;
`;

const ChangeCtr = styled.div<{ positive: boolean }>`
  font-size: 14px;
  font-weight: 600;
  color: ${({ positive }) =>
    positive ? COLORS.CONFIRMATION_MODAL_SUCCESS : COLORS.CONFIRMATION_MODAL_DELETE};
`;

const Highlight = styled.div<{ color: string }>`
  display: inline;
  margin-right: 5px;
  color: ${({ color }) => color};
`;

const ToolTipCtr = styled.div`
  display: inline;
`;

const NoDataSpan = styled.div`
  text-align: center;
  position: relative;
  bottom: 17px;
  color: ${COLORS.PLACEHOLDERS};
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin-bottom: 12px;
`;

export {
  ChartContainer,
  WaveCtr,
  LoaderCtr,
  Title,
  BarContainer,
  BarCtr,
  BarData,
  BackgroundCtr,
  BarTitle,
  Value,
  IconCtr,
  CustomIcon,
  TooltipWrapper,
  AverageCtr,
  Average,
  ChangeCtr,
  Highlight,
  ToolTipCtr,
  NoDataSpan,
  CardWrapper,
};
