import React, { useState } from 'react';

import { ISkillCategory } from '@learned/types';

import { SkillMatrixCommon } from './SkillMatrixCommon';

import { SkillCreateModal } from '../SkillCreateModal';

const EditSkillMatrix = () => {
  const [showSkillCreateModal, setShowSkillCreateModal] = useState(false);

  const [selectedCategoryId, setSelectedCategoryId] = useState<ISkillCategory['id']>();
  const onSkillCreate = () => {
    setShowSkillCreateModal(true);
  };
  return (
    <>
      <SkillMatrixCommon
        expanded={true}
        isEditable={true}
        onSkillCreate={onSkillCreate}
        onSelectedCategory={setSelectedCategoryId}
      />
      {showSkillCreateModal && (
        <SkillCreateModal
          onClose={() => setShowSkillCreateModal(false)}
          defaultSkillCategory={selectedCategoryId}
        />
      )}
    </>
  );
};

export { EditSkillMatrix };
