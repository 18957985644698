import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Banner } from '~/components/Banner';
import { ACTIONS_POSITION, BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

const IntegrationSection = () => {
  const { i18n } = useLingui();
  const hasIntegration = false; // TODO
  const _isDigitalMeeting = false; // TODO

  return (
    <div>
      {hasIntegration ? (
        /* TODO when integration connected */
        <div>TODO</div>
      ) : (
        /* <StyledDigitalMeeting
          tippyProps={{
            content:
              calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
              !calendarIntegration.hasOnlineMeetingProvider
                ? i18n._(
                    t`Your integration email account does not have access to create Microsoft Teams meetings`,
                  )
                : calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
                  calendarEvent.linkMeeting
                ? t`Microsoft Teams does not allow to remove meeting links once created`
                : null,
          }}
          checked={selectedIncludeLinkMeeting}
          onChange={(value) => onSelect('selectedIncludeLinkMeeting', value)}
          calendarType={calendarIntegration.type}
          disabled={
            !calendarIntegration.hasOnlineMeetingProvider ||
            (calendarIntegration.type === USER_INTEGRATION_TYPES.azure &&
              !!calendarEvent.linkMeeting)
          }
        />*/
        <Banner
          type={BANNER_TYPES.WARNING}
          subTitle={i18n._(
            t`Your calendar is not connected with Learned. Your conversation will not be synchronized with your calendar or the calendars of the participants.`,
          )}
          actionsPosition={ACTIONS_POSITION.COLUMN}
          actions={
            <Button
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              label={i18n._(t`Connect calendar`)}
              onClick={() => {}} // TODO
            />
          }
        />
      )}
    </div>
  );
};

export { IntegrationSection };
