import React, { useState } from 'react';

import { API_RETURN_FIELDS } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';

import { AddMultipleItems } from '~/components/AddMultipleItems';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { ImageWrapper } from './components/ImageWrapper';
import { StepFooter } from './components/StepFooter';
import {
  Section,
  Form,
  InputContainer,
  Label,
  Title,
  CombinedSection,
  StyledDropdown,
  JobDescriptionHeader,
} from './design';
import { useJobFamilies } from './hooks/useJobFamilies';

import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import {
  generateTextTranslationSuperAdmin,
  generateJobDescriptionSuperAdmin,
} from '~/services/completions';
import { convertLanguageValue } from '~/utils/convertMultiLangValue';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

import type { IGeneralForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: IBaseLanguageStateReturn;
  onCoverImageDelete?: () => void;
};

function StepDetails({
  sectionState,
  formMethods,
  languageState,
  onCoverImageDelete,
}: StepDetailsProps) {
  const { i18n } = useLingui();
  const { jobFamilies } = useJobFamilies();
  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);
  const {
    register,
    unregister,
    control,
    trigger,
    handleSubmit,
    getValues,
    formState,
    setValue,
    watch,
  } = formMethods;

  const jobName = watch('name')?.find(
    (name) => name.locale === languageState.primaryLanguage.locale,
  );

  const jobNameEnglish = watch('name').find((value) => value.locale === 'en_GB');

  const jobDescriptionEnglish = watch('description').find((value) => value.locale === 'en_GB');

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const onSubmit = () => sectionState.setCurrentSection(1);

  const generateDescription = async () => {
    try {
      if (!jobNameEnglish?.value) {
        return;
      }

      setIsGenerationLoading(true);
      const response = await generateJobDescriptionSuperAdmin(jobNameEnglish.value);
      if (response.code !== 200) {
        return;
      }

      const { description } = response.data;
      const index = watch('description').findIndex((value) => value.locale === 'en_GB');
      setValue(`description.${index}.value`, description);
    } catch (err) {
      console.error(err);
    } finally {
      setIsGenerationLoading(false);
    }
  };

  const translateDescription = async () => {
    const locales = languageState.languages
      .map(({ locale }) => locale)
      .filter((locale) => locale !== 'en_GB');

    try {
      if (!jobDescriptionEnglish?.value) {
        return;
      }

      setIsTranslationLoading(true);
      const response = await generateTextTranslationSuperAdmin(
        [jobDescriptionEnglish.value],
        locales,
      );
      if (response.code !== 200) {
        return;
      }

      const translatedTexts = response.data[API_RETURN_FIELDS.TRANSLATED_TEXTS];

      Object.entries(translatedTexts[0] as Record<string, string>).forEach(([locale, text]) => {
        const index = watch('description').findIndex((value) => value.locale === locale);
        setValue(`description.${index}.value`, text);
        setValue(`description.${index}.locale`, locale);
      });
    } catch (err) {
      console.error(err);
    } finally {
      setIsTranslationLoading(false);
    }
  };

  const handleCoverImageDelete = () => {
    setValue('coverImage', undefined);
    onCoverImageDelete && onCoverImageDelete();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Job Details</Trans>
      </Title>
      <Section $marginTop="14px">
        <Label>
          <Trans>Job template name</Trans>
        </Label>
        <InputContainer>
          {nameFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`name.${field.index}.value`)}
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <Input
                    value={value}
                    error={sectionState.triedToSubmit && !!formState.errors?.name}
                    onChange={(args) => {
                      onChange(args);
                      trigger('name');
                    }}
                    key={field.id}
                    width="318px"
                    height="38px"
                    leftIcon={
                      size(languageState.languages) > 1
                        ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                        : undefined
                    }
                  />
                );
              }}
            />
          ))}
        </InputContainer>
      </Section>
      <Section $marginTop="32px">
        <AddMultipleItems
          title={i18n._(t`Aliases`)}
          placeholder={i18n._(t`[Alias name]`)}
          field="aliases"
          modal={{
            title: i18n._(t`Job alias translations`),
            subtitle: jobName?.value || '',
            fieldLabel: i18n._(t`Job alias`),
          }}
          formMethods={formMethods}
          languageState={languageState}
        />
      </Section>

      <CombinedSection>
        <Section $marginTop="14px">
          <Label>
            <Trans>Job family</Trans>
          </Label>
          <InputContainer>
            <Controller
              {...register('jobFamily')}
              control={control}
              render={({ field: { onChange, value } }) => (
                <StyledDropdown
                  placeholder={i18n._(t`Empty`)}
                  $error={sectionState.triedToSubmit && !!formState.errors?.jobFamily}
                  selectedItem={jobFamilies.find((item) => item.id === value)}
                  items={jobFamilies}
                  onChange={(item) => {
                    onChange(item?.id);
                  }}
                  stringifyItem={(item) => item.name[languageState.primaryLanguage.locale]}
                  isSingleSelect
                />
              )}
            />
          </InputContainer>
        </Section>
        <Section $marginTop="14px" $width="100%">
          <Label>
            <Trans>Image</Trans>
          </Label>
          <Controller
            {...register('coverImage', {
              required: false,
            })}
            control={control}
            render={({ field: { value, onChange } }) => (
              <ImageWrapper
                image={value}
                onChange={onChange}
                onDelete={handleCoverImageDelete}
                alt={getSuperAdminMultiLangString(
                  convertLanguageValue(getValues('name')),
                  languageState,
                ).substring(0, 2)}
              />
            )}
          />
        </Section>
      </CombinedSection>

      <Section $marginTop="47px">
        <JobDescriptionHeader>
          <Label>
            <Trans>Job description</Trans>
          </Label>
          <div className="buttons">
            <Button
              disabled={!jobDescriptionEnglish?.value || isTranslationLoading}
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={<Trans>Translate Description</Trans>}
              onClick={translateDescription}
              isLoading={isTranslationLoading}
            />

            <Button
              disabled={
                !jobNameEnglish?.value || !!jobDescriptionEnglish?.value || isGenerationLoading
              }
              type="button"
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
              label={<Trans>Generate description</Trans>}
              onClick={generateDescription}
              isLoading={isGenerationLoading}
            />
          </div>
        </JobDescriptionHeader>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  minHeight={'231px'}
                  value={convertMarkdownStarsToStrong(value)}
                  compact
                  onChange={onChange}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </Section>
      <StepFooter isPrev={false} />
    </Form>
  );
}

export { StepDetails };
