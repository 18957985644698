import React from 'react';

import { TASK_STATUS, USER_REVIEW_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { transparentize } from 'polished';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Label from '~/components/Label';
import Tooltip from '~/components/Tooltip';
import { UserAvatar } from '~/components/UserAvatar';

import { TaskStatus } from './TaskStatus';

import { COLORS } from '~/styles';

import { EvaluatorLabel, ListRow, RowLeftBlock, RowRightBlock } from '../design';

import type { ITask, IUser, IUserReview, IUserReviewSignature } from '@learned/types';

const Placeholder = styled.div`
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
`;

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

const FirstRowWrapper = styled.div`
  margin-bottom: 10px;
`;

interface ListRowTaskProps {
  userId: IUser['id'];
  userRole?: string | null;
  isGuest: boolean;
  isAllGuestsSigned: boolean;
  signature?: IUserReviewSignature;
  reviewStatus: IUserReview['status'];
  isShowButton: boolean;
  onClick: () => void;
}

const ListRowSignature = ({
  userId,
  userRole,
  isGuest,
  isAllGuestsSigned,
  signature,
  reviewStatus,
  isShowButton,
  onClick,
}: ListRowTaskProps) => {
  const { i18n } = useLingui();

  const Signed = (
    <StyledDiv
      color={COLORS.STATUS_COMPLETED}
      backgroundColor={transparentize(0.8, COLORS.STATUS_COMPLETED)}
    >
      <StyledIcon icon={ICONS.CHECKMARK} size={ICON_SIZES.SMALL} color={COLORS.STATUS_COMPLETED} />
      {i18n._(t`Signed`)}
    </StyledDiv>
  );

  const getGuestSignatureStatus = () => {
    if (signature) {
      return Signed;
    }

    const status =
      reviewStatus === USER_REVIEW_STATUS.ARCHIVED ? TASK_STATUS.EXPIRED : TASK_STATUS.TODO;

    return <TaskStatus task={{ status } as ITask} />;
  };

  const getEmployeeSignatureStatus = () => {
    if (signature) {
      const NotAgree = (
        <StyledDiv
          color={COLORS.STATUS_EXPIRED}
          backgroundColor={transparentize(0.8, COLORS.STATUS_EXPIRED)}
        >
          <StyledIcon icon={ICONS.EXPIRED} size={ICON_SIZES.SMALL} color={COLORS.STATUS_EXPIRED} />
          {i18n._(t`Not agreed`)}
        </StyledDiv>
      );
      return signature.isAgree ? Signed : NotAgree;
    }

    // if not signed but review archived -> show expired
    if (reviewStatus === USER_REVIEW_STATUS.ARCHIVED) {
      return <TaskStatus task={{ status: TASK_STATUS.EXPIRED } as ITask} />;
    }

    // if all guests signed
    if (isAllGuestsSigned) {
      return <TaskStatus task={{ status: TASK_STATUS.TODO } as ITask} />;
    }

    // no signatures
    return (
      <Tooltip tooltip={i18n._(t`As employee you can sign when all guest have signed the review`)}>
        <Placeholder>{i18n._(t`Wait for guest`)}</Placeholder>
      </Tooltip>
    );
  };

  return (
    <div>
      <FirstRowWrapper>
        <ListRow>
          <RowRightBlock>
            <UserAvatar userId={userId} options={{ showTooltip: true }} />
            {userRole && <EvaluatorLabel>({userRole})</EvaluatorLabel>}
          </RowRightBlock>

          <RowLeftBlock>
            {isGuest ? getGuestSignatureStatus() : getEmployeeSignatureStatus()}
          </RowLeftBlock>
        </ListRow>

        {isShowButton && (
          <ListRow>
            <Button
              label={i18n._(t`Sign`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.NAVIGATION_SECONDARY}
              isLoading={false}
              onClick={onClick}
            />
          </ListRow>
        )}
      </FirstRowWrapper>
    </div>
  );
};

export { ListRowSignature };
