import { ROLES } from '@learned/constants';
import isEmpty from 'lodash/isEmpty';
import qs from 'qs';

import store from '~/store';
import getCurrentPath from '~/utils/getCurrentPath';
import getCurrentPathEncoded from '~/utils/getCurrentPathEncoded';
import history from '~/utils/history';

const addQuery = (queries = {}, noHash: boolean, addHash: boolean) => {
  if (isEmpty(queries) && isEmpty(addHash)) {
    return '';
  }
  let query = '';
  if (noHash || addHash) {
    query = `?${qs.stringify(queries, { encode: false })}`.replace(/#.*$/, '');
  } else {
    query = `?${qs.stringify(queries, { encode: false })}`;
  }
  if (addHash) {
    query = query + '#' + addHash;
  }

  return query;
};

type RoleParams = {
  companyId?: string;
  teamId?: string;
  role?: ROLES;
};

export default class LinkConstructor {
  /**
   * Create a LinkConstructor.
   * @param {array} roles - A list of roles.
   * @param {function} pathFn - function to create a path.
   * @param {object} defaults - defaults.
   */

  private pathFn: Function;
  private roles: ROLES[];
  private defaults: object;

  constructor(roles: ROLES[], pathFn: Function = () => {}, defaults = {}) {
    this.pathFn = pathFn;
    this.roles = roles;
    this.defaults = defaults;
  }

  routePath(role: ROLES) {
    if (!isEmpty(this.roles) && this.roles.indexOf(role) === -1) {
      throw Error('Wrong role for route');
    }

    switch (role) {
      case ROLES.USER:
        return `/company/:companyId/${this.pathFn({})}`;
      case ROLES.COACH:
        return `/company/:companyId/team/:teamId/${this.pathFn({})}`;
      case ROLES.ADMIN:
        return `/company/:companyId/admin/${this.pathFn({})}`;
      default:
        return this.pathFn({});
    }
  }

  build(
    { companyId, teamId, role }: RoleParams = {},
    {
      query = {},
      isBackPath = false,
      noHash = false,
      hash = false,
      backPathDefault = getCurrentPath(),
      isBackPathEncoded = false,
      ...options
    }: any = {},
  ) {
    const state = store.getState();
    const opt = Object.assign({}, this.defaults, options);
    if (isBackPath) {
      // @ts-ignore
      query.from = isBackPathEncoded ? getCurrentPathEncoded() : backPathDefault;
    }
    const selectedRole = role || (state.selected.role as ROLES);
    const company = companyId || state.selected.company;

    if (![ROLES.USER, ROLES.ADMIN, ROLES.COACH].includes(selectedRole) && !company) {
      throw new Error('LinkConstructor: Missing company param for url');
    }

    switch (selectedRole) {
      case ROLES.USER:
        return `/company/${company}/${this.pathFn(opt)}${addQuery(query, noHash, hash)}`;
      case ROLES.COACH: {
        const selectedTeam = teamId || state.selected.team;

        if (!selectedTeam) {
          throw new Error('LinkConstructor: Missing team param for url');
        }
        return `/company/${company}/team/${selectedTeam}/${this.pathFn(opt)}${addQuery(
          query,
          noHash,
          hash,
        )}`;
      }
      case ROLES.ADMIN:
        return `/company/${company}/admin/${this.pathFn(opt)}${addQuery(query, noHash, hash)}`;
      default:
        return `${this.pathFn(opt)}${addQuery(query, noHash, hash)}`;
    }
  }

  go(roleParams: RoleParams = {}, settings?: any) {
    const url = this.build(roleParams as RoleParams, settings);
    if (history.location.pathname === url) {
      history.go(0);
    } else {
      history.push(url);
    }
  }
}
