import React, { useState, useEffect, useCallback } from 'react';

import { API_RETURN_FIELDS, REPORT_CHART_TYPES, REPORT_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import filter from 'lodash/filter';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Button, ButtonVariant, ButtonSize } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { JobCardPlaceholder } from '~/components/JobCards/JobCardPlaceholder';
import { RectangularJobCardGroup } from '~/components/JobCards/RectangularJobCardGroup';
import { AddRoleModal } from '~/components/Modals/AddRoleModal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import BaseLayout from '~/layouts/BaseLayout';

import { CareerPathComponent } from './components/CareerPathComponent';
import { CustomPathSection } from './components/CustomPathSection';
import {
  Content,
  ContentContainer,
  Header,
  HorizontalLine,
  UserAvatar,
  UserAvatarWrapper,
  VerticalLine,
  Wrapper,
} from './design';

import { JOB_PROFILE_STATUSES, ROLES } from '~/constants';
import routes from '~/constants/routes';
import useBoolState from '~/hooks/useBoolState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import {
  getSelectedRole,
  getUser,
  _isModuleAmbitionsEnabled, // May need this part for my next tasks
  isCustomPathsEnabled,
} from '~/selectors/baseGetters';
import { createCareerPlansForUser, getCareerPlansFull } from '~/services/careerPlans';
import { getCompany } from '~/services/companies';
import { getJobProfiles } from '~/services/jobProfiles';
import { createLikes, deleteLike } from '~/services/likes';
import { getEngagementCharts } from '~/services/reports';
import { COLORS } from '~/styles';
import { getDateForTimeFrame } from '~/utils/reports';

function mapLikes(likes) {
  const result = {};
  Object.values(likes).forEach((like) => {
    result[like.jobProfile] = true;
  });
  return result;
}

const IS_ADD_ROLE_MODAL_VISIBLE = 'isAddRoleModalVisible';
const IS_ADD_AMBITION_MODAL_VISIBLE = 'isAddAmbitionModalVisible';

function UserCareer(props) {
  const { i18n } = useLingui();
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const [currentJobRoles, setCurrentJobRoles] = useState([]);
  const [allCareerPlans, setAllCareerPlans] = useState({});
  const [recommendedCareerPaths, setRecommendedCareerPaths] = useState([]);
  const [verticalCareerPaths, setVerticalCareerPaths] = useState([]);
  const [savedJobRoles, setSavedJobRoles] = useState([]);
  const [likes, setLikes] = useState([]);
  const [company, setCompany] = useState(null);

  const $loading = useBoolState();
  const selectedRole = useSelector(getSelectedRole);
  const selectedUser = useSelector(getUser);
  const isCustomPathsModuleEnabled = useSelector(isCustomPathsEnabled);

  const $isAddRoleShowing = useBoolState(query[IS_ADD_ROLE_MODAL_VISIBLE]);
  const $isAddAmbitionShowing = useBoolState(query[IS_ADD_AMBITION_MODAL_VISIBLE]);
  const $isRefresh = useBoolState(false);
  const $showRecommendedPath = useBoolState(false);
  const $showVerticalPath = useBoolState(false);
  const $showAddRole = useBoolState(false);

  const history = useHistory();
  const { addToast } = useToasts();
  const getMultiLangString = useMultiLangString();

  useEffect(() => {
    const fetchData = async () => {
      $loading.on();
      const { companyId } = props.match.params;

      const [company, result, jobProfiles] = await Promise.all([
        getCompany(companyId),
        getCareerPlansFull(),
        getJobProfiles({ status: JOB_PROFILE_STATUSES.ACTIVE.key }, {}, ['skills']),
      ]);

      const careerPlans = result?.data[API_RETURN_FIELDS.CAREER_PLANS] || [];
      const likes = Object.values(result?.data[API_RETURN_FIELDS.LIKES]) || [];

      const recommendedRoles = mapJobProfilesWithLikes(careerPlans.recommendedPaths, likes);
      const verticalPaths = mapJobProfilesWithLikes(careerPlans.verticalPaths, likes);

      Object.values(jobProfiles).forEach((jp) => {
        jp.name = getMultiLangString(jp.name || '');
      });

      const currentRoles = getCurrentRoles(careerPlans, jobProfiles) || [];
      const mapedLikes = mapLikes(likes);
      const savedRoles = filter(jobProfiles, (jobProfile) => {
        return (
          mapedLikes[jobProfile?.id] &&
          !currentRoles.find((r) => r?.jobProfile?.id === jobProfile?.id)
        );
      }).sort((a, b) => a?.name?.localeCompare(b?.name));

      company?.products?.career?.settings?.employeeCanSetAmbitions?.isEnabled
        ? $showAddRole.on()
        : $showAddRole.off();

      const roleJobProfileMap = currentRoles
        .map((cr) => ({
          roleId: cr.id,
          jobProfileId: cr.jobProfile?.id,
        }))
        .filter(({ jobProfileId }) => jobProfileId);

      if (roleJobProfileMap.length) {
        const reviewScorePayload = {
          viewAs: selectedRole,
          reportType: REPORT_TYPES.PERFORMANCE,
          dateRange: getDateForTimeFrame('24'),
          chartType: REPORT_CHART_TYPES.MEMBER_PERFORMANCE_SCORE,
          filters: {
            jobs: roleJobProfileMap.map((m) => m.jobProfileId),
          },
        };
        const res = await getEngagementCharts(reviewScorePayload);
        if (res?.data?.scores?.length) {
          res.data.scores
            .filter((performance) => performance.score && performance.score > 0)
            .forEach((performance) => {
              const roleId = roleJobProfileMap.find(
                (m) => m.jobProfileId === performance.jobProfileId,
              )?.roleId;

              const roleRef = currentRoles.find((c) => c.id === roleId);
              if (roleRef && roleRef.jobProfile) {
                roleRef.jobProfile.performance = {
                  score: performance.score,
                  maxScore: performance.maxScore,
                };
              }
            });
        }
      }

      setCompany(company);
      setAllCareerPlans(jobProfiles);
      setSavedJobRoles(savedRoles || []);
      setCurrentJobRoles(currentRoles || []);
      updateRecommendedPathVisibility(recommendedRoles, company);
      updateVerticalPathVisibility(verticalPaths, company);
      setVerticalCareerPaths(verticalPaths);
      setRecommendedCareerPaths(recommendedRoles);
      setLikes(likes);
      $loading.off();
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [$isRefresh.value]);

  const mapJobProfilesWithLikes = (careerPaths, likes) => {
    return careerPaths?.map((careerPath) => {
      const like = likes?.find((like) => like?.jobProfileId === careerPath?.id);
      if (like) {
        return { ...careerPath, like };
      }
      return careerPath;
    });
  };

  const getCurrentRoles = (careerPlans, jobProfiles) => {
    return careerPlans.careerPaths
      .filter(
        (careerPath) =>
          careerPath.jobProfile &&
          jobProfiles[careerPath.jobProfile]?.status === JOB_PROFILE_STATUSES.ACTIVE.key,
      )
      .map((cp) => {
        const jobProfile = jobProfiles[cp.jobProfile];
        return {
          ...cp,
          name: getMultiLangString(cp.name),
          jobProfile: jobProfile && {
            ...jobProfile,
            name: getMultiLangString(jobProfile?.name || ''),
          },
        };
      })
      .sort((a) => (a.primary ? -1 : 1));
  };

  const updateRecommendedPathVisibility = (recommendedCareerPaths, company) => {
    if (
      recommendedCareerPaths?.length > 0 &&
      company?.products?.career?.settings?.recommendedPath?.isEnabled
    ) {
      $showRecommendedPath.on();
    } else {
      $showRecommendedPath.off();
    }
  };

  const updateVerticalPathVisibility = (verticalCareerPath, company) => {
    if (
      verticalCareerPath?.length > 0 &&
      company?.products?.career?.settings?.verticalMove?.isEnabled
    ) {
      $showVerticalPath.on();
    } else {
      $showVerticalPath.off();
    }
  };

  const updateUrlQuery = (query) => {
    history.replace({
      search: location.search ? `${location.search}&${query}` : `?${query}`,
      hash: location.hash,
    });
  };

  const careerPlans = () => {
    $isAddRoleShowing.on();
    updateUrlQuery(`${IS_ADD_ROLE_MODAL_VISIBLE}=true`);
  };

  const onCloseRoleModal = () => {
    $isAddRoleShowing.off();

    const regex = new RegExp(
      `(&|\\?)${IS_ADD_ROLE_MODAL_VISIBLE}=true|(&|\\?)${IS_ADD_AMBITION_MODAL_VISIBLE}=true`,
      'i',
    );
    history.replace({
      search: location.search.replace(regex, ''),
      hash: location.hash,
    });
  };

  const addRoles = async (jobProfiles) => {
    $loading.on();
    await createCareerPlansForUser(jobProfiles);
    refresh();
    $loading.off();
  };

  const handleLikeButtonClick = async (jobProfileId, likeId) => {
    let toastParams = {};

    let response = null;

    if (!(jobProfileId || likeId)) {
      return;
    }

    if (likeId) {
      toastParams.title = i18n._(t`You have removed your like`);
      toastParams.subtitle = i18n._(
        t`HR and your manager will no longer see that you are interested.`,
      );
      toastParams.type = TOAST_TYPES.INFO;
      response = await deleteLike(likeId);
    } else {
      response = await createLikes([{ jobProfileId }]);
      toastParams.title = i18n._(t`You liked the role`);
      toastParams.subtitle = i18n._(t`The HR team and your manager see that you are interested.`);
      toastParams.type = TOAST_TYPES.SUCCESS;
    }
    refresh();
    addToast(toastParams);
    return response;
  };

  const onClickViewDetails = (roleId) => {
    if (!roleId) {
      return;
    }
    const params = {
      roleId,
      isBackPath: true,
    };
    history.push(
      selectedRole === ROLES.USER
        ? routes.JOB_PROFILE.build({}, params)
        : routes.JOB_PROFILE_SUPERVISION_USER.build(
            {},
            {
              ...params,
              isBackPath: true,
            },
          ),
    );
  };

  const refresh = useCallback(() => {
    $isRefresh.toggle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jobProfilesForAddRoleModal = {};
  Object.values(allCareerPlans).map((jobProfile) => {
    const existingRole = currentJobRoles?.find((i) => i.jobProfile?.id === jobProfile.id);
    const existingAmbition = savedJobRoles?.find((i) => i.id === jobProfile.id);
    if (!existingRole && (!$isAddAmbitionShowing.value || !existingAmbition)) {
      jobProfilesForAddRoleModal[jobProfile.id] = jobProfile;
    }
  });

  return (
    <>
      <Wrapper>
        <Header>
          <span>
            <Trans>Career</Trans>
          </span>
          {$showAddRole.value && (
            <Button
              variant={ButtonVariant.PRIMARY}
              icon={ICONS.ADD_PLUS}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Add role`)}
              onClick={careerPlans}
            />
          )}
        </Header>
      </Wrapper>

      <BaseLayout smallWidth>
        <ShowSpinnerIfLoading loading={$loading.value}>
          <ContentContainer>
            <UserAvatarWrapper className="placeholder">
              <UserAvatar>
                <img alt="avatarImage" src={selectedUser.avatarUrl} />
              </UserAvatar>
              <HorizontalLine className="horizontal-line" />
              <VerticalLine className="vertical-line" />
            </UserAvatarWrapper>
            <Content>
              {currentJobRoles?.length > 0 ? (
                <RectangularJobCardGroup
                  items={currentJobRoles}
                  onClickViewDetails={onClickViewDetails}
                  companyColor={company?.color}
                />
              ) : (
                <JobCardPlaceholder />
              )}
              {($isAddRoleShowing.value || $isAddAmbitionShowing.value) && (
                <AddRoleModal
                  jobProfiles={jobProfilesForAddRoleModal}
                  onClose={onCloseRoleModal}
                  save={addRoles}
                />
              )}
            </Content>
            {$showRecommendedPath.value && (
              <>
                <UserAvatarWrapper className="placeholder">
                  <HorizontalLine className="horizontal-line" />
                  <VerticalLine className="vertical-line" />
                </UserAvatarWrapper>
                <Content>
                  <CareerPathComponent
                    title="Recommended"
                    subtitle="Based on your current role"
                    companyColor={company?.color}
                    careerPaths={recommendedCareerPaths || []}
                    backgroundColor={COLORS.IN_PROGRESS_LIGHT}
                    textColor={COLORS.IN_PROGRESS}
                    onClickViewDetails={onClickViewDetails}
                    handleLikeButtonClick={handleLikeButtonClick}
                  />
                </Content>
              </>
            )}
            {$showVerticalPath.value && (
              <>
                <UserAvatarWrapper className="placeholder">
                  <HorizontalLine className="horizontal-line" />
                  <VerticalLine className="vertical-line" />
                </UserAvatarWrapper>
                <Content>
                  <CareerPathComponent
                    title="Vertical moves"
                    subtitle="Ready for your next career step?"
                    companyColor={company?.color}
                    careerPaths={verticalCareerPaths || []}
                    textColor={COLORS.GOALS_PINK}
                    backgroundColor={COLORS.GOALS_OKRS_LIGHT}
                    onClickViewDetails={onClickViewDetails}
                    handleLikeButtonClick={handleLikeButtonClick}
                  />
                </Content>
              </>
            )}
            {isCustomPathsModuleEnabled && (
              <>
                <UserAvatarWrapper className="placeholder">
                  <HorizontalLine className="horizontal-line" top={44} />
                  <VerticalLine className="vertical-line" />
                </UserAvatarWrapper>
                <Content>
                  <CustomPathSection
                    companyColor={company?.color}
                    items={likes}
                    refresh={refresh}
                    jobProfiles={jobProfilesForAddRoleModal}
                  />
                </Content>
              </>
            )}
          </ContentContainer>
        </ShowSpinnerIfLoading>
      </BaseLayout>
    </>
  );
}

export { UserCareer };
