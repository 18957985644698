import React, { useEffect, useState } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
  ROLES,
} from '@learned/constants';
import { IReportCustomReport } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { DashboardItemType } from 'src/pages/CommonReportsDashboard.tsx/types';
import { TData } from 'src/pages/Reports/types';
import styled from 'styled-components';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonVariant } from '~/components/Buttons';
import { CircularProgressCard } from '~/components/CircularProgressCard';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Card, { ETypes, TBarChart } from '~/components/Reports/DashboardCharts/Card';
import { getHeatmapColors } from '~/components/TableGrid/utils';

import routes from '~/constants/routes';
import { useAsyncFetch } from '~/hooks/useAsyncFetch';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser, getCustomReports, getSettingsRole } from '~/selectors/baseGetters';
import {
  getEngagementCharts,
  getEngagementDetails,
  IChartReportRequest,
  TEngagementReportResponse,
  TGetEngagementDetailsPayload,
} from '~/services/reports';
import { fetchReviewById } from '~/services/reviews';
import { getCustomReports as getReportsAction } from '~/store/customReports/actions';
import { COLORS } from '~/styles';
import { getTopAndBottomNElements } from '~/utils/reports';
// import { getMonthRangeFromDates } from '~/utils/reports';

const ResultsTabWrapper = styled.div`
  max-width: 1000px;
  margin: 24px auto 0;
  display: flex;
  flex-direction: column;
`;

const CardsSectionWrapper = styled.div<{ $size: number }>`
  max-width: 1000px;
  margin-top: 24px;
  display: grid;
  grid-gap: 32px;
  grid-template-columns: ${(props) => `repeat(${props.$size}, minmax(312px, 484px))`};
`;

const ResultsTab = () => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;
  const [rsdBannerVisibilityData, setRsdBannerVisibilityData] = useLocalStorage(
    LS_KEYS.LS_REVIEW_CYCLE_BANNER_VISIBILITY,
    { hideBannerFor: [] }, // array element structure is userId:reviewCycle
  );
  const [getRatingsDataPayload, setGetRatingsDataPayload] =
    useState<TGetEngagementDetailsPayload>();
  const [getChartsDataPayload, setGetChartsDataPayload] = useState<IChartReportRequest>();
  const [reportId, setReportId] = useState<string | undefined>();
  const customReports = useSelector(getCustomReports) as IReportCustomReport[];
  const userRole = useSelector(getSettingsRole);
  const dispatch = useDispatch();
  const [isBannerHovered, setIsBannerHovered] = useState(false);
  const currentUser = useSelector(getUser);

  const [averageValue, setAverageValue] = useState<number>(0);
  const [top3Teams, setTop3Teams] = useState<TBarChart[]>([]);
  const [bottom3Teams, setBottom3Teams] = useState<TBarChart[]>([]);

  const [review] = useAsyncFetch(
    {
      fetch: async () => {
        const reviewData = await fetchReviewById(reviewId);
        const review = reviewData.data.review as {
          settings: { startDate: string; endDate: string };
        };

        return review;
      },
      initialState: undefined,
    },
    [reviewId],
  );

  useEffect(() => {
    if (!review) {
      return;
    }

    // TODO: once the development is done, you can replace hard coded data with the commented data.
    //  const DateRange = getMonthRangeFromDates(review?.settings.startDate, review?.settings.endDate);
    const dateRange = {
      start: '2020-10-01',
      end: '2024-10-31',
    };

    const filters = {
      themes: [],
      teams: [],
      surveys: [],
      jobs: [],
      jobGroups: [],
      genders: [],
      ageGroups: [],
      educationLevels: [],
      reviews: [reviewId],
      skills: [],
      skillCategories: [],
      members: [],
    };

    const getRatingsDataPayload: TGetEngagementDetailsPayload = {
      viewAs: userRole,
      reportType: REPORT_TYPES.PERFORMANCE,
      additionalDimensions: [ENGAGEMENT_REPORT_CHART_DIMENSIONS.MEMBER],
      primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM,
      secondaryDimension: '',
      measure: ENGAGEMENT_REPORT_CHART_DIMENSIONS.THEME,
      filters,
      dateRange,
      options: {},
    };

    setGetRatingsDataPayload(getRatingsDataPayload);

    const getChartsDataPayload: IChartReportRequest = {
      viewAs: userRole,
      reportType: REPORT_TYPES.PERFORMANCE,
      chartType: REPORT_CHART_TYPES.BY_ONE_DIMENSION,
      primaryDimension: ENGAGEMENT_REPORT_CHART_DIMENSIONS.TEAM,
      sorting: {
        // sort by high to low
        orderBy: 'secondary',
        order: 'desc',
      },
      filters,
      options: {
        includeCompanyAverage: true,
      },
      dateRange,
    };

    setGetChartsDataPayload(getChartsDataPayload);
  }, [review, reviewId, userRole]);

  useEffect(() => {
    if (!rsdBannerVisibilityData.isBannerVisible) {
      return;
    }
    dispatch(getReportsAction(false));
  }, [dispatch, rsdBannerVisibilityData.isBannerVisible]);

  useEffect(() => {
    if (customReports) {
      const tabId = customReports
        .find((cr) => cr.reportType === REPORT_TYPES.PERFORMANCE)
        ?.tabs.find((tab) => {
          return tab.isDefault && tab.name === 'Per team per theme';
        })?.tabId;
      if (tabId) {
        setReportId(tabId);
      }
    }
  }, [customReports]);

  const onGoToReportClick = (dashboardItem: DashboardItemType) => {
    switch (userRole) {
      case ROLES.ADMIN:
        routes.REPORTS_ADMIN_CUSTOM_REPORT.go(
          { role: ROLES.ADMIN },
          {
            reportType: dashboardItem.reportType,
            reportId: dashboardItem.reportId,
          },
        );
        break;
      case ROLES.COACH:
        routes.REPORTS_COACH_CUSTOM_REPORT.go(
          { role: ROLES.USER },
          {
            reportType: dashboardItem.reportType,
            reportId: dashboardItem.reportId,
          },
        );
        break;
      case ROLES.USER:
        routes.REPORTS_USER_CUSTOM_REPORT.go(
          { role: ROLES.USER },
          {
            reportType: dashboardItem.reportType,
            reportId: dashboardItem.reportId,
          },
        );
        break;
      default:
        break;
    }
  };

  const [data, _isLoading] = useAsyncFetch(
    {
      fetch: async () => {
        if (!getRatingsDataPayload) {
          return;
        }
        const heatMap = await getEngagementDetails(getRatingsDataPayload);
        const heatmapData = heatMap.data as TData;
        return heatmapData;
      },
      initialState: { query: '', columns: [], rows: [], total: 0 },
    },
    [getRatingsDataPayload],
  );

  const [chartsData, isChartsDataLoading] = useAsyncFetch(
    {
      fetch: async () => {
        if (!getChartsDataPayload) {
          return;
        }
        const chartData = await getEngagementCharts(getChartsDataPayload);
        const heatmapData = chartData.data;
        return heatmapData;
      },
      initialState: {
        dimensionAverage: [],
      } as Partial<TEngagementReportResponse>,
    },
    [getChartsDataPayload],
  );

  useEffect(() => {
    // average row is a generated row so that it has no id
    const average =
      chartsData?.dimensionAverage?.find((dimension) => dimension.id === '')?.value || 0;

    setAverageValue(parseFloat(average.toString()));

    const [top3Teams, bottom3Teams] = getTopAndBottomNElements<TBarChart>(
      // average row should be omitted
      chartsData?.dimensionAverage
        ?.filter((item) => item.id !== '')
        .map((item) => ({
          ...item,
          title: item.name,
          value: item.value || 0,
          deviation: item.deviation || 0,
        })) || [],
      3,
    );

    setTop3Teams(top3Teams);
    setBottom3Teams(bottom3Teams);
  }, [chartsData, chartsData?.dimensionAverage, data]);

  const backgroundColors = React.useMemo(
    () => getHeatmapColors(parseFloat(averageValue.toFixed(1))),
    [averageValue],
  );

  return (
    <ResultsTabWrapper>
      {!rsdBannerVisibilityData.hideBannerFor.includes(`${currentUser.id}:${reviewId}`) && (
        <Banner
          isBannerHovered={isBannerHovered}
          setIsBannerHovered={setIsBannerHovered}
          actions={
            <Button
              label={i18n._(t`Advanced reporting`)}
              onClick={() => {
                if (!reportId) {
                  return;
                }

                onGoToReportClick({
                  reportType: REPORT_TYPES.PERFORMANCE,
                  reportId,
                });
              }}
              iconSize={ICON_SIZES.MEDIUM}
              variant={ButtonVariant.SECONDARY}
            />
          }
          subTitle={i18n._(t`Choose different view types and ask Luca for to analyze the data and suggest
              improvements.`)}
          title={i18n._(t`Go to Advanced Reporting for more data analysis options`)}
          type={BANNER_TYPES.INFO}
          bannerHoverIcon={
            <Icon
              icon={ICONS.CLOSE}
              size={ICON_SIZES.SMALL}
              color={COLORS.WHITE}
              onClick={() => {
                setRsdBannerVisibilityData({
                  hideBannerFor: [
                    ...rsdBannerVisibilityData.hideBannerFor,
                    `${currentUser.id}:${reviewId}`,
                  ],
                });
              }}
            />
          }
        />
      )}
      <CardsSectionWrapper $size={3}>
        <CircularProgressCard
          title="Average rating"
          color={backgroundColors[1]}
          overrideText={parseFloat(averageValue.toFixed(1)) === 0 ? '-' : undefined}
          progress={parseFloat(averageValue.toFixed(1))}
          isLoading={isChartsDataLoading}
        />
        <Card
          title={i18n._(t`Top 3 teams`)}
          type={ETypes.BAR_CHART}
          data={top3Teams}
          isLoading={isChartsDataLoading}
        />
        <Card
          title={i18n._(t`Bottom 3 teams`)}
          type={ETypes.BAR_CHART}
          data={bottom3Teams}
          isLoading={isChartsDataLoading}
        />
      </CardsSectionWrapper>
    </ResultsTabWrapper>
  );
};

export { ResultsTab };
