import React, { Dispatch, SetStateAction, useState } from 'react';

import styled, { css } from 'styled-components';

import IconMenu from '~/components/IconMenu';

import type { IColumnTable } from '~/@types/table';
import { COLORS } from '~/styles';

import { TD, TableWrapper, IconMenuWrapper } from '../../design';
import { TSecondaryHeaderColumn } from '../../types';
import { Header } from '../Header';

export type TMultiSelect = {
  checkedCount: number;
  isAllChecked: boolean;
  onCheckAll: () => void;
  onSelectItem: (item: any) => void;
  isItemChecked: (item: any) => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
};

interface IFixedTableProps<T> {
  columns: IColumnTable<any>[];
  data: Array<T>;
  onRowClick?: (item: any) => void;
  sortBy?: string;
  setSortBy?: Dispatch<SetStateAction<string | any>>;
  isMenuVisible?: boolean;
  createMenuItems?: (item: any) => void;
  onColClick?: {
    column: string;
    onClick: (item: any) => void;
  };
  isScrollbarVisible?: boolean;
  isStriped?: boolean;
  minWidth?: string;
  thColumnPadding?: string;
  showSortBy?: boolean;
  enableMultipleTableHeaderRows?: boolean;
  secondaryHeaderColumns?: TSecondaryHeaderColumn[];
}

const Wrapper = styled.div<{ isScrollbarVisible?: boolean; minWidth?: string }>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth || 'unset'};

  ${({ isScrollbarVisible }) =>
    isScrollbarVisible
      ? css`
          overflow-x: scroll;
          &::-webkit-scrollbar {
            height: 8px;
            position: relative;
          }

          &::-webkit-scrollbar-track {
            background: ${COLORS.BG_ELEMENTS};
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--company-color);
            border-radius: 100px;
          }

          &::-webkit-scrollbar:hover {
            cursor: pointer;
          }

          transform: rotateX(180deg);
        `
      : css`
          &::-webkit-scrollbar {
            display: none;
          }
        `}
`;

const FixedTable = ({
  columns,
  data,
  onRowClick,
  sortBy,
  setSortBy,
  isMenuVisible,
  createMenuItems,
  onColClick,
  isScrollbarVisible = true,
  isStriped,
  minWidth,
  thColumnPadding,
  showSortBy,
  enableMultipleTableHeaderRows,
  secondaryHeaderColumns,
}: IFixedTableProps<object & { id: string; status?: string }>) => {
  const [hovered, setHovered] = useState(-1);
  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  return (
    <Wrapper isScrollbarVisible={isScrollbarVisible} minWidth={minWidth}>
      <TableWrapper
        isScrollbarVisible={isScrollbarVisible}
        $isPointer={Boolean(onRowClick)}
        isStriped={isStriped}
      >
        <Header
          columns={columns}
          sortProps={sortProps}
          thColumnPadding={thColumnPadding}
          showSortBy={showSortBy}
          enableMultipleTableHeaderRows={enableMultipleTableHeaderRows}
          secondaryHeaderColumns={secondaryHeaderColumns}
        />
        <tbody>
          {data.map((item, i) => {
            return (
              <tr
                onMouseEnter={() => setHovered(i)}
                onMouseLeave={() => setHovered(-1)}
                key={JSON.stringify({ item, i })}
                onClick={() => onRowClick && onRowClick(item)}
                role="link"
              >
                {columns.map((column) => {
                  const isCallWithClick = !!onColClick && onColClick.column === column.accessor;

                  let renderedCellOutput = null;
                  if (column.renderCell) {
                    renderedCellOutput = column.renderCell(
                      item,
                      isCallWithClick ? onColClick?.onClick : undefined,
                      hovered === i,
                    );
                  }

                  if (Array.isArray(renderedCellOutput)) {
                    return renderedCellOutput.map((cell, index) => {
                      return (
                        <TD
                          key={`td-${column.accessor}-${index}`}
                          maxWidth={column.maxWidth}
                          minWidth={column.minWidth}
                          {...(column.renderCell && { padding: column.padding })}
                        >
                          <>{cell}</>
                        </TD>
                      );
                    });
                  } else {
                    return (
                      <TD
                        key={`td-${column.accessor}`}
                        maxWidth={column.maxWidth}
                        minWidth={column.minWidth}
                        {...(column.renderCell && { padding: column.padding })}
                      >
                        {column.renderCell ? (
                          <>{renderedCellOutput}</>
                        ) : (
                          <>
                            {/* @ts-ignore */}
                            {item?.[column.accessor]}
                          </>
                        )}
                      </TD>
                    );
                  }
                })}
                {isMenuVisible && (
                  <TD minWidth="26px" padding="16px 26px 16px 0">
                    <IconMenuWrapper>
                      {isMenuVisible && Boolean(createMenuItems) && (
                        <IconMenu
                          // @ts-ignore
                          items={createMenuItems(item)}
                        />
                      )}
                    </IconMenuWrapper>
                  </TD>
                )}
              </tr>
            );
          })}
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};

export { FixedTable };
