import React, { useState } from 'react';

import { REVIEW_RATING_STATUS, REVIEW_RATING_TYPE } from '@learned/constants';
import { IReviewQuestionDefaultData, IReviewRating } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import ToolTip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';
import type { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';

import {
  Answer,
  AnswerContainer,
  AnswerPlaceholder,
  AnswerWrapper,
  RatingWrapper,
  Row,
  UserContainer,
  Rating,
  Oval,
  Arrow,
  ExpandedWrapper,
  Label,
  Comment,
  LabelWrapper,
} from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { AvatarWrapper } from '../Avatar';
import { NotSharedLabel } from '../NotSharedLabel';

type Rating = IReviewRating & { id?: string; reviewType: string; isOpened: boolean };

function RatingAnswers({
  question,
}: {
  question: Omit<IQuestionDefaultData, 'question'> & {
    reviewRatings?: (IReviewRating & { reviewType: string })[];
    settings?: IReviewQuestionDefaultData;
  };
}) {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const [answers, setAnswers] = useState<Rating[]>(
    question.reviewRatings?.map((answer, index) => {
      return {
        ...answer,
        isOpened: question.reviewRatings && question.reviewRatings.length > 0 ? index === 0 : true,
      };
    }) || [],
  );

  const options = (question?.settings as IReviewQuestionDefaultData)?.options || [];

  const onClick = (questionId: number) => {
    setAnswers(
      answers.map((answer, index) => {
        if (questionId === index) {
          return {
            ...answer,
            isOpened: !answer.isOpened,
          };
        }
        return answer;
      }),
    );
  };

  const getRatingColor = (type: REVIEW_RATING_TYPE) => {
    switch (type) {
      case REVIEW_RATING_TYPE.SELF:
        return {
          dark: COLORS.REVIEW_SELF,
          light: COLORS.BG_LIST,
          default: COLORS.SELF_REVIEW,
        };
      case REVIEW_RATING_TYPE.COACH:
        return {
          dark: COLORS.REVIEW_COACH,
          light: COLORS.BG_LIST,
          default: COLORS.COACH_REVIEW,
        };
      case REVIEW_RATING_TYPE.PEER:
      case REVIEW_RATING_TYPE.PEER_EMAIL:
        return {
          dark: COLORS.PEER_REVIEW_DARK,
          light: COLORS.BG_LIST,
          default: COLORS.PEER_REVIEW,
        };
    }
  };

  const getMarginRight = (answer?: string | number | null | undefined) => {
    return options.length > 4
      ? 25 * (options.length - (answer as number)) + 10
      : (130 / options.length) * (options.length - (answer as number));
  };

  return (
    <>
      {answers?.map((rating, index) => {
        const ratingColor = getRatingColor(rating.type);
        return (
          <Row key={index}>
            <AnswerContainer clickable onClick={() => onClick(index)} $answered={!!rating?.answer}>
              <UserContainer>
                <Icon
                  transform={rating.isOpened ? 'rotate(90)' : 'rotate(0)'}
                  size={ICON_SIZES.MEDIUM}
                  icon={ICONS.NEXT}
                  color={rating.isOpened ? COLORS.COMPANY : COLORS.INACTIVE}
                />
                <div>
                  <AvatarWrapper
                    key={index}
                    id={rating.createdBy?.id}
                    answer={rating?.answer as string}
                    email={rating.createdBy?.email}
                    reviewType={rating.reviewType}
                  />
                </div>
              </UserContainer>

              <>
                <LabelWrapper $answered={!!rating?.answer}>
                  {rating.status === REVIEW_RATING_STATUS.CALIBRATE && (
                    <ToolTip
                      key={index}
                      placement={TOOLTIP_PLACEMENTS.TOP}
                      tooltip={i18n._(t`This input is not yet shared with the employee`)}
                    >
                      <NotSharedLabel />
                    </ToolTip>
                  )}
                </LabelWrapper>
              </>
              <RatingWrapper
                $answered={!!rating?.answer}
                maxWidth={options.length > 4 ? options.length * 25 : 130}
              >
                {options.map((_, index) => (
                  <Rating
                    key={index}
                    selected={index + 1 === rating.answer}
                    default={ratingColor?.default}
                    dark={ratingColor?.dark}
                  >
                    {index + 1 === rating.answer && <Oval />}
                  </Rating>
                ))}
              </RatingWrapper>
            </AnswerContainer>
            {rating.isOpened && (
              <AnswerWrapper>
                {rating?.answer && Number(rating?.answer) >= 0 ? (
                  <ExpandedWrapper>
                    <Arrow
                      background={ratingColor.light}
                      marginRight={getMarginRight(rating.answer)}
                    />
                    <Answer background={ratingColor.light}>
                      <Label>
                        <Trans>
                          {rating.answer}
                          <span>/{options.length}</span> -{' '}
                          {getMultiLangString(options[index].label)}
                        </Trans>
                      </Label>
                      {rating.comment && (
                        <Comment>
                          <Trans>{getMultiLangString(rating.comment)}</Trans>
                        </Comment>
                      )}
                    </Answer>
                  </ExpandedWrapper>
                ) : (
                  <AnswerPlaceholder>
                    {rating?.answer === null ? (
                      <Trans>Answered this question with N/A</Trans>
                    ) : (
                      <Trans>No answer provided yet</Trans>
                    )}
                  </AnswerPlaceholder>
                )}
              </AnswerWrapper>
            )}
          </Row>
        );
      })}
    </>
  );
}

export { RatingAnswers };
