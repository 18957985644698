import {
  IFile,
  IGetUserReviewsByIdRequest,
  IGetUserReviewsRequest,
  IUserReview,
} from '@learned/types';

import { IServerRoute, IGetServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';

import cloudRequest from '../cloudRequest';

export function getUserReviews(
  body: IGetUserReviewsRequest,
  { signal }: { signal?: AbortSignal } = {},
) {
  // @ts-ignore
  return cloudRequest(serverRoutes.userReviews.items as IServerRoute, {}, body, { signal });
}

export function getUserReview(userReviewId: IUserReview['id'], data?: IGetUserReviewsByIdRequest) {
  return cloudRequest((serverRoutes.userReviews.item as IGetServerRoute)(userReviewId), {}, data);
}

export function deleteUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function archiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.archiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function unarchiveUserReviewById(userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.unarchiveById as IGetServerRoute)(userReviewId),
    {},
    {},
  );
}

export function updatePeers(
  userReviewId: IUserReview['id'],
  data: {
    delete: IUserReview['peers'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updatePeers as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function updateCoaches(
  userReviewId: IUserReview['id'],
  data: {
    add?: IUserReview['coaches'];
    delete?: IUserReview['coaches'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updateCoaches as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function updateGuests(
  userReviewId: IUserReview['id'],
  data: {
    add?: IUserReview['guests'];
    delete?: IUserReview['guests'];
  },
) {
  return cloudRequest(
    (serverRoutes.userReviews.updateGuests as IGetServerRoute)(userReviewId),
    {},
    data,
  );
}

export function deleteUserReviewAttachment(fileId: IFile['id'], userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.deleteUserReviewAttachment as IGetServerRoute)(userReviewId),
    {
      file: fileId,
    },
  );
}

export function uploadUserReviewAttachment(file: File, userReviewId: IUserReview['id']) {
  return cloudRequest(
    (serverRoutes.userReviews.uploadUserReviewAttachment as IGetServerRoute)(userReviewId),
    {},
    { file },
    // @ts-ignore
    { sendAsFormData: true },
  );
}
