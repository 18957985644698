import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { ITask } from '@learned/types';
import { Trans } from '@lingui/macro';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { UserAvatar } from '~/components/UserAvatar';
import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';
import { SelectCoachesModal } from '~/pages/Reviews/Modals/SelectCoachesModal';
import { SelectGuestsModal } from '~/pages/Reviews/Modals/SelectGuestsModal';

import { Separator, AvatarWrapper } from './design';
import { useUserReview } from './hooks/useUserReview';
import { DeleteSection } from './sections/Delete';
import { GuestsSection } from './sections/Guests';
import { ProvideCoachInputSection } from './sections/ProvideCoachInput';
import { ProvidePeerInputSection } from './sections/ProvidePeerInput';
import { ReceivedInputSection } from './sections/ReceivedInput';
import { SelfReviewTasksSection } from './sections/SelfReviewTasks';
import { SignaturesSection } from './sections/Signatures';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';

import type { IReviewEmployeeOverviewForm } from './types';
import type { IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface IReviewParticipantModalProps {
  userReviewId: IUserReview['id'];
  tasksTypes: ITask['type'][];
  formMethods: UseFormReturn<IReviewEmployeeOverviewForm>;
  languageState: ILanguageStateReturn;
  onClose: (isRefresh?: boolean) => void;
}

const ReviewEmployeeOverviewForm = ({
  userReviewId,
  tasksTypes,
  formMethods,
  languageState: _languageState,
  onClose,
}: IReviewParticipantModalProps) => {
  const {
    isLoading,
    isAllowToDelete,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests,
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests,
    onDelete,
    onDeletePeer,
    onDeleteCoach,
    onDeleteGuest,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    onAddCoaches,
    showSelectGuestsModal,
    openSelectGuestsModal,
    closeSelectGuestsModal,
    onAddGuests,
    isEmployeeHasCoachRole,
  } = useUserReview({
    formMethods,
    userReviewId,
    onClose,
  });

  const { watch } = formMethods;
  const nominatePeersTask = watch('taskNominatePeers');
  const employee = watch('employee');
  const isSelfTasks =
    tasksTypes.includes(TASK_TYPE.REVIEW_SELF_EVALUATE) ||
    tasksTypes.includes(TASK_TYPE.REVIEW_PEER_NOMINATE);
  const isPeerInputTasks = tasksTypes.includes(TASK_TYPE.REVIEW_PEER_EVALUATE);
  const isCoachInputTasks = tasksTypes.includes(TASK_TYPE.REVIEW_COACH_EVALUATE);
  const isReceiveInputTasks = isPeerInputTasks || isCoachInputTasks;
  const isProvidePeerInput = isPeerInputTasks; // keep it like this to have more flexibility in future
  const isProvideCoachInput = isCoachInputTasks && isEmployeeHasCoachRole;

  return (
    <>
      <Modal
        onClose={() => onClose(false)}
        width={500}
        minWidth={500}
        height={'100%'}
        margin={'32px auto'}
        title={
          <div>
            <Trans>Employee overview</Trans>
            {employee && (
              <AvatarWrapper>
                <UserAvatar user={employee} />
              </AvatarWrapper>
            )}
          </div>
        }
        headerStyles={{ padding: '23px 29px 15px 34px' }}
        contentStyles={{ padding: '23px 29px 15px 34px', margin: '0', height: '100%' }}
        footerRight={
          <Button
            onClick={() => onClose(false)}
            label={<Trans>Cancel</Trans>}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
        }
      >
        <ShowSpinnerIfLoading loading={isLoading}>
          {isSelfTasks && (
            <SelfReviewTasksSection tasks={watch('tasksSelf')} formMethods={formMethods} />
          )}
          {isReceiveInputTasks && (
            <ReceivedInputSection
              tasksPeers={watch('tasksReceivedPeers')}
              tasksCoaches={watch('tasksReceivedCoaches')}
              isPeerInputTasks={isPeerInputTasks}
              isCoachInputTasks={isCoachInputTasks}
              formMethods={formMethods}
              isAllowToDeletePeers={isAllowToDeletePeers}
              isAllowToDeleteCoaches={isAllowToDeleteCoaches}
              isAllowToAddPeers={isAllowToAddPeers}
              isAllowToAddCoaches={isAllowToAddCoaches}
              onDeletePeer={onDeletePeer}
              onDeleteCoach={onDeleteCoach}
              onAddPeers={openNominatePeersModal}
              onAddCoaches={openSelectCoachesModal}
            />
          )}
          <GuestsSection
            guests={watch('guests')}
            coaches={watch('coaches')}
            onDeleteGuest={onDeleteGuest}
            onAddGuests={openSelectGuestsModal}
            isAllowToDeleteGuests={isAllowToDeleteGuests}
            isAllowToAddGuests={isAllowToAddGuests}
          />
          {watch('settings').isDigitalSign && <SignaturesSection formMethods={formMethods} />}
          <Separator />
          {isProvidePeerInput && (
            <ProvidePeerInputSection
              tasks={watch('tasksProvidedPeers')}
              formMethods={formMethods}
            />
          )}
          {isProvideCoachInput && (
            <ProvideCoachInputSection
              tasks={watch('tasksProvidedCoaches')}
              formMethods={formMethods}
            />
          )}
          {isAllowToDelete && <Separator />}
          {isAllowToDelete && <DeleteSection onDelete={onDelete} formMethods={formMethods} />}
        </ShowSpinnerIfLoading>
      </Modal>
      {showNominatePeersModal && nominatePeersTask && (
        <NominatePeers taskId={nominatePeersTask.id} closeModal={closeNominatePeersModal} />
      )}
      {showSelectCoachesModal && (
        <SelectCoachesModal
          userId={employee?.id}
          onSave={onAddCoaches}
          onClose={closeSelectCoachesModal}
          usersToHide={[employee?.id, ...watch('coaches')].filter((i) => i)}
        />
      )}
      {showSelectGuestsModal && (
        <SelectGuestsModal
          onSave={onAddGuests}
          onClose={closeSelectGuestsModal}
          usersToHide={[employee?.id, ...watch('guests')].filter((i) => i)}
        />
      )}
    </>
  );
};

export { ReviewEmployeeOverviewForm };
