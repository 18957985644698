import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { ReviewThemeSetup } from '~/pages/ReviewThemeSetup';
import { ReviewThemeView } from '~/pages/ReviewThemeView';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('review-themes', {
  routesPrivate: {
    user: [
      <PrivateRoute
        layout={FocusLayout}
        key={routes.REVIEW_THEME_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_THEME_UPDATE.routePath(ROLES.USER)}
        component={ReviewThemeSetup}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.REVIEW_THEME_VIEW.routePath(ROLES.USER)}
        exact
        path={routes.REVIEW_THEME_VIEW.routePath(ROLES.USER)}
        component={ReviewThemeView}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
