import React, { useState, useRef, useEffect } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ICONS, Icon } from '~/components/Icon';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ESort } from '~/pages/Reports/types';

import {
  TableCtr,
  LeftCtr,
  DataRaw,
  NumberLineBorder,
  RowTitle,
  RightCtr,
  PrimaryDimension,
  SecondaryDimension,
  SortIcons,
  DataCtr,
  ItemNr,
  DataCtrBackground,
  DataMultiCtr,
  DataMultiCtrBackground,
  DataMultiOuterCtr,
  Numbers,
  Line,
} from './HorizontalBarChart.design';

import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';
import { handleTooltipData } from '~/utils/reports';

import { GraphTooltip } from '../components/GraphTooltip';
import { TTotalGraphData } from '../types';

const BAR_HEIGHT = 30;
const BAR_MAX_HEIGHT = 40;
const barSize = 20;

const gaps = Array.from({ length: 100 / barSize + 1 }, (_, i) => i * barSize);

const numberLine = () => {
  return gaps.map((i) => (
    <Numbers key={`line-${i + 1}`} leftAlign={`${i}%`}>
      {i}
    </Numbers>
  ));
};

const barLines = () => {
  return gaps.map((i) => <Line key={`line-${i + 1}`} leftAlign={`${i}%`} />);
};

const getMultiComponent = (
  item: TTotalGraphData,
  isActive: boolean,
  colors: string[],
  showSelf = false,
  showPeer = false,
) => {
  if (item.benchmark || item.team || item.self || item.peer) {
    return (
      <DataMultiOuterCtr mHeight={`${BAR_MAX_HEIGHT}px`}>
        {!!item?.value && (
          <DataMultiCtr
            barWidth={`${toFixed(item.value || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.value || 0, 2)}%`}
          >
            <DataMultiCtrBackground
              isActive={isActive}
              bgClr={colors[0] || COLORS.CONFIRMATION_MODAL_INFO}
            />
          </DataMultiCtr>
        )}
        {showSelf && !!item?.self && (
          <DataMultiCtr
            barWidth={`${toFixed(item.self || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.self || 0, 2)}%`}
          >
            <DataMultiCtrBackground
              isActive={isActive}
              bgClr={colors[1] || COLORS.CONFIRMATION_MODAL_INFO}
            />
          </DataMultiCtr>
        )}
        {showPeer && !!item?.peer && (
          <DataMultiCtr
            barWidth={`${toFixed(item.peer || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.peer || 0, 2)}%`}
          >
            <DataMultiCtrBackground
              isActive={isActive}
              bgClr={colors[2] || COLORS.CONFIRMATION_MODAL_INFO}
            />
          </DataMultiCtr>
        )}
        {!!item?.team && (
          <DataMultiCtr
            barWidth={`${toFixed(item.team || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.team || 0, 2)}%`}
          >
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.ACCENT_WARNING} />
          </DataMultiCtr>
        )}
        {!!item?.benchmark && (
          <DataMultiCtr
            barWidth={`${toFixed(item.benchmark || 0, 2)}%`}
            deviation={toFixed(item.deviation || 0, 2)}
            isActive={isActive}
            value={`${toFixed(item.benchmark || 0, 2)}%`}
          >
            <DataMultiCtrBackground isActive={isActive} bgClr={COLORS.COMPANY} />
          </DataMultiCtr>
        )}
      </DataMultiOuterCtr>
    );
  }
  return (
    <DataCtr
      barWidth={`${toFixed(item.value, 2)}%`}
      mHeight={`${BAR_HEIGHT}px`}
      isActive={isActive}
      deviation={toFixed(item.deviation || 0, 2)}
    >
      <DataCtrBackground isActive={isActive} />
      <ItemNr isActive={isActive}>{toFixed(item.value, 2)}%</ItemNr>
    </DataCtr>
  );
};

interface IHorizontalBarChartProps {
  setShowSortedMenu: (value: ESort) => void;
  showSortedMenu: ESort | null;
  sortedBy?: string;
  getSortMenu: () => JSX.Element;
  data: TTotalGraphData[];
  handleDataRowClick: (item: TTotalGraphData) => void;
  // coach, self, peer colors
  colors: string[];
  showSelf?: boolean;
  showPeer?: boolean;
  tooltipTitle?: string;
  areGeneratedRowsClickable?: boolean;
}

const TOOLTIP_OFFSET_X = 20;
const TOOLTIP_OFFSET_Y = -20;

const HorizontalBarChart: React.FC<IHorizontalBarChartProps> = ({
  setShowSortedMenu,
  sortedBy,
  getSortMenu,
  showSortedMenu,
  data,
  handleDataRowClick,
  colors,
  showSelf = false,
  showPeer = false,
  tooltipTitle,
  areGeneratedRowsClickable = false,
}) => {
  const [activeRow, setActiveRow] = useState<null | number>(null);
  const [activeItem, setActiveItem] = useState<null | TTotalGraphData>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  const { i18n } = useLingui();
  const toolRef = useRef<HTMLDivElement>(null);

  const additionalDimensionCount = [showSelf, showPeer].filter((a) => !!a).length;

  useEffect(() => {
    if (!toolRef.current) {
      return;
    }

    toolRef.current.style.visibility = 'visible';
    toolRef.current.style.left = `${tooltipPosition.x}px`;
    toolRef.current.style.top = `${tooltipPosition.y}px`;

    if (!activeItem) {
      toolRef.current.style.visibility = 'hidden';
      return;
    }

    const tooltipValues = [
      {
        value: activeItem.value || 0,
        deviation: activeItem.deviation || 0,
        label: 'Coach review',
        color: colors[0], // primary color should be company
      },
    ];

    if (showSelf) {
      tooltipValues.push({
        value: activeItem.self || 0,
        deviation: activeItem.selfDeviation || 0,
        label: 'Self review',
        color: colors[1],
      });
    }

    if (showPeer) {
      tooltipValues.push({
        value: activeItem.peer || 0,
        deviation: activeItem.peerDeviation || 0,
        label: 'Peer review',
        color: colors[2],
      });
    }

    // lets use the data feeder
    handleTooltipData(toolRef.current, {
      title: activeItem?.name || '',
      values: tooltipValues,
    });
  }, [activeItem, colors, showPeer, showSelf, tooltipPosition.x, tooltipPosition.y]);

  return (
    <TableCtr>
      <LeftCtr>
        <PrimaryDimension
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            setShowSortedMenu(ESort.primary);
          }}
        >
          {i18n._(t`Primary dimension`)}
          <SortIcons>
            <Icon
              icon={ICONS.SORT_UP}
              width={9}
              height={7}
              color={sortedBy?.includes('primary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS}
            />
            <Icon
              icon={ICONS.SORT_DOWN}
              width={9}
              height={7}
              color={sortedBy?.includes('primary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS}
            />
          </SortIcons>
          {showSortedMenu === ESort.primary && getSortMenu()}
        </PrimaryDimension>
        {data.map((item, i) => (
          <RowTitle
            key={`data-${i + 1}`}
            isActive={activeRow === i}
            onMouseEnter={() => setActiveRow(i)}
            onMouseLeave={() => setActiveRow(null)}
            isClickable={!!item.id || areGeneratedRowsClickable}
            onClick={() => handleDataRowClick(item)}
            mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
          >
            <Tooltip tooltip={i18n._(t`${item.name}`)} size={TOOLTIP_SIZES.BIG}>
              <div>{i18n._(t`${item.name}`)}</div>
            </Tooltip>
          </RowTitle>
        ))}
      </LeftCtr>
      <RightCtr>
        <SecondaryDimension
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();
            setShowSortedMenu(ESort.secondary);
          }}
        >
          {i18n._(t`Average`)}
          <SortIcons>
            <Icon
              icon={ICONS.SORT_UP}
              width={9}
              height={7}
              color={sortedBy?.includes('secondary__asc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS}
            />
            <Icon
              icon={ICONS.SORT_DOWN}
              width={9}
              height={7}
              color={sortedBy?.includes('secondary__desc') ? COLORS.COMPANY : COLORS.PLACEHOLDERS}
            />
          </SortIcons>
          {showSortedMenu === ESort.secondary && getSortMenu()}
        </SecondaryDimension>

        {data.map((item, i) => (
          <DataRaw
            key={`data-${i + 1}`}
            onMouseEnter={(e) => {
              const parentElement = e.currentTarget.parentElement;
              if (parentElement) {
                const parentRect = parentElement.getBoundingClientRect();
                const relativeX = e.clientX - parentRect.left;
                const relativeY = e.clientY - parentRect.top;

                setTooltipPosition({
                  x: relativeX + TOOLTIP_OFFSET_X,
                  y: relativeY + TOOLTIP_OFFSET_Y,
                });
              }
              setActiveItem(item);
              setActiveRow(i);
            }}
            onMouseLeave={() => {
              setActiveItem(null);
              setActiveRow(null);
            }}
            isClickable={!!item.id || areGeneratedRowsClickable}
            isActive={activeRow === i}
            mHeight={Array.isArray(item.value) ? `${BAR_MAX_HEIGHT}px` : `${BAR_HEIGHT}px`}
            onClick={() => handleDataRowClick(item)}
          >
            {getMultiComponent(item, activeRow === i, colors, showSelf, showPeer)}
          </DataRaw>
        ))}
        <GraphTooltip
          ref={toolRef}
          tooltipTitle={tooltipTitle}
          additionalDataGroupsLength={additionalDimensionCount}
        />

        {numberLine()}
        {barLines()}
        <NumberLineBorder />
      </RightCtr>
    </TableCtr>
  );
};

export default HorizontalBarChart;
