import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import styled from 'styled-components';

import { DeadlineLabel } from '~/components/DeadlineLabel';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { TASK_STATUS_ITEMS } from '~/constants/tasks';

import type { ITask } from '@learned/types';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

interface ITaskStatusProps {
  task: ITask;
}

const TaskDeadline = ({ task }: ITaskStatusProps) => {
  const item = task.status && TASK_STATUS_ITEMS[task.status];

  return (
    <Wrapper>
      <StyledIcon
        icon={item.icon as ICONS}
        size={ICON_SIZES.SMALL}
        rotate={item.key === TASK_STATUS.TODO ? -90 : 0}
        color={item.color}
      />
      <DeadlineLabel deadline={task.deadline as unknown as string} />
    </Wrapper>
  );
};

export { TaskDeadline };
