import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Dropdown } from '~/components/Dropdown';

import { TASK_STATUS_ITEMS } from '~/constants/tasks';
import { IMultiSelectOption } from '~/constants/userReviews';

export interface IFilters {
  isShowFilters: boolean;
  search: string;
  setSearch: (value: string) => void;
  onChangeFilter: (key: string, value: any) => void;
  resetFilters: () => void;
  selectedStatus: IMultiSelectOption[];
}

const Filters = ({ filters }: { filters: IFilters }) => {
  const { i18n } = useLingui();

  // order in status filter
  const statusItems = [
    TASK_STATUS.UPCOMING,
    TASK_STATUS.TODO,
    TASK_STATUS.ACTIVE,
    TASK_STATUS.CALIBRATE,
    TASK_STATUS.COMPLETED,
    TASK_STATUS.DECLINED,
    TASK_STATUS.EXPIRED,
  ].map((key) => {
    const status = TASK_STATUS_ITEMS[key];
    return {
      id: status.key,
      key: status.key,
      name: status.key,
      translated: status.text,
    };
  });

  return (
    <>
      <Dropdown
        placeholder={i18n._(t`Status`)}
        selectedItems={filters.selectedStatus || []}
        items={statusItems}
        onChange={(selectedItems?: IMultiSelectOption[]) => {
          filters.onChangeFilter('selectedStatus', selectedItems || []);
        }}
        stringifyItem={(item) => item.translated(i18n)}
        isSingleSelect={false}
        skipSort={true}
      />
    </>
  );
};

export { Filters };
