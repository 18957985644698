import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Block } from '../components/Block';
import { Text, RowWrapper } from '../design';

import type { IUserReview } from '@learned/types';

interface IPrivacyBlockProps {
  privacy: IUserReview['privacy'];
}

const PrivacyBlock = ({ privacy }: IPrivacyBlockProps) => {
  const { i18n } = useLingui();

  const isAllEmployeeCoachesCanSeeReview = privacy.isAllEmployeeCoachesCanSeeReview;
  const label = isAllEmployeeCoachesCanSeeReview
    ? i18n._(
        t`Employees, guests and all current and future coaches of the employee can view the review.`,
      )
    : i18n._(t`Employees, guests and coaches selected for coach review can see the review.`);

  return (
    <Block title={i18n._(t`Privacy`)}>
      <RowWrapper>
        <Text>{label}</Text>
      </RowWrapper>
    </Block>
  );
};

export { PrivacyBlock };
