import React, { useCallback, useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  TASK_FILTER_BY_USER_TYPE,
  TASK_STATUS,
  TASK_TYPE,
} from '@learned/constants';
import { ITaskWithId, ITask, IUserReview } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';

import { StyledTableList, TableWrapper } from './design';
import { Filters } from './filters';
import { COLUMNS } from './taskColumns';
import { IFilter } from './types';

import { TASKS_FILTER_TYPES } from '~/constants';
import routes from '~/constants/routes';
import { TASK_STATUS_OPTIONS } from '~/constants/tasks';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import {
  checkModuleReviews,
  checkModuleRTFeedbacks,
  checkModuleSurvey,
} from '~/selectors/baseGetters';
import { getTasks } from '~/services/tasks';
import getTaskURL from '~/utils/getTaskURL';

const PAGE_SIZE = 5;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY_ALL = LS_KEYS.LS_HOME_TAB_ALL;
const LS_KEY_EMPLOYEE = LS_KEYS.LS_HOME_TAB_EMPLOYEE;
const LS_KEY_COACH = LS_KEYS.LS_HOME_TAB_COACH;
const getLsKey = (type: TASK_FILTER_BY_USER_TYPE) => {
  switch (type) {
    case TASK_FILTER_BY_USER_TYPE.ALL:
      return LS_KEY_ALL;
    case TASK_FILTER_BY_USER_TYPE.COACH:
      return LS_KEY_COACH;
    case TASK_FILTER_BY_USER_TYPE.EMPLOYEE:
    default:
      return LS_KEY_EMPLOYEE;
  }
};

const initialFilters: IFilter = {
  isShowFilters: false,
  search: '',
  selectedStatus: TASK_STATUS_OPTIONS.filter(
    (option) =>
      ![
        TASK_STATUS.UPCOMING,
        TASK_STATUS.COMPLETED,
        TASK_STATUS.DECLINED,
        TASK_STATUS.EXPIRED,
      ].includes(option.key),
  ),
  pagination: DEFAULT_PAGINATION,
};

type TasksSectionProps = {
  type: TASK_FILTER_BY_USER_TYPE;
  isTab?: boolean;
};

const TasksSection = ({ type, isTab = true }: TasksSectionProps) => {
  const { search: searchFromQuery }: { search?: string } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const isModuleRTFeedbacksEnabled = useSelector(checkModuleRTFeedbacks);
  const isModuleReviewsEnabled = useSelector(checkModuleReviews);
  const isModuleSurveyEnabled = useSelector(checkModuleSurvey);
  const [currentFilters, setCurrentFilters] = useLocalStorage(getLsKey(type), {
    ...initialFilters,
    search: searchFromQuery || initialFilters.search,
  });

  const [totalTasksCount, setTotalTaskCount] = useState<number>(0);
  const [allTasks, setAllTasks] = useState<ITaskWithId[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { i18n } = useLingui();
  const history = useHistory();

  const debCurrentFilters = useDebounce(currentFilters, 500);

  const [showNominatePeersModal, setShowNominatePeersModal] = useState(false);
  const [selectedTask, setSelectedTask] = useState<ITaskWithId | undefined>();

  const fetchData = async (signal?: AbortSignal) => {
    setIsLoading(true);
    const data = await getTasks(
      {
        // @ts-ignore
        filterTaskTypes: [TASKS_FILTER_TYPES.TASKS.key],
        // @ts-ignore
        taskStatus: currentFilters.selectedStatus.map((option) => option.key) as TASK_STATUS[],
        search: currentFilters.search,
        limit: currentFilters.pagination.limit,
        skip: currentFilters.pagination.skip,
        userType: type,
      },
      { signal },
    );

    if (data) {
      setAllTasks(data.tasks);
      setTotalTaskCount(data.totals[API_RETURN_FIELDS.TOTAL_FILTERED_TASKS]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    if (isModuleSurveyEnabled || isModuleRTFeedbacksEnabled || isModuleReviewsEnabled) {
      fetchData(signal);
    }

    return () => {
      controller.abort(); // cancel the request on component unmount
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debCurrentFilters]);

  const filterCounter = () => {
    const filters = [currentFilters.search, currentFilters.selectedStatus];
    return filters.filter((item) => !isEmpty(item)).length || undefined;
  };

  const filters = {
    isShowFilters: currentFilters.isShowFilters,
    search: currentFilters.search,

    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilter) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),
    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters((state: any) => ({
        ...state,
        pagination: DEFAULT_PAGINATION,
        [key]: value,
      })),
    resetFilters: () => setCurrentFilters(initialFilters),
    selectedStatus: currentFilters.selectedStatus,

    filterCount: filterCounter(),
  };

  const navigateToTask = useCallback(
    (task: ITaskWithId) => {
      // Navigate to path
      const path = getTaskURL(task);
      if (!isEmpty(task)) {
        history.push(path!);
      }
    },
    [history],
  );

  const navigateToUserReview = (userReviewId: IUserReview['id']) => {
    return routes.USER_REVIEW_DASHBOARD.go(undefined, {
      userReviewId,
      isBackPath: true,
    });
  };

  const onRowClick = useCallback(
    (task: ITaskWithId) => {
      const isClickable = ![
        TASK_STATUS.UPCOMING,
        TASK_STATUS.DECLINED,
        TASK_STATUS.EXPIRED,
      ].includes(task.status as unknown as ITask['status']);
      if (!isClickable) {
        return;
      }

      switch (task.type as unknown as ITask['type']) {
        case TASK_TYPE.REVIEW_PEER_NOMINATE: {
          // @ts-ignore
          task.status === TASK_STATUS.COMPLETED
            ? navigateToUserReview(task.targetId as IUserReview['id'])
            : openNominatePeersModal(task);
          return;
        }
        default:
          navigateToTask(task);
          return;
      }
    },
    [navigateToTask],
  );

  const openNominatePeersModal = (task: ITaskWithId) => {
    setSelectedTask(task);
    setShowNominatePeersModal(true);
  };

  const closeNominatePeersModal = async (isSubmit?: boolean) => {
    setShowNominatePeersModal(false);
    setSelectedTask(undefined);

    if (isSubmit) {
      await fetchData();
    }
  };

  return (
    <>
      <TableWrapper>
        <StyledTableList
          isTab={isTab}
          columns={COLUMNS}
          data={allTasks}
          onRowClick={onRowClick}
          isLoading={isLoading}
          filtersProps={{
            filters,
            isFiltered: !!currentFilters.search.length,
            isToggleHideFilterVisible: true,
            resetFilters: filters.resetFilters,
            filterComponents: <Filters filters={filters} />,
          }}
          paginationProps={{
            pagination: currentFilters.pagination,
            changePagination: ({ skip, limit, index }) =>
              setCurrentFilters({
                ...currentFilters,
                pagination: { ...currentFilters.pagination, skip, limit, index },
              }),
            totalCount: totalTasksCount,
            paginationItemLabel: i18n._(t`tasks`),
          }}
          isHideHeader
          placeholderProps={{
            emptyStateText: i18n._(t`No open tasks found!`),
            noResultText: i18n._(t`This search did not produce any results..`),
          }}
          isLeftCornerRounded={false}
        />
      </TableWrapper>
      {showNominatePeersModal && selectedTask && (
        <NominatePeers
          taskId={selectedTask.id || selectedTask.target.taskId || ''}
          closeModal={closeNominatePeersModal}
        />
      )}
    </>
  );
};

export { TasksSection };
