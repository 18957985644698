import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  JOB_TEMPLATES_PENDING_SORT_OPTIONS,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { ManageJobFamiliesModal } from '~/components/Modals/ManageJobFamiliesModal';
import { TableList } from '~/components/TableList';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { PENDING_JOB_TEMPLATES_COLUMNS } from './SuperAdminPendingJobTemplates.columns';
import { Wrapper } from './SuperAdminPendingJobTemplates.design';

import { SUPERADMIN_JOB_TEMPLATE_CREATE } from '~/constants/routes/superadmin';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import {
  deleteJobTemplatesPendingSuperAdmin,
  getJobTemplatesPendingSuperAdmin,
} from '~/services/jobTemplatesPending';

import { ConnectToExistingJobModal } from '../ConnectToExistingJobModal/ConnectToExistingJobModal';

import type { IJobTemplatePending } from '@learned/types';

function SuperAdminPendingJobLibrary({ reFetchTotalPending }: { reFetchTotalPending: () => void }) {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<JOB_TEMPLATES_PENDING_SORT_OPTIONS>(
    JOB_TEMPLATES_PENDING_SORT_OPTIONS.NAME_A_Z,
  );
  const debouncedSearch = useDebounce(search, 300);
  const [jobTemplatesPending, setJobTemplatesPending] = useState<IJobTemplatePending[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [isManageJobFamiliesModalVisible, setIsManageJobFamiliesModalVisible] = useState(false);
  const [isConnectToExistingJobModalVisible, setIsConnectToExistingJobModalVisible] =
    useState(false);
  const [jobTemplatePending, setJobTemplatePending] = useState<IJobTemplatePending | undefined>();

  const onDelete = async (ids: string[]) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure want to delete pending job template(s)`),
      description: i18n._(t`Deleting pending job template(s) cannot be undone!`),
    });
    if (isConfirmed) {
      await deleteJobTemplatesPendingSuperAdmin(ids);
      // confirm toast
      addToast({
        title: i18n._(t`Pending Job Template(s) deleted!`),
        subtitle: i18n._(
          t`Your pending job template(s) has been deleted from your company templates`,
        ),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();
      await reFetchTotalPending();
      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const createMenuItems = (item: IJobTemplatePending) => {
    return [
      {
        label: i18n._(t`Create new job`),
        action: () => {
          SUPERADMIN_JOB_TEMPLATE_CREATE.go(
            { role: 'super-admin' as ROLES },
            { query: { createFromJobTemplatePendingId: item?.id }, isBackPath: true },
          );
        },
        icon: ICONS.ADD_PLUS,
      },
      {
        label: i18n._(t`Add as alias`),
        action: () => {
          setIsConnectToExistingJobModalVisible(true);
          setJobTemplatePending(item);
        },
        icon: ICONS.ADD_PLUS,
      },
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const isAllSelected = jobTemplatesPending.every((item) => {
    return selectedItems.includes(item.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : jobTemplatesPending.map((item) => item.id);
    setSelectedItems(itemsToSelect);
  };

  const fetchData = async (showLoading = false) => {
    try {
      if (showLoading) {
        setIsLoading(true);
      }
      const result = await getJobTemplatesPendingSuperAdmin(
        { search: debouncedSearch },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      );

      setJobTemplatesPending(result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES_PENDING].items);
      setTotal(result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES_PENDING]?.[API_RETURN_FIELDS.TOTAL]);
    } finally {
      if (showLoading) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.limit, pagination.skip, debouncedSearch, sortBy]);

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IJobTemplatePending) => onSelectItem(item.id),
    isItemChecked: (item: IJobTemplatePending) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onDelete: () => onDelete(selectedItems),
  };

  const settingsButton = {
    label: t`Settings`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.SETTINGS,
    onClick: () => setIsManageJobFamiliesModalVisible(true),
  };

  return (
    <Wrapper>
      <TableList
        isLoading={isLoading}
        data={jobTemplatesPending}
        columns={PENDING_JOB_TEMPLATES_COLUMNS}
        filtersProps={{ isFiltered: !!search.length, filters: { search, setSearch } }}
        sortProps={{ sortBy, setSortBy }}
        paginationProps={{ pagination, changePagination, totalCount: total }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        menuProps={{ isMenuVisible: true, createMenuItems }}
        secondaryButton={settingsButton}
      />
      {isManageJobFamiliesModalVisible && (
        <ManageJobFamiliesModal closeModal={() => setIsManageJobFamiliesModalVisible(false)} />
      )}
      {isConnectToExistingJobModalVisible && (
        <ConnectToExistingJobModal
          closeModal={() => setIsConnectToExistingJobModalVisible(false)}
          jobTemplatePending={jobTemplatePending}
          refetchJobTemplates={fetchData}
        />
      )}
    </Wrapper>
  );
}

export { SuperAdminPendingJobLibrary };
