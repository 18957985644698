import React, { Dispatch, Fragment, SetStateAction, useMemo, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE } from '@learned/constants';
import { IMultiLangString } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, Icon } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';

import ActionBtn, { ACTION_TYPE } from './ActionBtn';
import AvatarComponent from './AvatarComponent';
import ContextMenu from './ContextMenu';
import {
  TableGridLeft,
  TableGridRight,
  TableGridContainer,
  FirstCell,
  SecondCell,
  OtherCell,
  DraggableBox,
  ExtendedHeader,
  HeaderItem,
  TableFullHeader,
  Unassigned,
  JobTitlesContainer,
  IconContainer,
  EmptyHolder,
  UnassignEnd,
  UnassignMiddle,
  HeaderItemInner,
  FirstCellInner,
  SpaceDivider,
  IconWrapper,
  SortingContainer,
  LoaderContainer,
  AddInitialJobFamily,
  CenterItem,
  EmptyPlaceHolder,
  AddJobSquare,
  EmptyHeaderItem,
  JobGroupPlaceholder,
} from './JobTable.design';

import routes from '~/constants/routes';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';

import { MODAL_TYPE, MODEL_TYPE } from '../constants';
import {
  TItemToMove,
  addToJobs,
  addToUnassigned,
  contrastColors,
  getAggregatedLevels,
  isUnassignItem,
  removeFromJobs,
  removeFromUnassigned,
  sortItOut,
} from '../utils';

import type { TCell, TColumnData, TFamilyData, TOtherData, TTableData } from '../types';

type TProps = {
  columnData: TFamilyData[];
  isEditMode?: boolean;
  jobData: TTableData[];
  onItemToggle?: (index: number) => void;
  onTableItemClick?: (selected: MODEL_TYPE, meta?: { title?: IMultiLangString }) => void;
  openedItems?: number[];
  otherData?: TOtherData | null;
  otherDataChange?: (data: TOtherData) => void;
  setColIndex?: Dispatch<SetStateAction<number>>;
  setJobData: Dispatch<SetStateAction<TTableData[]>>;
  setRowIndex?: Dispatch<SetStateAction<number>>;
  unAssignedJobs: TCell[];
  setUnAssignedJobs: Dispatch<SetStateAction<TCell[]>>;
  onOrderChange?: () => void;
  ascending?: boolean;
  cellLoader?: string | null;
  onDragEndAction?: (
    groupId: string,
    job: TItemToMove,
    levelNumber: number,
    jobFamilyId: string | null,
    unassign: boolean,
  ) => void;
  onJobLevelAdd?: (row: number, level: number) => void;
  onJobLevelDelete?: (row: number, level: number) => void;
  onJobFamilyDelete?: (col: number) => void;
  onJobGroupDelete?: (row: number) => void;
  setIsLastJob?: Dispatch<SetStateAction<boolean>>;
  setOpenedModal?: Dispatch<SetStateAction<MODAL_TYPE | null>>;
  setLevelTo?: Dispatch<SetStateAction<number>>;
  allJobGroupData?: TTableData[];
  pagination?: {
    skip: number;
    limit: number;
    index: number;
  };
};
export default function JobTable({
  columnData,
  isEditMode = false,
  jobData,
  onItemToggle,
  onTableItemClick,
  openedItems,
  otherData,
  otherDataChange,
  setColIndex,
  setJobData,
  setRowIndex,
  unAssignedJobs,
  setUnAssignedJobs,
  onOrderChange,
  onDragEndAction,
  ascending = true,
  cellLoader = null,
  onJobLevelAdd,
  onJobLevelDelete,
  onJobFamilyDelete,
  onJobGroupDelete,
  setIsLastJob,
  setOpenedModal,
  setLevelTo,
  pagination,
  allJobGroupData,
}: TProps) {
  const [opened, setOpened] = useState<null | string>(null);
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const MIN_LEFT_COLS = 2;
  const MIN_RIGHT_COLS = isEditMode ? 4 : 3;
  const MIN_COLS = MIN_LEFT_COLS + MIN_RIGHT_COLS;
  const isSpecialCase = columnData.length <= MIN_COLS;
  const RIGHT_COLS = isSpecialCase ? MIN_RIGHT_COLS : columnData.length - MIN_LEFT_COLS;
  const TOTAL_COLS = MIN_LEFT_COLS + RIGHT_COLS;
  const topHeaderRows = 1;
  const headerRows = 1;
  const aggregatedLevels = useMemo(() => getAggregatedLevels(true, jobData), [jobData]);
  const fullAggregatedLevels = useMemo(
    () => getAggregatedLevels(true, allJobGroupData),
    [allJobGroupData],
  );
  const isFullyOpened = (i: number, checkForOrder = false) => {
    if (checkForOrder && !ascending) {
      const numberOfGroups = jobData.length || 0;
      return (
        openedItems?.includes(numberOfGroups - i) || aggregatedLevels[numberOfGroups - i] === 1
      );
    }
    return openedItems?.includes(i) || aggregatedLevels[i] === 1;
  };

  const otherRows = jobData
    .map((row, i) => {
      if (isFullyOpened(i)) {
        return row.count || 1;
      }
      return 1;
    })
    .reduce((a, b) => a + b, 0);
  const totalRows = otherRows + topHeaderRows + headerRows + (isEditMode ? 4 : 0);

  const getNumOfFilledLevels = (item: TColumnData[] | null, column: number) => {
    if (!item) {
      return null;
    }
    const count = item
      .map((a) => a.columns)
      .map((b) => b.map((c, i) => (i === column ? c.values.length : null)))
      .flat()
      .filter((a) => a);
    const counter = (count as number[]).reduce((a, b) => a + b, 0);
    if (counter === 0) {
      return `${i18n._(t`No jobs yet`)}`;
    }
    return `${counter} ${i18n._(t`jobs`)}`;
  };

  const onPlusClick = (
    selected: MODEL_TYPE,
    meta?: { title?: IMultiLangString; values?: IMultiLangString },
  ) => {
    onTableItemClick && onTableItemClick(selected, meta);
  };

  const secondColumnText = (
    isCollapsed: boolean,
    rowNr: number,
    col: number,
    cellName?: IMultiLangString,
  ) => {
    if (cellName) {
      return getMultiLangString(cellName);
    }
    const skip = pagination?.skip || 0;
    const startingNumber = skip;
    const newRowNr = startingNumber + rowNr;
    if (fullAggregatedLevels && fullAggregatedLevels.length >= newRowNr) {
      const levelStartNr = ascending
        ? [...fullAggregatedLevels].slice(0, newRowNr).reduce((a, b) => a + b, 0) + 1
        : [...fullAggregatedLevels].slice(newRowNr).reduce((a, b) => a + b, 0);

      if (ascending && isCollapsed) {
        if (fullAggregatedLevels[newRowNr] <= 1) {
          return `${levelStartNr}`;
        }
        return `${levelStartNr}-${levelStartNr + fullAggregatedLevels[newRowNr] - 1}`;
      }

      if (ascending) {
        return `${levelStartNr + col}`;
      }

      if (isCollapsed) {
        if (fullAggregatedLevels[newRowNr] <= 1) {
          return `${levelStartNr - col}`;
        }
        if (ascending) {
          return `${levelStartNr - col - fullAggregatedLevels[newRowNr] + 1}-${levelStartNr - col}`;
        }
        return `${levelStartNr - col}-${levelStartNr - col - fullAggregatedLevels[newRowNr] + 1}`;
      }
      return `${levelStartNr - col}`;
    }
    return null;
  };

  const onDeleteContext = async (i: number) => {
    setOpened(null);
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        title: i18n._(t`Delete?`),
        description: i18n._(
          t`Are you sure you want to delete the job group? This cannot be undone.`,
        ),
      })
    ) {
      onJobGroupDelete && onJobGroupDelete(i);
    }
  };

  const onEditContext = (item: TTableData, i: number) => {
    otherDataChange &&
      otherDataChange(
        otherData ? { ...otherData, selectedColor: item.color } : { selectedColor: item.color },
      );
    setRowIndex && setRowIndex(i);
    onPlusClick(MODEL_TYPE.EDIT_JOB_GROUP, {
      values: item.name,
    });
    setOpened(null);
  };

  const onNewJobCreate = (rowIndex: number, colIndex: number, levelIndex: number) => {
    setRowIndex && setRowIndex(rowIndex);
    setColIndex && setColIndex(colIndex);
    setLevelTo && setLevelTo(levelIndex);
    setOpenedModal && setOpenedModal(MODAL_TYPE.NEW_JOB_PROFILE);
  };

  const navigateToJobProfile = (cell: TCell) => {
    routes.JOB_PROFILE.go(
      {},
      {
        roleId: cell.id,
        isBackPath: true,
      },
    );
  };

  return (
    <>
      <DragDropContext
        onDragEnd={(result) => {
          const { destination, draggableId } = result;
          if (!destination) {
            return;
          }

          const isUnassigned = isUnassignItem(unAssignedJobs, draggableId);
          const [removedItems, itemToMove] = isUnassigned
            ? removeFromUnassigned(unAssignedJobs, draggableId)
            : removeFromJobs(jobData, draggableId);

          let levelNumber = 0;
          let familyId = null;
          const destinationGroup = jobData.filter((a) => {
            if (a.levels) {
              const b = a.levels.filter((c, i) => {
                const index = c.columns.findIndex((d) => d.id === destination.droppableId);
                if (index > -1) {
                  familyId = c.columns[index].familyId || null;
                  levelNumber = i;
                }
                return index > -1;
              });
              return b.length > 0;
            }
            return false;
          });

          const isDestinationUnassigned = destination.droppableId.includes('unassigned');

          if (destinationGroup.length > 0) {
            onDragEndAction &&
              onDragEndAction(
                destinationGroup[0].id,
                itemToMove,
                levelNumber,
                familyId,
                isDestinationUnassigned,
              );
          } else {
            onDragEndAction &&
              onDragEndAction(
                'unassigned',
                itemToMove,
                levelNumber,
                familyId,
                isDestinationUnassigned,
              );
          }

          if (isUnassigned && isDestinationUnassigned) {
            setUnAssignedJobs(
              sortItOut(
                addToUnassigned(removedItems as TCell[], itemToMove, destination),
              ) as TCell[],
            );
            return;
          }

          if (!isUnassigned && !isDestinationUnassigned) {
            setJobData(addToJobs(removedItems as TTableData[], itemToMove, destination));
            return;
          }

          setJobData(
            isUnassigned
              ? addToJobs(jobData, itemToMove, destination)
              : (removedItems as TTableData[]),
          );
          setUnAssignedJobs(
            sortItOut(
              isUnassigned
                ? (removedItems as TCell[])
                : addToUnassigned(unAssignedJobs, itemToMove, destination),
            ) as TCell[],
          );
        }}
      >
        <TableFullHeader>
          <Unassigned>
            <UnassignMiddle>
              {unAssignedJobs.length > 0
                ? `${i18n._(t`Unassigned jobs`)} (${unAssignedJobs.length})`
                : i18n._(t`No Unassigned jobs`)}
            </UnassignMiddle>
            {isEditMode && (
              <UnassignEnd>
                {i18n._(t`Drag and drop job to group to assign`)} <Icon icon={ICONS.DRAGGABLE} />
              </UnassignEnd>
            )}
          </Unassigned>
          <Droppable droppableId={'unassigned'} direction="horizontal" isDropDisabled={!isEditMode}>
            {(provided, snapshot) => {
              return (
                <JobTitlesContainer
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {unAssignedJobs.map((cell, i) => (
                    <Draggable
                      draggableId={cell.id}
                      index={i}
                      key={cell.id}
                      isDragDisabled={!isEditMode}
                    >
                      {(draggableData) => (
                        <DraggableBox
                          {...draggableData.draggableProps}
                          {...draggableData.dragHandleProps}
                          ref={draggableData.innerRef}
                          isEditMode={isEditMode}
                          onClick={() => navigateToJobProfile(cell)}
                        >
                          {cellLoader === cell.id ? (
                            <LoaderContainer>
                              <Loader />
                            </LoaderContainer>
                          ) : (
                            <>
                              <Tippy content={getMultiLangString(cell?.name)} offset={[18, 10]}>
                                <CenterItem>{getMultiLangString(cell?.name)}</CenterItem>
                              </Tippy>
                              <AvatarComponent avatarArr={cell.members} />
                            </>
                          )}
                        </DraggableBox>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </JobTitlesContainer>
              );
            }}
          </Droppable>
        </TableFullHeader>
        <TableGridContainer
          rowSpan={totalRows}
          colSpan={TOTAL_COLS}
          calculatedWidth={TOTAL_COLS > 4 ? '100%' : `${TOTAL_COLS * 250}px`}
        >
          <ExtendedHeader rowSpan={1} colSpan={MIN_LEFT_COLS}>
            {i18n._(t`Levels`)}
          </ExtendedHeader>
          <ExtendedHeader rowSpan={1} colSpan={RIGHT_COLS}>
            {i18n._(t`Job Families`)}
          </ExtendedHeader>
          <TableGridLeft rowSpan={totalRows} colSpan={MIN_LEFT_COLS}>
            {columnData
              .filter((_, i) => i < MIN_LEFT_COLS)
              .map((col, j) => (
                <HeaderItem key={`header-${j + 1}`} rowSpan={1} colSpan={1}>
                  <HeaderItemInner maxSize={j === 0 ? '16ch' : '6ch'}>
                    {getMultiLangString(col.name)}
                  </HeaderItemInner>
                  {j === 1 && (
                    <SortingContainer onClick={onOrderChange} isSelected={ascending}>
                      <Icon icon={ICONS.DROPDOWN} className={'up'} width={12} height={12} />
                      <Icon icon={ICONS.DROPDOWN} className={'down'} width={12} height={12} />
                    </SortingContainer>
                  )}
                </HeaderItem>
              ))}
            {jobData.length === 0 && (
              <JobGroupPlaceholder
                rowSpan={1}
                colSpan={MIN_LEFT_COLS}
                onClick={() => {
                  setRowIndex && setRowIndex(0);
                  setIsLastJob && setIsLastJob(false);
                  onPlusClick(MODEL_TYPE.ADD_JOB_GROUP);
                }}
              >
                {isEditMode && i18n._(t`Add Job Group`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </JobGroupPlaceholder>
            )}
            {jobData.map((item, i) => {
              if (!isFullyOpened(i)) {
                return (
                  <Fragment key={`hidden-level-${i + 1}`}>
                    <FirstCell
                      bColor={item.color}
                      rowSpan={1}
                      colSpan={1}
                      colorInRgb={contrastColors(item.color || '')}
                      isEditMode={isEditMode}
                    >
                      {isEditMode && (
                        <ActionBtn
                          type={ACTION_TYPE.VERTICAL}
                          isFirst
                          onActionClicked={() => {
                            setRowIndex && setRowIndex(i);
                            setIsLastJob && setIsLastJob(false);
                            onPlusClick(MODEL_TYPE.ADD_JOB_GROUP);
                          }}
                        />
                      )}

                      <FirstCellInner>
                        {isEditMode && <SpaceDivider heightVal="36px" widthVal="36px" />}
                        {getMultiLangString(item?.name)}
                      </FirstCellInner>
                      <IconWrapper>
                        <IconContainer
                          onClick={() => {
                            onItemToggle && onItemToggle(i);
                          }}
                        >
                          <Icon
                            icon={ICONS.DROPDOWN}
                            className={'plus_icon_rotate'}
                            width={12}
                            height={12}
                          />
                        </IconContainer>
                        {isEditMode && (
                          <ContextMenu
                            heightVal="36px"
                            widthVal="36px"
                            itemOpened={`${i}`}
                            opened={opened}
                            setOpened={setOpened}
                            onEdit={() => onEditContext(item, i)}
                            onDelete={() => onDeleteContext(i)}
                          />
                        )}
                      </IconWrapper>
                      {isEditMode && (
                        <ActionBtn
                          type={ACTION_TYPE.VERTICAL}
                          isFirst={false}
                          onActionClicked={() => {
                            setRowIndex && setRowIndex(i);
                            setIsLastJob && setIsLastJob(true);
                            onPlusClick(MODEL_TYPE.ADD_JOB_GROUP);
                          }}
                        />
                      )}
                    </FirstCell>
                    <SecondCell
                      bColor={item.color}
                      key={`hidden-level-${i}`}
                      rowHeight={1}
                      colorInRgb={contrastColors(item.color || '')}
                      isEditMode={isEditMode}
                    >
                      {aggregatedLevels && aggregatedLevels.length >= i
                        ? secondColumnText(true, i, 0)
                        : null}
                      {isEditMode && (
                        <ActionBtn
                          type={ACTION_TYPE.VERTICAL}
                          isFirst={false}
                          onActionClicked={() => {
                            // Here job levels are added
                            onJobLevelAdd && onJobLevelAdd(i, 0);
                          }}
                        />
                      )}
                    </SecondCell>
                  </Fragment>
                );
              }

              return (
                <Fragment key={`item-${i + 1}`}>
                  <FirstCell
                    bColor={item.color}
                    rowSpan={item?.count}
                    colSpan={1}
                    colorInRgb={contrastColors(item.color || '')}
                    isEditMode={isEditMode}
                  >
                    {isEditMode && (
                      <ActionBtn
                        type={ACTION_TYPE.VERTICAL}
                        isFirst
                        onActionClicked={() => {
                          setRowIndex && setRowIndex(i);
                          setIsLastJob && setIsLastJob(false);
                          onPlusClick(MODEL_TYPE.ADD_JOB_GROUP);
                        }}
                      />
                    )}
                    <FirstCellInner>
                      {isEditMode && <SpaceDivider heightVal="36px" widthVal="36px" />}
                      {getMultiLangString(item?.name)}
                    </FirstCellInner>
                    <IconWrapper>
                      {openedItems?.includes(i) && aggregatedLevels[i] !== 1 && (
                        <IconContainer
                          onClick={() => {
                            onItemToggle && onItemToggle(i);
                          }}
                        >
                          <Icon
                            icon={ICONS.DROPDOWN}
                            className={'plus_icon'}
                            width={12}
                            height={12}
                          />
                        </IconContainer>
                      )}
                      {isEditMode && (
                        <ContextMenu
                          heightVal="36px"
                          widthVal="36px"
                          itemOpened={`${i}`}
                          opened={opened}
                          setOpened={setOpened}
                          onEdit={() => {
                            otherDataChange &&
                              otherDataChange(
                                otherData
                                  ? { ...otherData, selectedColor: item.color }
                                  : { selectedColor: item.color },
                              );
                            setRowIndex && setRowIndex(i);
                            onPlusClick(MODEL_TYPE.EDIT_JOB_GROUP, {
                              values: item.name,
                            });
                            setOpened(null);
                          }}
                          onDelete={async () => {
                            setOpened(null);
                            if (
                              await confirm({
                                type: CONFIRMATION_MODAL_TYPE.DELETE,
                                title: i18n._(t`Delete?`),
                                description: i18n._(
                                  t`Are you sure you want to delete the job group? This cannot be undone.`,
                                ),
                              })
                            ) {
                              onJobGroupDelete && onJobGroupDelete(i);
                            }
                          }}
                        />
                      )}
                    </IconWrapper>
                    {isEditMode && (
                      <ActionBtn
                        type={ACTION_TYPE.VERTICAL}
                        isFirst={false}
                        onActionClicked={() => {
                          setRowIndex && setRowIndex(i);
                          setIsLastJob && setIsLastJob(true);
                          onPlusClick(MODEL_TYPE.ADD_JOB_GROUP);
                        }}
                      />
                    )}
                  </FirstCell>

                  {item?.levels && item?.levels.length > 0 ? (
                    item?.levels?.map((cell, j) => {
                      return (
                        <SecondCell
                          bColor={item.color}
                          key={`level-${i}-${j + 1}`}
                          rowHeight={1}
                          colorInRgb={contrastColors(item.color || '')}
                          isEditMode={isEditMode}
                        >
                          {isEditMode && (
                            <ActionBtn
                              type={ACTION_TYPE.VERTICAL}
                              isFirst
                              onActionClicked={() => {
                                // Here job levels are added
                                onJobLevelAdd && onJobLevelAdd(i, j);
                              }}
                            />
                          )}
                          {isEditMode && <SpaceDivider heightVal="36px" widthVal="36px" />}
                          {secondColumnText(false, i, j, cell?.name)}

                          {isEditMode && item.levels && item?.levels.length > 1 && (
                            <ContextMenu
                              heightVal="36px"
                              widthVal="36px"
                              itemOpened={`${i}-${j}`}
                              opened={opened}
                              setOpened={setOpened}
                              onDelete={async () => {
                                setOpened(null);
                                if (
                                  await confirm({
                                    type: CONFIRMATION_MODAL_TYPE.DELETE,
                                    title: i18n._(t`Delete?`),
                                    description: i18n._(
                                      t`Are you sure you want to delete the job level? This cannot be undone.`,
                                    ),
                                  })
                                ) {
                                  onJobLevelDelete && onJobLevelDelete(i, j);
                                }
                              }}
                            />
                          )}
                          {isEditMode && item.levels && item?.levels.length === 1 && (
                            <SpaceDivider heightVal="36px" widthVal="36px" />
                          )}
                          {isEditMode && (
                            <ActionBtn
                              type={ACTION_TYPE.VERTICAL}
                              isFirst={false}
                              onActionClicked={() => {
                                // Here job levels are added
                                onJobLevelAdd && onJobLevelAdd(i, j + 1);
                              }}
                            />
                          )}
                        </SecondCell>
                      );
                    })
                  ) : (
                    <SecondCell
                      bColor={item.color}
                      key={`level-${i}-${0}-empty`}
                      rowHeight={1}
                      colorInRgb={contrastColors(item.color || '')}
                      isEditMode={isEditMode}
                    >
                      {secondColumnText(false, i, 0)}
                    </SecondCell>
                  )}
                </Fragment>
              );
            })}
          </TableGridLeft>
          <TableGridRight rowSpan={totalRows} colSpan={RIGHT_COLS} isEditMode={isEditMode}>
            {columnData
              .filter((_, i) => i > 1)
              .map((col, j) => {
                return (
                  <Fragment key={`header-${j + 1}`}>
                    <HeaderItem rowSpan={1} colSpan={1}>
                      {isEditMode && <SpaceDivider heightVal="18px" widthVal="18px" />}
                      <HeaderItemInner maxSize={RIGHT_COLS === 1 ? '20ch' : 'auto'}>
                        {getMultiLangString(col.name)}
                      </HeaderItemInner>
                      {isEditMode && (
                        <ContextMenu
                          heightVal="18px"
                          widthVal="18px"
                          itemOpened={`col-${j}`}
                          opened={opened}
                          setOpened={setOpened}
                          onEdit={() => {
                            setColIndex && setColIndex(j);
                            otherDataChange &&
                              otherDataChange(
                                otherData ? { ...otherData, id: col.id } : { id: col.id },
                              );
                            onPlusClick(MODEL_TYPE.EDIT_JOB_FAMILY, {
                              values: col.name,
                            });
                            setOpened(null);
                          }}
                          onDelete={async () => {
                            setOpened(null);
                            if (
                              await confirm({
                                type: CONFIRMATION_MODAL_TYPE.DELETE,
                                title: i18n._(t`Delete?`),
                                description: i18n._(
                                  t`Are you sure you want to delete the job group? This cannot be undone.`,
                                ),
                              })
                            ) {
                              onJobFamilyDelete && onJobFamilyDelete(j);
                            }
                          }}
                        />
                      )}
                      {isEditMode && j === 0 && (
                        <ActionBtn
                          type={ACTION_TYPE.POPOVER}
                          isFirst={true}
                          onActionClicked={() => {
                            setColIndex && setColIndex(j + 1);
                            onPlusClick(MODEL_TYPE.ADD_JOB_FAMILY);
                          }}
                        />
                      )}
                      {isEditMode && j + MIN_LEFT_COLS < columnData.length - 1 && (
                        <ActionBtn
                          type={ACTION_TYPE.HORIZONTAL}
                          isFirst={false}
                          onActionClicked={() => {
                            setColIndex && setColIndex(j + 1);
                            onPlusClick(MODEL_TYPE.ADD_JOB_FAMILY);
                          }}
                        />
                      )}
                      {isEditMode &&
                        j + MIN_LEFT_COLS === columnData.length - 1 &&
                        columnData.length >= MIN_COLS && (
                          <ActionBtn
                            type={ACTION_TYPE.POPOVER}
                            isFirst={false}
                            onActionClicked={() => {
                              setColIndex && setColIndex(j + 1);
                              onPlusClick(MODEL_TYPE.ADD_JOB_FAMILY);
                            }}
                          />
                        )}
                    </HeaderItem>
                  </Fragment>
                );
              })}
            {columnData.length > MIN_LEFT_COLS && columnData.length < MIN_COLS && (
              <EmptyHeaderItem
                rowSpan={1}
                colSpan={MIN_COLS - columnData.length}
                isHeader
                onClick={() => {
                  setColIndex && setColIndex(columnData.length - MIN_LEFT_COLS);
                  onTableItemClick && onTableItemClick(MODEL_TYPE.ADD_JOB_FAMILY);
                }}
              >
                {isEditMode && i18n._(t`Add Job Family`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </EmptyHeaderItem>
            )}
            {columnData.length === MIN_LEFT_COLS && (
              <AddInitialJobFamily
                onClick={() => {
                  setColIndex && setColIndex(0);
                  onTableItemClick && onTableItemClick(MODEL_TYPE.ADD_JOB_FAMILY);
                }}
                rowSpan={1}
                colSpan={MIN_COLS - columnData.length}
              >
                {isEditMode && i18n._(t`Add Job Family`)}
                {isEditMode && (
                  <Icon
                    icon={ICONS.ADD_PLUS}
                    width={16}
                    height={16}
                    color={COLORS.PLACEHOLDERS}
                    className="add_job_icon"
                  />
                )}
              </AddInitialJobFamily>
            )}
            {jobData.map((item, i) => {
              const tempLevels = item?.levels || [];
              const orderedLevels = ascending ? [...tempLevels] : [...tempLevels].reverse();
              return orderedLevels?.map((level, j) => {
                const currentRow = i % jobData.length;
                const currentLevel = j % item?.count;
                return (
                  <Fragment key={`group-level-${i}-${j + 1}`}>
                    {level.columns.map((column, k) => {
                      if (!isFullyOpened(currentRow)) {
                        if (currentLevel !== 0) {
                          return null;
                        }
                        return (
                          <Fragment key={`empty-${i}-${j}-${k + 1}`}>
                            <EmptyHolder rowSpan={1} colSpan={1}>
                              {getNumOfFilledLevels(item?.levels || null, k)}
                            </EmptyHolder>
                            {level.columns.length - 1 === k &&
                              level.columns.length < MIN_RIGHT_COLS && (
                                <EmptyHeaderItem
                                  rowSpan={1}
                                  colSpan={MIN_RIGHT_COLS - level.columns.length}
                                  isHeader={false}
                                />
                              )}
                          </Fragment>
                        );
                      }
                      return (
                        <Fragment key={`droppable-ctr-${i}-${j}-${k + 1}`}>
                          <Droppable
                            droppableId={column.id}
                            key={`droppable-${i}-${j}-${k + 1}`}
                            isDropDisabled={!isEditMode}
                          >
                            {(provided, snapshot) => {
                              return (
                                <OtherCell
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  isDraggingOver={snapshot.isDraggingOver}
                                  isEmpty={column.values.length === 0}
                                >
                                  {column.values.map((cell, l) => {
                                    return (
                                      <Draggable
                                        draggableId={cell.id}
                                        index={l}
                                        key={cell.id}
                                        isDragDisabled={!isEditMode}
                                      >
                                        {(draggableData) => (
                                          <DraggableBox
                                            {...draggableData.draggableProps}
                                            {...draggableData.dragHandleProps}
                                            ref={draggableData.innerRef}
                                            className="draggable-box"
                                            isEditMode={isEditMode}
                                            onClick={() => navigateToJobProfile(cell)}
                                          >
                                            {isFullyOpened(i) && (
                                              <>
                                                {cellLoader === cell.id ? (
                                                  <LoaderContainer>
                                                    <Loader />
                                                  </LoaderContainer>
                                                ) : (
                                                  <>
                                                    <Tippy
                                                      content={getMultiLangString(cell?.name)}
                                                      offset={[18, 10]}
                                                    >
                                                      <CenterItem>
                                                        {getMultiLangString(cell?.name)}
                                                      </CenterItem>
                                                    </Tippy>
                                                    <AvatarComponent avatarArr={cell.members} />
                                                  </>
                                                )}
                                              </>
                                            )}
                                            {isEditMode && (
                                              <ActionBtn
                                                type={ACTION_TYPE.VERTICAL}
                                                isFirst={false}
                                                onActionClicked={() => onNewJobCreate(i, k, j)}
                                              />
                                            )}
                                          </DraggableBox>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                  {isEditMode && column.values.length === 0 && (
                                    <EmptyPlaceHolder>
                                      <AddJobSquare
                                        onClick={() => {
                                          const level = ascending
                                            ? j
                                            : orderedLevels.length - 1 - j;
                                          onNewJobCreate(i, k, level);
                                        }}
                                      >
                                        <Icon
                                          icon={ICONS.ADD_PLUS}
                                          className={'plus_icon'}
                                          width={14}
                                          height={14}
                                          color={COLORS.PLACEHOLDERS}
                                        />
                                      </AddJobSquare>
                                    </EmptyPlaceHolder>
                                  )}
                                  {provided.placeholder}
                                </OtherCell>
                              );
                            }}
                          </Droppable>
                        </Fragment>
                      );
                    })}
                    {level.columns.length === 0 && level.columns.length < MIN_RIGHT_COLS && (
                      <EmptyHeaderItem
                        rowSpan={1}
                        colSpan={MIN_RIGHT_COLS - level.columns.length}
                        isHeader={false}
                      />
                    )}
                    {isFullyOpened(currentRow) && level.columns.length < MIN_RIGHT_COLS && (
                      <EmptyHeaderItem
                        rowSpan={1}
                        colSpan={MIN_RIGHT_COLS - level.columns.length}
                        isHeader={false}
                      />
                    )}
                  </Fragment>
                );
              });
            })}
          </TableGridRight>
        </TableGridContainer>
      </DragDropContext>
    </>
  );
}
