import React from 'react';

import { ROLES, SKILL_TEMPLATE_STATUS } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { ButtonSize, ButtonVariant } from '~/components/Buttons';
import DashboardHeader from '~/components/DashboardHeader';
import { ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SkillCategory } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/components/SkillCategory';
import { Levels } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/design';
import { useSkillCategories } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateForm/hooks';
import { FocusAreas } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateView/components';

import { Definition, Details, Divider, Header, StyledButton, Title, Wrapper } from './design';
import { useSkillTemplate } from './hooks';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { SUPERADMIN_SKILL_TEMPLATE_EDIT } from '~/constants/routes/superadmin';
import { useFromQuery } from '~/hooks/useFromQuery';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { COLORS } from '~/styles';
import { getSuperAdminMultiLangString } from '~/utils/superAdmin';

function SuperAdminSkillTemplateView() {
  const { goBack } = useFromQuery({ includeHash: true });
  const { skillTemplate, isLoading } = useSkillTemplate();
  const { skillCategories, isLoading: isSkillCategoriesLoading } = useSkillCategories();

  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const skillCategory = skillCategories.find(
    (category) => category.id === skillTemplate?.skillCategory,
  );

  return (
    <>
      <DashboardHeader
        title={<Trans>SA - Skill matrix</Trans>}
        onBack={goBack}
        actions={
          skillTemplate?.status !== SKILL_TEMPLATE_STATUS.ARCHIVED && (
            <StyledButton
              type="button"
              label={<Trans>Edit</Trans>}
              variant={ButtonVariant.TEXT_PRIMARY}
              icon={ICONS.EDIT_PENCIL}
              size={ButtonSize.MEDIUM}
              onClick={() =>
                SUPERADMIN_SKILL_TEMPLATE_EDIT.go(
                  { role: 'super-admin' as ROLES },
                  { skillTemplateId: skillTemplate?.id, isBackPath: true },
                )
              }
            />
          )
        }
      />
      <Wrapper>
        <ShowSpinnerIfLoading loading={isLoading || isSkillCategoriesLoading}>
          <>
            {skillTemplate && skillCategory && (
              <>
                <Details>
                  <Header>
                    <Title>{getSuperAdminMultiLangString(skillTemplate.name, languageState)}</Title>
                    <SkillCategory>
                      {getSuperAdminMultiLangString(skillCategory.name, languageState)}
                    </SkillCategory>
                  </Header>
                  <Definition>
                    <h2>
                      <Trans>Definition</Trans>
                    </h2>
                    <RickTextView
                      html={getSuperAdminMultiLangString(skillTemplate.description, languageState)}
                      color={COLORS.ICONS_PRIMARY}
                      isHtmlWithoutStyles={undefined}
                      className={undefined}
                    />
                  </Definition>
                </Details>
                <Divider />
                <Levels>
                  <h2>
                    <Trans>Levels & Focus area&apos;s</Trans>
                  </h2>
                  {skillCategory.skillLevels.map((skillLevel, index) => {
                    const focusAreas = skillTemplate.focusAreas.find(
                      (area) => area.level === index,
                    );
                    return focusAreas ? (
                      <FocusAreas
                        focusAreas={focusAreas}
                        key={index}
                        levelName={skillLevel}
                        languageState={languageState}
                      />
                    ) : null;
                  })}
                </Levels>
              </>
            )}
          </>
        </ShowSpinnerIfLoading>
      </Wrapper>
    </>
  );
}

export { SuperAdminSkillTemplateView };
