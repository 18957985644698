import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import { Block } from '../components/Block';
import { RowWrapper, Text } from '../design';
interface ITimeframeReviewBlockProps {
  startDate: Date;
  endDate: Date;
}

const TimeframeReviewBlock = ({ startDate, endDate }: ITimeframeReviewBlockProps) => {
  const { i18n } = useLingui();
  const start = moment(startDate).format('DD-MM-YYYY');
  const end = moment(endDate).format('DD-MM-YYYY');
  return (
    <Block title={i18n._(t`Start & end date review`)}>
      <RowWrapper>
        <Text>{i18n._(t`From ${start} to ${end}`)}</Text>
      </RowWrapper>
    </Block>
  );
};

export { TimeframeReviewBlock };
