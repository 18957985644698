import React, { useEffect, useMemo, useState } from 'react';

import { USER_REVIEW_STATUS } from '@learned/constants';
import { Trans, useLingui } from '@lingui/react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { STATUS, STATUS_PROVIDER } from '~/constants/statusProvider';
import { useAutoSave } from '~/hooks/useAutoSave';
import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { createReviewSummary, getReviewSummaries } from '~/services/reviewSummaries';
import { isNotNil } from '~/utils/typePredicates';

import type { IReviewSummaryForm } from '../types';
import type { IReviewSummary } from '@learned/types';

const useSummary = ({
  userReviewId,
  status,
}: {
  userReviewId: string;
  status: USER_REVIEW_STATUS;
}) => {
  const { i18n } = useLingui();
  const currentUser = useSelector(getUser);
  const [reviewSummaries, setReviewSummaries] = useState<IReviewSummary[]>([]);
  const isVisible = useBoolState(false);
  const formMethods = useForm<IReviewSummaryForm>({
    defaultValues: {
      content: '',
    },
  });

  const {
    getValues,
    watch,
    formState: { isDirty },
    setValue,
  } = formMethods;
  const watchContent = watch('content');

  const autoSaveState = useAutoSave(async () => {
    const result = await createReviewSummary({
      content: getValues('content'),
      userReview: userReviewId,
    });

    if (result.data.reviewSummary) {
      setReviewSummaries((prevState) => {
        if (prevState.find((item) => item.id === result.data.reviewSummary.id)) {
          return prevState.map((item) => {
            if (item.id === result.data.reviewSummary.id) {
              return {
                ...item,
                content: result.data.reviewSummary.content,
              };
            } else {
              return item;
            }
          });
        } else {
          return [...prevState, result.data.reviewSummary].filter(isNotNil);
        }
      });
    }
  });

  useEffect(() => {
    const currentUserReviewSummary = reviewSummaries.find(
      ({ createdBy }) => createdBy === currentUser.id,
    );
    if (currentUserReviewSummary) {
      setValue('content', currentUserReviewSummary.content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(reviewSummaries)]);

  useEffect(() => {
    if (isDirty) {
      autoSaveState.run('reviewSummary');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchContent]);

  const fetchReviewSummaries = async () => {
    const { data } = await getReviewSummaries({ userReviewId });

    setReviewSummaries(data.reviewSummaries);
  };

  useEffect(() => {
    fetchReviewSummaries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId]);

  const openMySummaryTooltip = useMemo(() => {
    const statusProps =
      STATUS_PROVIDER[status === USER_REVIEW_STATUS.PUBLISHED ? STATUS.UPCOMING : status];
    if (statusProps) {
      return (
        <Trans
          id="You can't edit your summary because the review has status {status}."
          // @ts-ignore
          values={{
            status: statusProps.text(i18n),
          }}
        />
      );
    }

    return '';
  }, [i18n, status]);

  return {
    isVisible,
    formMethods,
    autoSaveState,
    reviewSummaries,
    openMySummaryTooltip,
  };
};

export { useSummary };
