import React from 'react';

export enum BANNER_TYPES {
  INFO = 'info',
  WARNING = 'warning',
  DELETE = 'delete',
}

export enum ACTIONS_POSITION {
  ROW = 'row',
  COLUMN = 'column',
}

export interface IBannerProps {
  type?: BANNER_TYPES;
  actions?: React.ReactNode;
  title?: string;
  subTitle?: string;
  isColumnView?: boolean;
  actionsPosition?: ACTIONS_POSITION;
  bannerHoverIcon?: React.ReactNode;
  isBannerHovered?: boolean;
  setIsBannerHovered?: (isBannerHovered: boolean) => void;
}
