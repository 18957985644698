import { createReducer } from 'redux-act';

import * as actions from './actions';

export default createReducer(
  {
    [actions.setJobProfile]: (state, payload) => {
      return {
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    },
    [actions.setJobProfiles]: (_state, payload = {}) => {
      return {
        data: payload,
      };
    },
    [actions.updateSkillsToJobProfile]: (state, payload) => {
      return {
        data: {
          ...state.data,
          [payload.id]: payload,
        },
      };
    },
    [actions.finishUpdateJobProfiles]: (state, updatedJobProfiles) => {
      const newJPs = { ...state.data };
      updatedJobProfiles.forEach((updatedJP) => (newJPs[updatedJP.id] = updatedJP));

      return {
        ...state,
        data: newJPs,
      };
    },
    [actions.setGeneratedJobProfile]: (state, payload) => {
      // Save the `payload` into the `generatedJobProfile` field of the state
      return { ...state, generatedJobProfile: payload };
    },
  },
  {},
);
