import React, { ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import moment from 'moment';

import { Button, ButtonVariant } from '~/components/Buttons';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import RickTextView from '~/components/RickTextView';
import { UserAvatar } from '~/components/UserAvatar';

import { Content, Header, IconWrapper, Summary, Title, Wrapper } from './design';

import { COLORS } from '~/styles';

import type { IReviewSummary } from '@learned/types';

interface SummariesProps {
  reviewSummaries: IReviewSummary[];
  onOpenMySummary: () => void;
  isOpenMySummaryDisabled?: boolean;
  openMySummaryTooltip?: ReactNode;
}

const Summaries = ({
  reviewSummaries,
  onOpenMySummary,
  isOpenMySummaryDisabled,
  openMySummaryTooltip,
}: SummariesProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.SUMMARY} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Summary</Trans>
        </Title>
        <Button
          variant={isOpenMySummaryDisabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
          label={i18n._(t`Open my Summary`)}
          onClick={onOpenMySummary}
          disabled={isOpenMySummaryDisabled}
          tooltip={isOpenMySummaryDisabled ? openMySummaryTooltip : ''}
        />
      </Header>
      {isEmpty(reviewSummaries) ? (
        <Summary>
          <Content color={COLORS.PLACEHOLDERS}>
            <div className="arrow" />
            <Trans>No review summery created yet</Trans>
          </Content>
        </Summary>
      ) : (
        reviewSummaries.map(({ id, createdBy, content, meta }) => (
          <Summary key={id}>
            <div className="header">
              <UserAvatar userId={createdBy} />
              <span className="date">
                <Trans>Last updated</Trans>{' '}
                {moment(meta?.createdDate || meta?.lastModifiedDate).format('DD/MM/YYYY')}
              </span>
            </div>
            <Content>
              <div className="arrow" />
              <RickTextView html={content} />
            </Content>
          </Summary>
        ))
      )}
    </Wrapper>
  );
};

export { Summaries };
