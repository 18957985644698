import { ROLES } from '@learned/constants';

import { pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'user-reviews';
const defaultUserReviewId = ':userReviewId';

export const USER_REVIEW_DASHBOARD = new LinkConstructor(
  [ROLES.USER],
  ({ userReviewId = defaultUserReviewId }) => pageDashboard(routeName, { id: userReviewId }),
);
