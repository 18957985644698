import { ROLES } from '@learned/constants';

import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

// roadmap
export const ROADMAP = new LinkConstructor([ROLES.USER, ROLES.COACH, ROLES.ADMIN], () =>
  pageOverview('roadmap'),
);

export default {
  ROADMAP,
};
