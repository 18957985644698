import { ROLES } from '@learned/constants';

import {
  pageOverview,
  pageDashboard,
  pageSupervisionUser,
  pageCreate,
  pageUpdate,
} from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const careerRouteName = 'career';
const routeName = 'job-profiles';
const defaultJobProfileId = ':roleId';
const defaultUserId = ':userId';

export const JOB_PROFILES_ADMIN = new LinkConstructor(
  [ROLES.ADMIN],
  () => `${pageOverview(routeName)}`,
);

// dashboard
export const JOB_PROFILE = new LinkConstructor(
  [ROLES.USER, ROLES.COACH, ROLES.ADMIN],
  ({ roleId = defaultJobProfileId }) => pageDashboard(routeName, { id: roleId }),
);

export const JOB_CREATE = new LinkConstructor([ROLES.ADMIN], () => `${pageCreate(routeName)}`);

// update
export const JOB_UPDATE = new LinkConstructor(
  [ROLES.ADMIN],
  ({ roleId = defaultJobProfileId }) => `${pageUpdate(routeName, { id: roleId })}`,
);

// supervision
export const JOB_PROFILE_SUPERVISION_USER = new LinkConstructor(
  [ROLES.COACH, ROLES.ADMIN],
  ({ roleId = defaultJobProfileId, userId = defaultUserId }) =>
    pageSupervisionUser(routeName, { id: roleId, userId }),
);

// user career
export const USER_CAREER = new LinkConstructor(
  [ROLES.USER],
  () => `${pageOverview(careerRouteName)}`,
);
