import React from 'react';

import { type ITaskWithId } from '@learned/types';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { DeadlineLabel } from '~/components/DeadlineLabel';

import { SubText } from './subText';

import type { IColumnTable } from '~/@types/table';
import { TASKS_TYPES_OLD } from '~/constants';
import { getUsers } from '~/selectors/baseGetters';
import { COLORS } from '~/styles';
import getUserFullName from '~/utils/getUserFullName';

const Name = styled.div<{
  isGrayColor?: boolean;
}>`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: -0.18px;
  color: ${({ isGrayColor }) => (isGrayColor ? COLORS.TEXT_SECONDARY : COLORS.BLACK)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 420px;
`;

const Wrap = styled.div<{ isLeftMargin?: boolean }>`
  margin-left: ${(props) => (props.isLeftMargin ? '15px' : '0px')};
  width: 100%;
`;

const NameRow = styled.div<{ isCentered?: boolean; padding?: string }>`
  background-color: inherit;
  padding: ${({ padding }) => padding || 'unset'};
  min-width: 420px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

interface INameWithSubText {
  task: ITaskWithId;
  isLeftMargin?: boolean;
}

const NameWithSubText = ({ task, isLeftMargin = true }: INameWithSubText) => {
  const { i18n } = useLingui();
  const taskType = Object.values(TASKS_TYPES_OLD).find((type) => type.key === task.type);

  const user = useSelector(getUsers)[task.target.userId];

  return (
    <Wrap isLeftMargin={isLeftMargin}>
      <Name>
        {taskType
          ? taskType?.text(
              { ...task, target: { ...task.target, userName: user ? getUserFullName(user) : '' } },
              i18n,
            )
          : task.name}
      </Name>
      <SubText task={task} user={user} />
    </Wrap>
  );
};

const TaskNameRow = ({ task }: { task: ITaskWithId }) => {
  return (
    <NameRow>
      <FlexRow>
        <NameWithSubText isLeftMargin={false} task={task} />
      </FlexRow>
    </NameRow>
  );
};

const COLUMNS: IColumnTable<ITaskWithId>[] = [
  {
    name: '',
    accessor: 'task',
    renderCell: (task) => {
      return <TaskNameRow key={task.target.targetId} task={task} />;
    },
  },
  {
    name: '',
    accessor: 'date',
    renderCell: (task: ITaskWithId) => {
      return <DeadlineLabel deadline={task.deadline as string} />;
    },
  },
];

export { COLUMNS };
