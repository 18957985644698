import React, { Fragment, useContext } from 'react';

import { REPORT_TYPES } from '@learned/constants';
import { useLingui } from '@lingui/react';
import Tippy from '@tippyjs/react';

import { Loader } from '~/components/Buttons/components/Loader';

import {
  DescriptionContainer,
  LucaHeaderTable,
  LucaOuterTable,
  LucaTable,
  TableHeaderItem,
  TableItem,
  TableItemValue,
  LoadingContainer,
} from './LucaDescription.design';

import { EngagementReportContext } from '../EngagementContext';

let COLUMN_COUNT = 5;

type TProps = {
  ratings?: {
    date: string;
    pName: string;
    rating: number;
    comment: string;
    user_name: string;
    question_name: string;
    user_id: string;
    pId: string;
  }[];
  isLoading: boolean;
};

const evaluateCharLength = (text?: string) => !!(text && text.length < 24);

const LucaRatingsTable = ({ ratings, isLoading }: TProps): JSX.Element => {
  const { i18n } = useLingui();
  let titles: string[] = [];

  const { reportType } = useContext(EngagementReportContext);

  if (reportType === REPORT_TYPES.ENGAGEMENT) {
    titles = [
      i18n._('Date'),
      i18n._('Survey name'),
      i18n._('Question'),
      i18n._('Rating'),
      i18n._('Comment'),
    ];
    COLUMN_COUNT = 5;
  } else if (reportType === REPORT_TYPES.PERFORMANCE) {
    titles = [
      i18n._('Date'),
      i18n._('Member'),
      i18n._('Review'),
      i18n._('Question'),
      i18n._('Rating'),
      i18n._('Comment'),
    ];
    COLUMN_COUNT = 6;
  }

  return (
    <DescriptionContainer>
      {isLoading && (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      )}
      {ratings && ratings.length > 0 && (
        <LucaOuterTable columnCount={COLUMN_COUNT} reportType={reportType}>
          <LucaHeaderTable columnCount={COLUMN_COUNT} rowCount={1}>
            {titles.map((title) => (
              <TableHeaderItem key={title}>{title}</TableHeaderItem>
            ))}
          </LucaHeaderTable>
          <LucaTable columnCount={COLUMN_COUNT} rowCount={ratings.length} isLoading={isLoading}>
            {ratings.map((row, rowIndex) => {
              const rowPosition = rowIndex % 2 === 0 ? true : false;
              return (
                <Fragment key={`${rowIndex}-date`}>
                  <TableItem isPositionedOdd={rowPosition}>{row.date}</TableItem>
                  {reportType === REPORT_TYPES.PERFORMANCE && (
                    <TableItem isPositionedOdd={rowPosition}>{row.user_name}</TableItem>
                  )}
                  <TableItem isPositionedOdd={rowPosition} showHoverStyles>
                    <Tippy
                      content={row.pName}
                      offset={[18, 10]}
                      disabled={evaluateCharLength(row.pName)}
                    >
                      <TableItemValue>{row.pName}</TableItemValue>
                    </Tippy>
                  </TableItem>
                  <TableItem isPositionedOdd={rowPosition} showHoverStyles>
                    <Tippy
                      content={row.question_name}
                      offset={[18, 10]}
                      disabled={evaluateCharLength(row.question_name)}
                    >
                      <TableItemValue>{row.question_name}</TableItemValue>
                    </Tippy>
                  </TableItem>
                  <TableItem isPositionedOdd={rowPosition}>{row.rating}</TableItem>
                  <TableItem isPositionedOdd={rowPosition} showHoverStyles>
                    <Tippy
                      content={row.comment}
                      offset={[18, 10]}
                      disabled={evaluateCharLength(row.comment)}
                    >
                      <TableItemValue>{row.comment}</TableItemValue>
                    </Tippy>
                  </TableItem>
                </Fragment>
              );
            })}
          </LucaTable>
        </LucaOuterTable>
      )}
    </DescriptionContainer>
  );
};

export { LucaRatingsTable };
