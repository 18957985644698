import { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, USER_REVIEW_STATUS } from '@learned/constants';
import { IUserReview, ITask, JOIN_USER_REVIEW_BY_ID } from '@learned/types';
import isEmpty from 'lodash/isEmpty';
import moment, { Moment } from 'moment/moment';
import { useSelector } from 'react-redux';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser, getUsers } from '~/selectors/baseGetters';
import { getUserReview } from '~/services/userReviews';
import getUserFullName from '~/utils/getUserFullName';

import type { IPlanCalendarEventForm } from '../types';
import type { UseFormReturn } from 'react-hook-form';

interface IUseReviewProps {
  formMethods: UseFormReturn<IPlanCalendarEventForm>;
  userReviewId: IUserReview['id'];
  onClose: (isRefresh?: boolean) => void;
}

export const useUserReview = ({ formMethods, userReviewId, onClose }: IUseReviewProps) => {
  const { setValue } = formMethods;
  const user = useSelector(getUser);
  const users = useSelector(getUsers);
  const getMultiLangString = useMultiLangString();
  const [isLoading, setIsLoading] = useState(false);

  const [item, setItem] = useState<IUserReview>();

  const { watch } = formMethods;

  // init values
  const initDate = watch('initDate');
  const initStartTime = watch('initStartTime');
  const initEndTime = watch('initEndTime');
  const initIncludeLinkMeeting = watch('initIncludeLinkMeeting');

  // selected values
  const selectedDate = watch('selectedDate');
  const selectedStartTime = watch('selectedStartTime');
  const selectedEndTime = watch('selectedEndTime');
  const selectedIncludeLinkMeeting = watch('selectedIncludeLinkMeeting');

  const isArchived = item?.status === USER_REVIEW_STATUS.ARCHIVED;

  const isReviewBlockedForChanges = isArchived;

  const isValuesChanged =
    !moment(initDate).isSame(moment(selectedDate)) ||
    !moment(initStartTime).isSame(moment(selectedStartTime)) ||
    !moment(initEndTime).isSame(moment(selectedEndTime)) ||
    initIncludeLinkMeeting !== selectedIncludeLinkMeeting;
  const isAllowToSubmit = isValuesChanged && !isReviewBlockedForChanges && !isArchived;

  const fetchUserReview = async () => {
    const result = await getUserReview(userReviewId, {
      join: [
        JOIN_USER_REVIEW_BY_ID.TASKS,
        JOIN_USER_REVIEW_BY_ID.EMPLOYEE_TASKS_IN_OTHER_USER_REVIEWS,
      ],
    });
    const userReview: IUserReview & { tasks: ITask[]; employeeTasksInOtherUserReviews: ITask[] } =
      result.data[API_RETURN_FIELDS.USER_REVIEW];
    setItem(userReview);
    return userReview;
  };

  const setFormValues = async () => {
    setIsLoading(true);
    const userReview = await fetchUserReview();
    const employeeId = userReview.createdFor;
    const employee = users[employeeId];
    const reviewName = getMultiLangString(userReview.name);
    setValue('employee', employee);
    setValue('dateOfConversation', userReview.dateOfConversation);
    setValue('dateOfConversationEnd', userReview.dateOfConversationEnd);
    setValue('userFullName', getUserFullName(employee));
    setValue('reviewName', reviewName);

    // calendar event
    const calendarIntegration = user?.integrations?.find(
      (integration: any) => !integration.isRevoked && !integration.isDeleted,
    );
    const hasIntegration = !isEmpty(calendarIntegration);
    setValue('hasIntegration', hasIntegration);

    const _calendarEvent = {
      title: getMultiLangString(userReview.name),
      description: getMultiLangString(userReview.description),
      // @ts-ignore TODO add calendarEvent in api
      // linkMeeting:
      //   userReview?.calendarEvent?.[`${calendarIntegration?.type}Connection`]?.linkMeeting,
      attendees: userReview.guests.map((guestId) => users[guestId]?.email).filter((email) => email),
    };

    setIsLoading(false);
  };

  useEffect(() => {
    setFormValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReviewId]);

  const calcTime = () => {
    const initialDate = moment(selectedDate).format('DD/MM/YYYY');
    const initialTime = moment(selectedStartTime).format('HH:mm');
    const fullDate = moment(initialDate + ' ' + initialTime, 'DD/MM/YYYY HH:mm');
    const initialDate2 = moment(selectedDate).format('DD/MM/YYYY');
    const initialTime2 = moment(selectedEndTime).format('HH:mm');
    const fullDate2 = moment(initialDate2 + ' ' + initialTime2, 'DD/MM/YYYY HH:mm');

    return { startTime: fullDate, endTime: fullDate2 };
  };

  const onSelect = (key: any, value: any) => setValue(key, value);

  const onSubmit = async () => {
    setIsLoading(true);
    const _time = calcTime();

    // create/update event
    // TODO add api call

    onClose(true);
    setIsLoading(false);
  };

  const onChangeDate = (value: string | null) => {
    setValue('selectedDate', convertDateToISOString(moment(value)));
  };

  const onChangeStartTime = (value: string | null) => {
    const selectedEndTime = watch('selectedEndTime');

    const startHours = moment(value).hours();
    const startMinutes = moment(value).minutes();
    const startTime = moment().hours(startHours).minutes(startMinutes);

    // set startTime
    setValue('selectedStartTime', convertDateToISOString(startTime));

    // set endTime = startTime + 30 min, when
    //  - endTime does not exist
    //  - startTime > endTime
    const isStartTimeLaterEndTime =
      startTime &&
      selectedEndTime &&
      moment(startTime).format('HH:mm') >= moment(selectedEndTime).format('HH:mm');
    if (!selectedEndTime || isStartTimeLaterEndTime) {
      setValue('selectedEndTime', convertDateToISOString(startTime.clone().add(30, 'minute')));
    }
  };

  const onChangeEndTime = (value: string | null) => {
    const selectedStartTime = watch('selectedStartTime');

    const endHours = moment(value).hours();
    const endMinutes = moment(value).minutes();
    const endTime = moment().hours(endHours).minutes(endMinutes);

    // set endTime
    setValue('selectedEndTime', convertDateToISOString(endTime));

    // set startTime = endTime - 30 min, when
    // - startTime does not exist
    // - startTime > endTime
    const isStartTimeLaterEndTime =
      selectedStartTime &&
      endTime &&
      moment(selectedStartTime).format('HH:mm') >= moment(endTime).format('HH:mm');

    if (!selectedStartTime || isStartTimeLaterEndTime) {
      setValue('selectedStartTime', convertDateToISOString(endTime.clone().add(-30, 'minute')));
    }
  };

  const convertDateToISOString = (date: Moment) => {
    return date.startOf('minute').toISOString();
  };

  return {
    isLoading,
    isAllowToSubmit,
    onSubmit,
    onSelect,
    onChangeDate,
    onChangeStartTime,
    onChangeEndTime,
  };
};
