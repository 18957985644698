import { TASK_STATUS } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { transparentize } from 'polished';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

export const TASK_STATUS_ITEMS = {
  [TASK_STATUS.UPCOMING]: {
    key: TASK_STATUS.UPCOMING,
    text: (i18n: I18n) => i18n._(t`Upcoming`),
    icon: null,
    color: COLORS.STATUS_UPCOMING,
    backGroundColor: transparentize(0.8, COLORS.STATUS_UPCOMING),
  },
  [TASK_STATUS.TODO]: {
    key: TASK_STATUS.TODO,
    text: (i18n: I18n) => i18n._(t`To Do`),
    icon: ICONS.DROPDOWN,
    color: COLORS.TODO_BLUE,
    backGroundColor: transparentize(0.8, COLORS.TODO_BLUE),
  },
  [TASK_STATUS.ACTIVE]: {
    key: TASK_STATUS.ACTIVE,
    text: (i18n: I18n) => i18n._(t`In progress`),
    icon: ICONS.IN_PROGRESS,
    color: COLORS.STATUS_IN_PROGRESS,
    backGroundColor: transparentize(0.8, COLORS.STATUS_IN_PROGRESS),
  },
  [TASK_STATUS.COMPLETED]: {
    key: TASK_STATUS.COMPLETED,
    text: (i18n: I18n) => i18n._(t`Completed`),
    icon: ICONS.CHECKMARK,
    color: COLORS.STATUS_COMPLETED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_COMPLETED),
  },
  [TASK_STATUS.DECLINED]: {
    key: TASK_STATUS.DECLINED,
    text: (i18n: I18n) => i18n._(t`Declined`),
    icon: ICONS.DECLINED,
    color: COLORS.STATUS_DECLINED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_DECLINED),
  },
  [TASK_STATUS.EXPIRED]: {
    key: TASK_STATUS.EXPIRED,
    text: (i18n: I18n) => i18n._(t`Expired`),
    icon: ICONS.EXPIRED,
    color: COLORS.STATUS_EXPIRED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_EXPIRED),
  },
  [TASK_STATUS.CALIBRATE]: {
    key: TASK_STATUS.CALIBRATE,
    text: (i18n: I18n) => i18n._(t`Calibrate`),
    icon: ICONS.CALIBRATE,
    color: COLORS.STATUS_CALIBRATE,
    backGroundColor: transparentize(0.8, COLORS.STATUS_CALIBRATE),
  },
};

export const TASK_STATUS_OPTIONS = [
  TASK_STATUS.UPCOMING,
  TASK_STATUS.TODO,
  TASK_STATUS.ACTIVE,
  TASK_STATUS.CALIBRATE,
  TASK_STATUS.COMPLETED,
  TASK_STATUS.DECLINED,
  TASK_STATUS.EXPIRED,
].map((key) => {
  const status = TASK_STATUS_ITEMS[key];
  return {
    id: status.key,
    key: status.key,
    name: status.key,
    translated: status.text,
  };
});
