export enum MODEL_TYPE {
  NONE = 'NONE',
  ADD_JOB_GROUP = 'ADD_JOB_GROUP',
  ADD_JOB_FAMILY = 'ADD_JOB_FAMILY',
  ADD_JOB_LEVEL = 'ADD_JOB_LEVEL',

  EDIT_JOB_GROUP = 'EDIT_JOB_GROUP',
  EDIT_JOB_FAMILY = 'EDIT_JOB_FAMILY',

  DELETE_JOB_GROUP = 'DELETE_JOB_GROUP',
}

export enum MULTI_LANG {
  en_GB = 'en_GB',
  nl_NL = 'nl_NL',
  de_DE = 'de_DE',
}

export enum MODAL_TYPE {
  NEW_JOB_PROFILE = 'NEW_JOB_PROFILE',
  IMPORT_ROLES = 'IMPORT_ROLES',
  CLEANUP_ROLES = 'CLEANUP_ROLES',
  SYNC_ROLES = 'SYNC_ROLES',
  ROLE_RELEVANCIES = 'ROLE_RELEVANCIES',
  CREATE_JOB_FROM_LUCA = 'CREATE_JOB_FROM_LUCA',
}

export enum VIEW_TYPE {
  JOB_VIEW = 'JOB_VIEW',
  JOB_EDIT = 'JOB_EDIT',
}
