import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.div`
  display: inline-flex;
`;

export const Counter = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 18px;
  padding-left: 4px;
`;

export const NextStepsWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 120px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 40px;
`;

export const NextStepCard = styled.div`
  display: flex;
  align-items: start;
  gap: 16px;
  background-color: ${COLORS.WHITE};
  border-radius: 8px;
  padding: 12px;
  box-sizing: border-box;
  margin-bottom: 8px;

  .main {
    width: 100%;
  }

  .menu {
    visibility: hidden;
  }

  &:hover {
    background-color: ${COLORS.HOVER};
    .menu {
      visibility: visible;
    }
  }
`;

export const Content = styled.div<{ isCompleted: boolean }>`
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  text-decoration: ${({ isCompleted }) => (isCompleted ? 'line-through' : 'unset')};
  margin-bottom: 14px;
  word-break: break-all;
`;
