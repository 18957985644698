import React, { useEffect, useState } from 'react';

import { GOAL_PROGRESS_TYPES, GOAL_TYPES, GOAL_STATUSES_NEW } from '@learned/constants';
import { ICreateGoal, IGoal } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import Modal from '~/components/Modal';
import Divider from '~/components/UI/Divider';
import defaultGoal from '~/pages/GoalSetup/defaultGoal';
import { GoalError } from '~/pages/GoalSetup/types';
import { IPopulatedReviewTask } from '~/pages/ReviewGiveFeedback/types';

import { FooterContainer } from './design';
import { Header } from './Header';
import { StepOne } from './StepOne';
import { StepTwo } from './StepTwo';

import useBoolState from '~/hooks/useBoolState';
import { getUser } from '~/selectors/baseGetters';
import { getCurrentGoal } from '~/selectors/currentGoal';
import { getGoal } from '~/services/goals';
import * as currentGoalActions from '~/store/currentGoal/actions';
import noMultyRun from '~/utils/noMultyRun';

interface IProps {
  isUpdate: boolean;
  onClose: () => void;
  refetchGoals: () => void;
  handleDelete: (goal: IGoal) => void;
  updatePublishedGoal: (goal: ICreateGoal) => void;
  updateGoal: (goal: ICreateGoal, isDraft: boolean) => Promise<void>;
  handleCreateGoal: (goal: ICreateGoal, isDraft: boolean) => Promise<void>;
  userFrom?: IPopulatedReviewTask['userFrom'];
}

const Container = styled.div<{ padding?: string }>`
  max-height: 80vh;
  height: max-content;
  overflow: auto;
  padding: ${({ padding }) => (padding ? padding : '0 14px')};
`;

const LeftContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const RightContent = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 14px;
  letter-spacing: -0.16px;
  color: #46474e;

  & > span {
    color: #8389a0;
  }
`;

export const CreateGoalModal = ({
  userFrom,
  onClose,
  updateGoal,
  refetchGoals,
  handleDelete,
  handleCreateGoal,
  updatePublishedGoal,
  isUpdate = false,
}: IProps) => {
  const { i18n } = useLingui();
  const $loading = useBoolState(false);
  const $isUpdate = useBoolState(isUpdate);
  const $isButtonsDisabled = useBoolState(isUpdate);

  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const goal = useSelector(getCurrentGoal);
  // const [savedType, setSavedType] = useState<SAVED_TYPES | null>(null);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    const fetch = async () => {
      let selectedGoal;
      if (isUpdate && goal) {
        // @ts-ignore
        selectedGoal = await getGoal(goal.id, ['activities', 'goalCycles', 'skills'], {
          isFillParent: true,
          isGetProgress: true,
        });
      } else {
        selectedGoal = await defaultGoal({
          type: GOAL_TYPES.PERSONAL,
          currentUser,
        });
      }

      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoal(selectedGoal));
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalOwners([userFrom || '']));
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  const onBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const validateGoal = ({ isDraft }: { isDraft: boolean }) => {
    let error: GoalError = {};
    if (!goal.name) {
      error.name = i18n._(t`Goal name is required`);
    }

    if (isEmpty(goal.owners)) {
      error.owners = i18n._(t`At least one owner is required`);
    }

    if (isDraft) {
      // @ts-ignore
      dispatch(currentGoalActions.setCurrentGoalErrors(error));
      return isEmpty(error);
    }

    if (goal.type === GOAL_TYPES.TEAM && isEmpty(goal.teams)) {
      error.teams = i18n._(t`Team is required`);
    }

    if (
      (goal.progressType === GOAL_PROGRESS_TYPES.AVG || goal.isCurrentGoalAddActivities) &&
      isEmpty(goal.activities)
    ) {
      error.activities = i18n._(t`At least one activity is required`);
    }

    if ([GOAL_PROGRESS_TYPES.CURRENCY, GOAL_PROGRESS_TYPES.NUMBERS].includes(goal.progressType)) {
      if (
        goal.progressDetails?.min === undefined ||
        goal.progressDetails?.min < 0 ||
        goal.progressDetails?.min >= goal.progressDetails?.max
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        goal.progressDetails?.max === undefined ||
        goal.progressDetails?.max < 0 ||
        goal.progressDetails?.max <= goal.progressDetails?.min
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }

    if (GOAL_PROGRESS_TYPES.PERCENTAGE === goal.progressType) {
      if (
        goal.progressDetails?.min === undefined ||
        goal.progressDetails?.min < 0 ||
        goal.progressDetails?.min >= goal.progressDetails?.max
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, min: i18n._(t`Invalid min value`) },
        };
      }
      if (
        goal.progressDetails?.max === undefined ||
        goal.progressDetails?.max < 0 ||
        goal.progressDetails?.max <= goal.progressDetails?.min
      ) {
        error = {
          ...error,
          progressDetails: { ...error.progressDetails, max: i18n._(t`Invalid max value`) },
        };
      }
    }
    // @ts-ignore
    dispatch(currentGoalActions.setCurrentGoalErrors(error));
    return isEmpty(error);
  };

  const handleSubmit = noMultyRun(async (isDraft = false) => {
    $loading.on();

    if (validateGoal({ isDraft })) {
      $isButtonsDisabled.on();
      if (!$isUpdate.value) {
        await handleCreateGoal(goal, isDraft);
      } else {
        if (isDraft && goal.status === GOAL_STATUSES_NEW.DRAFT) {
          await updateGoal(goal, isDraft);
        } else {
          updatePublishedGoal(goal);
        }
      }
      refetchGoals();
      $isButtonsDisabled.off();
      $loading.off();
    }
  });

  return (
    <Modal width={750} hideFooter isHideHeader centerModal showDivider={false}>
      <Header
        isUpdate={$isUpdate.value}
        handleOnDeleteClick={() => handleDelete(goal)}
        handleOnSaveClick={() => handleSubmit(true)}
        onClose={onClose}
      />
      <Divider />
      <Container>{currentStep === 1 && <StepOne isUpdate={$isUpdate.value} />}</Container>
      <Container padding="unset">
        {currentStep === 2 && <StepTwo isUpdate={$isUpdate.value} />}
      </Container>
      <FooterContainer>
        <LeftContent>
          <Button
            label={i18n._(t`Cancel`)}
            onClick={onClose}
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
          />
          <StepCount>
            Step {currentStep} <span>of 2</span>
          </StepCount>
        </LeftContent>
        <RightContent>
          {currentStep === 2 && (
            <Button
              label={i18n._(t`Back`)}
              onClick={onBack}
              icon={ICONS.BACK}
              iconSize={ICON_SIZES.SMALL}
              variant={ButtonVariant.SECONDARY}
              size={ButtonSize.MEDIUM}
            />
          )}
          <Button
            label={currentStep === 1 ? i18n._(t`Next`) : i18n._(t`Publish`)}
            onClick={
              currentStep === 1 ? () => setCurrentStep(currentStep + 1) : () => handleSubmit(false)
            }
            variant={ButtonVariant.NAVIGATION_PRIMARY}
            size={ButtonSize.MEDIUM}
          />
        </RightContent>
      </FooterContainer>
    </Modal>
  );
};
