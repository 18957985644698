import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { FocusAreaType } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, type UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';
import { AddSkillModal } from '~/components/Modals/AddSkillModal';
import { AddSkillModalResource, WatchSkill } from '~/components/Modals/AddSkillModal/types';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';
import Switch from '~/components/Switch';

import { SkillTemplateTable } from './components/SkillTemplatesTable';
import { StepFooter } from './components/StepFooter';
import { Description, Form, Header, StyledSkillLevelsDropdown, Title } from './design';
import { IAutoCompleteSkills, IGeneralForm } from './types';

import { ISelectedFocusArea } from '~/@types/job';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { generateSkillsForJobProfileCategory } from '~/services/jobProfiles';
import { COLORS } from '~/styles';

import type { ISkill, ISkillCategory, ISkillTemplate } from '@learned/types';

type StepSkillCategoryProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategories: ISkillCategory[];
  skillTemplates: ISkillTemplate[];
  skills: ISkill[];
  setSkillTemplates: Dispatch<SetStateAction<ISkillTemplate[]>>;
  setSkills: Dispatch<SetStateAction<ISkill[]>>;
  jobTemplateId?: string;
  languageState: ILanguageStateReturn;
};

function StepSkillCategory({
  sectionState,
  formMethods,
  skillCategories,
  skillTemplates,
  skills,
  setSkillTemplates,
  setSkills,
  jobTemplateId,
  languageState,
}: StepSkillCategoryProps) {
  const { handleSubmit, register, control, watch, setValue } = formMethods;
  const { i18n } = useLingui();
  const [isAddSkillModalVisible, setIsAddSkillModalVisible] = useState(false);
  const [source, setSource] = useState<ISkillTemplate | ISkill>();

  const getMultiLangString = useMultiLangString();

  const jobName = watch('name')?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  );

  const { skillCategoryId, skillCategoryTemplateId, skillCategoryName, skillLevels } =
    useMemo(() => {
      const skillCategoryId = sectionState.sections[sectionState.currentSection].id;
      const skillCategoryName = sectionState.sections[sectionState.currentSection].title;
      const skillCategory = skillCategories.find(({ id }) => id === skillCategoryId);
      const skillLevels = skillCategory?.skillLevels.map((_, index) => ++index) || [];
      const skillCategoryTemplateId = skillCategory?.skillCategoryTemplate || undefined;

      return { skillCategoryId, skillCategoryName, skillLevels, skillCategoryTemplateId };
    }, [sectionState.currentSection, sectionState.sections, skillCategories]);

  const onSubmit = () => sectionState.setCurrentSection(1);

  useEffect(() => {
    if (source) {
      setIsAddSkillModalVisible(true);
    }
  }, [source]);

  const onGenerateSkills = async () => {
    // If no job name, skip it
    if (!jobName?.value) {
      return;
    }

    const currentSkills = watch(`skills.${skillCategoryId}`).skills as WatchSkill[];

    // Get the current selected/populated skills
    const currentSkillNames = skills
      .filter((skill) => {
        return currentSkills.find((item) => item.skill === skill.id);
      })
      .map((skill) => getMultiLangString(skill.name));

    const currentTemplateSkillNames = skillTemplates
      .filter((skill) => {
        return currentSkills.find((item) => item.skill === skill.id);
      })
      .map((skill) => getMultiLangString(skill.name));

    // Get generated skills
    const {
      data,
    }: {
      data: IAutoCompleteSkills;
    } = await generateSkillsForJobProfileCategory(skillCategoryId, {
      name: jobName?.value,
      locales: languageState.companyLanguages.map((item) => item.locale),
      existingSkillNames: [...currentSkillNames, ...currentTemplateSkillNames],
    });

    // Populate Skills
    data.skills.forEach((generatedSkill) => {
      currentSkills.push({
        skill: generatedSkill.id,
        selectedFocusAreas: [
          {
            level: watch(`skills.${skillCategoryId}`).defaultFocusAreaLevel,
            type: FocusAreaType.SELECT_LEVEL,
          },
        ],
      } as WatchSkill);
    });

    data.skillTemplates.forEach((generatedSkill) => {
      currentSkills.push({
        skillTemplate: generatedSkill.id,
        selectedFocusAreas: [
          {
            level: watch(`skills.${skillCategoryId}`).defaultFocusAreaLevel,
            type: FocusAreaType.SELECT_LEVEL,
          },
        ],
      } as WatchSkill);
    });

    const newSkills = data.skills.map((item) => ({
      ...item,
      skillCategory: skillCategoryId,
    })) as ISkill[];

    const newTemplateSkills = data.skillTemplates.map((item) => ({
      ...item,
      skillCategory: skillCategoryTemplateId,
    })) as ISkillTemplate[];

    setSkills([...skills, ...newSkills]);
    setSkillTemplates([...skillTemplates, ...newTemplateSkills]);

    setValue(
      `skills.${skillCategoryId}.skills`,
      currentSkills as {
        skill?: string;
        skillTemplate?: string;
        selectedFocusAreas: ISelectedFocusArea[];
      }[],
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Header>
        <Title>{skillCategoryName}</Title>
        <div className="buttons">
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.SECONDARY}
            label={i18n._(t`Generate skills`)}
            onClick={onGenerateSkills}
          />
          <Button
            type="button"
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            icon={ICONS.ADD_PLUS}
            iconSize={ICON_SIZES.SMALL}
            label={i18n._(t`Add skill or KPI`)}
            onClick={() => {
              setIsAddSkillModalVisible(true);
            }}
          />
        </div>
      </Header>
      <Header marginBottom="20px">
        <div className="switchWrapper">
          <Controller
            {...register(`skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`)}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Switch onChange={onChange} checked={value} className="switch" />
            )}
          />
          <Description color={COLORS.TEXT_HOVER} width="316px">
            <Trans>Automatically add the focus areas for the selected skill level</Trans>
          </Description>
        </div>
        <Controller
          {...register(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          control={control}
          render={({ field: { onChange } }) => (
            <StyledSkillLevelsDropdown
              selectedItem={skillLevels.find(
                (value) => value === watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`),
              )}
              items={skillLevels}
              onChange={onChange}
              stringifyItem={(item) => i18n._(t`Level ${item}`)}
              isSingleSelect
            />
          )}
        />
      </Header>
      <SkillTemplateTable
        skillCategoryId={skillCategoryId}
        formMethods={formMethods}
        skillTemplates={skillTemplates}
        skills={skills}
        setSource={setSource}
        skillCategories={skillCategories}
        isEditMode
      />
      <StepFooter
        onPrev={() => sectionState.setCurrentSection(--sectionState.currentSection)}
        onNext={() => sectionState.setCurrentSection(++sectionState.currentSection)}
      />
      {isAddSkillModalVisible && skillCategoryId && (
        <AddSkillModal
          onClose={() => {
            setIsAddSkillModalVisible(false);
            setSource(undefined);
          }}
          skillCategoryName={skillCategoryName}
          defaultFocusAreaLevel={watch(`skills.${skillCategoryId}.defaultFocusAreaLevel`)}
          isDefaultFocusAreaLevelEnabled={watch(
            `skills.${skillCategoryId}.isDefaultFocusAreaLevelEnabled`,
          )}
          formMethods={formMethods}
          skillCategoryId={skillCategoryId}
          skillCategoryTemplateId={skillCategoryTemplateId}
          initSource={source}
          resource={AddSkillModalResource.SKILLS}
          setSkillTemplates={setSkillTemplates}
          setSkills={setSkills}
          skillLevels={skillLevels}
          jobTemplateId={jobTemplateId}
          skillTemplates={skillTemplates}
          selectedSkills={skills}
        />
      )}
    </Form>
  );
}

export { StepSkillCategory };
