import React, { useState } from 'react';
import type { Dispatch } from 'react';

import { Locals_all } from '@learned/constants';
import { IGeneratedFocusArea, IMultiLangString, ISkillCategory } from '@learned/types';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import nanoid from 'nanoid';
import { DragDropContext } from 'react-beautiful-dnd';
import { useFieldArray, UseFormReturn } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';

import { FocusAreas } from './components/FocusAreas';
import { StepFooter } from './components/StepFooter';
import { Form, Title, Subtitle, Levels, FocusAreaHeader, ActionContainerHeader } from './design';

import { FocusArea } from '~/constants/focusAreas';
import type { IBaseLanguageStateReturn } from '~/hooks/useLanguageState';
import {
  generateSkillFocusAreasSuperAdmin,
  generateTextTranslationSuperAdmin,
} from '~/services/completions';

import type { IGeneralForm } from './types';

type StepFocusAreasProps = {
  setCurrentSection: Dispatch<number>;
  formMethods: UseFormReturn<IGeneralForm>;
  skillCategory?: ISkillCategory;
  languageState: IBaseLanguageStateReturn;
};

function StepFocusAreas({
  setCurrentSection,
  skillCategory,
  languageState,
  formMethods,
}: StepFocusAreasProps) {
  const { i18n } = useLingui();

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);

  const { control, handleSubmit, getValues, setValue, watch } = formMethods;

  const { replace } = useFieldArray({
    control,
    name: 'focusAreas',
  });

  const skillName = watch('name').find(
    (language) => language.locale === languageState.primaryLanguage.locale,
  );

  const focusAreasFields: FocusArea[] = watch('focusAreas');

  const onSubmit = () => setCurrentSection(2);

  const areThereEmptyTextOnFocusAreas = () => {
    return focusAreasFields.some((item) =>
      item.values.some((value) => !value.name[languageState.primaryLanguage.locale]),
    );
  };

  const onTranslate = async () => {
    setIsTranslationLoading(true);

    const newFocusAreas: FocusArea[] = watch('focusAreas');

    // Getting strings from Primary Locale fields
    const focusAreaNames = newFocusAreas.flatMap((item) =>
      item.values.map((value) => value.name[languageState.primaryLanguage.locale]),
    );

    if (focusAreaNames.length === 0) {
      setIsTranslationLoading(false);
      return;
    }

    const response: {
      data: {
        translatedTexts: IMultiLangString[];
      };
    } = await generateTextTranslationSuperAdmin(focusAreaNames, [
      Locals_all.de_DE,
      Locals_all.nl_NL,
    ]);

    // Iterate over focusAreaNames and values to update translations
    let counter = 0;
    newFocusAreas.map((item, i) => {
      item.values.map((value, j) => {
        const newValue = value;
        newValue.name = {
          ...newValue.name,
          [Locals_all.de_DE as string]: response.data.translatedTexts[counter][Locals_all.de_DE],
          [Locals_all.nl_NL as string]: response.data.translatedTexts[counter][Locals_all.nl_NL],
        } as IMultiLangString;
        counter++;
        item.values[j] = newValue;
      });
      newFocusAreas[i] = item;
    });

    replace(newFocusAreas);
    setIsTranslationLoading(false);
  };

  const onGenerateFocusAreas = async () => {
    if (!skillName?.value || !skillCategory?.id) {
      return;
    }

    setIsGenerationLoading(true);

    const response = await generateSkillFocusAreasSuperAdmin(skillName.value, skillCategory?.id);
    const focusAreas = (response.data?.focusAreas as IGeneratedFocusArea[]) || [];
    const primaryLocale = languageState.primaryLanguage.locale;

    const newFocusAreas: FocusArea[] = watch('focusAreas');

    focusAreas.forEach((focusAreaItem, index) => {
      newFocusAreas[index].level = focusAreaItem.level;
      newFocusAreas[index].values = [];

      new Array(focusAreaItem.values.length).fill(true).map(() =>
        newFocusAreas[index].values.push({
          name: {},
          _id: nanoid(),
        }),
      );

      focusAreaItem.values.forEach((value, i) => {
        newFocusAreas[index].values[i].name = {
          [Locals_all.de_DE]: '',
          [primaryLocale]: value,
          [Locals_all.nl_NL]: '',
        };
      });
    });

    replace(newFocusAreas);
    setIsGenerationLoading(false);
  };

  const moveItem = ({
    sourceId,
    targetId,
    sourceIndex,
    targetIndex,
  }: {
    sourceId: string;
    targetId?: string;
    sourceIndex: number;
    targetIndex?: number;
  }) => {
    if (targetId === undefined || targetIndex === undefined) {
      return;
    }

    const sourceFocusAreaIndex = parseInt(sourceId, 10);
    const targetFocusAreaIndex = parseInt(targetId, 10);
    if (sourceId === targetId) {
      const fieldsCopy = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const [removed] = fieldsCopy.splice(sourceIndex, 1);
      fieldsCopy.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, fieldsCopy);
    } else {
      const sourceFields = getValues(`focusAreas.${sourceFocusAreaIndex}.values`);
      const targetFields = getValues(`focusAreas.${targetFocusAreaIndex}.values`);
      const [removed] = sourceFields.splice(sourceIndex, 1);
      targetFields.splice(targetIndex, 0, removed);
      setValue(`focusAreas.${sourceFocusAreaIndex}.values`, sourceFields);
      setValue(`focusAreas.${targetFocusAreaIndex}.values`, targetFields);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <FocusAreaHeader>
        <Title>
          <Trans>Levels & focus areas</Trans>
        </Title>
        {skillCategory && (
          <ActionContainerHeader>
            <Button
              className={'buttons'}
              type={'button'}
              label={i18n._(t`Translate`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              isLoading={isTranslationLoading}
              onClick={() => onTranslate()}
              disabled={
                isTranslationLoading || isGenerationLoading || areThereEmptyTextOnFocusAreas()
              }
            />
            <Button
              className={'buttons'}
              type={'button'}
              label={i18n._(t`Generate focus areas`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              isLoading={isGenerationLoading}
              onClick={() => onGenerateFocusAreas()}
              disabled={isTranslationLoading || isGenerationLoading || !skillName?.value}
            />
          </ActionContainerHeader>
        )}
      </FocusAreaHeader>

      <Subtitle>
        <Trans>
          A description of what characteristics an employee needs to meet. Add or remove levels if
          necessary.
        </Trans>
      </Subtitle>

      {!skillCategory && (
        <Trans>No category selected, go back to step 1 and select a category first</Trans>
      )}

      {!isGenerationLoading && !isTranslationLoading && skillCategory && (
        <Levels>
          <DragDropContext
            onDragEnd={(result) => {
              const sourceId = result.source.droppableId;
              const targetId = result.destination?.droppableId;

              const sourceIndex = result.source.index;
              const targetIndex = result.destination?.index;
              moveItem({ sourceId, targetId, sourceIndex, targetIndex });
            }}
          >
            {skillCategory.skillLevels.map((levelName, index) => (
              <FocusAreas
                formMethods={formMethods}
                levelName={levelName}
                key={index}
                index={index}
                languageState={languageState}
              />
            ))}
          </DragDropContext>
        </Levels>
      )}

      <StepFooter hideSeparator onPrev={() => setCurrentSection(0)} />
    </Form>
  );
}

export { StepFocusAreas };
