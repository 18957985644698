import styled from 'styled-components';

import { COLORS } from '~/styles';

const NoDataPlaceholderCtr = styled.div`
  pointer-events: none;
  user-select: none;
  font-family: Poppins;
  div#content {
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }

  span.title {
    font-size: 22px;
    color: ${COLORS.ICONS_PRIMARY};
    margin: 18px 121px 9px;
  }

  span.text-regular {
    font-size: 14px;
    color: ${COLORS.LABEL_GRAY};
    width: 438px;
    text-align: center;
    margin: 9px 0 0;
  }
`;

export { NoDataPlaceholderCtr };
