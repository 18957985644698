import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import RickTextView from '~/components/RickTextView';

const headerStyles = {
  padding: '0px 32px',
  fontSize: '24px',
};

import { Text } from '../design';

const modalContentStyle = {
  flex: '1',
  padding: '16px 31px 18px 32px',
};

interface IInvitationModalProps {
  message: string;
  onClose: () => void;
}

const InvitationModal = ({ onClose, message }: IInvitationModalProps) => {
  const { i18n } = useLingui();

  return (
    <Modal
      title={i18n._(t`Invitation message`)}
      onClose={onClose}
      minWidth={500}
      width={500}
      headerStyles={headerStyles}
      contentStyles={modalContentStyle}
      footerRight={
        <Button
          variant={ButtonVariant.SECONDARY}
          size={ButtonSize.MEDIUM}
          label={i18n._(t`Cancel`)}
          onClick={onClose}
        />
      }
    >
      <Text>
        <RickTextView html={message} />
      </Text>
    </Modal>
  );
};

export { InvitationModal };
