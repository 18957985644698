import React, { useEffect, useState } from 'react';

import { ISkillCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import { ISkillGenerated } from '~/pages/SkillSetsOverview/types';

import { CreateFromLuca } from './Templates/CreateFromLuca';

import { useLanguageState } from '~/hooks/useLanguageState';
import { getSkillCategories } from '~/services/skillCategories';
import { generateSkill } from '~/services/skills';
import { getCompanyLocales, LOCALES_ARRAY } from '~/utils/companyLanguages';

type CreateSkillFromLucaModalProps = {
  onClose?(): void;
  skillName?: string;
  onClickManually?(selectedCategoryId: string): void;
  onClickUsingAI?(data: ISkillGenerated, selectedCategoryId: string): void;
  defaultCategoryId?: ISkillCategory['id'];
};

const CreateSkillFromLucaModal = ({
  onClose,
  skillName,
  onClickManually,
  onClickUsingAI,
  defaultCategoryId,
}: CreateSkillFromLucaModalProps) => {
  const { i18n } = useLingui();

  const [loading, setLoading] = useState(false);
  const languageState = useLanguageState();
  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISkillCategory>();

  useEffect(() => {
    const fetch = async () => {
      const skillCategoriesMap = await getSkillCategories();
      setCategories(Object.values(skillCategoriesMap));
    };
    fetch();
  }, []);

  useEffect(() => {
    if (categories && defaultCategoryId) {
      const category = categories.find((s) => s.id === defaultCategoryId);
      setSelectedCategory(category);
      return;
    }
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [defaultCategoryId, categories]);

  const handleAICreation = async () => {
    setLoading(true);

    if (selectedCategory) {
      const { data }: { data: { skill: ISkillGenerated } } = await generateSkill({
        name: skillName,
        locales: getCompanyLocales(languageState, LOCALES_ARRAY.PRIMARY_FIRST),
        skillCategory: selectedCategory.id,
      });
      onClickUsingAI?.(data.skill, selectedCategory.id);
    }
    setLoading(false);
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      width={500}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <CreateFromLuca
        title={i18n._(t`Create a skill`)}
        subtitle={skillName}
        description={i18n._(t`Auto generate your skill with Luca AI?`)}
        loadingMessage={i18n._(t`Generating your skill, this might take a minute…`)}
        onClose={onClose}
        loading={loading}
        onClickManually={() => selectedCategory && onClickManually?.(selectedCategory.id)}
        onClickUsingAI={handleAICreation}
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={(item) => setSelectedCategory(item)}
      />
    </Modal>
  );
};

export { CreateSkillFromLucaModal };
