import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Banner } from '~/components/Banner';
import { BANNER_TYPES } from '~/components/Banner/types';
import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import { IntegrationSection } from '~/pages/Reviews/Modals/PlanCalendarEventModal/PlanCalendarEventForm/IntegrationSection';

import { Title, SubTitle, Row, ButtonMargin, SectionWrapper } from './design';
import { useUserReview } from './hooks/useUserReview';
import { InputRow } from './InputRow';
import { IPlanCalendarEventForm } from './types';

import type { IUserReview } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface IPlanCalendarEventFormProps {
  userReviewId: IUserReview['id'];
  formMethods: UseFormReturn<IPlanCalendarEventForm>;
  onClose: (isSubmit?: boolean) => void;
}

const PlanCalendarEventForm = ({
  userReviewId,
  formMethods,
  onClose,
}: IPlanCalendarEventFormProps) => {
  const { i18n } = useLingui();

  const { isLoading, isAllowToSubmit, onSubmit, onChangeDate, onChangeStartTime, onChangeEndTime } =
    useUserReview({
      userReviewId,
      formMethods,
      onClose,
    });

  const { watch } = formMethods;

  const selectedDate = watch('selectedDate');
  const selectedStartTime = watch('selectedStartTime');
  const selectedEndTime = watch('selectedEndTime');
  const _selectedIncludeLinkMeeting = watch('selectedIncludeLinkMeeting');
  const userFullName = watch('userFullName');
  const reviewName = watch('reviewName');

  return (
    <Modal
      title={
        <div>
          <Title>{i18n._(t`Set a date and time`)}</Title>
          <SubTitle>{i18n._(t`For ${userFullName}’s ${reviewName}`)}</SubTitle>
        </div>
      }
      onClose={() => onClose(false)}
      width={500}
      minWidth={500}
      headerStyles={{ padding: '0 20px 0 32px' }}
      contentStyles={{ padding: '0 32px' }}
      footerRight={
        <Row>
          <ButtonMargin>
            <Button
              label={i18n._(t`Cancel`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              isLoading={isLoading}
              onClick={() => onClose(false)}
            />
          </ButtonMargin>
          <Button
            label={i18n._(t`Save`)}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.PRIMARY}
            isLoading={isLoading}
            disabled={!isAllowToSubmit}
            onClick={onSubmit}
          />
        </Row>
      }
    >
      <div>
        <SectionWrapper>
          <InputRow
            selectedDate={selectedDate}
            onChangeDate={onChangeDate}
            selectedStartTime={selectedStartTime}
            onChangeStartTime={onChangeStartTime}
            selectedEndTime={selectedEndTime}
            onChangeEndTime={onChangeEndTime}
          />
        </SectionWrapper>
        <IntegrationSection />
        <SectionWrapper />

        <SectionWrapper>
          <Banner
            type={BANNER_TYPES.INFO}
            subTitle={i18n._(t`An e-mail will be sent to all participants`)}
          />
        </SectionWrapper>
      </div>
    </Modal>
  );
};

export { PlanCalendarEventForm };
