import React from 'react';

import { ROLES } from '@learned/constants';
import { Trans } from '@lingui/macro';

import { FilterJobTemplateStatus } from '~/components/AutocompleteFilters/JobTemplates';
import { ManageJobFamiliesModal } from '~/components/Modals/ManageJobFamiliesModal';
import { TableList } from '~/components/TableList';

import { JOB_TEMPLATES_COLUMNS } from './SuperAdminAllJobTemplates.columns';
import { Wrapper } from './SuperAdminAllJobTemplates.design';
import { useAllJobTemplates } from './useAllJobTemplates';

import routes from '~/constants/routes';

import { FilterForQualityTitle, FilterForQualityWrapper, StyledSwitch } from '../../design';

function SuperAdminAllJobLibrary() {
  const {
    isLoading,
    jobTemplates,
    filters,
    search,
    statuses,
    setStatuses,
    sortBy,
    setSortBy,
    pagination,
    changePagination,
    total,
    multiSelect,
    createMenuItems,
    actionButton,
    settingsButton,
    createJobTemplateButton,
    isManageJobFamiliesModalVisible,
    setIsManageJobFamiliesModalVisible,
    $isFilterCompetencies,
  } = useAllJobTemplates();

  const FilterCompetenciesJobs = () => {
    return (
      <FilterForQualityWrapper>
        <StyledSwitch
          checked={$isFilterCompetencies.value}
          onChange={$isFilterCompetencies.toggle}
        />
        <FilterForQualityTitle>
          <Trans>Filter competencies</Trans>
        </FilterForQualityTitle>
      </FilterForQualityWrapper>
    );
  };

  return (
    <Wrapper>
      <TableList
        isLoading={isLoading}
        data={jobTemplates}
        columns={JOB_TEMPLATES_COLUMNS}
        onRowClick={(item) => {
          routes.SUPERADMIN_JOB_TEMPLATE_VIEW.go(
            { role: 'super-admin' as ROLES },
            {
              jobTemplateId: item.id,
              isBackPath: true,
            },
          );
        }}
        filtersProps={{
          filters,
          isFiltered: !!search.length,
          isToggleHideFilterVisible: true,
          filterComponents: (
            <>
              <FilterJobTemplateStatus selectedItems={statuses} onChange={setStatuses} />
              <FilterCompetenciesJobs />
            </>
          ),
        }}
        sortProps={{ sortBy, setSortBy }}
        paginationProps={{ pagination, changePagination, totalCount: total }}
        multiSelectProps={{ isMultiSelectVisible: true, multiSelect }}
        menuProps={{ isMenuVisible: true, createMenuItems }}
        actionButton={actionButton}
        secondaryButton={settingsButton}
        thirdButton={createJobTemplateButton}
      />
      {isManageJobFamiliesModalVisible && (
        <ManageJobFamiliesModal closeModal={() => setIsManageJobFamiliesModalVisible(false)} />
      )}
    </Wrapper>
  );
}

export { SuperAdminAllJobLibrary };
