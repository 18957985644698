import React from 'react';

import { TASK_TYPE } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { COLORS } from '~/styles';

import { List } from '../../components/List';
import { ListRowSelf } from '../../components/ListRowSelf';
import { ListRow, SidebarSection } from '../../design';

import type { ITask } from '@learned/types';

const Wrapper = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Placeholder = styled.div`
  font-size: 14px;
  color: ${COLORS.PLACEHOLDERS};
`;

interface ISelfTasksSidebarSectionProps {
  tasks: ITask[];
  employeeFirstName: string;
  isEmployee: boolean;
  isAllowToGiveSelfReview: (task: ITask) => boolean;
  isAllowToNominatePeers: (task: ITask) => boolean;
  onGiveSelfReview: (task: ITask) => void;
  onNominatePeers: (task: ITask) => void;
}

const EmployeeTasksSidebarSection = ({
  tasks,
  isEmployee,
  employeeFirstName,
  isAllowToGiveSelfReview,
  isAllowToNominatePeers,
  onGiveSelfReview,
  onNominatePeers,
}: ISelfTasksSidebarSectionProps) => {
  const { i18n } = useLingui();

  const getTaskData = (task: ITask) => {
    switch (task.type) {
      case TASK_TYPE.REVIEW_SELF_EVALUATE:
        return {
          title: i18n._(t`Prepare your self review`),
          isShowButton: isAllowToGiveSelfReview,
          onClick: onGiveSelfReview,
        };
      case TASK_TYPE.REVIEW_PEER_NOMINATE:
      default:
        return {
          title: i18n._(t`Ask peers for input`),
          isShowButton: isAllowToNominatePeers,
          onClick: onNominatePeers,
        };
    }
  };

  return (
    <SidebarSection>
      <List>
        {isEmpty(tasks) ? (
          <ListRow>
            <Placeholder>
              {isEmployee
                ? i18n._(t`You do not have any tasks for this review.`)
                : i18n._(t`${employeeFirstName} do not have any tasks for this review.`)}
            </Placeholder>
          </ListRow>
        ) : (
          tasks.map((task, key) => {
            const data = getTaskData(task);

            return (
              <Wrapper key={key}>
                <ListRowSelf
                  title={data.title}
                  task={task}
                  isShowButton={data.isShowButton}
                  onClick={data.onClick}
                />
              </Wrapper>
            );
          })
        )}
      </List>
    </SidebarSection>
  );
};

export { EmployeeTasksSidebarSection };
