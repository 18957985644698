import type { ICreateServerRoute, IUpdateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

import type { ICreateOrUpdateReviewRatingRequest, IReviewRating } from '@learned/types';

export function createOrUpdateReviewRatings(data: ICreateOrUpdateReviewRatingRequest) {
  return cloudRequest(
    (serverRoutes.reviewRatings.createOrUpdate as ICreateServerRoute)(data.isAutoSave),
    {},
    data,
  ) as Promise<{
    code: number;
    data: { reviewRatings: IReviewRating[] };
  }>;
}

export function createOrUpdateOutsideReviewRatings(
  data: ICreateOrUpdateReviewRatingRequest,
  token: string,
) {
  const tokenEncoded = encodeURIComponent(token);
  return cloudRequest(
    (serverRoutes.reviewRatings.createOrUpdateOutside as IUpdateServerRoute)(
      tokenEncoded,
      data.isAutoSave,
    ),
    {},
    data,
    // @ts-ignore
    { noLogin: true },
  );
}
