import { useEffect, useState } from 'react';

import {
  JOB_TEMPLATE_STATUS,
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  JOB_TEMPLATES_SORT_OPTIONS,
  ROLES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';

import { ButtonVariant } from '~/components/Buttons';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TOAST_TYPES, useToasts } from '~/components/Toast';

import { JOB_TEMPLATE_STATUS_WITH_NAMES, type IJobTemplateStatus } from '~/constants/jobTemplate';
import routes from '~/constants/routes';
import { SUPERADMIN_JOB_TEMPLATE_EDIT } from '~/constants/routes/superadmin';
import useBoolState from '~/hooks/useBoolState';
import useDebounce from '~/hooks/useDebounce';
import { usePagination } from '~/hooks/usePagination';
import {
  getJobTemplatesSuperAdmin,
  archiveJobTemplatesSuperAdmin,
  downloadJobTemplatesCSVSuperAdmin,
  updateJobTemplateSuperAdmin,
} from '~/services/jobTemplates';
import { isNotFalse } from '~/utils/typePredicates';

import type { IJobTemplate } from '@learned/types';

export const useAllJobTemplates = () => {
  const { i18n } = useLingui();
  const { addToast } = useToasts();
  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const [statuses, setStatuses] = useState<IJobTemplateStatus[]>(
    Object.values(JOB_TEMPLATE_STATUS_WITH_NAMES).filter(
      (item) => item.id !== JOB_TEMPLATE_STATUS.ARCHIVED,
    ),
  );
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<JOB_TEMPLATES_SORT_OPTIONS>(
    JOB_TEMPLATES_SORT_OPTIONS.NAME_A_Z,
  );
  const [isManageJobFamiliesModalVisible, setIsManageJobFamiliesModalVisible] = useState(false);
  const debouncedSearch = useDebounce(search, 300);

  const [jobTemplates, setJobTemplates] = useState<IJobTemplate[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const $isFilterCompetencies = useBoolState(false);

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const isAllSelected = jobTemplates.every((item) => {
    return selectedItems.includes(item.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : jobTemplates.map((item) => item.id);
    setSelectedItems(itemsToSelect);
  };

  const fetchData = async (showLoading = false) => {
    try {
      if (showLoading) {
        setIsLoading(true);
      }
      const result = await getJobTemplatesSuperAdmin(
        {
          search,
          ...(!isEmpty(statuses) && { statuses: statuses.map((status) => status.key) }),
          isFilterCompetencies: $isFilterCompetencies.value,
        },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      );

      setJobTemplates(result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES].items);
      setTotal(result?.data?.[API_RETURN_FIELDS.JOB_TEMPLATES]?.[API_RETURN_FIELDS.TOTAL]);
    } finally {
      if (showLoading) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.limit,
    pagination.skip,
    debouncedSearch,
    sortBy,
    statuses,
    $isFilterCompetencies.value,
  ]);

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IJobTemplate) => onSelectItem(item.id),
    isItemChecked: (item: IJobTemplate) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onArchive: () => onArchive(selectedItems),
  };

  const filters = {
    search,
    setSearch,
  };

  const exportCSV = async () => {
    if (!isEmpty(jobTemplates)) {
      addToast({
        title: i18n._(t`Exporting CSV`),
        subtitle: i18n._(
          t`Your CSV is being downloaded. This can take some time. It will download when it is ready.`,
        ),
        type: TOAST_TYPES.INFO,
      });

      await downloadJobTemplatesCSVSuperAdmin(
        { search, ...(!isEmpty(statuses) && { statuses: statuses.map((status) => status.key) }) },
        {
          sortBy,
        },
      );
    }
  };

  const actionButton = {
    label: t`Export csv`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.EXPORT,
    disabled: !isEmpty(jobTemplates),
    onClick: exportCSV,
  };

  const settingsButton = {
    label: t`Settings`,
    variant: ButtonVariant.SECONDARY,
    icon: ICONS.SETTINGS,
    onClick: () => setIsManageJobFamiliesModalVisible(true),
  };

  const createJobTemplateButton = {
    label: t`Create job template`,
    variant: ButtonVariant.PRIMARY,
    icon: ICONS.ADD_PLUS,
    onClick: () => {
      routes.SUPERADMIN_JOB_TEMPLATE_CREATE.go(
        { role: 'super-admin' as ROLES },
        { isBackPath: true },
      );
    },
  };

  const onArchive = async (ids: string[]) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Are you sure want to archive job template(s)`),
      description: ' ',
    });
    if (isConfirmed) {
      await archiveJobTemplatesSuperAdmin(ids);
      // confirm toast
      addToast({
        title: i18n._(t`Job Template(s) archived!`),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();

      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const onUnarchive = async (id: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Are you sure want to unarchive job template`),
      description: ' ',
    });
    if (isConfirmed) {
      await updateJobTemplateSuperAdmin(id, { status: JOB_TEMPLATE_STATUS.DRAFT });
      // confirm toast
      addToast({
        title: i18n._(t`Job Template(s) unarchived!`),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();

      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const onSetToDraft = async (id: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Are you sure want to set the job template to draft?`),
      description: ' ',
    });
    if (isConfirmed) {
      await updateJobTemplateSuperAdmin(id, { status: JOB_TEMPLATE_STATUS.DRAFT });
      // confirm toast
      addToast({
        title: i18n._(t` Job Template set to draft!`),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();

      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };
  const onPublish = async (id: string) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Are you sure want to publish job template?`),
      description: ' ',
    });
    if (isConfirmed) {
      await updateJobTemplateSuperAdmin(id, { status: JOB_TEMPLATE_STATUS.PUBLISHED });
      // confirm toast
      addToast({
        title: i18n._(t` Job Template published!`),
        type: TOAST_TYPES.INFO,
      });
      // // re-fetch items
      await fetchData();

      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const createMenuItems = (item: IJobTemplate) => {
    if (item.status === JOB_TEMPLATE_STATUS.ARCHIVED) {
      return [
        {
          label: i18n._(t`Unarchive`),
          action: () => onUnarchive(item.id),
          icon: ICONS.EDIT_PENCIL,
        },
      ];
    } else {
      return [
        item.status === JOB_TEMPLATE_STATUS.DRAFT && {
          label: i18n._(t`Publish`),
          action: () => onPublish(item.id),
          icon: ICONS.CHECKMARK,
        },
        {
          label: i18n._(t`Edit`),
          action: () =>
            SUPERADMIN_JOB_TEMPLATE_EDIT.go(
              { role: 'super-admin' as ROLES },
              { jobTemplateId: item?.id, isBackPath: true },
            ),
          icon: ICONS.EDIT_PENCIL,
        },
        item.status === JOB_TEMPLATE_STATUS.PUBLISHED && {
          label: i18n._(t`Set to draft`),
          action: () => onSetToDraft(item.id),
          icon: ICONS.EDIT_PENCIL,
        },
        {
          label: i18n._(t`Archive`),
          action: () => onArchive([item.id]),
          icon: ICONS.ARCHIVE,
        },
      ].filter(isNotFalse);
    }
  };

  return {
    isLoading,
    jobTemplates,
    filters,
    search,
    statuses,
    setStatuses,
    sortBy,
    setSortBy,
    pagination,
    changePagination,
    total,
    multiSelect,
    createMenuItems,
    actionButton,
    settingsButton,
    createJobTemplateButton,
    isManageJobFamiliesModalVisible,
    setIsManageJobFamiliesModalVisible,
    $isFilterCompetencies,
  };
};
