import React, { Component } from 'react';

import './styles.scss';
import { t } from '@lingui/macro';
import { withI18n } from '@lingui/react';
import * as Sentry from '@sentry/react';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { withToasts, TOAST_TYPES } from '~/components/Toast';
import { CommonError, NotFound, ForcedSSO, MaintenanceMode, TooManyRequests } from '~/pages/Errors';

import auth0Errors from '~/auth0/errors';
import * as appActions from '~/store/app/actions';
import { updateApp } from '~/store/app/actions';

class ErrorHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidUpdate(_prevProps, prevState) {
    const { dispatch, isErrorInToast } = this.props;

    if (prevState.hasError) {
      this.setState({ hasError: false });
    }

    if (isErrorInToast) {
      dispatch(appActions.setIsErrorInToast());
    }
  }

  componentDidCatch(error, errorInfo) {
    const { user } = this.props;

    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        if (user) {
          scope.setUser({
            id: user.id,
            email: user.email,
          });
        }
        Sentry.captureException(error);
        this.setState({ hasError: true });
      });
    } else {
      this.setState({ hasError: true });
    }
  }

  showToast = () => {
    const { i18n, toasts, error } = this.props;
    toasts.add({
      title: i18n._(t`API error`),
      subtitle: error,
      type: TOAST_TYPES.ERROR,
    });
  };

  render() {
    const { isRequestError, requestErrorCode, isErrorInToast, error, dispatch } = this.props;
    const params = new URLSearchParams(window.location.search);
    const errorCode = params.get('errorCode') || requestErrorCode;

    const isMaintenanceMode = error.status === 503 && error?.data?.message === 'Maintenance mode';

    const { maintenanceTitle, maintenanceBody } = get(error, 'data', {});
    if (isMaintenanceMode) {
      // add maintenance title and body to store
      dispatch(updateApp({ maintenanceTitle, maintenanceBody }));
      return <MaintenanceMode />;
    }

    if (isErrorInToast) {
      this.showToast();
    } else if (
      !isErrorInToast &&
      (this.state.hasError || isRequestError || params.has('errorCode'))
    ) {
      switch (errorCode) {
        case 404:
          return <NotFound />;
        case auth0Errors.FORCE_SSO.NOT_ALLOW_CONNECTION.key:
          return <ForcedSSO />;
        case 429:
          return <TooManyRequests />;
        default:
          return <CommonError />;
      }
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isRequestError: state.app.isRequestError,
  requestErrorCode: state.app.requestErrorCode,
  isErrorInToast: state.app.isErrorInToast,
  error: state.app.error,
});

export default withI18n()(withRouter(connect(mapStateToProps)(withToasts(ErrorHandler))));
