import React from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { Header, IconWrapper, Title, Wrapper } from './design';

const Signatures = () => {
  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.SIGNATURES} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Signatures</Trans>
        </Title>
      </Header>
    </Wrapper>
  );
};

export { Signatures };
