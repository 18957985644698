import { ROLES } from '@learned/constants';

import { pageUpdate, pageDashboard, pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'review-themes';
const defaultThemeId = ':reviewThemeId';

export const REVIEW_THEME = new LinkConstructor([ROLES.USER], () => `${pageOverview(routeName)}`);

// update
export const REVIEW_THEME_UPDATE = new LinkConstructor(
  [ROLES.ADMIN, ROLES.COACH, ROLES.USER],
  ({ reviewThemeId = defaultThemeId }) => pageUpdate(routeName, { id: reviewThemeId }),
);

export const REVIEW_THEME_VIEW = new LinkConstructor(
  [ROLES.ADMIN, ROLES.COACH, ROLES.USER],
  ({ reviewThemeId = defaultThemeId }) => pageDashboard(routeName, { id: reviewThemeId }),
);
