import React from 'react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import { LeftSection, Wrapper, Title, SubTitle, IconContainer, RightSection } from './design';
import { ACTIONS_POSITION, BANNER_TYPES, IBannerProps } from './types';

import { COLORS } from '~/styles';

const STYLES = {
  [BANNER_TYPES.INFO]: {
    backgroundImage:
      'linear-gradient(266deg, rgba(175, 184, 232, 0.1) 100%, rgba(86, 107, 250, 0.1) 0%)',
    icon: ICONS.INFO_3,
    color: COLORS.ACCENT_INFO,
    titleTextColor: COLORS.TEXT_HOVER,
    subtitleTextColor: COLORS.SUBTEXT,
  },
  [BANNER_TYPES.WARNING]: {
    backgroundImage:
      'linear-gradient(264deg, rgba(255, 138, 138, 0.1) 100%, rgba(255, 82, 82, 0.2) 0%)',
    icon: ICONS.WARNING_2,
    color: COLORS.ACCENT_ERROR,
    titleTextColor: COLORS.ACCENT_ERROR,
    subtitleTextColor: COLORS.ACCENT_ERROR,
  },
  [BANNER_TYPES.DELETE]: {
    backgroundImage:
      'linear-gradient(264deg, rgba(255, 138, 138, 0.1) 100%, rgba(255, 82, 82, 0.2) 0%)',
    icon: null,
    color: null,
    titleTextColor: null,
    subtitleTextColor: null,
  },
};

const Banner = ({
  type = BANNER_TYPES.INFO,
  actions,
  title,
  subTitle,
  actionsPosition = ACTIONS_POSITION.ROW,
  bannerHoverIcon,
  isBannerHovered = false,
  setIsBannerHovered = () => {},
}: IBannerProps) => {
  const { icon, color, titleTextColor, backgroundImage, subtitleTextColor } = STYLES[type];

  const isRowView = actionsPosition === ACTIONS_POSITION.ROW;

  return (
    <Wrapper
      $backgroundImage={backgroundImage}
      onMouseEnter={() => {
        setIsBannerHovered(true);
      }}
      onMouseLeave={() => {
        setIsBannerHovered(false);
      }}
      $isRowView={isRowView}
    >
      <LeftSection>
        {icon && (
          <IconContainer $backgroundColor={color} isBannerHovered={isBannerHovered}>
            {isBannerHovered && bannerHoverIcon ? (
              bannerHoverIcon
            ) : (
              <Icon icon={icon} size={ICON_SIZES.SMALL} color={COLORS.WHITE} />
            )}
          </IconContainer>
        )}

        <div>
          {title && <Title $color={titleTextColor}>{title}</Title>}
          {subTitle && <SubTitle $color={subtitleTextColor}>{subTitle}</SubTitle>}
        </div>
      </LeftSection>

      <RightSection $isRowView={isRowView}>{actions}</RightSection>
    </Wrapper>
  );
};

export { Banner };
