import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;
  /* TODO: remove this after implementing the section */
  min-height: 287px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .collapse {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
  font-weight: 500;
`;
