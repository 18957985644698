import React from 'react';

import { useLingui } from '@lingui/react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ICONS, Icon, ICON_SIZES } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import MenuIcon from './MenuIcon';

import { MENU_ITEMS } from '../constants';
import {
  StyledPrimaryMenuItem,
  MenuItemTitle,
  MenuItem,
  MenuRow,
  OutsideLink,
  MenuCol,
  UserName,
  UserEmail,
  UserMenuItemTitle,
  Circle,
  NotificationDot,
} from '../styles/menuItemDesign';
import { IMenuItem } from '../types';

interface IPrimaryMenuItemProps {
  item: IMenuItem;
  isMenuCollapsed: boolean;
  isShowTooltip?: boolean;
  onLinkClick?: () => void;
  isCurrent?: boolean;
  linkTo?: string;
  isSmallScreenHeight?: boolean;
}

const LinkStyled = styled(Link)`
  text-decoration: none;
  width: 100%;
  display: flex;
`;

const MenuItemBlock = ({
  item,
  isMenuCollapsed,
  isCurrent,
  onLinkClick,
  isShowTooltip,
  linkTo,
  isSmallScreenHeight,
}: IPrimaryMenuItemProps) => {
  const { i18n } = useLingui();
  const isShowDotMenu = isSmallScreenHeight && !isCurrent && isMenuCollapsed && item.icon;

  const dotItem = (
    <MenuItem isDotItemStyle>
      <Circle />
    </MenuItem>
  );

  const itemContent = (
    <MenuItem
      isCurrentRoute={isCurrent}
      isBigMargins={isSmallScreenHeight && isMenuCollapsed && isCurrent}
    >
      <MenuRow isMenuCollapsed={isMenuCollapsed}>
        {(item.icon || item.iconNode) && !isShowDotMenu && (
          <>
            {item?.numberOfNotifications ? <NotificationDot /> : null}
            <MenuIcon menuBlock={item} isCurrent={isCurrent} />
          </>
        )}
        {!isMenuCollapsed && (
          <MenuCol>
            {item.key !== MENU_ITEMS.ACCOUNT ? (
              <Tooltip
                placement={TOOLTIP_PLACEMENTS.RIGHT}
                delay={[500, 0]}
                tooltip={item.title?.(i18n)}
                size={TOOLTIP_SIZES.DEFAULT_TL}
                arrow={false}
              >
                <MenuItemTitle
                  isCurrent={isCurrent}
                  isBigLeftMargin={!(item.icon || item.iconNode)}
                >
                  {item.title?.(i18n)}
                </MenuItemTitle>
              </Tooltip>
            ) : (
              <UserMenuItemTitle
                isCurrent={isCurrent}
                isBigLeftMargin={!(item.icon || item.iconNode)}
              >
                <UserName>{item.userFullName}</UserName>
                <UserEmail className="userEmail">{item.userEmail}</UserEmail>
              </UserMenuItemTitle>
            )}
            {item.key === MENU_ITEMS.ACCOUNT && (
              <Icon icon={ICONS.SETTINGS} size={ICON_SIZES.MEDIUM} />
            )}
          </MenuCol>
        )}
      </MenuRow>
    </MenuItem>
  );

  const content = isShowTooltip ? (
    <Tooltip
      placement={TOOLTIP_PLACEMENTS.RIGHT}
      delay={[500, 0]}
      tooltip={item.title?.(i18n)}
      size={TOOLTIP_SIZES.DEFAULT_TL}
      arrow={false}
    >
      {isShowDotMenu ? dotItem : itemContent}
    </Tooltip>
  ) : (
    itemContent
  );

  return item.onClick ? (
    <StyledPrimaryMenuItem
      role="button"
      onClick={item.onClick}
      tabIndex={0}
      onKeyDown={item.onClick}
    >
      {content}
    </StyledPrimaryMenuItem>
  ) : item?.href ? (
    <OutsideLink href={item.href} target="_blank" rel="noopener noreferrer">
      {content}
    </OutsideLink>
  ) : (
    <LinkStyled
      // @ts-ignore
      to={linkTo}
      onClick={onLinkClick}
    >
      {content}
    </LinkStyled>
  );
};

export default MenuItemBlock;
