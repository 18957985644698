import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import Tooltip, { TOOLTIP_PLACEMENTS } from '~/components/Tooltip';

import { ItemBlock } from './components/ItemBlock';
import { BottomSection, Button, ButtonsWrapper, ItemsWrapper, Wrapper } from './design';

import { COLORS } from '~/styles';

import type { DashboardSidebarProps } from './types';

const DashboardSidebar = ({
  isMenuCollapsed,
  setIsMenuCollapsed,
  items,
  buttons,
}: DashboardSidebarProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper isMenuCollapsed={isMenuCollapsed}>
      <ItemsWrapper>
        {items?.map(({ title, icon, content, isContentVisibleByDefault }, index) => (
          <ItemBlock
            key={`${title}-${index}`}
            title={title}
            icon={icon}
            content={content}
            isMenuCollapsed={isMenuCollapsed}
            setIsMenuCollapsed={setIsMenuCollapsed}
            isContentVisibleByDefault={isContentVisibleByDefault}
          />
        ))}
      </ItemsWrapper>
      <BottomSection>
        <ButtonsWrapper>
          {buttons?.map(({ title, icon, color, onClick, disabled, tooltip }, key) => {
            const btn = (
              <Button
                disabled={disabled}
                key={title}
                color={color}
                onClick={onClick}
                isMenuCollapsed={isMenuCollapsed}
              >
                <div className="icon">
                  <Icon icon={icon} size={ICON_SIZES.SMALL} />
                </div>
                <div className="title">{title}</div>
              </Button>
            );

            const isMenuClosedAndActive = !disabled && !isMenuCollapsed;

            return tooltip || isMenuClosedAndActive ? (
              <Tooltip
                key={key}
                tooltip={isMenuClosedAndActive ? title : tooltip}
                placement={
                  isMenuClosedAndActive ? TOOLTIP_PLACEMENTS.RIGHT : TOOLTIP_PLACEMENTS.BOTTOM
                }
                arrow={!isMenuClosedAndActive}
              >
                <div>{btn}</div>
              </Tooltip>
            ) : (
              btn
            );
          })}
        </ButtonsWrapper>
        <button
          className="toggleButton"
          onClick={() => setIsMenuCollapsed((prevState) => !prevState)}
        >
          {isMenuCollapsed && <span>{i18n._(t`Close menu`)}</span>}
          <Icon
            icon={isMenuCollapsed ? ICONS.ARROW_DOUBLE_LEFT : ICONS.ARROW_DOUBLE_RIGHT}
            size={ICON_SIZES.MEDIUM}
            color={COLORS.SUBTEXT}
          />
        </button>
      </BottomSection>
    </Wrapper>
  );
};

export { DashboardSidebar };
