import React from 'react';

import { Trans } from '@lingui/macro';
import styled from 'styled-components';

import { COLORS } from '~/styles';
import convertToTimeString from '~/utils/convertToTimeString';

const DateRow = styled.div<{ isDeadlineClose?: boolean; isOverdue?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: ${({ isDeadlineClose }) => (isDeadlineClose ? COLORS.WARNING : COLORS.GRAVEL)};
  color: ${({ isOverdue }) => (isOverdue ? COLORS.ACCENT_ERROR : '')};
  text-align: center;
  justify-content: center;
`;

interface IDeadlineLabelProps {
  deadline: string;
}

const DeadlineLabel = ({ deadline }: IDeadlineLabelProps) => {
  const getDeadline = (deadline: string) => {
    const currentDate = new Date();
    const deadlineDate = new Date(deadline);
    const millisecondsPerDay = 24 * 60 * 60 * 1000;

    const differenceInMilliSeconds = deadlineDate.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInMilliSeconds / millisecondsPerDay);
    if (differenceInDays > 7) {
      return convertToTimeString(deadline);
    }
    if (differenceInDays > 1) {
      return `${differenceInDays} days`;
    }

    if (differenceInDays === 1) {
      return `${differenceInDays} day`;
    }

    if (differenceInDays < 0) {
      return 'overdue';
    }
    return `${differenceInDays} days`;
  };

  const deadlineText = getDeadline(deadline);

  return (
    <DateRow isDeadlineClose={deadlineText.includes('day')} isOverdue={deadlineText === 'overdue'}>
      {deadlineText === 'overdue' ? <Trans>Overdue</Trans> : deadlineText}
    </DateRow>
  );
};

export { DeadlineLabel };
