import React, { Dispatch, SetStateAction, useEffect } from 'react';

import { CONFIRMATION_MODAL_TYPE, REVIEW_STATUS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';
import qs from 'qs';
import { useHistory, useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { SideBar } from '~/components/SideBar';
import { useSectionState } from '~/components/SideBar/SectionStateHook';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import { LoadingModal } from '~/pages/Reviews/components/LoadingModal';
import { useReview } from '~/pages/Reviews/EditIndividual/ReviewIndividualForm/hooks/useReview';

import { Wrapper } from './design';
import { useReviewInvitation } from './hooks/useReviewInvitation';
import { useReviewTasks } from './hooks/useReviewTasks';
import { StepEmployee } from './StepEmployee';
import { StepFinalCheck } from './StepFinalCheck';
import { StepGeneral } from './StepGeneral';
import { StepSettings } from './StepSettings';

import routes from '~/constants/routes';
import { useFromQuery } from '~/hooks/useFromQuery';
import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { useReviewTemplates } from '../../hooks/useReviewTemplates';

import type { IReviewIndividualForm } from './types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewIndividualFormProps {
  formMethods: UseFormReturn<IReviewIndividualForm>;
  languageState: ILanguageStateReturn;
  setUserId: Dispatch<SetStateAction<string | undefined>>;
}

const ReviewIndividualForm = ({
  formMethods,
  languageState,
  setUserId,
}: ReviewIndividualFormProps) => {
  const history = useHistory();
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const sectionState = useSectionState([]);
  const { goBack } = useFromQuery({ includeHash: true });
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isCreatingNew = query.isCreatingNew;
  const params: Record<string, string | undefined> = useParams();
  const reviewId = params.reviewId as string;

  const { addToast } = useToasts();
  const { deleteReview, isAllowToDelete, isReviewLoading, saveReview, isReviewSaving } = useReview({
    formMethods,
    reviewId,
    setUserId,
  });
  const { reviewTemplates } = useReviewTemplates();
  const { autoGenerateDates } = useReviewTasks({ formMethods });
  const { autoSelectInvitationTemplate, invitationTemplates, refreshInvitationTemplates } =
    useReviewInvitation({ formMethods, languageState });

  const {
    watch,
    trigger,
    formState: { dirtyFields, errors },
    handleSubmit,
    setValue,
  } = formMethods;
  const isAutoGenerate = watch('settings.isAutoTimeline');
  const cycleStartDate = watch('settings.startDate');
  const watchStatus = watch('status');
  const isDraft = watchStatus === REVIEW_STATUS.DRAFT;

  useEffect(() => {
    sectionState.setSections([
      {
        title: i18n._(t`General`),
      },
      {
        title: i18n._(t`Select job and participants`),
      },
      {
        title: i18n._(t`Settings`),
        fields: [
          i18n._(t`General`),
          i18n._(t`Notifications`),
          i18n._(t`Privacy`),
          i18n._(t`Invitation`),
          i18n._(t`Timeline`),
        ],
      },
      {
        title: i18n._(t`Final check`),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isReviewLoading) {
      autoSelectInvitationTemplate();
    }
    if (isCreatingNew) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReviewLoading]);

  useEffect(() => {
    if (!watch('employees')?.[0]?.id) {
      setValue('employees', [], { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(watch('employees'))]);

  // Validation
  useEffect(() => {
    sectionState.setErrorSection(0, !!errors.name || !!errors.reviewTemplate);
    sectionState.setErrorSection(1, !!errors.employees);
    sectionState.setErrorSection(2, !!errors.settings || !!errors.tasks);
    if (errors) {
      sectionState.goToFirstErrorSection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  useEffect(() => {
    if (isAutoGenerate) {
      autoGenerateDates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutoGenerate, cycleStartDate]);

  const onCloseButton = async () => {
    if (isEmpty(dirtyFields)) {
      goBack();
    } else {
      const isConfirmed = await confirm({
        type: CONFIRMATION_MODAL_TYPE.INFO,
        title: i18n._(t`Close without saving?`),
        description: i18n._(
          t`Are you sure you want to close without saving? This action cannot be undone.`,
        ),
        submitButton: !isDraft && i18n._(t`Save changes`),
      });
      if (isConfirmed) {
        onSaveReview();
      } else {
        goBack();
      }
    }
  };

  const goToAllTab = () =>
    history.push(
      routes.REVIEWS.build(
        // @ts-ignore
        { role: ROLES.USER },
        { hash: 'all' },
      ),
    );

  const onPublishReview = async () => {
    if (!isEmpty(errors)) {
      return;
    }

    const response = await saveReview(isDraft ? REVIEW_STATUS.PUBLISHED : undefined);
    if (response.code === 200) {
      addToast({
        title: isDraft ? i18n._(t`Review published`) : i18n._(t`Review saved`),
        type: TOAST_TYPES.SUCCESS,
      });
    }
    goBack();
  };

  const onClickPublish = async () => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.WARNING,
      title: i18n._(t`Publish review?`),
      description: i18n._(
        t`Are you sure you want to publish the review? From the start date, the review will be available to all participants.`,
      ),
    });
    if (isConfirmed) {
      sectionState.setTriedToSubmit();
      handleSubmit(onPublishReview)();
    }
  };

  const onDelete = async () => {
    const confirmResult = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Delete review?`),
      description: i18n._(
        t`Are you sure you want to delete this review? This action cannot be undone.`,
      ),
    });

    if (confirmResult) {
      await deleteReview();
      addToast({
        title: i18n._(t`Review deleted`),
        type: TOAST_TYPES.SUCCESS,
      });
      goToAllTab();
    }
  };

  const onSaveReview = async () => {
    trigger('name');
    if (errors.name) {
      return;
    }

    const response = await saveReview();
    if (response.code === 200) {
      addToast({ title: i18n._(t`Review saved as draft`), type: TOAST_TYPES.SUCCESS });
    }
    goBack();
  };

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const onSaveNotDraftCycle = () => {
    sectionState.setTriedToSubmit();
    handleSubmit(onPublishReview)();
  };

  const iconsButtons: HeaderIconButtons[] = [
    ...(isAllowToDelete
      ? [
          {
            icon: ICONS.DELETE_BIN,
            tooltip: i18n._(t`Delete`),
            onClick: async () => {
              await onDelete();
            },
          } as HeaderIconButtons,
        ]
      : []),
    {
      icon: ICONS.SAVE,
      tooltip: i18n._(t`${isDraft ? 'Save draft' : 'Save'}`),
      onClick: isDraft ? onSaveReview : onSaveNotDraftCycle,
    },
  ];

  return (
    <>
      <HeaderFocusMode
        title={i18n._(
          t`${isCreatingNew ? 'Create' : 'Edit'} review: ${getMultiLangString(nameMultiLang)}`,
        )}
        goBack={onCloseButton}
        languageState={languageState}
        submitButton={
          isDraft
            ? {
                title: i18n._(t`Publish`),
                type: ButtonVariant.PRIMARY,
                onClick: onClickPublish,
              }
            : undefined
        }
        iconButtons={iconsButtons}
      />
      <Wrapper>
        <SideBar
          sections={sectionState.sections}
          currentSection={sectionState.currentSection}
          setCurrentSection={sectionState.setCurrentSection}
        />
        <ShowSpinnerIfLoading loading={isReviewLoading}>
          {sectionState.currentSection === 0 && (
            <StepGeneral
              sectionState={sectionState}
              formMethods={formMethods}
              reviewTemplates={reviewTemplates}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === 1 && (
            <StepEmployee
              sectionState={sectionState}
              formMethods={formMethods}
              languageState={languageState}
            />
          )}
          {sectionState.currentSection === 2 && (
            <StepSettings
              sectionState={sectionState}
              formMethods={formMethods}
              languageState={languageState}
              refreshInvitationTemplates={refreshInvitationTemplates}
              invitationTemplates={invitationTemplates}
            />
          )}
          {sectionState.currentSection === 3 && (
            <StepFinalCheck
              sectionState={sectionState}
              formMethods={formMethods}
              onPublish={onClickPublish}
            />
          )}
        </ShowSpinnerIfLoading>
      </Wrapper>
      {isReviewSaving && <LoadingModal title={i18n._(t`Saving, please wait...`)} />}
    </>
  );
};

export { ReviewIndividualForm };
