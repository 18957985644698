import React, { useState } from 'react';

import isEmpty from 'lodash/isEmpty';

import IconMenu from '~/components/IconMenu';
import PaginationBar from '~/components/PaginationBar';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TablePlaceholder } from '~/components/TablePlaceholder';

import { FixedTable } from './components/FixedTable';
import { Header } from './components/Header';
import { Placeholder } from './components/Placeholder';
import { Top } from './components/Top';
import { TD, TableWrapper, IconMenuWrapper, MainTable, Wrapper, TableContainer } from './design';
import { isKeyTheSame, isNotFixedFilter } from './utils';

import { ColumnPosition } from '~/@types/table';

import type { ITableGridProps } from './types';

const TableGrid = ({
  columns,
  data,
  onRowClick,
  sortBy,
  setSortBy,
  isLoading,
  isMenuVisible,
  createMenuItems,
  onColClick,
  onCellClick = () => {},
  isScrollbarVisible,
  isLeftColumnsStriped,
  isRightColumnsStriped,
  leftMinWidth,
  rightMinWidth,
  visibleColumn,
  showSortBy,
  actionButton,
  filtersProps,
  paginationProps,
  placeholderProps,
  thColumnPadding,
  showTopArea = true,
  className,
  enableMultipleTableHeaderRows,
  secondaryHeaderColumns,
  isHeatmapColored,
}: ITableGridProps) => {
  const [hovered, setHovered] = useState(-1);

  const handleSortBy = (key?: string) => {
    setSortBy && setSortBy(key || '');
  };

  const sortProps = {
    sortBy,
    handleSortBy,
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    if (paginationProps?.pagination) {
      const newPagination = {
        ...paginationProps?.pagination,
        limit,
      };

      paginationProps?.changePagination(newPagination);
      paginationProps?.onPaginationChange?.(newPagination);
    }
  };

  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    if (paginationProps?.pagination) {
      const newPagination = {
        ...paginationProps?.pagination,
        skip,
        index,
      };

      paginationProps?.changePagination(newPagination);
      paginationProps?.onPaginationChange?.(newPagination);
    }
  };

  return (
    <Wrapper className={className} shouldHighlightAutoRows={Boolean(!isHeatmapColored)}>
      {showTopArea && filtersProps && (
        <Top
          filters={filtersProps?.filters}
          actionButton={actionButton}
          filterComponents={filtersProps?.filterComponents}
          isToggleHideFilterVisible={filtersProps?.isToggleHideFilterVisible}
          resetFilters={filtersProps?.resetFilters}
        />
      )}
      <ShowSpinnerIfLoading height="150px" loading={!!isLoading}>
        {!isEmpty(data) ? (
          <TableContainer>
            {/* below FixedTable component renders fixed columns of the table */}
            <FixedTable
              columns={columns.filter(
                (column) => column.isFixed && column.position === ColumnPosition.LEFT,
              )}
              data={data}
              isStriped={isLeftColumnsStriped}
              minWidth={leftMinWidth}
              sortBy={sortBy}
              setSortBy={setSortBy}
              showSortBy={showSortBy}
              thColumnPadding={thColumnPadding}
              onColClick={onColClick}
              enableMultipleTableHeaderRows={enableMultipleTableHeaderRows}
              secondaryHeaderColumns={secondaryHeaderColumns?.filter(
                (column) => column.columnPosition === ColumnPosition.LEFT,
              )}
            />
            <MainTable isScrollbarVisible={isScrollbarVisible}>
              <TableWrapper
                isScrollbarVisible={isScrollbarVisible}
                $isPointer={Boolean(onRowClick)}
                isStriped={Boolean(!isHeatmapColored)}
              >
                <Header
                  columns={columns.filter(isNotFixedFilter)}
                  secondaryHeaderColumns={secondaryHeaderColumns?.filter(
                    ({ isFixed }) => isFixed === false,
                  )}
                  enableMultipleTableHeaderRows={enableMultipleTableHeaderRows}
                  sortProps={sortProps}
                  isMenuVisible={isMenuVisible}
                  visibleColumn={visibleColumn}
                  showSortBy={showSortBy}
                  thColumnPadding={thColumnPadding}
                />
                <tbody>
                  {data.map((item, i, allData) => {
                    return (
                      <tr
                        onMouseEnter={() => setHovered(i)}
                        onMouseLeave={() => setHovered(-1)}
                        key={JSON.stringify({ item, i })}
                        onClick={() => onRowClick && onRowClick(item)}
                        role="link"
                      >
                        {columns.filter(isNotFixedFilter).map((column, index) => {
                          const isCallWithClick = onColClick?.column === column.accessor;

                          const isFirstCell = index === 0 && i === 0;
                          let rowspan = 1;
                          if (column.mergeSameCells && column.mergeSameCellsKey) {
                            const previousRow = i > 0 ? allData[i - 1] : undefined;

                            if (isKeyTheSame(item, previousRow, column.mergeSameCellsKey)) {
                              // previous cell was the same as current so we don't render it (the previous cell is going to take this space)
                              return <></>;
                            }

                            // find number of next rows with same item based on merge key
                            for (let j = i + 1; j < allData.length; j++) {
                              if (!isKeyTheSame(item, allData[j], column.mergeSameCellsKey)) {
                                break;
                              }
                              rowspan += 1;
                            }
                          }
                          if (placeholderProps?.mainTable?.isVisible) {
                            if (isFirstCell) {
                              return (
                                <TD
                                  key={`td-${column.accessor}`}
                                  colSpan={columns.filter(isNotFixedFilter).length}
                                  rowSpan={data.length}
                                >
                                  <Placeholder
                                    icon={placeholderProps?.mainTable.icon}
                                    title={placeholderProps?.mainTable.title}
                                  />
                                </TD>
                              );
                            } else {
                              if (columns.filter(isNotFixedFilter).length - 1 === index) {
                                return <td key={`td-${column.accessor}`} />;
                              } else {
                                return <></>;
                              }
                            }
                          } else {
                            let renderedCellOutput = null;
                            if (column.renderCell) {
                              renderedCellOutput = column.renderCell(
                                item,
                                isCallWithClick ? onColClick?.onClick : undefined,
                                hovered === i,
                              );
                            }

                            // we can return an array from the render cell function to support the multi header function
                            if (Array.isArray(renderedCellOutput)) {
                              return renderedCellOutput.map((cell, index) => {
                                return (
                                  <TD
                                    key={`td-${column.accessor}-${index}`}
                                    maxWidth={column.maxWidth}
                                    minWidth={column.minWidth}
                                    {...(column.renderCell && { padding: column.padding })}
                                    rowSpan={rowspan}
                                    $isPointer={true}
                                  >
                                    <>{cell}</>
                                  </TD>
                                );
                              });
                            } else {
                              return (
                                <TD
                                  key={`td-${column.accessor}`}
                                  maxWidth={column.maxWidth}
                                  minWidth={column.minWidth}
                                  {...(column.renderCell && { padding: column.padding })}
                                  rowSpan={rowspan}
                                  $isPointer={true}
                                  onClick={() => {
                                    return onCellClick(item, column);
                                  }}
                                >
                                  {column.renderCell ? (
                                    <>{renderedCellOutput}</>
                                  ) : (
                                    <>
                                      {/* @ts-ignore */}
                                      {item?.[column.accessor]}
                                    </>
                                  )}
                                </TD>
                              );
                            }
                          }
                        })}
                        {isMenuVisible && (
                          <TD minWidth="26px" padding="16px 26px 16px 0">
                            <IconMenuWrapper>
                              {isMenuVisible && Boolean(createMenuItems) && (
                                <IconMenu
                                  // @ts-ignore
                                  items={createMenuItems(item)}
                                />
                              )}
                            </IconMenuWrapper>
                          </TD>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </TableWrapper>
            </MainTable>
            {!placeholderProps?.mainTable?.isVisible && (
              <FixedTable
                columns={columns.filter(
                  (column) => column.isFixed && column.position === ColumnPosition.RIGHT,
                )}
                secondaryHeaderColumns={secondaryHeaderColumns?.filter(
                  (column) => column.columnPosition === ColumnPosition.RIGHT,
                )}
                enableMultipleTableHeaderRows={enableMultipleTableHeaderRows}
                data={data}
                isStriped={isRightColumnsStriped}
                minWidth={rightMinWidth}
                sortBy={sortBy}
                setSortBy={setSortBy}
                showSortBy={showSortBy}
              />
            )}
          </TableContainer>
        ) : (
          <TablePlaceholder
            isLoading={isLoading}
            isFiltered={filtersProps?.isFiltered}
            noResultText={placeholderProps?.noResultText}
            emptyStateText={placeholderProps?.emptyStateText}
            onResetSearch={() => filtersProps?.filters.setSearch('')}
          />
        )}
      </ShowSpinnerIfLoading>

      {paginationProps && (
        <PaginationBar
          pagination={paginationProps?.pagination}
          changePagination={onPageChangeClick}
          changePageSize={handleChangeItemsPerPage}
          count={paginationProps?.totalCount}
          customPaginationList={paginationProps?.customPaginationList}
          noShadow
          noBorder
          noTopBorder
          showCount
        />
      )}
    </Wrapper>
  );
};

export { TableGrid };
