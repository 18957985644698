// The Horizontal bar chart
import React, { ChangeEvent, useContext, useEffect, useMemo, useState, useCallback } from 'react';

import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
} from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { TTotalGraphData } from 'src/components/Graphs/types';

import { Loader } from '~/components/Buttons/components/Loader';
import { ColoredCircle, GraphFooter, Tile } from '~/components/Graphs/custom/AreaGraph.design';
import HorizontalBarChart from '~/components/Graphs/custom/HorizontalBarChart';
import { SpiderGraph } from '~/components/Graphs/custom/SpiderGraph';
import ChevronIcon from '~/components/Icons/Chevron';
import PaginationBar from '~/components/PaginationBar';

import {
  MainCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  LoaderCtr,
  PrimaryDimensionHolder,
  SortCtr,
  SortRow,
  PaginationCtr,
} from './TotalStyle';

import useDebounce from '~/hooks/useDebounce';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { usePagination } from '~/hooks/usePagination';
import getCurrentCompany from '~/selectors/getCurrentCompany';
import { TLucaModalData, getEngagementCharts } from '~/services/reports';
import { COLORS, PRIMARY_COLOR } from '~/styles';
import { getDateForTimeFrame, prepareReportFilters, sanitizeDimensions } from '~/utils/reports';

import NoDataPlaceholder from '../../Components/NoDataPlaceholder';
import { MONTH_OPTIONS, PAGINATION_PRIMARY_OPTIONS } from '../../options';
import { EOptions, TOption, ESort, REPORT_VISUAL_TYPES } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

type TProps = { optionChangeHandler: (key: EOptions, value?: string | TOption[] | null) => void };

const dataGroupsConfig: {
  [key: string]: {
    key: string;
    label: string;
    color: string;
    accessor: 'value' | 'self' | 'peer';
    deviationAccessor: 'deviation' | 'selfDeviation' | 'peerDeviation';
  };
} = {
  COACH: {
    key: 'primary',
    label: 'Coach review',
    color: COLORS.COMPANY,
    accessor: 'value',
    deviationAccessor: 'deviation',
  },
  SELF: {
    key: 'secondary',
    label: 'Self review',
    color: COLORS.TALENT,
    accessor: 'self',
    deviationAccessor: 'selfDeviation',
  },
  PEER: {
    key: 'tertiary',
    label: 'Peer review',
    color: COLORS.ACCENT_SUCCESS,
    accessor: 'peer',
    deviationAccessor: 'peerDeviation',
  },
};

function Total({ optionChangeHandler }: TProps): JSX.Element {
  const { i18n } = useLingui();

  const [showInformationModal, setShowInformationModal] = useState(false);
  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);
  const [showNoResultPlaceholder, setShowNoResultPlaceholder] = useState(false);

  const [data, setData] = useState<TTotalGraphData[]>([]);

  const {
    shouldShowLuca,
    options,
    viewAs,
    filters,
    reportType,
    dimensions,
    isWaiting,
    sorting,
    visualTypes,
    reportId,
  } = useContext(EngagementReportContext);

  const durationTooltipText = MONTH_OPTIONS.find(
    (item) => item.key === filters.monthSelected,
  )?.title(i18n);

  const multiHeaderRowOptions = useMemo(
    () =>
      [
        options.includeSelfReviewAverage ? 'SELF' : null,
        options.includePeerReviewAverage ? 'PEER' : null,
      ].filter((item): item is 'PEER' | 'SELF' => !!item),
    [options.includePeerReviewAverage, options.includeSelfReviewAverage],
  );

  const filteredDataGroups =
    reportType === REPORT_TYPES.PERFORMANCE ? ['COACH', ...multiHeaderRowOptions] : [];

  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [sortedBy, setSortedBy] = useState('');
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 1000);
  const currentCompany = useSelector(getCurrentCompany);
  const companyColor = currentCompany.color || PRIMARY_COLOR;

  useEffect(() => {
    setSearch('');
  }, [reportId]);

  const generateSpiderChartConfigOverrides = useCallback(() => {
    if (!data || !data[0]?.ratingLabels) {
      return {};
    }

    return {
      ratingLabels: 5,
      beginAtZero: true,
      stepSize: 1,
    };
  }, [data]);

  const visualType =
    Object.keys(visualTypes).find((k) => visualTypes[k].isEnabled && visualTypes[k].selected) ||
    REPORT_VISUAL_TYPES.BAR_HORIZONTAL;

  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  const [showSortedMenu, setShowSortedMenu] = useState<ESort | null>(null);
  const onPageChangeClick = async ({ index, skip }: { index: number; skip: number }) => {
    const newPagination = {
      ...pagination,
      skip,
      index,
    };
    changePagination(newPagination);
  };

  const handleChangeItemsPerPage = ({ limit }: { limit: number }) => {
    const newPagination = {
      skip: 0,
      index: 1,
      limit,
    };
    changePagination(newPagination);
  };

  const handleDataRowClick = (item: TTotalGraphData) => {
    setInformationModalData({
      filters: {
        ...(item.id ? { primaryDimensionValue: item.id } : {}),
      },
      value: item.value,
      ...(item.id ? { primaryDimensionName: item.name } : { primaryDimensionName: 'Average' }),
    });
    setShowInformationModal(true);
  };

  const sortBy = {
    primary: {
      asc: {
        key: 'primary__asc',
        label: `${i18n._(t`A-Z Alphabetic`)}`,
      },
      desc: {
        key: 'primary__desc',
        label: `${i18n._(t`Z-A Alphabetic`)}`,
      },
    },
    secondary: {
      asc: {
        key: 'secondary__asc',
        label: `${i18n._(t`Low to high`)}`,
      },
      desc: {
        key: 'secondary__desc',
        label: `${i18n._(t`High to low`)}`,
      },
    },
  };

  const primaryRef = useOutsideClick<HTMLDivElement>(() => {
    setShowSortedMenu(null);
  });

  const getData = async () => {
    try {
      if (dimensions.primary === 'primary_none' || !dimensions.primary) {
        return;
      }
      setIsLoading(true);
      const payload = {
        viewAs,
        reportType,
        chartType: REPORT_CHART_TYPES.BY_ONE_DIMENSION,
        primaryDimension: sanitizeDimensions(
          dimensions.primary || 'theme',
        ) as ENGAGEMENT_REPORT_CHART_DIMENSIONS,
        sorting,
        filters: {
          ...prepareReportFilters(filters),
          ...(debouncedSearch &&
            debouncedSearch.trim().length > 0 && { search: debouncedSearch || '' }),
        },
        options: {
          includeCompanyAverage: options.includeCompanyAverage,
          includeBenchmark: options.includeBenchmark,
          includeTeamAverage: options.includeTeamAverage,
          includePeerReviewAverage: options.includePeerReviewAverage,
          includeSelfReviewAverage: options.includeSelfReviewAverage,
        },
        dateRange: getDateForTimeFrame(filters.monthSelected),
        pagination,
      };
      const chartData = (await getEngagementCharts(payload))?.data;
      setShowNoResultPlaceholder(Boolean(chartData.total === 0));
      setData(chartData.dimensionAverage || []);
      setTotalCount(chartData?.total || 0);
    } finally {
      setIsLoading(false);
    }
  };

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const getSortMenu = () => {
    return (
      <SortCtr ref={primaryRef}>
        {showSortedMenu &&
          Object.values(sortBy[showSortedMenu]).map((item, i) => (
            <SortRow
              key={`sort-${i + 1}`}
              onClick={(e) => {
                e.stopPropagation();
                setShowSortedMenu(null);
                setSortedBy(item.key);
                optionChangeHandler(EOptions.sorting, item.key);
              }}
            >
              {item.key.includes('asc') ? (
                <ChevronIcon
                  size={12}
                  className="icon"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__asc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              ) : (
                <ChevronIcon
                  size={12}
                  className="icon rotate"
                  width="12px"
                  height="15px"
                  color={
                    sortedBy?.includes(`${showSortedMenu}__desc`)
                      ? COLORS.COMPANY
                      : COLORS.PLACEHOLDERS
                  }
                />
              )}
              {item.label}
            </SortRow>
          ))}
      </SortCtr>
    );
  };

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includeTeamAverage,
    options.includePeerReviewAverage,
    options.includeSelfReviewAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    filters.reviewsOptionSelected,
    filters.skillOptionsSelected,
    filters.skillCategoryOptionsSelected,
    filters.performanceCategoryOptionsSelected,
    filters.memberOptionsSelected,
    dimensions.measure,
    dimensions.primary,
    dimensions.secondary,
    pagination.index,
    debouncedSearch,
    sortedBy,
  ]);

  const isLoadingState = isWaiting || isLoading;

  if (showNoResultPlaceholder) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <MainCtr>
          <LoaderCtr>
            <NoDataPlaceholder />
          </LoaderCtr>
        </MainCtr>
      </HeaderIncluded>
    );
  }

  return (
    <HeaderIncluded isLoading={isLoading}>
      <MainCtr>
        {isLoadingState ? (
          <LoaderCtr>
            <Loader />
          </LoaderCtr>
        ) : (
          <>
            {visualType !== REPORT_VISUAL_TYPES.SPIDER && (
              <FilterCtr>
                <FilterBtnCtr>
                  <SearchCtr>
                    <SearchFieldWrapper
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setSearch(e.currentTarget.value);
                      }}
                      value={search}
                      placeholder={i18n._(t`Search...`)}
                    />
                  </SearchCtr>
                </FilterBtnCtr>
              </FilterCtr>
            )}
            {/* TODO: conditionally render spider graph*/}
            {visualType === REPORT_VISUAL_TYPES.SPIDER && (
              <SpiderGraph
                dataGroups={['COACH', ...multiHeaderRowOptions].map((option) => {
                  const item = dataGroupsConfig[option];
                  return {
                    data: data
                      .filter((data) => data.name !== 'Average')
                      .map((d) => ({
                        id: d.id,
                        key: d.name,
                        value: d[item.accessor] || 0,
                        deviation: d[item.deviationAccessor] || 0,
                        ratingLabels: d.ratingLabels,
                      })),
                    key: item.key,
                    colors: option === 'COACH' ? [companyColor] : [item.color],
                    label: item.label,
                  };
                })}
                height={478}
                tooltipTitle={durationTooltipText}
                bgColor={companyColor}
                onPointClick={handleDataRowClick}
                colors={[
                  dataGroupsConfig.COACH.color,
                  dataGroupsConfig.SELF.color,
                  dataGroupsConfig.PEER.color,
                ]}
                configOverrides={generateSpiderChartConfigOverrides()}
              />
            )}

            {/* this is just a styled div with a 40px height*/}
            <PrimaryDimensionHolder />
            {visualType === REPORT_VISUAL_TYPES.BAR_HORIZONTAL && (
              <HorizontalBarChart
                setShowSortedMenu={setShowSortedMenu}
                sortedBy={sortedBy}
                getSortMenu={getSortMenu}
                showSortedMenu={showSortedMenu}
                data={data}
                handleDataRowClick={handleDataRowClick}
                showSelf={multiHeaderRowOptions.includes('SELF')}
                showPeer={multiHeaderRowOptions.includes('PEER')}
                colors={[
                  dataGroupsConfig.COACH.color,
                  dataGroupsConfig.SELF.color,
                  dataGroupsConfig.PEER.color,
                ]}
                tooltipTitle={durationTooltipText}
                areGeneratedRowsClickable
              />
            )}
            <PaginationCtr>
              <PaginationBar
                pagination={pagination}
                changePagination={onPageChangeClick}
                changePageSize={handleChangeItemsPerPage}
                count={totalCount}
                noShadow
                noBorder
                noTopBorder
                showCount
              />
            </PaginationCtr>
            {shouldShowLuca && <Luca />}
          </>
        )}
        <GraphFooter>
          {filteredDataGroups.map((dataGroup, i) => {
            const option = dataGroupsConfig[dataGroup];
            return (
              <Tile key={`tiles-${i + 1}`}>
                <ColoredCircle selectedColor={option.color} />
                {option.label}
              </Tile>
            );
          })}
        </GraphFooter>
        {showInformationModal && informationModalData && (
          <InformationModal
            data={informationModalData}
            onClose={setShowInformationModal}
            baseRef={baseRef}
          />
        )}
      </MainCtr>
    </HeaderIncluded>
  );
}

export default Total;
