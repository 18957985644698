// table grid ie. the heatmap table
import React, {
  ChangeEvent,
  useState,
  useContext,
  useEffect,
  MouseEventHandler,
  useMemo,
  useCallback,
} from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, REVIEW_RATING_TYPE, ROLES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Loader } from '~/components/Buttons/components/Loader';
import { ICONS, Icon } from '~/components/Icon';
import { TableGrid } from '~/components/TableGrid';
import { checkEmptyData } from '~/components/TableGrid/utils';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';

import {
  EmptyCell,
  NormalCell,
  NormalCellCtr,
  NotAvailableCtr,
  SearchFieldWrapper,
  SearchCtr,
  FilterBtnCtr,
  FilterCtr,
  HeatmapCtr,
  BodyCtr,
  Nester,
} from './CustomStyles';
import { NoDataTextContainer } from './OvertimeStyles';

import { ColumnPosition, IColumnTable } from '~/@types/table';
import useDebounce from '~/hooks/useDebounce';
import { useOutsideClick } from '~/hooks/useOutsideClick';
import { usePagination } from '~/hooks/usePagination';
import useSearchState from '~/hooks/useSearchState';
import {
  TGetEngagementDetailsPayload,
  TLucaModalData,
  TSortingOrder,
  getEngagementDetails,
} from '~/services/reports';
import { COLORS } from '~/styles';
import {
  getDateForTimeFrame,
  prepareReportFilters,
  processRows,
  sanitizeDimensions,
} from '~/utils/reports';

import { isCorrectDimensionSelected, isFirst } from '../../common';
import { Cell } from '../../Components/Cell';
import CollapseIndicator from '../../Components/IconWrapper';
import NoDataPlaceholder from '../../Components/NoDataPlaceholder';
import { MENU_SIZE, PAGINATION_PRIMARY_OPTIONS } from '../../options';
import { TEngagementData, TDataStruct, TData, EOptions, TOption, TColumns } from '../../types';
import { EngagementReportContext } from '../EngagementContext';
import { HeaderIncluded } from '../Header';
import { InformationModal } from '../InformationModal';
import { Luca } from '../Luca';

export type TFetchDataProp = {
  sortBy?: string;
  skip?: number;
  limit?: number;
  dt?: TDataStruct;
};

type TProps = { optionChangeHandler: (key: EOptions, value?: string | TOption[] | null) => void };

const EngagementCustomPage = ({ optionChangeHandler }: TProps) => {
  const $search = useSearchState();
  const { i18n } = useLingui();
  const [showInformationModal, setShowInformationModal] = useState(false);
  const [engagementData, setEngagementData] = useState<TEngagementData[]>([]);
  const [heatMapColumns, setHeatMapColumns] = useState<TColumns[]>([]);
  const [columns, setColumns] = useState<IColumnTable<any>[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sortBy, setSortBy] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const debouncedSearch = useDebounce($search.value, 1000);
  const [isHavingSecondary, setIsHavingSecondary] = useState(false);
  const [search, setSearch] = useState('');
  const [noDataMessage, setNoDataMessage] = useState('');
  const [informationModalData, setInformationModalData] = useState<TLucaModalData | null>(null);
  const [showNoResultPlaceholder, setShowNoResultPlaceholder] = useState(false);

  const { viewAs, shouldShowLuca, options, reportType, dimensions, filters, isWaiting, reportId } =
    useContext(EngagementReportContext);
  const { pagination, changePagination } = usePagination(PAGINATION_PRIMARY_OPTIONS[0].id);

  useEffect(() => {
    $search.set('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const { primary, secondary, measure, secondDimension, thirdDimension, fourthDimension } =
    dimensions;

  const shouldBenchmarkDisabled =
    primary === 'theme' &&
    (secondary === 'secondary_none' || secondary === 'primary_none' || !secondary) &&
    (measure === 'month' || measure === 'quarter' || measure === 'year');

  const multiHeaderRowOptions = useMemo(
    () =>
      [
        options.includePeerReviewAverage ? REVIEW_RATING_TYPE.PEER : null,
        options.includeSelfReviewAverage ? REVIEW_RATING_TYPE.SELF : null,
      ].filter((item): item is REVIEW_RATING_TYPE => !!item),
    [options.includePeerReviewAverage, options.includeSelfReviewAverage],
  );

  const enableMultipleTableHeaderRows = useMemo(() => {
    return multiHeaderRowOptions.length > 0;
  }, [multiHeaderRowOptions]);

  const getAdditionalDimensions = useCallback(() => {
    const { secondDimension, thirdDimension, fourthDimension } = dimensions;
    const DimensionArray = [secondDimension, thirdDimension, fourthDimension];

    const selectedDimensions: string[] = [];

    for (let i = 0; i < 3; i++) {
      // if any earlier dimension is _none should not collect later dimensions, eg: no fourth if second is _none
      const dimension = DimensionArray[i];
      if (!dimension) {
        continue;
      }
      if (dimension.includes('_none')) {
        break;
      }

      selectedDimensions.push(dimension);
    }
    return selectedDimensions;
  }, [dimensions]);

  const getData = async () => {
    if (primary === 'primary_none') {
      setNoDataMessage(i18n._(t`Please select a primary dimension`));
    } else if (measure === 'measure_none' || !measure) {
      setNoDataMessage(i18n._(t`Please select a measurement type`));
    } else {
      setNoDataMessage(i18n._(t`There is no data available yet. Please try again later.`));
    }
    if (!isCorrectDimensionSelected(primary, secondary, measure)) {
      setEngagementData([]);
      setColumns([]);
      return;
    }
    setIsLoading(true);
    setTotalCount(0);
    const sortedArray = sortBy.split('__') || [];
    const payload: TGetEngagementDetailsPayload = {
      viewAs,
      reportType,
      chartType: REPORT_CHART_TYPES.BY_TWO_DIMENSION,
      primaryDimension: sanitizeDimensions(primary),
      secondaryDimension: sanitizeDimensions(secondary),
      measure: sanitizeDimensions(measure),
      ...(reportType === REPORT_TYPES.PERFORMANCE
        ? { additionalDimensions: getAdditionalDimensions() }
        : {}),
      dateRange: getDateForTimeFrame(filters.monthSelected),
      filters: {
        ...prepareReportFilters(filters),
        ...(debouncedSearch &&
          debouncedSearch.trim().length > 0 && { search: debouncedSearch || '' }),
      },
      sorting: {
        orderBy: sortedArray.length === 2 ? sortedArray[0] : '',
        order: sortedArray.length === 2 ? (sortedArray[1] as TSortingOrder) : ('' as TSortingOrder),
      },
      options: {
        includeCompanyAverage: options.includeCompanyAverage,
        includeBenchmark: shouldBenchmarkDisabled && options.includeBenchmark,
        isHeatmapColored: options.isHeatmapColored,
        includePeerReviewAverage: options.includePeerReviewAverage,
        includeSelfReviewAverage: options.includeSelfReviewAverage,
        ...(viewAs === ROLES.USER && { includeTeamAverage: options.includeTeamAverage }),
      },
      pagination,
    };
    try {
      const heatmap = await getEngagementDetails(payload);
      const heatmapData = heatmap.data as TData;
      setShowNoResultPlaceholder(Boolean(heatmapData.total === 0));
      const hasSecondaryColumn = heatmapData.columns?.filter(
        (item) => item.id === 'secondaryDimension',
      );
      const engagementRows: TEngagementData[] = heatmapData.rows.map((item, i, arr) => {
        return {
          ...item,
          showPrimary: hasSecondaryColumn.length === 0 ? true : isFirst(arr, i),
          nestedLevel: 0, // that is what we are using to nest the data
        };
      });

      const processedEngagementRows: TEngagementData[] = [];

      processRows(engagementRows, 0, processedEngagementRows);

      setTotalCount(heatmapData.total || 0);

      setEngagementData(processedEngagementRows);
      setIsHavingSecondary(hasSecondaryColumn.length > 0);

      // when doubleHeader rows are enabled, we need to process columns with column spans
      if (enableMultipleTableHeaderRows) {
        // adding  a one because the default value also needs a column
        const colSpanLength = multiHeaderRowOptions.length + 1;

        // first header column row when doubleHeader rows are enabled
        const multiHeaderColumns = heatmapData.columns.map((item) => {
          if (item.id !== 'primaryDimension') {
            return {
              ...item,
              colSpan: colSpanLength,
              isFixed: item.id === 'average' || item.id === 'benchmark',
              position:
                item.id === ''
                  ? ColumnPosition.LEFT
                  : ['average', 'benchmark'].includes(item.id.toString())
                  ? ColumnPosition.RIGHT
                  : null,
            };
          }
          return item;
        });
        setHeatMapColumns(multiHeaderColumns);
      } else {
        setHeatMapColumns(heatmapData.columns);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // prepare the second header row columns
  const secondaryHeaderColumns: {
    title: string;
    isFixed: boolean;
    columnPosition?: ColumnPosition;
  }[] = [];
  heatMapColumns.forEach((item) => {
    if (item.id === 'primaryDimension') {
      secondaryHeaderColumns.push({
        title: '',
        columnPosition: ColumnPosition.LEFT,
        isFixed: true,
      });
    } else {
      const shouldRightAlign = item.id === 'benchmark' || item.id === 'average';
      ['COACH', ...multiHeaderRowOptions].forEach((option: string) => {
        // this will push "COACH", "PEER" and "SELF" to the secondary header row based on their availability
        secondaryHeaderColumns.push({
          title: option,
          isFixed: shouldRightAlign ? true : false,
          columnPosition: shouldRightAlign ? ColumnPosition.RIGHT : undefined,
        });
      });
    }
  });

  // this function is used as a callback for onColClick function as well. there we are not passing the column,
  // so that the value, measurement related data will not be generated. this is OK as we are not using measure for the primary row click,
  // note: also the value is deprecated and perhaps it will be removed in the future.
  const onCellClick = useCallback(
    (row: TEngagementData, column?: any, reviewer?: REVIEW_RATING_TYPE) => {
      setInformationModalData({
        filters: {
          ...(reportType === REPORT_TYPES.ENGAGEMENT
            ? {
                primaryDimensionValue: row.id,
                secondaryDimensionValue: row.secondaryId,
                measureValue: column?.id,
                // not passing additional dimensions here assuming we are not using nested dimensions for engagement reports
              }
            : {
                // this is little trickier. row id is the least dimension and the first of the additional dimension is the primary dimension in nested dimensions approach
                // going through how nested dimensions work will make it clear for you.
                // also for the initial rows, ( nested level 0 ) primary dimension value is the row.id
                primaryDimensionValue:
                  row.nestedLevel > 0 ? row.additionalDimensionValues[0] : row.id,
                measureValue: column?.id,
                additionalDimensionValues:
                  row.nestedLevel > 0 ? [...row.additionalDimensionValues.slice(1), row.id] : [],
              }),
        },
        value: row.cells.find((c) => c.measure === column?.id)?.value || 0,
        measureName: column?.name,
        ...(reportType === REPORT_TYPES.ENGAGEMENT
          ? {
              primaryDimensionName: row.name,
              secondaryDimensionName: row.secondaryName,
            }
          : {
              additionalDimensionNames: [...row.additionalDimensionNames, row.name || ''],
            }),
        reviewer,
      });
      setShowInformationModal(true);
    },
    [reportType],
  );

  const onColClick = useMemo(
    () => ({
      column: 'primaryDimension',
      onClick: onCellClick,
    }),
    [onCellClick],
  );

  useEffect(() => {
    const hasRightAlignedColumns =
      columns.filter((column) => column.isFixed && column.position === ColumnPosition.RIGHT)
        .length > 0;
    const heatmapColumns = heatMapColumns.map((heatmapColumn, heatmapColumnIndex: number) => {
      const isLastColumn = heatmapColumnIndex === heatMapColumns.length - 1;
      const shouldShowDarkBackground =
        isLastColumn && !options.isHeatmapColored && hasRightAlignedColumns;

      if (heatmapColumn.id === 'primaryDimension' || heatmapColumn.id === 'secondaryDimension') {
        return {
          ...heatmapColumn,
          id: heatmapColumn.id,
          name: `${heatmapColumn.name}`,
          accessor: `${heatmapColumn.id}`,
          renderCell: (
            cell: TEngagementData,
            onClick: MouseEventHandler<HTMLDivElement> | undefined,
          ) => {
            if (
              heatmapColumn.id === 'primaryDimension' &&
              !cell.showPrimary &&
              reportType !== REPORT_TYPES.PERFORMANCE // currently nested data is only for performance
            ) {
              return null;
            }
            const secondaryColumnName =
              cell.secondaryName && cell.secondaryName.trim().length > 0 ? cell.secondaryName : '';
            const displayName =
              heatmapColumn.id === 'primaryDimension' ? cell.name : secondaryColumnName;
            return (
              <NormalCellCtr
                className="cell rounded-corners"
                clickable={!!onClick}
                onClick={() => {
                  /* @ts-ignore */
                  onClick ? onClick(cell) : {};
                }}
              >
                <Tooltip tooltip={displayName} size={TOOLTIP_SIZES.BIG}>
                  <NormalCell
                    mWidth={
                      isHavingSecondary
                        ? `${MENU_SIZE.LEFT_HALF_WIDTH}px`
                        : `${MENU_SIZE.LEFT_FULL_WIDTH}px`
                    }
                  >
                    <div className="cell-content-wrapper">
                      <Nester nestLevel={cell.nestedLevel} />
                      <div
                        className={`cell-dimension-section ${
                          options.isHeatmapColored ? 'cell-hover-with-color' : ''
                        }`}
                      >
                        {cell.children && cell.children.length > 0 && (
                          <CollapseIndicator
                            isCollapsed={cell.isCollapsed}
                            handleOnClick={() => {
                              if (cell.isCollapsed) {
                                const newRows = engagementData.map((row) => {
                                  const isRowAChild =
                                    row.parents.join(',') ===
                                    [...cell.parents, cell.temporalUniqueId].join(',');

                                  if (row.temporalUniqueId === cell.temporalUniqueId) {
                                    return {
                                      ...row,
                                      isCollapsed: false,
                                    };
                                  }

                                  if (isRowAChild) {
                                    return {
                                      ...row,
                                      isVisible: true,
                                    };
                                  }
                                  return row;
                                });

                                setEngagementData(newRows);
                              } else {
                                // handle collapse
                                const newRows = engagementData.map((row) => {
                                  const isRowAChild = row.parents
                                    .join(',')
                                    .includes([...cell.parents, cell.temporalUniqueId].join(','));

                                  if (row.temporalUniqueId === cell.temporalUniqueId) {
                                    return {
                                      ...row,
                                      isCollapsed: true,
                                    };
                                  }

                                  if (isRowAChild) {
                                    return {
                                      ...row,
                                      isVisible: false,
                                      isCollapsed: true,
                                    };
                                  }

                                  return row;
                                });

                                setEngagementData(newRows);
                              }
                            }}
                          />
                        )}
                        <span className="text-content-wrapper">{displayName}</span>
                      </div>
                    </div>
                  </NormalCell>
                </Tooltip>
              </NormalCellCtr>
            );
          },
          isFixed: true,
          centerAlign: true,
          position: ColumnPosition.LEFT,
          maxWidth: heatmapColumn.id === 'primaryDimension' ? '200px' : '100px',
          minWidth: '40px',
          padding: '7px',
          showHeaderTooltip: true,
          sortBy: {
            asc: {
              key: `${heatmapColumn.id}__asc`,
              label: (i18n: I18n) => i18n._(t`A-Z Alphabetic`),
            },
            desc: {
              key: `${heatmapColumn.id}__desc`,
              label: (i18n: I18n) => i18n._(t`Z-A Alphabetic`),
            },
          },
        };
      }
      return {
        ...heatmapColumn,
        id: heatmapColumn.id,
        name: `${heatmapColumn.name}`,
        accessor: `${heatmapColumn.id}`,
        // render main table cell (middle table)
        renderCell: (row: TEngagementData) => {
          const cellValue = row.cells.find((c) => c.measure === heatmapColumn.id);
          if (!cellValue?.value) {
            if (heatmapColumn.id === 'benchmark') {
              return (
                <Tooltip tooltip={i18n._(t`Not enough data available, please check back later`)}>
                  <EmptyCell className="cell">
                    <NotAvailableCtr>{i18n._(t`N/A`)}</NotAvailableCtr>
                  </EmptyCell>
                </Tooltip>
              );
            }
            return (
              <Tooltip tooltip={i18n._(t`Reporting threshold has not been reached`)}>
                <EmptyCell className="cell">
                  <Icon className="incognito" icon={ICONS.INCOGNITO} fill={COLORS.PLACEHOLDERS} />
                </EmptyCell>
              </Tooltip>
            );
          }

          if (enableMultipleTableHeaderRows) {
            return (
              [REVIEW_RATING_TYPE.COACH, ...multiHeaderRowOptions] as REVIEW_RATING_TYPE[]
            ).map((option, index) => {
              let value = 0;
              switch (option) {
                case REVIEW_RATING_TYPE.COACH:
                  value = cellValue?.value || 0;
                  break;
                case REVIEW_RATING_TYPE.PEER:
                  value = cellValue.peer || 0;
                  break;
                case REVIEW_RATING_TYPE.SELF:
                  value = cellValue.self || 0;
                  break;
              }
              return (
                <Cell
                  key={index}
                  value={value}
                  noColor={!options.isHeatmapColored}
                  onClick={() => {
                    onCellClick(row, heatmapColumn, option);
                  }}
                  extraClassNamesWhenNoColors={
                    shouldShowDarkBackground ? ['dark-cell-background'] : []
                  }
                >
                  {value}%
                </Cell>
              );
            });
          }

          return (
            <Cell
              value={cellValue?.value || 0}
              noColor={!options.isHeatmapColored}
              onClick={() => {
                onColClick.onClick(row);
              }}
              extraClassNamesWhenNoColors={shouldShowDarkBackground ? ['dark-cell-background'] : []}
            >
              {cellValue?.value}%
            </Cell>
          );
        },
        isFixed: heatmapColumn.id === 'average' || heatmapColumn.id === 'benchmark',
        position: ColumnPosition.RIGHT,
        maxWidth: '20px',
        minWidth: '110px',
        padding: '7px',
        centerAlign: true,
        showHeaderTooltip: true,
        ...(!secondary && {
          sortBy: {
            asc: {
              key: `${heatmapColumn.id}__desc`,
              label: (i18n: I18n) => i18n._(t`High to low`),
            },
            desc: {
              key: `${heatmapColumn.id}__asc`,
              label: (i18n: I18n) => i18n._(t`Low to high`),
            },
          },
        }),
      };
    });
    setColumns(heatmapColumns);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    enableMultipleTableHeaderRows,
    engagementData,
    heatMapColumns,
    i18n,
    isHavingSecondary,
    multiHeaderRowOptions,
    onCellClick,
    onColClick,
    options.isHeatmapColored,
    reportType,
    secondary,
  ]);

  useEffect(() => {
    if (!isWaiting) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isWaiting,
    options.includeBenchmark,
    options.includeCompanyAverage,
    options.includeTeamAverage,
    options.includePeerReviewAverage,
    options.includeSelfReviewAverage,
    filters.ageGroupSelected,
    filters.educationLevelsSelected,
    filters.gendersSelected,
    filters.jobsGroupsSelected,
    filters.jobsSelected,
    filters.monthSelected,
    filters.surveysOptionSelected,
    filters.teamsOptionSelected,
    filters.themesOptionSelected,
    filters.reviewsOptionSelected,
    filters.skillOptionsSelected,
    filters.skillCategoryOptionsSelected,
    filters.performanceCategoryOptionsSelected,
    filters.memberOptionsSelected,
    measure,
    primary,
    secondary,
    secondDimension,
    thirdDimension,
    fourthDimension,
    pagination.index,
    options.isHeatmapColored,
    debouncedSearch,
    sortBy,
  ]);

  useEffect(() => {
    optionChangeHandler(EOptions.reset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const baseRef = useOutsideClick<HTMLDivElement>(() => {
    setShowInformationModal(false);
  });

  const getRightMinWidth = () => {
    if (options.includeBenchmark && options.includeCompanyAverage) {
      return `${MENU_SIZE.RIGHT_FULL_WIDTH}px`;
    }
    if (options.includeCompanyAverage) {
      return `${MENU_SIZE.ONLY_AVERAGE}px`;
    }
    if (options.includeBenchmark) {
      return `${MENU_SIZE.ONLY_BENCHMARK}px`;
    }
    return '0px';
  };

  const isLoadingState = isWaiting || isLoading;

  if (showNoResultPlaceholder) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <HeatmapCtr>
          <BodyCtr>
            <NoDataPlaceholder />
          </BodyCtr>
        </HeatmapCtr>
      </HeaderIncluded>
    );
  }

  if (!isLoading && engagementData.length === 0) {
    return (
      <HeaderIncluded isLoading={isLoading}>
        <HeatmapCtr>
          <BodyCtr>
            <NoDataTextContainer>{noDataMessage}</NoDataTextContainer>
          </BodyCtr>
        </HeatmapCtr>
      </HeaderIncluded>
    );
  }

  const mainTablePlaceholder = checkEmptyData({
    data: engagementData,
    column: 'cells',
    field: 'measure',
    skipColumns: [],
    reportingThreshold: 0,
    i18n,
  });

  return (
    <HeaderIncluded isLoading={isLoading}>
      {isLoadingState ? (
        <BodyCtr>
          <Loader />
        </BodyCtr>
      ) : (
        <>
          <HeatmapCtr>
            <FilterCtr>
              <FilterBtnCtr>
                <SearchCtr>
                  <SearchFieldWrapper
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      $search.set(e.currentTarget.value);
                    }}
                    value={$search.value}
                    placeholder={i18n._(t`Search...`)}
                  />
                </SearchCtr>
              </FilterBtnCtr>
            </FilterCtr>
            <TableGrid
              isHeatmapColored={options.isHeatmapColored}
              data={engagementData.filter((item) => item.isVisible)}
              columns={columns}
              secondaryHeaderColumns={secondaryHeaderColumns}
              enableMultipleTableHeaderRows={enableMultipleTableHeaderRows}
              onColClick={onColClick}
              onCellClick={onCellClick}
              isLeftColumnsStriped
              isScrollbarVisible
              showSortBy={true}
              leftMinWidth={
                isHavingSecondary
                  ? `${MENU_SIZE.LEFT_HALF_WIDTH * 2 + 10}px`
                  : `${MENU_SIZE.LEFT_FULL_WIDTH + 10}px`
              }
              rightMinWidth={getRightMinWidth()}
              isLoading={isLoading}
              paginationProps={{
                pagination,
                changePagination,
                totalCount,
              }}
              placeholderProps={{
                noResultText: i18n._(t`No surveys found`),
                emptyStateText: i18n._(t`No surveys yet… Let’s create one!`),
                mainTable: mainTablePlaceholder,
              }}
              filtersProps={{
                filters: {
                  search,
                  setSearch: (value) => setSearch(value),
                },
                isToggleHideFilterVisible: false,
                isFiltered: false,
              }}
              showTopArea={false}
              sortBy={''}
              setSortBy={(value) => {
                setSortBy(value);
                optionChangeHandler(EOptions.sorting, value);
              }}
              paginationIndexAlternatives={PAGINATION_PRIMARY_OPTIONS.map((item) => ({
                id: item.id,
                label: item.label(i18n),
              }))}
            />
          </HeatmapCtr>
          {shouldShowLuca && <Luca />}
          {showInformationModal && informationModalData && (
            <InformationModal
              data={informationModalData}
              onClose={setShowInformationModal}
              baseRef={baseRef}
            />
          )}
        </>
      )}
    </HeaderIncluded>
  );
};

export default EngagementCustomPage;
