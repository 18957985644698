import React, { useContext, useState } from 'react';

import { REPORT_CHART_TYPES } from '@learned/constants';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { MultiSelectDropdown } from '~/components/Dropdown/components/MultiSelectDropdown';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import SelectDropDown from '~/components/SelectDropDown';
import Tooltip from '~/components/Tooltip';
import { EOptions, TOptionMenu, TOption } from '~/pages/Reports/types';

import {
  Container,
  Header,
  Inner,
  Tabs,
  InnerTab,
  TabBody,
  Section,
  SectionHead,
  DropDownOuter,
  SectionBody,
  ResetFilters,
  DropDownCtr,
  CheckBoxOutCtr,
  CheckBoxCtr,
  Label,
  Separator,
  VerticalTab,
} from './ControlPanelStyles';
import { EngagementReportContext } from './EngagementContext';

import { COLORS } from '~/styles';

import { RIGHT_MENU_TABS } from '../../../components/SideMenu/constants';
import { ControlPanelAboutTabContent } from '../Components/ControlPanelAboutTabContent';

type TRenderedTableItem = {
  title: (item: I18n) => string;
};

type TProps = {
  optionMenu: TOptionMenu;
  showMenu: boolean;
  onShow: (val: boolean) => void;
  monthSelected: string;
  enabledOptions: EOptions[];
  isLoading: boolean;
  currentTab: REPORT_CHART_TYPES | null;
  optionChangeHandler: (key: EOptions, value?: string | TOption[] | null) => void;
};

function ControlPanel({
  optionMenu,
  showMenu,
  onShow,
  enabledOptions,
  isLoading,
  optionChangeHandler,
}: TProps): JSX.Element {
  const { i18n } = useLingui();
  const [tab, setTab] = useState<RIGHT_MENU_TABS>(RIGHT_MENU_TABS.SETTINGS);
  const { viewAs, reportType } = useContext(EngagementReportContext);

  const IndustryBenchMarkForceToEnable =
    optionMenu?.primary?.key === 'theme' &&
    (optionMenu?.secondary?.key === 'secondary_none' ||
      optionMenu?.secondary?.key === 'primary_none' ||
      !optionMenu?.secondary?.key) &&
    (optionMenu?.measure?.key === 'month' ||
      optionMenu?.measure?.key === 'quarter' ||
      optionMenu?.measure?.key === 'year');

  const getTooltipData = (enabled: boolean) => {
    return (
      <CheckBoxCtr
        onClick={() =>
          !enabled &&
          enabledOptions.includes(EOptions.includeBenchmark) &&
          optionChangeHandler(EOptions.includeBenchmark)
        }
        className={
          !enabled && enabledOptions.includes(EOptions.includeBenchmark)
            ? 'enabledOptions'
            : 'disabled'
        }
      >
        <Icon
          className="checkbox"
          size={ICON_SIZES.MEDIUM}
          icon={
            !enabled &&
            enabledOptions.includes(EOptions.includeBenchmark) &&
            optionMenu.includeBenchmark
              ? ICONS.CHECKBOX_CHECKED
              : ICONS.CHECKBOX
          }
        />

        <span>{i18n._(t`Industry benchmark`)}</span>
      </CheckBoxCtr>
    );
  };

  const getPrimaryTab = (from: EOptions, wrapWithTooltip: boolean, ele: JSX.Element) => {
    let message = '';
    if (from === EOptions.primaryDimension) {
      message = i18n._(t`Switch to Custom to apply primary dimension`);
    } else if (from === EOptions.secondaryDimension) {
      message = i18n._(t`Switch to Custom to apply secondary dimension`);
    } else if (from === EOptions.secondDimension) {
      message = i18n._(t`Switch to Custom to apply second dimension`);
    } else if (from === EOptions.thirdDimension) {
      message = i18n._(t`Switch to Custom to apply third dimension`);
    } else if (from === EOptions.fourthDimension) {
      message = i18n._(t`Switch to Custom to apply fourth dimension`);
    } else if (from === EOptions.measure) {
      message = i18n._(t`Switch to Custom to apply measurement`);
    }

    if (wrapWithTooltip) {
      return <Tooltip tooltip={message}>{ele}</Tooltip>;
    }
    return ele;
  };

  const isPrimaryOptionEnabled = enabledOptions.includes(EOptions.primaryDimension);
  const isSecondaryOptionEnabled = enabledOptions.includes(EOptions.secondaryDimension);
  const isAdditionalOptionsEnabled = enabledOptions.includes(EOptions.additionalDimensions);
  const isMeasureEnabled = enabledOptions.includes(EOptions.measure);
  const isHeatmapEnabled = enabledOptions.includes(EOptions.isHeatmapColored);
  const isAverageEnabled = enabledOptions.includes(EOptions.includeCompanyAverage);
  const isTeamAverageEnabled = enabledOptions.includes(EOptions.includeTeamAverage);
  const isBenchMarkEnabled = enabledOptions.includes(EOptions.includeBenchmark);

  const isTimeFrameEnabled = enabledOptions.includes(EOptions.timeFrame);
  const isThemeEnabled = enabledOptions.includes(EOptions.themes);
  const isTeamEnabled = enabledOptions.includes(EOptions.teams);
  const isSurveyEnabled = enabledOptions.includes(EOptions.surveys);
  const isReviewEnabled = enabledOptions.includes(EOptions.reviews);

  const isJobEnabled = enabledOptions.includes(EOptions.jobs);
  const isJobGroupEnabled = enabledOptions.includes(EOptions.jobGroups);
  const isGenderEnabled = enabledOptions.includes(EOptions.genders);
  const isAgeGroupEnabled = enabledOptions.includes(EOptions.ageGroups);
  const isEducationLevelEnabled = enabledOptions.includes(EOptions.educationLevels);
  const isSkillEnabled = enabledOptions.includes(EOptions.skills);
  const isSkillCategoryEnabled = enabledOptions.includes(EOptions.skillCategories);
  const isPerformanceCategoryEnabled = enabledOptions.includes(EOptions.performanceCategories);
  const isMemberFilterEnabled = enabledOptions.includes(EOptions.members);

  const isPeerReviewAverageEnabled = enabledOptions.includes(EOptions.includePeerReviewAverage);
  const isSelfReviewAverageEnabled = enabledOptions.includes(EOptions.includeSelfReviewAverage);

  const showDimensionSec =
    isPrimaryOptionEnabled ||
    isSecondaryOptionEnabled ||
    isMeasureEnabled ||
    isHeatmapEnabled ||
    isAverageEnabled ||
    isPeerReviewAverageEnabled ||
    isSelfReviewAverageEnabled ||
    isBenchMarkEnabled;

  const showFilterSec =
    isTimeFrameEnabled ||
    isThemeEnabled ||
    isTeamEnabled ||
    isSurveyEnabled ||
    isReviewEnabled ||
    isJobEnabled ||
    isJobGroupEnabled ||
    isGenderEnabled ||
    isAgeGroupEnabled ||
    isSkillEnabled ||
    isEducationLevelEnabled ||
    isSkillCategoryEnabled ||
    isPerformanceCategoryEnabled ||
    isMemberFilterEnabled;

  const filterCount = [
    ...optionMenu.themesOptionSelected,
    ...optionMenu.teamsOptionSelected,
    ...optionMenu.surveysOptionSelected,
    ...optionMenu.jobsSelected,
    ...optionMenu.jobsGroupsSelected,
    ...optionMenu.gendersSelected,
    ...optionMenu.ageGroupSelected,
    ...optionMenu.educationLevelsSelected,
    ...optionMenu.skillOptionsSelected,
    ...optionMenu.skillCategoryOptionsSelected,
    ...optionMenu.performanceCategoryOptionsSelected,
    ...optionMenu.memberOptionsSelected,
  ].length;

  const getTab = () => {
    if (tab === RIGHT_MENU_TABS.SETTINGS) {
      return (
        <>
          {showDimensionSec && (
            <>
              <Section>
                <SectionHead>{`${i18n._(t`Dimensions`)}`}</SectionHead>
                <SectionBody gap={'10px'}>
                  {isPrimaryOptionEnabled && (
                    <>
                      {getPrimaryTab(
                        EOptions.primaryDimension,
                        false,
                        <DropDownCtr>
                          <Label>{`${i18n._(t`Primary dimension`)}`}</Label>
                          <SelectDropDown
                            options={optionMenu.primaryOptions}
                            value={optionMenu.primary?.key}
                            onChange={(val: string) =>
                              optionChangeHandler(EOptions.primaryDimension, val)
                            }
                            keyName="key"
                            borderColor={COLORS.BORDERS}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  {/* secondaryDimension is for engagement reports only,  */}
                  {isSecondaryOptionEnabled && (
                    <>
                      {getPrimaryTab(
                        EOptions.secondaryDimension,
                        false,
                        <DropDownCtr>
                          <Label>{`${i18n._(t`Secondary dimension`)}`}</Label>
                          <SelectDropDown
                            options={optionMenu.secondaryOptions}
                            value={optionMenu.secondary?.key}
                            onChange={(val: string) =>
                              optionChangeHandler(EOptions.secondaryDimension, val)
                            }
                            keyName="key"
                            borderColor={COLORS.BORDERS}
                            disabled={!(optionMenu.primary && optionMenu.measure)}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  {/* for performance reports we consider second, third, fourth dimensions with the primary dimension */}
                  {isAdditionalOptionsEnabled && optionMenu.primary && (
                    <>
                      {getPrimaryTab(
                        EOptions.secondDimension,
                        false,
                        <DropDownCtr>
                          <Label>{`${i18n._(t`Second dimension`)}`}</Label>
                          <SelectDropDown
                            options={optionMenu.secondDimensionOptions}
                            value={optionMenu.secondDimension?.key}
                            onChange={(val: string) =>
                              optionChangeHandler(EOptions.secondDimension, val)
                            }
                            keyName="key"
                            borderColor={COLORS.BORDERS}
                            // TODO: discuss conditions for enabling conditions.
                            // disabled={!(optionMenu.primary && optionMenu.measure)}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  {isAdditionalOptionsEnabled &&
                    optionMenu.secondDimension?.key !== 'additional_none' &&
                    optionMenu.secondDimension && (
                      <>
                        {getPrimaryTab(
                          EOptions.thirdDimension,
                          false,
                          <DropDownCtr>
                            <Label>{`${i18n._(t`Third dimension`)}`}</Label>
                            <SelectDropDown
                              options={optionMenu.thirdDimensionOptions}
                              value={optionMenu.thirdDimension?.key}
                              onChange={(val: string) =>
                                optionChangeHandler(EOptions.thirdDimension, val)
                              }
                              keyName="key"
                              borderColor={COLORS.BORDERS}
                              // temporally disable disabling option
                              // disabled={!(optionMenu.primary && optionMenu.measure)}
                              listMarginTop="8px"
                              small
                              labelStyle={{
                                fontSize: '12px',
                              }}
                              optionStyles={{
                                fontSize: '12px',
                              }}
                              label={i18n._(t`Select`)}
                              renderLabel={(item: TRenderedTableItem) => (
                                <div>{item.title(i18n)}</div>
                              )}
                              width="195px"
                              listHeight="200px"
                            />
                          </DropDownCtr>,
                        )}
                      </>
                    )}
                  {isAdditionalOptionsEnabled &&
                    ![optionMenu.thirdDimension?.key, optionMenu.secondDimension?.key].includes(
                      'additional_none',
                    ) &&
                    optionMenu.thirdDimension && (
                      <>
                        {getPrimaryTab(
                          EOptions.fourthDimension,
                          false,
                          <DropDownCtr>
                            <Label>{`${i18n._(t`Fourth dimension`)}`}</Label>
                            <SelectDropDown
                              options={optionMenu.fourthDimensionOptions}
                              value={optionMenu.fourthDimension?.key}
                              onChange={(val: string) =>
                                optionChangeHandler(EOptions.fourthDimension, val)
                              }
                              keyName="key"
                              borderColor={COLORS.BORDERS}
                              // temporally disable disabling option
                              // disabled={!(optionMenu.primary && optionMenu.measure)}
                              listMarginTop="8px"
                              small
                              labelStyle={{
                                fontSize: '12px',
                              }}
                              optionStyles={{
                                fontSize: '12px',
                              }}
                              label={i18n._(t`Select`)}
                              renderLabel={(item: TRenderedTableItem) => (
                                <div>{item.title(i18n)}</div>
                              )}
                              width="195px"
                              listHeight="200px"
                            />
                          </DropDownCtr>,
                        )}
                      </>
                    )}

                  {isMeasureEnabled && (
                    <>
                      {getPrimaryTab(
                        EOptions.measure,
                        false,
                        <DropDownCtr>
                          <Label>{`${i18n._(t`Measurement`)}`}</Label>
                          <SelectDropDown
                            options={optionMenu.measureOptions}
                            value={optionMenu.measure?.key}
                            onChange={(val: string) => optionChangeHandler(EOptions.measure, val)}
                            keyName="key"
                            disabled={!optionMenu.primary}
                            borderColor={COLORS.BORDERS}
                            listMarginTop="8px"
                            small
                            labelStyle={{
                              fontSize: '12px',
                            }}
                            optionStyles={{
                              fontSize: '12px',
                            }}
                            label={i18n._(t`Select`)}
                            renderLabel={(item: TRenderedTableItem) => (
                              <div>{item.title(i18n)}</div>
                            )}
                            width="195px"
                            listHeight="200px"
                          />
                        </DropDownCtr>,
                      )}
                    </>
                  )}
                  <CheckBoxOutCtr>
                    {isHeatmapEnabled && (
                      <CheckBoxCtr onClick={() => optionChangeHandler(EOptions.isHeatmapColored)}>
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            optionMenu.isHeatmapColored ? ICONS.CHECKBOX_CHECKED : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Heatmap colors`)}</span>
                      </CheckBoxCtr>
                    )}
                    {isAverageEnabled && (
                      <CheckBoxCtr
                        onClick={() => optionChangeHandler(EOptions.includeCompanyAverage)}
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            optionMenu.includeCompanyAverage
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Company average`)}</span>
                      </CheckBoxCtr>
                    )}
                    {isTeamAverageEnabled && (
                      <CheckBoxCtr onClick={() => optionChangeHandler(EOptions.includeTeamAverage)}>
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            optionMenu.includeTeamAverage ? ICONS.CHECKBOX_CHECKED : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Team average`)}</span>
                      </CheckBoxCtr>
                    )}
                    {isPeerReviewAverageEnabled && (
                      <CheckBoxCtr
                        onClick={() => optionChangeHandler(EOptions.includePeerReviewAverage)}
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            optionMenu.includePeerReviewAverage
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Peer review(s)`)}</span>
                      </CheckBoxCtr>
                    )}
                    {isSelfReviewAverageEnabled && (
                      <CheckBoxCtr
                        onClick={() => optionChangeHandler(EOptions.includeSelfReviewAverage)}
                      >
                        <Icon
                          className="checkbox"
                          size={ICON_SIZES.MEDIUM}
                          icon={
                            optionMenu.includeSelfReviewAverage
                              ? ICONS.CHECKBOX_CHECKED
                              : ICONS.CHECKBOX
                          }
                        />
                        <span>{i18n._(t`Self review(s)`)}</span>
                      </CheckBoxCtr>
                    )}
                    {isBenchMarkEnabled && (
                      <>
                        {isLoading || IndustryBenchMarkForceToEnable ? (
                          <>{getTooltipData(!IndustryBenchMarkForceToEnable)}</>
                        ) : (
                          <Tooltip
                            tooltip={i18n._(
                              t`Industry benchmarks are only available for Learned themes. Please select "themes" in primary dimension and do not select a secondary row dimension, and select month, quarter or year in measurement.`,
                            )}
                          >
                            {getTooltipData(true)}
                          </Tooltip>
                        )}
                      </>
                    )}
                  </CheckBoxOutCtr>
                </SectionBody>
              </Section>
              <Separator />
            </>
          )}
          {/* Filter Section --> */}
          {showFilterSec && (
            <Section>
              <SectionHead>{`${i18n._(t`Filters`)}`}</SectionHead>
              <SectionBody gap={'10px'}>
                {isTimeFrameEnabled && (
                  <DropDownOuter enabled={true}>
                    <SelectDropDown
                      options={optionMenu.monthOptions}
                      value={optionMenu.monthSelected}
                      onChange={(val: string) => optionChangeHandler(EOptions.timeFrame, val)}
                      keyName="key"
                      borderColor={COLORS.BORDERS}
                      listMarginTop="8px"
                      small
                      labelStyle={{
                        fontSize: '12px',
                      }}
                      optionStyles={{
                        fontSize: '12px',
                      }}
                      label={i18n._(t`Select`)}
                      renderLabel={(item: TRenderedTableItem) => <div>{item.title(i18n)}</div>}
                      width="195px"
                      listHeight="200px"
                    />
                  </DropDownOuter>
                )}
                {isTeamEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Teams`)}
                      items={[...optionMenu.teamsOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.teamsOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.teams, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isSurveyEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Surveys`)}
                      items={[...optionMenu.surveysOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.surveysOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.surveys, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isReviewEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Reviews`)}
                      items={[...optionMenu.reviewsOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.reviewsOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.reviews, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isThemeEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Themes`)}
                      items={[...optionMenu.themesOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.themesOptionSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.themes, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isAgeGroupEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Age Groups`)}
                      items={[...optionMenu.ageGroupOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.ageGroupSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.ageGroups, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isGenderEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Genders`)}
                      items={[...optionMenu.genderOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.gendersSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.genders, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isJobEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Jobs`)}
                      items={[...optionMenu.jobsOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.jobsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.jobs, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isJobGroupEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Job Groups`)}
                      items={[...optionMenu.jobGroupOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.jobsGroupsSelected.map((item) => item)]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.jobGroups, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isEducationLevelEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Education Levels`)}
                      items={[...optionMenu.educationLevelOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.educationLevelsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.educationLevels, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isSkillEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Skills`)}
                      items={[...optionMenu.skillOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.skillOptionsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.skills, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isSkillCategoryEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Skill Categories`)}
                      items={[...optionMenu.skillCategoryOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.skillCategoryOptionsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.skillCategories, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isMemberFilterEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Member`)}
                      items={[...optionMenu.memberOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.memberOptionsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.members, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {isPerformanceCategoryEnabled && (
                  <DropDownOuter enabled={true}>
                    <MultiSelectDropdown
                      placeholder={i18n._(t`Performance Categories`)}
                      items={[...optionMenu.performanceCategoryOptions]}
                      renderItem={(item) => item.title(i18n)}
                      selectedItems={[...optionMenu.performanceCategoryOptionsSelected]}
                      onChange={(value) => {
                        optionChangeHandler(EOptions.performanceCategories, value);
                      }}
                      stringifyItem={(item) => item.title(i18n)}
                    />
                  </DropDownOuter>
                )}
                {filterCount > 0 && (
                  <ResetFilters
                    onClick={() => optionChangeHandler(EOptions.reset, null)}
                    enabled={
                      enabledOptions.includes(EOptions.themes) ||
                      enabledOptions.includes(EOptions.teams) ||
                      enabledOptions.includes(EOptions.surveys) ||
                      enabledOptions.includes(EOptions.timeFrame)
                    }
                  >
                    {i18n._(t`Reset filters`)}
                  </ResetFilters>
                )}
              </SectionBody>
            </Section>
          )}
        </>
      );
    } else if (tab === RIGHT_MENU_TABS.ABOUT) {
      return <ControlPanelAboutTabContent viewAs={viewAs} reportType={reportType} />;
    }
  };
  return (
    <Container showMenu={showMenu}>
      <Header onClick={() => onShow(!showMenu)}>
        <Icon
          className={showMenu ? 'checkbox-open' : 'checkbox'}
          size={ICON_SIZES.SMALL}
          icon={ICONS.COLLAPSE_MENU}
        />
        {showMenu && `${i18n._(t`Close`)}`}
      </Header>
      {showMenu ? (
        <Inner isDisabled={isLoading}>
          <Tabs>
            <InnerTab
              isSelected={tab === RIGHT_MENU_TABS.SETTINGS}
              onClick={() => setTab(RIGHT_MENU_TABS.SETTINGS)}
            >{`${i18n._(t`Settings`)}`}</InnerTab>
            <InnerTab
              isSelected={tab === RIGHT_MENU_TABS.ABOUT}
              onClick={() => setTab(RIGHT_MENU_TABS.ABOUT)}
            >{`${i18n._(t`About`)}`}</InnerTab>
          </Tabs>
          <TabBody>{getTab()}</TabBody>
        </Inner>
      ) : (
        <VerticalTab onClick={() => onShow(!showMenu)}>{`${i18n._(
          t`Settings & About`,
        )}`}</VerticalTab>
      )}
    </Container>
  );
}

export default ControlPanel;
