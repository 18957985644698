import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { UserAvatar } from '~/components/UserAvatar';

import { TaskStatus } from './TaskStatus';

import { useOnHover } from '~/hooks/useOnHover';

import { ListRow, RowRightBlock, RowLeftBlock } from '../design';

import type { ITask, IUser } from '@learned/types';

const FirstRowWrapper = styled.div`
  margin-bottom: 10px;
`;

interface ListRowTaskProps {
  task: ITask;
  onDelete?: (task: ITask) => void;
  isShowButton?: (task: ITask) => boolean;
  onClick: (task: ITask) => void;
}

const ListRowOthers = ({
  task,
  onDelete,
  isShowButton = () => false,
  onClick,
}: ListRowTaskProps) => {
  const { i18n } = useLingui();
  const { ref, isHovering } = useOnHover();

  const getButtonLabel = () => {
    switch (task.status) {
      case TASK_STATUS.TODO:
        return i18n._(t`Start`);
      case TASK_STATUS.ACTIVE:
      case TASK_STATUS.CALIBRATE:
        return i18n._(t`Continue`);
      case TASK_STATUS.COMPLETED:
        return i18n._(t`Edit`);
      default:
        return '';
    }
  };

  return (
    <div>
      <FirstRowWrapper>
        <ListRow ref={ref} $isHovering={onDelete && isHovering}>
          <RowRightBlock>
            {task.userTo.email ? (
              <UserAvatar
                user={{ email: task.userTo.email } as IUser}
                options={{ showTooltip: true }}
              />
            ) : (
              // @ts-ignore
              <UserAvatar userId={task.userTo.id} options={{ showTooltip: true }} />
            )}
          </RowRightBlock>
          <RowLeftBlock>
            <TaskStatus
              task={task}
              onDelete={isHovering && onDelete ? () => onDelete(task) : undefined}
            />
          </RowLeftBlock>
        </ListRow>
      </FirstRowWrapper>

      {isShowButton(task) && (
        <ListRow>
          <Button
            label={getButtonLabel()}
            size={ButtonSize.MEDIUM}
            variant={ButtonVariant.NAVIGATION_SECONDARY}
            isLoading={false}
            onClick={() => onClick(task)}
          />
        </ListRow>
      )}
    </div>
  );
};

export { ListRowOthers };
