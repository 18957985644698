import styled, { css } from 'styled-components';

import { ButtonWhite } from '~/components/Button';

import { ISection, SECTION_STATUS } from './types';

import { COLORS } from '~/styles';

export const SectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: auto;
  height: 100%;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const WhiteSpace = styled.div`
  min-height: calc(50% - 25px);
  max-height: calc(50% - 25px);
`;

export const Sections = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: visible;
  align-content: space-between;
  min-height: auto;
  gap: 20px;
`;

export const Lines = styled.div`
  position: absolute;
  display: flex;
  max-height: calc(100% - 50px);
  min-height: calc(100% - 50px);
  top: 25px;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-height: 50px;
  width: 1px;
  z-index: 10;
  background-color: ${({ color }) => color || '#dcdcdc'};
  margin: 0 0 0 26.25px;
`;

export const Container = styled.div`
  position: relative;
`;

export const BackButton = styled(ButtonWhite)<{ isHidden?: boolean }>`
  height: 32px;
  min-width: 80px;
  padding: 0 14px 0 10px;
  border-radius: 100px;
  font-size: 12px;
  border: none;
  color: ${COLORS.COMPANY};
  background-color: unset;

  ${(props) =>
    props.isHidden
      ? css`
          visibility: hidden;
        `
      : ''}

  &:hover {
    color: ${COLORS.COMPANY};
    background-color: unset;
  }

  & .icon {
    color: ${COLORS.COMPANY};
    margin-right: 4px;
    fill: currentColor;
  }
`;

export const StyledNavigationButton = styled(BackButton)`
  position: absolute;
  z-index: 9999999;
  font-size: 14px;
  color: ${COLORS.MIDDLE_GRAY};

  transition: opacity 0.5s, visibility 0.5s;
  ${(props) =>
    props.isHidden
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

export const PreviousButton = styled(StyledNavigationButton)`
  top: 17.5px;
  left: 32px;
`;

export const NextButton = styled(StyledNavigationButton)`
  bottom: 17.5px;
  left: 32px;
`;

export const Title = styled.span<{ bold?: boolean }>`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  max-height: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  gap: 7px;
    ${(props) =>
      props.bold &&
      css`
        font-weight: bold;
      `}}
`;

export const SectionNumber = styled.span`
  min-width: 25px;
  max-width: 25px;
`;

export const AnimatedTitle = styled.div<{ shouldAnimate?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 12px;

  gap: 13px;

  ${(props) =>
    props.shouldAnimate
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
        `}

  transition: opacity 0.5s;
`;

export const Animated = styled.div`
  position: relative;
  display: flex;
  gap: 13px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-left: 12px;
`;

export const Wrapper = styled.div<{ parentHeight?: number }>`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  scroll-margin-top: ${(props) =>
    props.parentHeight ? `${props.parentHeight / 2 - 25}px;` : 'unset;'}}
`;

export const Circle = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: inherit;
  z-index: 20;

  min-width: 12px;
  min-height: 12px;
  width: 12px;
  height: 12px;
`;

export const ActiveCircle = styled(Circle)`
  border: 1px solid ${COLORS.COMPANY};
`;

export const NoCircle = styled(Circle)`
  border: 1px solid transparent;
`;

export const Dot = styled.span`
  width: 6px;
  height: 6px;
  background-color: ${COLORS.COMPANY};
  border-radius: 100px;
`;

export const InactiveDot = styled(Dot)`
  background-color: ${COLORS.BORDERS};
`;

export const ErrorDot = styled(Dot)`
  background-color: ${COLORS.ACCENT_ERROR};
`;

export const CompletedCircle = styled(Circle)`
  border: 1px solid ${COLORS.COMPANY};
  background-color: ${COLORS.COMPANY};
  color: white;
`;

export const NotCompletedPastCircle = styled(Circle)`
  border: 1px solid ${COLORS.COMPANY};
`;

export const NotCompletedFutureCircle = styled(Circle)`
  border: 1px solid ${COLORS.BORDERS};
`;

export const ErrorCircle = styled(Circle)`
  border: 1px solid ${COLORS.ACCENT_ERROR};
  background-color: ${COLORS.ACCENT_ERROR};
  color: ${COLORS.WHITE};
`;

export const Minus = styled.span`
  width: 6px;
  height: 1px;
  background-color: ${COLORS.WHITE};
`;

export const LockedCircle = styled(Circle)`
  color: ${COLORS.PLACEHOLDERS};
  margin-left: 1px;
`;

export const Card = styled.div<{
  status: ISection['status'];
  titleColor: string;
  isCollapsed?: boolean;
  noHover?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 0 21px 0 10px;
  border-radius: 6px;

  cursor: default;
  ${(props) =>
    !props.noHover &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${COLORS.WHITE};

        ${NoCircle} {
          border: 1px solid ${COLORS.BORDERS};
        }
        ${InactiveDot} {
          background-color: ${COLORS.COMPANY};
        }
      }
    `}
  min-width: 291px;
  max-width: 291px;
  flex-grow: 0;
  max-height: 50px;
  min-height: 50px;

  background-color: ${COLORS.BG_PAGE};
  color: ${(props) => props.titleColor};

  ${({ status, isCollapsed, noHover }) =>
    status &&
    !noHover &&
    status === SECTION_STATUS.CURRENT &&
    css`
      ${() =>
        !isCollapsed &&
        css`
          background-color: ${COLORS.WHITE};
          color: ${COLORS.TEXT_HOVER};
          box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
        `}

      box-shadow: 0 0 0 0 rgba(145, 157, 165, 0.12);
      transition: background-color 0.5s, box-shadow 0.5s;
    `}
`;
