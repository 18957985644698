import React, { useState } from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import { IAutoCompleteJobProfile } from '~/pages/JobProfilesOverview/JobForm/types';

import { CreateFromLuca } from './Templates/CreateFromLuca';

import { useLanguageState } from '~/hooks/useLanguageState';
import { autoCompleteJobProfile } from '~/services/jobProfiles';

type CreateJobModalProps = {
  onClose?(): void;
  jobName?: string;
  onClickManually?(): void;
  onClickUsingAI?(data: IAutoCompleteJobProfile): void;
};

const CreateJobProfileFromLucaModal = ({
  onClose,
  jobName,
  onClickManually,
  onClickUsingAI,
}: CreateJobModalProps) => {
  const { i18n } = useLingui();

  const [loading, setLoading] = useState(false);
  const languageState = useLanguageState();

  const handleAICreation = async () => {
    setLoading(true);

    const { data }: { data: IAutoCompleteJobProfile } = await autoCompleteJobProfile({
      name: jobName,
      locale: languageState.companyLanguages.map((item) => item.locale),
    });
    onClickUsingAI?.(data);
    setLoading(false);
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      width={500}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <CreateFromLuca
        title={i18n._(t`Create a job`)}
        subtitle={jobName}
        description={i18n._(t`Auto generate your Job with Luca AI?`)}
        loadingMessage={i18n._(t`Generating your job, this might take a minute…`)}
        onClose={onClose}
        loading={loading}
        onClickManually={onClickManually}
        onClickUsingAI={handleAICreation}
      />
    </Modal>
  );
};

export { CreateJobProfileFromLucaModal };
