import React, { ReactNode } from 'react';

import isEmpty from 'lodash/isEmpty';

import { AnswerView, Container, Description, HeaderWrapper, ThemeTitle, Title } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import type { IReviewQuestion } from '@learned/types';

interface IQuestionWrapperProps {
  children?: ReactNode;
  questionNumber: number;
  themeName: string;
  question: IReviewQuestion;
}

export const QuestionWrapper = ({
  children,
  questionNumber,
  themeName,
  question,
}: IQuestionWrapperProps) => {
  const getMultiLangString = useMultiLangString();

  return (
    <Container>
      <HeaderWrapper>
        <ThemeTitle>{`Question: ${questionNumber} | ${getMultiLangString(themeName)}`}</ThemeTitle>
        <Title>{getMultiLangString(question.name)}</Title>
        {!isEmpty(question.description) && (
          <Description>{getMultiLangString(question.description || '')}</Description>
        )}
      </HeaderWrapper>
      <AnswerView>{children}</AnswerView>
    </Container>
  );
};
