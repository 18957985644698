import { createAction } from 'redux-act';

import { IAutoCompleteJobProfile } from '~/pages/JobProfilesOverview/JobForm/types';

import * as jobProfilesService from '~/services/jobProfiles';

export const setJobProfile = createAction('set job profile');
export const setJobProfiles = createAction<object>('finish set job profiles');
export const updateSkillsToJobProfile = createAction('set jobprofiles');

export const startUpdateJobProfiles = createAction('start update jobProfiles');
export const finishUpdateJobProfiles = createAction<object>('finish update jobProfiles');

export const setGeneratedJobProfile = createAction<IAutoCompleteJobProfile | undefined>(
  'SET_GENERATED_JOB_PROFILE',
);

// Getters
export const getGeneratedJobProfile = (state: any) =>
  state.jobProfiles.generatedJobProfile as IAutoCompleteJobProfile;

import type { Dispatch } from 'redux';

/*
 * data example [[JobProfile1Id, updateData1{}], [JobProfile2Id, updateData2{}], ...]
 * */
export function updateJobProfiles(data: Array<[string, object]>, callback: () => void) {
  return async function (dispatch: Dispatch) {
    dispatch(startUpdateJobProfiles());
    const jobProfiles = await jobProfilesService.updateJobProfiles(data);
    dispatch(finishUpdateJobProfiles(jobProfiles));
    callback();
    return jobProfiles;
  };
}
