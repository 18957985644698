import React, { useEffect, useState } from 'react';

import {
  API_RETURN_FIELDS,
  CONFIRMATION_MODAL_TYPE,
  ROLES,
  SKILL_TEMPLATES_PENDING_SORT_OPTIONS,
} from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import every from 'lodash/every';

import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { PENDING_SKILLS_COLUMNS } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix/SuperAdminPendingSkills.columns';

import { ConnectPendingSkillModal } from './components/ConnectPendingSkillModal';
import { Wrapper } from './SuperAdminPendingSkills.design';

import { SUPERADMIN_SKILL_TEMPLATE_CREATE } from '~/constants/routes/superadmin';
import useDebounce from '~/hooks/useDebounce';
import { useMultiSelectState } from '~/hooks/useMultiSelectState';
import { usePagination } from '~/hooks/usePagination';
import {
  deleteSkillTemplatesPendingSuperAdmin,
  listSkillTemplatesPendingSuperAdmin,
} from '~/services/skillTemplatesPending';

import type { ISkillTemplatePending } from '@learned/types';

function SuperAdminPendingSkills({
  reFetchTotalPending: reFetchTotalPending,
}: {
  reFetchTotalPending: () => void;
}) {
  const { i18n } = useLingui();
  const { pagination, changePagination } = usePagination(20);
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search, 300);
  const [total, setTotal] = useState<number>();
  const [sortBy, setSortBy] = useState<SKILL_TEMPLATES_PENDING_SORT_OPTIONS>(
    SKILL_TEMPLATES_PENDING_SORT_OPTIONS.NAME_A_Z,
  );
  const [skillTemplates, setSkillTemplates] = useState<ISkillTemplatePending[]>([]);
  const {
    selectedItems: selectedSkillTemplates,
    onSelectItem,
    isItemChecked,
    onCheckAll,
    resetSelectedItems,
  } = useMultiSelectState(skillTemplates);
  const [pendingSkillToConnect, setPendingSkillToConnect] = useState<ISkillTemplatePending>();

  useEffect(() => {
    let isMounted = true;

    async function fetch() {
      const result = await listSkillTemplatesPendingSuperAdmin(
        { search: debouncedSearch },
        {
          skip: pagination.skip,
          limit: pagination.limit,
          sortBy,
        },
      );
      if (isMounted && result?.code === 200) {
        setSkillTemplates(result?.data?.[API_RETURN_FIELDS.SKILL_TEMPLATES_PENDING]);
        setTotal(result?.data?.[API_RETURN_FIELDS.TOTAL]);
      }
    }

    fetch();

    return () => void (isMounted = false);
  }, [pagination.limit, pagination.skip, debouncedSearch, sortBy]);

  const refetchSkillTemplates = async () => {
    const result = await listSkillTemplatesPendingSuperAdmin(
      { search: debouncedSearch },
      {
        skip: pagination.skip,
        limit: pagination.limit,
        sortBy,
      },
    );
    if (result?.code === 200) {
      setSkillTemplates(result?.data?.[API_RETURN_FIELDS.SKILL_TEMPLATES_PENDING]);
      setTotal(result?.data?.[API_RETURN_FIELDS.TOTAL]);
    }
  };

  const deleteItem = async (item: ISkillTemplatePending) => {
    const alertString = i18n._(t`Are you sure you want to delete this pending skill template?`);
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        description: alertString,
      })
    ) {
      const response = await deleteSkillTemplatesPendingSuperAdmin([item.id]);
      if (response?.code === 200) {
        await refetchSkillTemplates();
        reFetchTotalPending();
      }
    }
  };

  const deleteSelectedItems = async () => {
    const alertString = i18n._(
      t`Are you sure you want to delete ${selectedSkillTemplates.length} pending skill templates?`,
    );
    if (
      await confirm({
        type: CONFIRMATION_MODAL_TYPE.DELETE,
        description: alertString,
      })
    ) {
      const response = await deleteSkillTemplatesPendingSuperAdmin(selectedSkillTemplates);
      if (response?.code === 200) {
        resetSelectedItems();
        await refetchSkillTemplates();
        await reFetchTotalPending();
      }
    }
  };

  return (
    <Wrapper>
      <TableList
        data={skillTemplates}
        columns={PENDING_SKILLS_COLUMNS}
        filtersProps={{ isFiltered: true, filters: { search, setSearch } }}
        paginationProps={{ pagination, changePagination, totalCount: total }}
        sortProps={{ sortBy, setSortBy }}
        menuProps={{
          isMenuVisible: true,
          createMenuItems: (item) => [
            {
              label: <Trans>Create</Trans>,
              action: () =>
                SUPERADMIN_SKILL_TEMPLATE_CREATE.go(
                  { role: 'super-admin' as ROLES },
                  { query: { createFromSkillPendingId: item?.id }, isBackPath: true },
                ),
              icon: ICONS.ADD_PLUS,
            },
            {
              label: <Trans>Connect to skill as alias</Trans>,
              action: () => setPendingSkillToConnect(item),
              icon: ICONS.ADD_PLUS,
            },
            {
              icon: ICONS.DELETE_BIN,
              action: () => deleteItem(item),
              isWarning: true,
              label: <Trans>Delete</Trans>,
            },
          ],
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect: {
            checkedCount: selectedSkillTemplates.length,
            isAllChecked: every(
              skillTemplates.map((skillTemplate) =>
                selectedSkillTemplates.includes(skillTemplate.id),
              ),
            ),
            onSelectItem,
            isItemChecked,
            onCheckAll,
            onDelete: deleteSelectedItems,
          },
        }}
      />
      {pendingSkillToConnect && (
        <ConnectPendingSkillModal
          pendingSkillTemplate={pendingSkillToConnect}
          refetchSkillTemplates={refetchSkillTemplates}
          onClose={() => setPendingSkillToConnect(undefined)}
        />
      )}
    </Wrapper>
  );
}

export { SuperAdminPendingSkills };
