import { ROLES } from '@learned/constants';

import { pageUpdate, pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'survey-templates';
const defaultSurveyTemplateId = ':surveyTemplateId';

export const SURVEY_TEMPLATE_UPDATE = new LinkConstructor(
  [ROLES.USER],
  ({ surveyTemplateId = defaultSurveyTemplateId }) =>
    pageUpdate(routeName, { id: surveyTemplateId }),
);

export const SURVEY_TEMPLATE_VIEW = new LinkConstructor(
  [ROLES.USER],
  ({ surveyTemplateId = defaultSurveyTemplateId }) =>
    pageDashboard(routeName, { id: surveyTemplateId }),
);
