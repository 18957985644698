import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import FocusLayout from '~/layouts/FocusLayout';
import { SurveysDashboard } from '~/pages/SurveyDashboard';
import { Surveys } from '~/pages/Surveys';
import { SurveyUpdate } from '~/pages/SurveyUpdate';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('surveys', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.SURVEYS_OVERVIEW.routePath(ROLES.USER)}
        exact
        path={routes.SURVEYS_OVERVIEW.routePath(ROLES.USER)}
        component={Surveys}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.SURVEY_UPDATE.routePath(ROLES.USER)}
        exact
        path={routes.SURVEY_UPDATE.routePath(ROLES.USER)}
        component={SurveyUpdate}
        allowedRoles={[ROLES.USER]}
      />,
      <PrivateRoute
        layout={FocusLayout}
        key={routes.SURVEY_DASHBOARD.routePath(ROLES.USER)}
        exact
        path={routes.SURVEY_DASHBOARD.routePath(ROLES.USER)}
        component={SurveysDashboard}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
