import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import difference from 'lodash/difference';
import isEmpty from 'lodash/isEmpty';

import { List } from '../../components/List';
import { ListRowPlaceholder } from '../../components/ListRowPlaceholder';
import { ListRowSignature } from '../../components/ListRowSignature';
import { SidebarSection } from '../../design';

import type { IReviewDashboardUserForm } from '../../../types';
import type { IUser } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ISignaturesSectionProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  isAllowToSign: (userId: IUser['id']) => boolean;
  onSign: () => void;
}

const SignaturesSection = ({ formMethods, onSign, isAllowToSign }: ISignaturesSectionProps) => {
  const { i18n } = useLingui();
  const { watch } = formMethods;
  const employee = watch('employee');
  const guests = watch('guests');
  const signatures = watch('signatures');
  const status = watch('status');

  const usersToSign = [employee.id, ...guests].filter((i) => i);
  const isAllGuestsSigned =
    !isEmpty(guests) &&
    isEmpty(
      difference(
        guests,
        signatures.map((s) => s.userId),
      ),
    );

  return (
    <SidebarSection>
      <List>
        {isEmpty(usersToSign) ? (
          <ListRowPlaceholder text={i18n._(t`No guests selected.`)} />
        ) : (
          usersToSign.map((userId, key) => {
            const signature = signatures.find((item) => item.userId === userId);

            return (
              <ListRowSignature
                key={key}
                userId={userId}
                isGuest={guests.includes(userId)}
                isAllGuestsSigned={isAllGuestsSigned}
                signature={signature}
                reviewStatus={status}
                isShowButton={isAllowToSign(userId)}
                onClick={onSign}
              />
            );
          })
        )}
      </List>
    </SidebarSection>
  );
};

export { SignaturesSection };
