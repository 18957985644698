import React, { useEffect, useState } from 'react';

import { API_RETURN_FIELDS, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useHistory } from 'react-router';

import routes from '~/constants/routes';
import { fetchNextSteps } from '~/services/nextSteps';

import { SIDEBAR_ITEM_TYPES } from '../../constants';
import { SidebarRow } from '../SidebarRow';

const NextStepsCard = () => {
  const history = useHistory();
  const { i18n } = useLingui();
  const [activeSteps, setActiveSteps] = useState<number>(0);
  const [nextSteps, setNextSteps] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await fetchNextSteps({
        onlyActiveStepsCount: false,
        filters: {},
        options: {},
      });
      setIsLoading(false);
      setActiveSteps(data[API_RETURN_FIELDS.TOTAL_ACTIVE_NEXT_STEPS] || 0);
      setNextSteps(data[API_RETURN_FIELDS.TOTAL_NEXT_STEPS] || 0);
    };
    fetchData();
  }, []);

  const onCardClick = () => {
    if (activeSteps) {
      history.push(routes.NEXT_STEPS.build({ role: ROLES.USER }));
    }
  };

  return (
    <>
      <SidebarRow
        type={
          !nextSteps ? SIDEBAR_ITEM_TYPES.NEXT_STEPS_EMPTY_STATE : SIDEBAR_ITEM_TYPES.NEXT_STEPS
        }
        onClick={onCardClick}
        isShowPlaceholder={!nextSteps || !activeSteps}
        isLoading={isLoading}
        name={i18n._(t`${activeSteps} step(s) to complete`)}
      />
    </>
  );
};

export { NextStepsCard };
