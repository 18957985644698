import { ROLES } from '@learned/constants';

import { pageDashboard } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'survey-tasks';
const defaultSurveyTaskId = ':surveyTaskId';

export const SURVEY_TASK = new LinkConstructor(
  [ROLES.USER],
  ({ surveyTaskId = defaultSurveyTaskId }) => pageDashboard(routeName, { id: surveyTaskId }),
);
