import { useEffect, useState } from 'react';

import { REVIEW_QUESTION_TYPES, REVIEW_RATING_TYPE, TASK_TYPE } from '@learned/constants';

import { RATING_USER_TYPE } from '../types';

import type { IReviewQuestion, IReviewRating, ITask, IUserReview } from '@learned/types';

type UserReview = Omit<IUserReview, 'userReviewQuestions'> & {
  tasks: ITask[];
  userReviewQuestions: IReviewQuestion[];
};

export const useReviewRatings = ({ userReview }: { userReview?: UserReview }) => {
  const [reviewRatings, setReviewRatings] = useState<any[]>([]);

  const getReviewRatingType = (reviewTask: ITask) => {
    switch (reviewTask.type) {
      case TASK_TYPE.REVIEW_COACH_EVALUATE:
        return { reviewType: RATING_USER_TYPE.COACH, type: REVIEW_RATING_TYPE.COACH };
      case TASK_TYPE.REVIEW_PEER_EVALUATE:
        return reviewTask?.userTo?.email
          ? { reviewType: RATING_USER_TYPE.EXTERNAL_PEER, type: REVIEW_RATING_TYPE.PEER }
          : { reviewType: RATING_USER_TYPE.PEER, type: REVIEW_RATING_TYPE.PEER };
    }
  };

  const getEmptyTextRatingQuestion = (
    question: IReviewQuestion & { reviewRatings?: IReviewRating[] },
    reviewTask: ITask,
  ) => {
    return {
      // we have to use -1 and '' to start with because null means user refused to answer
      answer: question.type === REVIEW_QUESTION_TYPES.RATING ? -1 : '',
      comment: null,
      createdBy: reviewTask.userTo,
      company: question.company,
      createdFor: reviewTask.userFrom,
      userReview: reviewTask.target,
      userReviewQuestion: question.id,
    };
  };

  const mapReviewRating = (
    question: IReviewQuestion & { reviewRatings?: IReviewRating[] },
    task: ITask,
  ) => {
    const rating = question.reviewRatings?.find((rating) => rating.task === task.id);
    const reviewRating = rating || getEmptyTextRatingQuestion(question, task);
    return {
      ...reviewRating,
      ...getReviewRatingType(task),
    };
  };

  const getTextReviewRatings = (
    question: IReviewQuestion & { reviewRatings?: IReviewRating[] },
    userReview: UserReview,
  ) => {
    const selfReviewTask = userReview.tasks.find(
      (task) => task.type === TASK_TYPE.REVIEW_SELF_EVALUATE,
    );
    let employeeReviews;
    if (selfReviewTask) {
      const reviewRating =
        question.reviewRatings?.find((rating) => rating.task === selfReviewTask?.id) ||
        getEmptyTextRatingQuestion(question, selfReviewTask);
      employeeReviews = { ...reviewRating, ...getReviewRatingType(selfReviewTask) };
    }

    const coachReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_COACH_EVALUATE,
    );
    const coachReviews = coachReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);

    const internalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.id,
    );
    const externalPeerReviewTasks = userReview.tasks.filter(
      (task) => task.type === TASK_TYPE.REVIEW_PEER_EVALUATE && task.userTo?.email,
    );
    const internalPeerReviews = internalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    const externalPeerReviews = externalPeerReviewTasks
      .map((task) => mapReviewRating(question, task))
      .filter(Boolean);
    return [
      employeeReviews,
      ...coachReviews,
      ...internalPeerReviews,
      ...externalPeerReviews,
    ].filter(Boolean);
  };

  const getAllReviewRatings = (userReview: UserReview) => {
    const ratings = userReview.userReviewQuestions.map((question) => {
      switch (question.type) {
        case REVIEW_QUESTION_TYPES.TEXT:
        case REVIEW_QUESTION_TYPES.RATING:
          return { ...question, reviewRatings: getTextReviewRatings(question, userReview) };
        case REVIEW_QUESTION_TYPES.GOAL_PLAN:
          return question;
      }
    });
    return ratings.flat();
  };

  useEffect(() => {
    if (userReview) {
      const reviewRatings = getAllReviewRatings(userReview);
      setReviewRatings(reviewRatings.filter(Boolean));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userReview]);

  return { reviewRatings };
};
