import React from 'react';

import { ICalendarEvent } from '@learned/types';

import { CalendarBlock } from './blocks/Calendar';
import { ConversationDateTimeBlock } from './blocks/ConversationDateTime';
import { GuestsBlock } from './blocks/Guests';
import { InvitationMessageBlock } from './blocks/InvitationMessage';
import { PrivacyBlock } from './blocks/Privacy';
import { TimeframeReviewBlock } from './blocks/TimeframeReview';
import { Separator } from './design';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { SidebarSection } from '../../design';

import type { IReviewDashboardUserForm } from '../../../types';
import type { UseFormReturn } from 'react-hook-form';

interface IDetailsSectionProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  onDeleteGuest: (guestId: string) => void;
  onAddGuests: () => void;
  onPlanConversation: () => void;
  isAllowToDeleteGuests: boolean;
  isAllowToAddGuests: boolean;
  isAllowToPlanConversation: boolean;
  isAllowedToAccessMeetingLink: boolean;
}

const DetailsSection = ({
  formMethods,
  onAddGuests,
  onDeleteGuest,
  isAllowToDeleteGuests,
  isAllowToAddGuests,
  isAllowToPlanConversation,
  isAllowedToAccessMeetingLink,
  onPlanConversation,
}: IDetailsSectionProps) => {
  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;
  const privacy = watch('privacy');
  const settings = watch('settings');
  const descriptionMultiLang = turnArrayIntoMultiLang(watch('description') || []);
  const guests = watch('guests');
  const coaches = watch('coaches');
  const dateOfConversation = watch('dateOfConversation');
  const dateOfConversationEnd = watch('dateOfConversationEnd');
  const calendarEvent = {} as ICalendarEvent; // TODO replace this real calendarEvent

  return (
    <SidebarSection>
      <ConversationDateTimeBlock
        dateOfConversation={dateOfConversation}
        dateOfConversationEnd={dateOfConversationEnd}
        guests={guests}
        isAllowToPlanConversation={isAllowToPlanConversation}
        onPlanConversation={onPlanConversation}
      />
      <GuestsBlock
        guests={guests}
        coaches={coaches}
        onAddGuests={onAddGuests}
        onDeleteGuest={onDeleteGuest}
        isAllowToAddGuests={isAllowToAddGuests}
        isAllowToDeleteGuests={isAllowToDeleteGuests}
      />
      <CalendarBlock
        isAllowedToAccessMeetingLink={isAllowedToAccessMeetingLink}
        calendarEvent={calendarEvent}
      />
      <Separator />
      <PrivacyBlock privacy={privacy} />
      <InvitationMessageBlock message={getMultiLangString(descriptionMultiLang)} />
      <TimeframeReviewBlock startDate={settings.startDate} endDate={settings?.endDate as Date} />
    </SidebarSection>
  );
};

export { DetailsSection };
