import React from 'react';

import { Trans } from '@lingui/macro';

import { Icon, ICONS } from '~/components/Icon';

import { Header, Title, Wrapper } from './design';

import { COLORS } from '~/styles';

const Scores = () => {
  return (
    <Wrapper>
      <Header>
        <Title>
          <Trans>Overall ratings:</Trans>
        </Title>
      </Header>
      <div className="collapse">
        <Icon icon={ICONS.NEXT} color={COLORS.PLACEHOLDERS} />
        <Title>
          <Trans>Scores per theme</Trans>
        </Title>
      </div>
    </Wrapper>
  );
};

export { Scores };
