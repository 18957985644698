import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  background: ${COLORS.BG_PAGE};
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 340px;
  display: flex;
  flex-direction: column;

  & img {
    width: 169.4px;
    height: 157.8px;
    margin-left: 19.6px;
    margin-bottom: 24.6px;
  }
`;

export const Title = styled.div`
  font-size: 40px;
  font-weight: bold;
  color: ${COLORS.TEXT_HOVER};
  margin-bottom: 10px;
`;

export const Description = styled.div`
  color: ${COLORS.SUBTEXT};
  font-size: 16px;
  margin-bottom: 24px;
`;
