import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ $backgroundImage: string; $isRowView?: boolean }>`
  width: 100%;
  min-height: 58px;
  box-sizing: border-box;
  background-image: ${(props) => props.$backgroundImage};
  padding: 15px 32px 15px 16px;
  border-radius: 6px;
  ${(props) =>
    props.$isRowView &&
    css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
`;

export const LeftSection = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSection = styled.div<{ $isRowView?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    !props.$isRowView &&
    css`
      margin: 10px 0 0 40px;
    `}
`;

export const IconContainer = styled.div<{ $backgroundColor: string; isBannerHovered?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  min-width: 26px;
  min-height: 26px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  margin-right: 16px;

  ${(props) =>
    css`
      background-color: ${props.$backgroundColor};
    `} {
  }
  ${(props) =>
    props.isBannerHovered &&
    css`
      cursor: pointer;
    `}
`;

export const Title = styled.div<{ $color: string | null }>`
  font-size: 14px;
  color: ${(props) => props.$color || COLORS.TEXT_HOVER};
`;

export const SubTitle = styled.div<{ $color: string | null }>`
  font-size: 12px;
  color: ${(props) => props.$color || COLORS.SUBTEXT};
`;
