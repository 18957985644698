import { ROLES } from '@learned/constants';

import { pageOverview } from '~/constants/routes/utils/helpers';
import LinkConstructor from '~/constants/routes/utils/LinkConstructor';

const routeName = 'notifications';

// overview
export const NOTIFICATIONS = new LinkConstructor([ROLES.USER, ROLES.ADMIN], () =>
  pageOverview(routeName),
);
