import React, { useEffect, useState } from 'react';

import { ISkillCategory } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import Modal from '~/components/Modal';
import { ISkillGenerated } from '~/pages/SkillSetsOverview/types';

import { CreateFromLuca } from './Templates/CreateFromLuca';

import { SUPER_ADMIN_LANGUAGES } from '~/constants/languages';
import { useGenericLanguageState } from '~/hooks/useLanguageState';
import { LS_KEYS } from '~/hooks/useLocalStorage';
import { listSkillCategoriesSuperAdmin } from '~/services/skillCategories';
import { generateSkillTemplate } from '~/services/skillTemplates';
import { getTemplateLocales, LOCALES_ARRAY } from '~/utils/companyLanguages';

type CreateSkillTemplateFromLucaModalProps = {
  onClose?(): void;
  skillName: string;
  onClickManually?(selectedCategoryId: string): void;
  onClickUsingAI?(data: ISkillGenerated, selectedCategory: ISkillCategory): void;
  defaultCategoryId?: ISkillCategory['id'];
};

const CreateSkillTemplateFromLucaModal = ({
  onClose,
  skillName,
  onClickManually,
  onClickUsingAI,
  defaultCategoryId,
}: CreateSkillTemplateFromLucaModalProps) => {
  const { i18n } = useLingui();

  const [loading, setLoading] = useState(false);
  const languageState = useGenericLanguageState({
    defaultLanguages: SUPER_ADMIN_LANGUAGES,
    primaryLanguage: SUPER_ADMIN_LANGUAGES[0],
    localStorageKey: LS_KEYS.LS_SUPER_ADMIN_LANGUAGES,
  });

  const [categories, setCategories] = useState<ISkillCategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<ISkillCategory>();

  useEffect(() => {
    const fetch = async () => {
      const response = await listSkillCategoriesSuperAdmin();
      setCategories(response.data.skillCategories);
    };
    fetch();
  }, []);

  useEffect(() => {
    if (categories && defaultCategoryId) {
      const category = categories.find((s) => s.id === defaultCategoryId);
      setSelectedCategory(category);
      return;
    }
    if (categories && categories.length > 0) {
      setSelectedCategory(categories[0]);
    }
  }, [defaultCategoryId, categories]);

  const handleAICreation = async () => {
    setLoading(true);

    if (selectedCategory) {
      const { data }: { data: { skillTemplate: ISkillGenerated } } = await generateSkillTemplate(
        skillName,
        getTemplateLocales(languageState, LOCALES_ARRAY.PRIMARY_FIRST),
        selectedCategory.id,
      );
      onClickUsingAI?.(data.skillTemplate, selectedCategory);
    }
    setLoading(false);
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      width={500}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <CreateFromLuca
        title={i18n._(t`Create a skill`)}
        subtitle={skillName}
        description={i18n._(t`Auto generate your skill with Luca AI?`)}
        loadingMessage={i18n._(t`Generating your skill, this might take a minute…`)}
        onClose={onClose}
        loading={loading}
        onClickManually={() => selectedCategory && onClickManually?.(selectedCategory.id)}
        onClickUsingAI={handleAICreation}
        categories={categories}
        selectedCategory={selectedCategory}
        onSelectCategory={(item) => setSelectedCategory(item)}
      />
    </Modal>
  );
};

export { CreateSkillTemplateFromLucaModal };
