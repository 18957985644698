export enum API_RETURN_FIELDS {
  /* GENERAL */
  TOTAL = 'total',
  TABS_ITEMS_TOTAL = 'tabsItemsTotal',
  SKIP = 'skip',
  LIMIT = 'limit',

  /* COLLECTIONS */
  ACTIVITIES = 'activities',
  USER_LIBRARY_ITEMS = 'userLibraryItems',
  USER_ACTIVITIES = 'userActivities',
  USER_ACTIVITY = 'userActivity',
  ACTIVITY = 'activity',
  ACTIVITY_CATEGORIES = 'activityCategories',
  ACTIVITY_CATEGORY = 'activityCategory',
  COMPANY_DELETE_REQUESTS = 'companyDeleteRequests',
  COMPANY_DELETE_REQUEST = 'companyDeleteRequest',
  COMPANY = 'company',
  COMPANY_SETTINGS = 'companySettings',
  DESCRIPTION = 'description',
  USER_COMPANIES = 'userCompanies',
  FILTERED_ACTIVITIES = 'filteredActivities',
  FOCUS_AREAS = 'focusAreas',
  RATINGS = 'ratings',
  ROLE_RELEVANCIES = 'roleRelevancies',
  ROLE_RELEVANCY = 'roleRelevancy',
  USER = 'user',
  USERS = 'users',
  CONVERSATIONS = 'conversations',
  KPI = 'kpi',
  KPIS = 'kpis',
  CV_SETTINGS = 'cvSettings',
  JOB_FAMILY = 'jobFamily',
  JOB_PROFILE = 'jobProfile',
  JOB_FAMILIES = 'jobFamilies',
  JOB_TEMPLATE = 'jobTemplate',
  JOB_TEMPLATES = 'jobTemplates',
  JOB_TEMPLATES_PENDING = 'jobTemplatesPending',
  JOB_TEMPLATE_PENDING = 'jobTemplatePending',
  JOB_LEVEL_GROUPS = 'jobLevelGroups',
  PATH = 'path',
  PATHS = 'paths',
  PRODUCTS = 'products',
  USER_PATH = 'userPath',
  USER_PATHS = 'userPaths',
  ACTIVITIES_COMPLETED = 'activitiesCompleted',
  SKILL_TEMPLATES_PENDING = 'skillTemplatesPending',
  SKILL_TEMPLATE = 'skillTemplate',
  SKILL_TEMPLATES = 'skillTemplates',
  SKILL_CATEGORIES = 'skillCategories',
  SKILL = 'skill',
  SKILLS = 'skills',
  SURVEY = 'survey',
  SURVEYS = 'surveys',
  SURVEY_TASK = 'surveyTask',
  SURVEY_TASKS = 'surveyTasks',
  SURVEY_EVENTS = 'surveyEvents',
  SURVEY_THEMES = 'surveyThemes',
  SURVEY_THEME = 'surveyTheme',
  SURVEY_TEMPLATES = 'surveyTemplates',
  SURVEY_MEMBERS = 'surveyMembers',
  SURVEY_PARTICIPATION = 'surveyParticipation',
  SURVEY_TEMPLATE = 'surveyTemplate',
  SURVEY_QUESTIONS = 'surveyQuestions',
  SURVEY_QUESTION = 'surveyQuestion',
  SURVEY_RATINGS = 'surveyRatings',
  USER_SURVEYS = 'userSurveys',
  USER_SURVEY = 'userSurvey',
  USER_CONNECTION = 'userConnection',
  SURVEY_RESULTS_QUESTIONS = 'surveyResultsQuestions',
  SURVEY_RESULTS_THEMES = 'surveyResultsThemes',
  SURVEY_RESULTS_AVERAGE = 'surveyResultsAverage',
  SURVEY_RESULTS_TEAMS = 'surveyResultsTeams',
  REPORT = 'report',
  CURRENT_CONVO_STEPS = 'currentConvSteps',
  PREVIOUS_INCOMPLETED_STEPS = 'previousUncompletedSteps',
  NEXT_STEPS = 'nextSteps',
  TOTAL_ACTIVE_NEXT_STEPS = 'totalActiveNextSteps',
  TOTAL_NEXT_STEPS = 'totalNextSteps',
  TOTAL_FILTERED_TASKS = 'totalTasks',
  INDUSTRIES = 'industries',
  INDUSTRY = 'industry',
  PEXELS = 'pexels',
  TRANSLATED_TEXTS = 'translatedTexts',
  CAREER_PLANS = 'careerPlans',
  LIKES = 'likes',

  // REVIEWS 3.0
  TASK = 'task',
  REVIEW_THEMES = 'reviewThemes',
  REVIEW_THEME = 'reviewTheme',
  REVIEW_SUMMARY = 'reviewSummary',
  REVIEW_SUMMARIES = 'reviewSummaries',
  REVIEW_TEMPLATES = 'reviewTemplates',
  REVIEW_TEMPLATE = 'reviewTemplate',
  REVIEW_RATINGS = 'reviewRatings',
  REVIEW_QUESTION = 'reviewQuestion',
  REVIEW = 'review',
  REVIEWS = 'reviews',
  USER_REVIEWS = 'userReviews',
  USER_REVIEW = 'userReview',
  REVIEW_INVITATION_TEMPLATE = 'reviewInvitationTemplate',
  REVIEW_INVITATION_TEMPLATES = 'reviewInvitationTemplates',
  TASKS = 'tasks',
  LOGS = 'logs',
  // add more
}
