import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Text = styled.span`
  color: ${COLORS.TEXT_HOVER};
  font-size: 24px;
  text-align: center;
  margin-bottom: 4px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: row;
  border-top: solid 1px rgba(151, 151, 151, 0.1);
  padding-top: 24px;
`;

export const Card = styled.div`
  margin-top: 116px;
  display: flex;
  flex-direction: column;
  width: 500px;
  box-sizing: border-box;
  padding: 25px 32px 24px 31px;
  border-radius: 10px;
  background-color: ${COLORS.WHITE};
  align-items: start;

  & img {
    margin-top: 27px;
    margin-bottom: 27px;
    align-self: center;
  }
`;
