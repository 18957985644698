import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ isDragging?: boolean }>`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;

  .draggable {
    position: relative;
    background-color: ${COLORS.BG_PAGE};
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0 0 10px 3px rgba(70, 71, 78, 0.25);
    border: 1px solid ${COLORS.BORDERS};

    .resize {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .dragHandle {
      rotate: 90deg;
      cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'grab')};
    }
  }
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${COLORS.TEXT_HOVER};
`;

export const Description = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_MAIN};
  margin-bottom: 14px;
`;

export const Header = styled.div`
  .top {
    display: flex;
    justify-content: space-between;
  }
`;

export const Buttons = styled.div`
  display: flex;
  button {
    color: ${COLORS.TOOLTIP_BACKGROUND};
    transform: scale(0.85);
  }
`;

export const Main = styled.div<{ marginTop?: string }>`
  position: absolute;
  top: 64px;
  margin-top: ${({ marginTop }) => marginTop || '14px'};
  bottom: 16px;
  left: 16px;
  right: 16px;
`;
