import React from 'react';

import { useForm } from 'react-hook-form';

import { PlanCalendarEventForm } from './PlanCalendarEventForm';

import type { IPlanCalendarEventForm } from './PlanCalendarEventForm/types';
import type { IUserReview } from '@learned/types';

interface IPlanCalendarEventModalProps {
  userReviewId: IUserReview['id'];
  onClose: (isSubmit?: boolean) => void;
}

const PlanCalendarEventModal = ({ onClose, userReviewId }: IPlanCalendarEventModalProps) => {
  const formMethods = useForm<IPlanCalendarEventForm>({
    mode: 'all',
    defaultValues: {
      selectedDate: null,
      selectedStartTime: null,
      selectedEndTime: null,
      dateOfConversation: null,
      dateOfConversationEnd: null,
    },
  });

  return (
    <PlanCalendarEventForm
      userReviewId={userReviewId}
      formMethods={formMethods}
      onClose={onClose}
    />
  );
};

export { PlanCalendarEventModal };
