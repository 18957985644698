import React, { useEffect, useState } from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';

import { useMultiLangString } from '~/hooks/useMultiLangString';

import { JobStackGroup } from '../design';
import { RectangularJobCard } from '../RectangularJobCard';

import type { ICareerPlan } from '@learned/types';

interface IJobProfile {
  id: string;
  performance: {
    score: number;
    maxScore: number;
  };
}

interface IItem extends Omit<ICareerPlan, 'jobProfile'> {
  jobProfile: IJobProfile;
}

interface IRectangularJobCardGroupProps {
  companyColor?: string;
  items: IItem[];
  onClickViewDetails: (roleId: string) => void;
}

const RectangularJobCardGroup = ({
  items,
  onClickViewDetails,
  companyColor,
}: IRectangularJobCardGroupProps) => {
  const getMultiLangString = useMultiLangString();
  const [jobs, setJobs] = useState<IItem[]>([]);
  const [transformThisId, setTransformThisId] = useState<string>();
  const [transformPrevId, setTransformPrevId] = useState<string>();

  useEffect(() => {
    const currentPrimary: IItem[] = [];
    const current: IItem[] = [];
    const previous: IItem[] = [];

    items.forEach((item) => {
      if (item.status === CAREER_PLAN_STATUSES.CURRENT && item.primary) {
        currentPrimary.push(item);
      } else if (item.status === CAREER_PLAN_STATUSES.CURRENT && !item.primary) {
        current.push(item);
      } else {
        previous.push(item);
      }
    });

    setJobs([...currentPrimary, ...current, ...previous].reverse());
  }, [items]);

  // bring the clicked card to the top
  const bringCardToTop = (id: string) => () => {
    const lastJobId = jobs.at(-1)?.id;

    if (id !== lastJobId) {
      const reOrderedJobs = [...jobs];
      const index = reOrderedJobs.findIndex((item) => item.id === id);
      const item = reOrderedJobs[index];
      reOrderedJobs.splice(index, 1);
      reOrderedJobs.push(item);

      setTransformThisId(id);
      setTransformPrevId(lastJobId);

      setTimeout(() => {
        setJobs(reOrderedJobs);
        setTransformThisId(undefined);
        setTransformPrevId(undefined);
      }, 200);
    }
  };

  return (
    <JobStackGroup count={jobs.length}>
      {jobs.map((item) => {
        return (
          // eslint-disable-next-line
          <div
            key={item.id}
            onClick={bringCardToTop(item.id)}
            id={item.id}
            className={
              item.id === transformThisId
                ? 'transformThis'
                : item.id === transformPrevId
                ? 'transformPrev'
                : ''
            }
          >
            <RectangularJobCard
              description={getMultiLangString(item.description ?? '')}
              jobTitle={item.name}
              image={item.coverImage}
              status={item.status}
              performance={item.jobProfile.performance}
              jobFamily={''} // TODO: Add job family here once career plans updated
              jobLevel={''} // TODO: Add job level here once career plans updated
              onClickViewDetails={() => onClickViewDetails(item.jobProfile.id)}
              companyColor={companyColor}
            />
          </div>
        );
      })}
    </JobStackGroup>
  );
};

export { RectangularJobCardGroup };
