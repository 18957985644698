import styled from 'styled-components';

import { COLORS } from '~/styles';

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 33px 0;
`;

const Description = styled.div`
  max-width: 256px;
  margin-top: 11px;
  font-size: 20px;
  line-height: 1.3;
  letter-spacing: -0.22px;
  text-align: center;
  color: ${COLORS.TEXT_MAIN};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;
  gap: 12px;
`;

const TitleContainer = styled.div`
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 26px;
  letter-spacing: -0.29px;
  color: ${COLORS.TEXT_HOVER};
`;

const SubTitle = styled.div`
  font-size: 14px;
  line-height: 1.86;
  letter-spacing: -0.16px;
  color: ${COLORS.SUBTEXT};
`;

const ActionContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

const SectionTitle = styled.div`
  margin: 22px 0 12px 0;
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
`;

const CategoriesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .categories-dropdown {
    height: 32px;
    min-width: 150px;
  }
`;

export {
  Content,
  Footer,
  Header,
  Title,
  ActionContainer,
  SubTitle,
  TitleContainer,
  Description,
  SectionTitle,
  CategoriesSection,
};
