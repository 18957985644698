import React, { useState } from 'react';

import { Locals_all } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import size from 'lodash/size';
import { Controller } from 'react-hook-form';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Editor from '~/components/Editor';
import { Input } from '~/components/Input';
import type { ISectionState } from '~/components/SideBar/SectionStateHook';

import { StepFooter } from './components/StepFooter';
import {
  ActionContainerHeader,
  CombinedSection,
  FakeInput,
  FakeInputLabel,
  Form,
  InputContainer,
  Label,
  Section,
  SkillDescriptionHeader,
  StyledDropdown,
  Title,
} from './design';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangFieldArray } from '~/hooks/useMultiLangFieldArray';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { generateTextTranslation, generateSkillDescription } from '~/services/completions';

import type { IGeneralForm } from './types';
import type { ISkillTemplate, ISkillCategory } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

type StepDetailsProps = {
  sectionState: ISectionState;
  formMethods: UseFormReturn<IGeneralForm>;
  languageState: ILanguageStateReturn;
  setSkillCategory: (skillCategory?: ISkillCategory) => void;
  skillTemplate?: ISkillTemplate;
  skillCategories: ISkillCategory[];
};

function StepDetails({
  sectionState,
  formMethods,
  languageState,
  setSkillCategory,
  skillTemplate,
  skillCategories,
}: StepDetailsProps) {
  const getMultiLangString = useMultiLangString();
  const { i18n } = useLingui();
  const { watch, register, unregister, control, trigger, handleSubmit, setValue } = formMethods;

  const skillCategoryId = watch('skillCategory');

  const [isGenerationLoading, setIsGenerationLoading] = useState(false);
  const [isTranslationLoading, setIsTranslationLoading] = useState(false);

  const nameFieldArray = useMultiLangFieldArray({
    name: 'name',
    control,
    unregister,
    languageState,
  });

  const descriptionFieldArray = useMultiLangFieldArray({
    name: 'description',
    control,
    unregister,
    languageState,
  });

  const skillName = watch('name')?.find(
    (name) => name.locale === languageState.companyPrimaryLanguage.locale,
  );

  const skillDescription = watch('description').find(
    (value) => value.locale === languageState.companyPrimaryLanguage.locale,
  );

  const onSubmit = () => sectionState.setCurrentSection(1);

  const onGenerateDescription = async () => {
    if (skillName) {
      setIsGenerationLoading(true);

      const { data }: { data: { description: string } } = await generateSkillDescription(
        skillName.value,
        skillCategoryId,
        skillName.locale as Locals_all,
      );

      const index = watch('description').findIndex((value) => value.locale === skillName.locale);
      setValue(`description.${index}.value`, data.description);
      setIsGenerationLoading(false);
    }
  };
  const onTranslateDescription = async () => {
    const skillDescription = watch('description').find(
      (value) => value.locale === languageState.companyPrimaryLanguage.locale,
    );

    if (skillDescription) {
      setIsTranslationLoading(true);

      const translateResponse: {
        data: {
          translatedTexts: Record<string, string>[];
        };
      } = await generateTextTranslation(
        [skillDescription.value],
        languageState.languages
          .filter((language) => language.locale !== languageState.companyPrimaryLanguage.locale)
          .map((item) => item.locale),
      );

      languageState.languages
        .filter((language) => language.locale !== languageState.companyPrimaryLanguage.locale)
        .forEach((language) => {
          const objText = translateResponse.data.translatedTexts[0];
          const index = watch('description').findIndex((value) => value.locale === language.locale);
          setValue(`description.${index}.value`, objText[language.locale]);
        });

      setIsTranslationLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit, onSubmit)}>
      <Title>
        <Trans>Skill or KPI details</Trans>
      </Title>
      <CombinedSection>
        {skillTemplate && (
          <Section $marginTop={14}>
            <Label>
              <Trans>Skill or KPI template</Trans>
            </Label>
            <InputContainer>
              <FakeInput>
                <FakeInputLabel>{getMultiLangString(skillTemplate.name)}</FakeInputLabel>
              </FakeInput>
            </InputContainer>
          </Section>
        )}
        <Section $marginTop={14}>
          <Label>
            <Trans>Customise name</Trans>
          </Label>
          <InputContainer>
            {nameFieldArray.fields.map((field) => (
              <Controller
                key={field.id}
                {...register(`name.${field.index}.value`)}
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <Input
                      value={value}
                      error={sectionState.triedToSubmit && !!formMethods.formState.errors?.name}
                      onChange={(args) => {
                        onChange(args);
                        trigger('name');
                      }}
                      key={field.id}
                      width="318px"
                      height="38px"
                      leftIcon={
                        size(languageState.languages) > 1
                          ? getUnicodeFlagIcon(
                              field.locale.substring(field.locale.indexOf('_') + 1),
                            )
                          : undefined
                      }
                    />
                  );
                }}
              />
            ))}
          </InputContainer>
        </Section>
      </CombinedSection>
      <Section $marginTop={14}>
        <Label>
          <Trans>Category</Trans>
        </Label>
        <InputContainer>
          <Controller
            {...register('skillCategory')}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <StyledDropdown
                  $error={
                    sectionState.triedToSubmit && !!formMethods.formState.errors?.skillCategory
                  }
                  selectedItem={skillCategories.find((item) => item.id === value)}
                  items={skillCategories}
                  onChange={(item) => {
                    onChange(item?.id);
                    setSkillCategory(item);
                  }}
                  stringifyItem={(item) => getMultiLangString(item.name)}
                  isSingleSelect
                />
              );
            }}
          />
        </InputContainer>
      </Section>
      <Section $marginTop={47}>
        <SkillDescriptionHeader>
          <Label>
            <Trans>Description</Trans>
          </Label>
          <ActionContainerHeader>
            <Button
              type={'button'}
              label={i18n._(t`Translate description`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              isLoading={isTranslationLoading}
              onClick={() => onTranslateDescription()}
              disabled={!skillDescription?.value || isTranslationLoading || isGenerationLoading}
            />
            <Button
              type={'button'}
              label={i18n._(t`Generate description`)}
              size={ButtonSize.MEDIUM}
              variant={ButtonVariant.SECONDARY}
              isLoading={isGenerationLoading}
              onClick={() => onGenerateDescription()}
              disabled={!!skillDescription?.value || !skillName?.value || isGenerationLoading}
            />
          </ActionContainerHeader>
        </SkillDescriptionHeader>
        <InputContainer>
          {descriptionFieldArray.fields.map((field) => (
            <Controller
              key={field.id}
              {...register(`description.${field.index}.value`)}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Editor
                  minHeight={'231px'}
                  value={value}
                  compact
                  onChange={onChange}
                  big
                  leftIcon={
                    size(languageState.languages) > 1
                      ? getUnicodeFlagIcon(field.locale.substring(field.locale.indexOf('_') + 1))
                      : undefined
                  }
                />
              )}
            />
          ))}
        </InputContainer>
      </Section>
      <StepFooter onPrev={() => sectionState.setCurrentSection(0)} />
    </Form>
  );
}

export { StepDetails };
