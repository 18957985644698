import styled from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  margin-bottom: 46px;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${COLORS.TEXT_HOVER};
  font-weight: 500;
  & .description {
    color: ${COLORS.SUBTEXT};
    font-size: 12px;
    font-weight: normal;
    margin-left: 10px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.SUBTEXT};
  color: ${COLORS.WHITE};
  width: 24px;
  height: 24px;
  border-radius: 100px;
  margin-right: 9px;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.08);
`;

export const Placeholder = styled.div<{ color?: string }>`
  color: ${({ color }) => color || 'unset'};
  position: relative;
  margin-top: 7px;
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 14px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 48px;
  display: flex;
  align-items: center;
`;

export const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const File = styled.div<{ color?: string }>`
  background-color: ${COLORS.BG_LIST};
  border-radius: 6px;
  padding: 10px 16px 12px 26px;
  box-sizing: border-box;
  font-size: 14px;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    color: ${COLORS.TEXT_MAIN};
    text-decoration: none;
  }

  .delete {
    visibility: hidden;
  }

  &:hover {
    background-color: ${COLORS.HOVER};
    .delete {
      visibility: visible;
    }
  }
`;
