import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICON_SIZES, ICONS } from '~/components/Icon';

import { List } from '../../../components/List';
import { ListRowGuest } from '../../../components/ListRowGuest';
import { ListRowPlaceholder } from '../../../components/ListRowPlaceholder';
import { Block } from '../components/Block';

import type { IUserReview } from '@learned/types';

const ButtonWrapper = styled.div`
  margin-top: 12px;
  margin-left: 36px;
`;

interface IGuestsBlockProps {
  guests: IUserReview['guests'];
  coaches: IUserReview['guests'];
  onDeleteGuest: (guestId: string) => void;
  onAddGuests: () => void;
  isAllowToDeleteGuests: boolean;
  isAllowToAddGuests: boolean;
}

const GuestsBlock = ({
  guests,
  coaches,
  onDeleteGuest,
  onAddGuests,
  isAllowToDeleteGuests,
  isAllowToAddGuests,
}: IGuestsBlockProps) => {
  const { i18n } = useLingui();

  return (
    <Block title={i18n._(t`Guests`)} isCounter={true} counter={guests.length}>
      <div>
        <List>
          {isEmpty(guests) ? (
            <ListRowPlaceholder text={i18n._(t`No guests selected.`)} isWarning={false} />
          ) : (
            guests.map((guestId, key) => (
              <ListRowGuest
                key={key}
                guestId={guestId}
                isCoach={coaches.includes(guestId)}
                onDelete={isAllowToDeleteGuests ? onDeleteGuest : undefined}
              />
            ))
          )}
        </List>
        <ButtonWrapper>
          {isAllowToAddGuests && (
            <Button
              variant={ButtonVariant.TEXT_PRIMARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`Add guests`)}
              onClick={onAddGuests}
              icon={ICONS.ADD_USER}
              iconSize={ICON_SIZES.SMALL}
            />
          )}
        </ButtonWrapper>
      </div>
    </Block>
  );
};

export { GuestsBlock };
