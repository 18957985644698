import React, { useEffect, useMemo } from 'react';

import { Circle } from '~/components/TableOfContents/components/Circle';

import { useScrollEffect } from '~/hooks/useScrollEffect';
import { COLORS } from '~/styles';

import { Animated, AnimatedTitle, Card, SectionNumber, Title, Wrapper } from '../design';
import { SECTION_STATUS, SECTION_TYPE } from '../types';

import type { ISectionProps } from '../types';

const Section = ({
  section,
  index,
  currentSection,
  setCurrentSection,
  parentHeight,
  isCollapsed,
  hideErrorState,
}: ISectionProps) => {
  const { ref, scrollToRef } = useScrollEffect<HTMLDivElement>({ behavior: 'smooth' });
  const isParentSection = section.type === SECTION_TYPE.PARENT;
  const isChildSection = section.type === SECTION_TYPE.CHILD;
  const isSectionCompleted = section.status === SECTION_STATUS.DONE;
  const isCurrentSection =
    currentSection === index ||
    (isParentSection && (section.childSections ?? []).includes(currentSection));
  let status = isCurrentSection ? SECTION_STATUS.CURRENT : section.status;

  if (hideErrorState === true && status === SECTION_STATUS.ERROR) {
    status = undefined;
  }

  useEffect(() => {
    if (currentSection === index) {
      scrollToRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  const titleColor = useMemo(() => {
    if (isParentSection) {
      if (isCurrentSection) {
        return COLORS.TEXT_HOVER;
      } else {
        return COLORS.SUBTEXT;
      }
    }

    if (isChildSection) {
      if (isCurrentSection) {
        return COLORS.COMPANY;
      }
      if (isSectionCompleted) {
        return COLORS.SUBTEXT;
      } else if (status === SECTION_STATUS.ERROR) {
        return COLORS.ACCENT_ERROR;
      } else {
        return COLORS.TEXT_MAIN;
      }
    }

    return COLORS.TEXT_MAIN;
  }, [isParentSection, isSectionCompleted, isChildSection, isCurrentSection, status]);

  return (
    <Wrapper parentHeight={parentHeight} ref={ref}>
      <Card
        isCollapsed={isCollapsed}
        status={status}
        onClick={() => void (!isParentSection && setCurrentSection(index))}
        noHover={isParentSection}
        titleColor={titleColor}
      >
        <Circle
          index={index}
          status={status}
          sectionType={section.type}
          currentSection={currentSection}
        />
        <Animated>
          <AnimatedTitle shouldAnimate={isCollapsed}>
            <Title bold={isParentSection}>{section.sectionNumber} </Title>
          </AnimatedTitle>
          <AnimatedTitle shouldAnimate={!isCollapsed}>
            <Title bold={isParentSection}>
              <SectionNumber>{section.sectionNumber}</SectionNumber>
              <span>{section.title}</span>
            </Title>
          </AnimatedTitle>
        </Animated>
      </Card>
    </Wrapper>
  );
};

export { Section };
