import React from 'react';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Button, ButtonVariant } from '~/components/Buttons';

import { Wrapper, Content, Title, Description } from './NotFound.design';

import Image404 from '~/assets/3.0/placeholders/404.png';

import * as appActions from '~/store/app/actions';

import type { createAction } from 'redux-act';

const NotFound = () => {
  const { i18n } = useLingui();
  const dispatch = useDispatch();
  const history = useHistory();

  const goBack = () => {
    dispatch(
      (
        appActions.setIsRequestError as unknown as ReturnType<
          typeof createAction<{ value: boolean }>
        >
      )({
        value: false,
      }),
    );
    history.goBack();
  };

  return (
    <Wrapper>
      <Content>
        <img src={Image404} alt="404" />
        <Title>
          <Trans>Ooops!</Trans>
        </Title>
        <Description>
          <Trans>
            This page does not seem to exist. Please go back and and retry your previous action.
            Does it happen again? Please contact us via the chat.
          </Trans>
        </Description>
        <div>
          <Button variant={ButtonVariant.PRIMARY} onClick={goBack} label={i18n._(t`Go to home`)} />
        </div>
      </Content>
    </Wrapper>
  );
};

export { NotFound };
