import React, { useState } from 'react';

import {
  REVIEW_QUESTION_TYPES,
  TASK_TARGET_COLLECTION,
  TASK_TYPE,
  USER_REVIEW_PEER_TYPE,
} from '@learned/constants';
import { IUser } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import Modal from '~/components/Modal';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';
import { TabSlider } from '~/components/TabSlider';
import { ITabSliderItem } from '~/components/TabSlider/types';
import { TOAST_TYPES, useToasts } from '~/components/Toast';
import InfoBox from '~/components/UI/InfoBox';
import { useReviewTask } from '~/pages/ReviewGiveFeedback/hooks';

import {
  ActionContainer,
  Container,
  Footer,
  Header,
  SubTitle,
  Title,
  TitleContainer,
} from './design';
import { InvitationMessage } from './InvitationMessage';
import { getInviteDefaultMessage } from './InvitationMessage/getInviteDefaultMessage';
import { InviteExternalPeers } from './InviteExternalPeers';
import { InvitePeers } from './InvitePeers';
import { PeerQuestion, QuestionsForPeers } from './QuestionsForPeers';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { getUser } from '~/selectors/baseGetters';
import { createTasks } from '~/services/tasks';

type NominatePeersProps = {
  closeModal?: (isSubmit?: boolean) => void;
  taskId: string;
};

const NominatePeers = ({ taskId, closeModal }: NominatePeersProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();
  const user = useSelector(getUser) as IUser;
  const languageState = useLanguageState();

  const { reviewTask, userReview, isLoading } = useReviewTask({ reviewTaskId: taskId });

  const [peers, setPeers] = useState<string[]>([]);
  const [externalPeers, setExternalPeers] = useState<string[]>([]);
  const [inviteMessage, setInviteMessage] = useState(getInviteDefaultMessage(user.firstName));
  const [showEditInviteMessage, setShowEditInviteMessage] = useState(false);
  const { addToast } = useToasts();

  const reviewName = getMultiLangString(userReview?.name || '');

  const tabs: ITabSliderItem[] = [
    {
      label: `${i18n._(t`Invite peers `)} (${peers.length})`,
      key: 'one',
      isDefault: true,
      content: (
        <InvitePeers
          selectedIds={peers}
          excludeIds={[
            user.id,
            ...(userReview?.coaches ? userReview.coaches : []),
            ...(userReview?.peers
              ? userReview.peers
                  .filter((item) => item.type === USER_REVIEW_PEER_TYPE.USER)
                  .map((item) => item.value)
              : []),
          ]}
          onSelectedPeersIds={setPeers}
        />
      ),
    },
    {
      label: `${i18n._(t`Invite external peers `)} (${externalPeers.length})`,
      key: 'two',
      isDefault: false,
      content: (
        <InviteExternalPeers
          selectedItems={externalPeers}
          excludedEmails={userReview?.peers
            .filter((item) => item.type === USER_REVIEW_PEER_TYPE.EMAIL)
            .map((item) => item.value)}
          onSelectedEmails={setExternalPeers}
        />
      ),
    },
  ];

  const onSend = async () => {
    if (isEmpty(userReview)) {
      return;
    }

    if (peers.length + externalPeers.length === 0) {
      addToast({
        title: i18n._(t`Select at least one peer`),
        type: TOAST_TYPES.ERROR,
      });
      return;
    }

    await createTasks({
      target: userReview.id,
      targetCollection: TASK_TARGET_COLLECTION.USER_REVIEWS,
      tasks: [
        {
          type: TASK_TYPE.REVIEW_PEER_EVALUATE,
          description: {
            [languageState.companyPrimaryLanguage.locale]: inviteMessage,
          },
          usersTo: [
            ...peers.map((peer) => ({
              id: peer,
            })),
            ...externalPeers.map((peer) => ({
              email: peer,
            })),
          ],
        },
      ],
    });

    addToast({
      title: i18n._(t`Request for input sent!`),
      type: TOAST_TYPES.SUCCESS,
    });
    closeModal?.(true);
  };

  const getQuestions = () => {
    const questions: PeerQuestion[] = [];

    userReview?.questions?.map((item) => {
      // Prevent duplication on questions
      if (
        item.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL &&
        questions.find((question) => question.skillId === item.settings.skill)
      ) {
        return;
      }

      if (
        item.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY &&
        questions.find(
          (question) =>
            question.skillId === item.settings.skill && question.jobId === item.settings.jobProfile,
        )
      ) {
        return;
      }

      const peerQuestion = {
        question: item.name,
        type: item.type,
        ...(item.type === REVIEW_QUESTION_TYPES.CUSTOM_SKILL && {
          skillId: item.settings.skill,
          skill: item.settings.skillName,
        }),
        ...(item.type === REVIEW_QUESTION_TYPES.SKILL_CATEGORY && {
          skillId: item.settings.skill,
          skill: item.settings.skillName,
          jobName: item.settings.jobProfileName,
          jobId: item.settings.jobProfile,
        }),
      } as PeerQuestion;

      questions.push(peerQuestion);
    });

    return questions;
  };

  return (
    <Modal
      showDivider={false}
      centerModal
      contentStyles={{ padding: '24px 32px', overflow: 'visible' }}
      borderRadius={6}
      hideFooter
      isHideHeader
    >
      <ShowSpinnerIfLoading height={'565px'} loading={isLoading}>
        <Header>
          <TitleContainer>
            <Title>{i18n._(t`Ask peers for input`)}</Title>
            <SubTitle>{i18n._(t`For ${reviewName}`)}</SubTitle>
          </TitleContainer>
          <ActionContainer>
            <Button
              size={ButtonSize.MEDIUM}
              onClick={() => closeModal?.(false)}
              variant={ButtonVariant.CLOSE}
            />
          </ActionContainer>
        </Header>

        <Container>
          {!isEmpty(getMultiLangString(reviewTask?.description || '')) && (
            <InfoBox
              value={getMultiLangString(reviewTask?.description || '')}
              isRichText
              margin={'0 0 24px 0'}
            />
          )}

          <TabSlider
            items={tabs}
            tabTitleAlignment={'left'}
            noPadding={true}
            paddingWrapper={'0 0'}
            tabPadding={'6px 24px'}
          />

          <InvitationMessage
            value={inviteMessage}
            editMode={showEditInviteMessage}
            onCancel={() => {
              setShowEditInviteMessage(false);
            }}
            onSave={(value) => {
              setInviteMessage(value);
              setShowEditInviteMessage(false);
            }}
            onEdit={() => setShowEditInviteMessage(true)}
          />

          <QuestionsForPeers questions={getQuestions()} />
        </Container>

        <Footer>
          <Button
            label={i18n._(t`Cancel`)}
            type="button"
            variant={ButtonVariant.SECONDARY}
            size={ButtonSize.MEDIUM}
            onClick={() => closeModal?.(false)}
          />
          <Button
            label={i18n._(t`Send`)}
            onClick={onSend}
            type="button"
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.MEDIUM}
            disabled={showEditInviteMessage}
          />
        </Footer>
      </ShowSpinnerIfLoading>
    </Modal>
  );
};

export { NominatePeers };
