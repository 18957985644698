import React from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useParams } from 'react-router-dom';

import RocketIcon from '~/components/Icons/Rocket';
import Placeholder from '~/components/Placeholder';
import ShowSpinnerIfLoading from '~/components/ShowSpinnerIfLoading';

import { useLanguageState } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { RoleGuard } from '~/hooks/useRoleGuard';

import { GiveReview } from '../components/GiveReview';
import { InputNoLongerPossible } from '../components/InputNoLongerPossible';
import { useReviewTask } from '../hooks';

function SelfReview() {
  const params: Record<string, string> = useParams();
  const { i18n } = useLingui();
  const languageState = useLanguageState();
  const reviewTaskId = params.taskId;

  const { reviewTask, userReview, isLoading } = useReviewTask({ reviewTaskId });
  const introTitle = i18n._(t`Prepare for your self review`);

  return (
    <RoleGuard role={ROLES.USER}>
      <ShowSpinnerIfLoading loading={isLoading}>
        {reviewTask && userReview && (
          <GiveReview
            key={reviewTask.id}
            reviewTask={reviewTask}
            userReview={userReview}
            introTitle={introTitle}
            languageState={languageState}
            useMultiLangString={useMultiLangString}
            InputNoLongerPossibleScreen={InputNoLongerPossible}
          />
        )}
        {(!reviewTask || !userReview) && (
          <Placeholder title={i18n._(t`No review task`)} Icon={RocketIcon} />
        )}
      </ShowSpinnerIfLoading>
    </RoleGuard>
  );
}

export { SelfReview };
