import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

// collapse menu item

export const CollapseMenuBlock = styled.div<{
  isMenuCollapsed: boolean;
}>`
  min-height: 54px;
  width: ${({ isMenuCollapsed }) => (isMenuCollapsed ? '46px' : '247px')};
  box-sizing: border-box;
  padding: ${({ isMenuCollapsed }) => (isMenuCollapsed ? '0 10px' : '0 24px 0 10px')};
  margin: 0 auto;
  align-items: center;
  justify-content: ${({ isMenuCollapsed }) => (isMenuCollapsed ? 'center' : 'right')};
  display: flex;
  cursor: pointer;

span {
  font-size: 12px;
  color: ${COLORS.PLACEHOLDERS};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-right: 10px;
}

&:hover {
  span {
    color: ${COLORS.SUBTEXT};
  }
`;

export const CollapseIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserName = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 148px;
  max-width: 148px;
  max-height: 17px;
`;

export const UserEmail = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 148px;
  max-width: 148px;
  max-height: 17px;
  color: ${COLORS.SUBTEXT};
`;

export const MenuItemTitle = styled.div<{
  isCurrent?: boolean;
  isBigLeftMargin?: boolean;
}>`
  margin-left: ${({ isBigLeftMargin }) => (isBigLeftMargin ? '17px' : '4px')};
  padding: 12px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const MenuRow = styled.div<{
  isMenuCollapsed?: boolean;
}>`
  align-items: center;
  justify-content: ${({ isMenuCollapsed }) => (isMenuCollapsed ? 'center' : 'left')};
  display: flex;
  width: ${({ isMenuCollapsed }) => (isMenuCollapsed ? '46px' : '100%')};
  position: relative;
`;

export const MenuCol = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 14px;
`;

export const MenuItem = styled.div<{
  isCurrentRoute?: boolean;
  isBigMargins?: boolean;
  isDotItemStyle?: boolean;
}>`
  min-height: ${({ isDotItemStyle }) => (isDotItemStyle ? '22px' : '46px')};
  width: 100%;
  margin-bottom: ${({ isBigMargins, isDotItemStyle }) =>
    isBigMargins ? '8px' : isDotItemStyle ? '0' : '2px'};
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${({ isBigMargins }) =>
    isBigMargins &&
    css`
      margin-top: 8px;
    `}

  ${({ isCurrentRoute, isDotItemStyle }) =>
    isCurrentRoute
      ? css`
          background-color: var(--company-color);
          border-radius: 6px;
          box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
          color: ${COLORS.WHITE};

          svg {
            color: ${COLORS.WHITE};
          }

          .userEmail {
            color: ${COLORS.PLACEHOLDERS};
          }
        `
      : isDotItemStyle
      ? css`
          background-color: none;
          &:hover {
            span {
              background-color: var(--company-color);
            }
          }
        `
      : css`
          background-color: none;
          font-weight: normal;
          color: ${COLORS.TEXT_MAIN};

          svg {
            color: ${COLORS.TEXT_MAIN};
          }

          &:hover {
            border-radius: 6px;
            box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
            background-color: ${COLORS.WHITE};
            color: var(--company-color);

            svg {
              color: var(--company-color);
            }

            .userEmail {
              color: var(--company-color);
            }
          }
        `}
`;

export const Circle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${COLORS.DOT_MENU_ITEM};
`;

export const MenuBlockIcon = styled.div`
  width: 46px;
  min-width: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const StyledPrimaryMenuItem = styled.div<{
  isCurrentRoute?: boolean;
}>`
  box-sizing: border-box;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: ${(props) => (props.isCurrentRoute ? COLORS.BG_PAGE : 'none')};
  cursor: pointer;

  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &:hover {
    background-color: ${COLORS.BG_PAGE};
  }
`;

export const OutsideLink = styled.a`
  text-decoration: none;
  display: flex;
  width: 100%;
  cursor: pointer;
`;

export const NotificationsCircle = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  background-color: ${COLORS.ACCENT_WARNING};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: solid 1px ${COLORS.BG_PAGE};
  box-sizing: border-box;
  position: absolute;
  right: 0px;
  top: -13px;
  z-index: 10;
`;

export const NotificationDot = styled(NotificationsCircle)`
  position: absolute;
  left: 25px;
  top: 10px;
  z-index: 10;
`;

export const NotificationsBadge = styled.div<{
  isCurrentRoute?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 22px;
  height: 22px;
  box-sizing: border-box;
  border-radius: 6px;
  border: solid 1px ${COLORS.TIPPY_BACKGROUND};
  padding: 4px 3px 0;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.TEXT_MAIN};
  margin-right: -2px;

  ${({ isCurrentRoute }) =>
    isCurrentRoute &&
    css`
      border: solid 1px ${COLORS.WHITE};
      color: ${COLORS.WHITE};
    `}
`;

export const UserMenuItemTitle = styled.div<{
  isCurrent?: boolean;
  isBigLeftMargin?: boolean;
}>`
  margin-left: ${({ isBigLeftMargin }) => (isBigLeftMargin ? '17px' : '4px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 4px;
`;

export const UploadLogoText = styled.div`
  color: var(--company-color);
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const UploadLogoWrapper = styled.div`
  width: 46px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  z-index: 1000;
  border-radius: 6px;
  background-color: ${COLORS.WHITE};

  svg {
    min-height: 26px;
    min-width: 26px;
    height: 26px;
    width: 26px;
    color: var(--company-color);
  }

  input[type='file'] {
    justify-self: center;
    width: 46px;
    height: 46px;
    z-index: 10000;
    opacity: 0;
    position: absolute;
    cursor: pointer;
  }
`;
