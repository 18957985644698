// TODO move enums and consts to constants package not types
import { IMultiLangString } from './multiLangString';
import { IUserTo } from './tasks';
import { FILTER_TASK_TYPES, TASK_FILTER_BY_USER_TYPE, TASK_STATUS } from '@learned/constants';

/**
 * @deprecated since version 3.0 {@see tasks.ts}
 * still in use, no replacement yet
 */
export enum TASKS_STATUSES {
  ACTIVE = 'active',
  COMPLETED = 'completed',
}

export enum TASKS_ACTIONS {
  START = 'start',
  CONTINUE = 'continue',
  UPDATE = 'update',
  SHOW = 'show',
  NO_ACTION = 'no_action',
  SHARED = 'shared',
  SHARE = 'share',
  MARK_AS_DONE = 'mark_as_done',
  VIEW = 'view',
  PROBLEM = 'problem',
  ARCHIVED = 'archived',
  /* REVIEWS 3.0 */
  UPCOMING = 'upcoming',
  TODO = 'todo',
  ACTIVE = 'active',
  CALIBRATE = 'calibrate',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  EXPIRED = 'expired',
}

export enum UPDATED_TASK_ACTIONS {
  IN_PROGRESS = 'in_progress',
  PROBLEM = 'problem',
  ARCHIVED = 'archived',
  MARK_AS_DONE = 'mark_as_done', // introducing this here to avoid type confusions (not a new task action)
  // review-3.0
  UPCOMING = 'upcoming',
  TODO = 'todo',
  ACTIVE = 'active',
  CALIBRATE = 'calibrate',
  COMPLETED = 'completed',
  DECLINED = 'declined',
  EXPIRED = 'expired',
}

export const TASK_ACTIONS_MAP = {
  [TASKS_ACTIONS.START]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.CONTINUE]: UPDATED_TASK_ACTIONS.IN_PROGRESS,
  [TASKS_ACTIONS.UPDATE]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.SHOW]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.NO_ACTION]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.SHARED]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.SHARE]: UPDATED_TASK_ACTIONS.IN_PROGRESS,
  [TASKS_ACTIONS.ARCHIVED]: UPDATED_TASK_ACTIONS.ARCHIVED,
  [TASKS_ACTIONS.VIEW]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.PROBLEM]: UPDATED_TASK_ACTIONS.PROBLEM,
  [TASKS_ACTIONS.MARK_AS_DONE]: TASKS_ACTIONS.MARK_AS_DONE,
  /* from REVIEWS 3.0*/
  [TASKS_ACTIONS.UPCOMING]: UPDATED_TASK_ACTIONS.UPCOMING,
  [TASKS_ACTIONS.TODO]: UPDATED_TASK_ACTIONS.TODO,
  [TASKS_ACTIONS.ACTIVE]: UPDATED_TASK_ACTIONS.ACTIVE,
  [TASKS_ACTIONS.CALIBRATE]: UPDATED_TASK_ACTIONS.CALIBRATE,
  [TASKS_ACTIONS.COMPLETED]: UPDATED_TASK_ACTIONS.COMPLETED,
  [TASKS_ACTIONS.DECLINED]: UPDATED_TASK_ACTIONS.DECLINED,
  [TASKS_ACTIONS.EXPIRED]: UPDATED_TASK_ACTIONS.EXPIRED,
};

type TaskTargetType = {
  target: Pick<ITaskOld['target'], 'userName' | 'name'>;
};

export const TASKS_NAMES_BY_TYPE = {
  RT_FEEDBACK: {
    key: 'rtFeedback',
    text: (task: TaskTargetType) => `Answer the feedback request of ${task.target.userName}'s`,
  },
  SELF_REVIEW: {
    key: 'selfReview',
    text: (task: TaskTargetType) => `Prepare for your ${task.target.name}`,
  },
  NOMINATE_PEERS: {
    key: 'nominatePeers',
    text: (task: TaskTargetType) => `Ask peers for input for your ${task.target.name}`,
  },
  PEER_REVIEW: {
    key: 'peerReview',
    text: (task: TaskTargetType) =>
      `Provide input for ${task.target.userName}'s ${task.target.name}`,
  },
  COACH_REVIEW: {
    key: 'coachReview',
    text: (task: TaskTargetType) =>
      `Provide input for ${task.target.userName}'s ${task.target.name}`,
  },
  GOAL_TASK: {
    key: 'goalTask',
    text: (_task: TaskTargetType) => `Update your goals`,
  },
  ASK_FEEDBACK: {
    key: 'ask_feedback',
    text: (_task: TaskTargetType) => `Ask someone for feedback`,
  },
  GIVE_FEEDBACK: {
    key: 'give_feedback',
    text: (_task: TaskTargetType) => `Give someone feedback or a compliment`,
  },
  NEXT_STEP_CONVERSATION: {
    key: 'next_step_conversation',
    text: (task: TaskTargetType) => task.target.name,
  },
  NEXT_STEP_REVIEW: {
    key: 'next_step_review',
    text: (task: TaskTargetType) => task.target.name,
  },
  ANSWER_SURVEY_TRADITIONAL: {
    key: 'answer_survey_traditional',
    text: (task: TaskTargetType) => task.target.name,
  },
  ANSWER_SURVEY_PULSE: {
    key: 'answer_survey_pulse',
    text: (task: TaskTargetType) => task.target.name,
  },
};

/**
 * @deprecated since Review 3.0 (user ITask from constants/tasks.ts)
 */
export enum TASKS_TYPES_OLD {
  RT_FEEDBACK = 'rtFeedback',
  SELF_REVIEW = 'selfReview',
  NOMINATE_PEERS = 'nominatePeers',
  PEER_REVIEW = 'peerReview',
  COACH_REVIEW = 'coachReview',
  GOAL_TASK = 'goalTask',
  ASK_FEEDBACK = 'ask_feedback',
  GIVE_FEEDBACK = 'give_feedback',
  NEXT_STEP_REVIEW = 'next_step_review',
  NEXT_STEP_CONVERSATION = 'next_step_conversation',
  ANSWER_SURVEY_TRADITIONAL = 'answer_survey_traditional',
  ANSWER_SURVEY_PULSE = 'answer_survey_pulse',
}

/**
 * @deprecated since Review 3.0 (user ITask from type/tasks.ts)
 */
export interface ITaskOld {
  type: TASKS_TYPES_OLD;
  deadline: string | null;
  status: TASKS_ACTIONS; // status for correct rendering
  taskStatus: TASKS_STATUSES; // status to filter and count by active/completed
  createdDate?: string | null;
  repeat?: number;
  target: {
    name: string; // target item (review) name - for render and search
    targetId: string; // target item (review, rtFeedback) id
    requestId: string;
    targetName?: string;
    originalTargetId?: string;
    taskId?: string;
    userId: string; // for peers/coaches tasks - user for whom need to provide feedback
    userName?: string;
  };
}

export type ITaskWithId = ITaskOld & {
  id: string;
  name?: IMultiLangString;
  userTo?: IUserTo;
  targetId?: string;
};

// Requests
export interface IGetTasksRequest {
  filterTaskTypes: FILTER_TASK_TYPES;
  taskStatus?: TASK_STATUS[];
  userType: TASK_FILTER_BY_USER_TYPE;
  limit: string;
  skip: number;
  search: string;
}
