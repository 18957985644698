import styled, { css } from 'styled-components';

export const SidebarWrapper = styled.div``;

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: calc(40px + 64px);
  width: 100%;

  & .content {
    width: 750px;
  }
`;

export const Wrapper = styled.div<{ isMenuCollapsed?: boolean }>`
  display: flex;
  ${({ isMenuCollapsed }) =>
    isMenuCollapsed &&
    css`
      ${MainWrapper} {
        margin-left: 380px;
      }
    `}
`;
