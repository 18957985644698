import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import DatePicker from '~/components/DatePickerDeprecated';
import { Label } from '~/pages/Reviews/Modals/PlanCalendarEventModal/PlanCalendarEventForm/design';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr;
  grid-column-gap: 36px;
`;

interface IInputRowProps {
  selectedDate: string | null;
  onChangeDate: (value: string) => void;
  selectedStartTime: string | null;
  onChangeStartTime: (value: string) => void;
  selectedEndTime: string | null;
  onChangeEndTime: (value: string) => void;
}

const InputRow = ({
  selectedDate,
  onChangeDate,
  selectedStartTime,
  onChangeStartTime,
  selectedEndTime,
  onChangeEndTime,
}: IInputRowProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <div>
        <Label>{i18n._(t`Date`)}</Label>
        <DatePicker
          // @ts-ignore
          disabled={false}
          selected={selectedDate}
          onChange={onChangeDate}
          placeholder={i18n._(t`Select date`)}
        />
      </div>
      <div>
        <Label>{i18n._(t`Start time`)}</Label>
        <DatePicker
          // @ts-ignore
          showTimeSelect={true}
          showTimeSelectOnly={true}
          placeholder={'09:00'}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          disabled={false}
          timeIntervals={15}
          selected={selectedStartTime}
          onChange={onChangeStartTime}
          timeCaption="time"
          isHideIcon={true}
        />
      </div>
      <div>
        <Label>{i18n._(t`End time`)}</Label>
        <DatePicker
          // @ts-ignore
          showTimeSelect={true}
          showTimeSelectOnly={true}
          placeholder={'10:00'}
          dateFormat="HH:mm"
          timeFormat="HH:mm"
          width={80}
          disabled={false}
          timeIntervals={15}
          selected={selectedEndTime}
          onChange={onChangeEndTime}
          timeCaption="time"
          isHideIcon
        />
      </div>
    </Wrapper>
  );
};

export { InputRow };
