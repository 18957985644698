import styled from 'styled-components';

import { COLORS } from '~/styles';

export const RowWrapper = styled.div`
  padding: 0 0 0 36px;
`;

export const Text = styled.div`
  font-size: 14px;
  color: ${COLORS.TEXT_MAIN};
`;

export const Separator = styled.div`
  height: 1px;
  border-bottom: 1px solid ${COLORS.BORDERS};
  padding-top: 24px;
  margin: 0 0 0 36px;
`;
