import styled from 'styled-components';

import { Button } from '~/components/Buttons';

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const NotificationWrapper = styled.div`
  margin-top: 32px;
  width: 1000px;
  max-height: 80vh;
  overflow: auto;

  & > div {
    padding-bottom: 32px;
    padding-top: 10px;
  }
`;

export const Label = styled.span`
  padding-left: 32px;
`;

export const DeleteButton = styled(Button)`
  height: 12px;
  width: 12px;
`;
