import React from 'react';

import { transparentize } from 'polished';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';

import { COLORS } from '~/styles';

import 'react-circular-progressbar/dist/styles.css';

const ProgressWrap = styled.div<{ $size: number }>`
  width: ${(props) => props.$size}px;
  height: ${(props) => props.$size}px;
`;

interface ICircularProgressProps {
  progress: number;
  size: number;
  strokeWidth: number;
  pathColor: string;
  trailColor: string;
  textColor: string;
  textSize: string;
  isHideText: boolean;
  overrideText?: string;
}

const CircularProgress: React.FC<ICircularProgressProps> = ({
  progress = 0,
  size = 64,
  strokeWidth = 12,
  pathColor = 'var(--company-color)',
  trailColor = transparentize(0.8, COLORS.SUBTEXT),
  textColor = COLORS.SUBTEXT,
  textSize = '13,3px',
  isHideText,
  overrideText,
}) => (
  <ProgressWrap $size={size}>
    <CircularProgressbar
      value={progress}
      text={overrideText ? overrideText : isHideText ? undefined : `${progress}%`}
      strokeWidth={strokeWidth}
      styles={buildStyles({
        pathColor,
        trailColor,
        textColor: progress === 100 ? pathColor : textColor,
        textSize,
      })}
    />
  </ProgressWrap>
);

export default CircularProgress;
