enum REPORT_TYPES {
  ENGAGEMENT = 'engagement',
  PERFORMANCE = 'performance',
}

enum REPORT_CHART_TYPES {
  BY_MONTH = 'by_month',
  BY_THEME_RANK = 'by_theme_rank',
  BY_ONE_DIMENSION = 'by_one_dimension',
  BY_TWO_DIMENSION = 'by_two_dimension',
  MEMBER_PERFORMANCE_SCORE = 'member_performance_score'
}

enum ENGAGEMENT_REPORT_CHART_DIMENSIONS {
  THEME = 'theme',
  SURVEY = 'survey',
  TEAM = 'team',
  QUESTION = 'question',
  MONTH = 'month',
  WEEK = 'week',
  YEAR = 'year',
  QUARTER = 'quarter',
  AGE_GROUP = 'age_group',
  GENDER = 'gender',
  JOB = 'job',
  JOB_GROUP = 'job_group',
  PERFORMANCE_CATEGORY = 'performance_category',
  EDUCATION_LEVEL = 'education_level',
  _LEARNED_THEME = '_learned_theme',
  REVIEW = 'review',
  MEMBER = 'member',
  SKILL = 'skill',
  SKILL_CATEGORY = 'skill_category',
  FOCUS_AREA = 'focus_area',
}

enum LUCA_INSIGHT_STATUS {
  PENDING = 'PENDING',
  READY = 'READY',
  FAILED = 'FAILED',
  NO_DATA = 'NO_DATA',
  SUCCEEDED = 'SUCCEEDED',
}

enum LUCA_INSIGHT_TARGET {
  ENGAGEMENT_SUMMARY = 'ENGAGEMENT_SUMMARY',
  PERFORMANCE_SUMMARY = 'PERFORMANCE_SUMMARY',
  ENGAGEMENT_REPORT_ = 'ENGAGEMENT_REPORT_',
  ENGAGEMENT_LEARNED_THEME_ = 'ENGAGEMENT_LEARNED_THEME_',
  PERFORMANCE_REPORT_ = 'PERFORMANCE_REPORT_',
  PERFORMANCE_LEARNED_THEME_ = 'PERFORMANCE_LEARNED_THEME_',
}

export {
  REPORT_TYPES,
  REPORT_CHART_TYPES,
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  LUCA_INSIGHT_STATUS,
  LUCA_INSIGHT_TARGET,
};
