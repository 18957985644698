import styled, { css } from 'styled-components';

import { COLORS } from '~/styles';

export const Wrapper = styled.div<{ shouldHighlightAutoRows?: boolean }>`
  position: relative;
  background-color: ${COLORS.WHITE};
  border-radius: 0 10px 10px 10px;

  ${({ shouldHighlightAutoRows }) =>
    shouldHighlightAutoRows &&
    css`
      & table {
        tbody > tr:first-child {
          .cell {
            background-color: ${COLORS.BG_ELEMENTS};
          }
        }
        tbody {
          .dark-cell-background {
            background-color: ${COLORS.BG_ELEMENTS};
          }
          .rounded-corners {
            border-radius: 10px;
          }
          .cell {
            &:hover {
              background-color: ${COLORS.HOVER};
            }
          }
        }
      }
    `}
`;

export const TableContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 2px;
  width: 100%;
`;

export const MainTable = styled.div<{ isScrollbarVisible?: boolean }>`
  position: relative;
  width: 100%;

  ${({ isScrollbarVisible }) =>
    isScrollbarVisible
      ? css`
          overflow-x: scroll;
          table-layout: fixed;

          &::-webkit-scrollbar {
            height: 8px;
            position: absolute;
          }

          &::-webkit-scrollbar-track {
            background: ${COLORS.BG_ELEMENTS};
            border-radius: 100px;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--company-color);
            border-radius: 100px;
          }

          &::-webkit-scrollbar:hover {
            cursor: pointer;
          }

          transform: rotateX(180deg);
        `
      : css`
          &::-webkit-scrollbar {
            display: none;
          }
        `}
`;

export const TH = styled.th<{
  minWidth?: string;
  maxWidth?: string;
  maxHeight?: string;
  padding?: string;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  max-height: ${({ maxHeight }) => maxHeight || '33px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
`;

export const SortIcons = styled.span<{ visibility?: boolean }>`
  margin-left: 4px;
  display: inline-flex;
  flex-direction: column;
  visibility: ${({ visibility }) => (visibility ? 'visible' : 'hidden')};
`;

export const THLabel = styled.div<{
  $isPointer?: boolean;
  padding?: string;
  centerAlign?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ centerAlign }) => (centerAlign ? 'center' : 'space-between')};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer' : 'default')};
  padding: ${({ padding }) => padding || '7px 18px'};
  background-color: ${COLORS.BG_PAGE};
  border-radius: 6px;
  box-sizing: border-box;
  width: 100%;

  &:hover {
    background-color: ${COLORS.HOVER};
    ${SortIcons} {
      visibility: visible;
    }
  }

  // this should take the same height even if there is no text
  span {
    min-height: 18px;
  }
`;

export const TableWrapper = styled.table<{
  $isPointer: boolean;
  isScrollbarVisible?: boolean;
  isStriped?: boolean;
}>`
  border-collapse: collapse;
  background-color: ${COLORS.WHITE};
  width: 100%;
  ${({ isScrollbarVisible }) =>
    isScrollbarVisible &&
    css`
      transform: rotateX(180deg);
    `}

  ${({ isStriped }) =>
    isStriped &&
    css`
      & tr:nth-child(odd) .cell {
        background-color: ${COLORS.BG_LIST};
      }
    `}

  & tr {
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};
    border: 0;
  }

  & th,
  td {
    text-align: left;
    padding: 1.5px;
    box-sizing: border-box;
  }

  & th {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    & ${THLabel} {
      display: inline-flex;
      align-items: center;
    }
  }

  & td {
    font-size: 14px;
    cursor: ${(props) => (props.$isPointer ? 'pointer' : 'default')};
    height: 56px;

    & .withPercentage {
      display: inline-flex;
      align-items: center;
      gap: 12px;
      width: 80px;
    }
  }
`;

export const TD = styled.td<{
  minWidth?: string;
  maxWidth?: string;
  padding?: string;
  isGrayColor?: boolean;
  $isPointer?: boolean;
}>`
  min-width: ${({ minWidth }) => minWidth || '120px'};
  width: ${({ maxWidth }) => maxWidth || 'unset'};
  color: ${({ isGrayColor }) => (isGrayColor ? COLORS.BG_LIST : COLORS.TEXT_MAIN)};
  cursor: ${({ $isPointer }) => ($isPointer ? 'pointer !important' : 'default')};
`;

export const THead = styled.thead`
  & .rotate {
    rotate: 180deg;
  }
`;

export const SortMenu = styled.div`
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(145, 157, 165, 0.12);
  border: solid 1px ${COLORS.BORDERS};
  width: 150px;
  padding: 4px;
  z-index: 2;

  & .item {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    font-weight: normal;
    height: 32px;
    border-radius: 6px;
    color: ${COLORS.DARK_GRAY};
    cursor: pointer;

    &:first-child {
      margin-bottom: 4px;
    }

    & .icon {
      width: 32px;
    }

    &.selected {
      background-color: var(--company-color);
      color: ${COLORS.WHITE};
      & .icon {
        color: ${COLORS.WHITE};
      }
    }

    &:not(.selected):hover {
      background-color: ${COLORS.HOVER};
    }
  }
`;

export const IconMenuWrapper = styled.div`
  display: flex;
  align-items: center;
`;
