import React from 'react';

import { ROLES } from '@learned/constants';

import PrivateRoute from '~/components/PrivateRoute';
import { NotificationsPage } from '~/pages/Notifications';

import Module from './createModule';

import routes from '~/constants/routes';

export default new Module('notifications', {
  routesPrivate: {
    user: [
      <PrivateRoute
        key={routes.NOTIFICATIONS.routePath(ROLES.USER)}
        path={routes.NOTIFICATIONS.routePath(ROLES.USER)}
        component={NotificationsPage}
        allowedRoles={[ROLES.USER]}
      />,
    ],
  },
  routesPublic: [],
});
