import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import map from 'lodash/map';
import * as plurals from 'make-plural/plurals';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import 'moment/locale/nl';

import ConversationsModule from '~/components/App/modules/conversations';
import ConversationTemplatesModule from '~/components/App/modules/conversationTemplates';
import DashboardsModule from '~/components/App/modules/dashboards';
import EngagementModule from '~/components/App/modules/engagement';
import GeneralModule from '~/components/App/modules/general';
import GoalsModule from '~/components/App/modules/goals';
import GoalTasksModule from '~/components/App/modules/goalTasks';
import InvitesModule from '~/components/App/modules/invites';
import JobProfilesModule from '~/components/App/modules/jobprofiles';
import LearningsModule from '~/components/App/modules/learnings';
import LogsModule from '~/components/App/modules/logs';
import MembersModule from '~/components/App/modules/members';
import NextStepsModule from '~/components/App/modules/nextSteps';
import NineGridModule from '~/components/App/modules/nineGrid';
import Notifications from '~/components/App/modules/notifications';
import PathsModule from '~/components/App/modules/paths';
import ProfileModule from '~/components/App/modules/profile';
import ReportsModule from '~/components/App/modules/reports';
import ReviewsModule from '~/components/App/modules/reviews';
import ReviewTemplatesModule from '~/components/App/modules/reviewTemplates';
import ReviewThemesModule from '~/components/App/modules/reviewThemes';
import RTFeedbacksModule from '~/components/App/modules/rtfeedbacks';
import SettingsModule from '~/components/App/modules/settings';
import SkillsModule from '~/components/App/modules/skills';
import SurveysModule from '~/components/App/modules/surveys';
import SurveyTasksModule from '~/components/App/modules/surveyTasks';
import SurveyTemplatesModule from '~/components/App/modules/surveyTemplates';
import TeamsModule from '~/components/App/modules/teams';
import ThemesModule from '~/components/App/modules/themes';
import UserPathsModule from '~/components/App/modules/userPaths';
import UserPublicModule from '~/components/App/modules/userPublic';
import UserSurveysModule from '~/components/App/modules/userSurveys';
import CssColors from '~/components/CssColors';
import ErrorHandler from '~/components/ErrorHandler';
import FacebookPixel from '~/components/FacebookPixel';
import GoogleAnalytics from '~/components/GoogleAnalytics';
import { GoogleTagManager } from '~/components/GoogleTagManager';
import Intercom from '~/components/Intercom';
import IntercomUpscope from '~/components/IntercomUpscope';
import SupportCheck from '~/components/SupportCheck';
import { ToastContainer } from '~/components/Toast/design';
import { ToastAdapter } from '~/components/Toast/ToastAdapter';
import FocusLayout from '~/layouts/FocusLayout';
import LoginLayout from '~/layouts/LoginLayout';
import MainLayout from '~/layouts/MainLayout';
import PublicLayout from '~/layouts/PublicLayout';
import CongratulationsPageCompany from '~/pages/CongratulationsPageCompany';
import DisabledAccountPage from '~/pages/DisabledAccountPage';
import { CommonError, NotFound, MaintenanceMode, TooManyRequests } from '~/pages/Errors';
import LogoutPage from '~/pages/LogoutPage';
import MyPermissionDeniedPage from '~/pages/MyPermissionDeniedPage';
import NoAccountPage from '~/pages/NoAccountPage';
import RegisterWithCompanyPage from '~/pages/RegisterWithCompany';
import { ReviewTemplateSetup } from '~/pages/ReviewTemplateSetup';
import SuperAdminCompanies from '~/pages/SuperAdminDashboard/SuperAdminCompanies';
import SuperAdminCompanyMembers from '~/pages/SuperAdminDashboard/SuperAdminCompanyMembers';
import { SuperAdminCreateJobTemplate } from '~/pages/SuperAdminDashboard/SuperAdminCreateJobTemplate';
import { SuperAdminCreateSkillTemplate } from '~/pages/SuperAdminDashboard/SuperAdminCreateSkillTemplate';
import { SuperAdminEditJobTemplate } from '~/pages/SuperAdminDashboard/SuperAdminEditJobTemplate';
import { SuperAdminIndustries } from '~/pages/SuperAdminDashboard/SuperAdminIndustries';
import { SuperAdminJobLibrary } from '~/pages/SuperAdminDashboard/SuperAdminJobLibrary';
import SuperAdminOverview from '~/pages/SuperAdminDashboard/SuperAdminOverview';
import SuperAdminRequests from '~/pages/SuperAdminDashboard/SuperAdminRequests';
import { SuperAdminSkillMatrix } from '~/pages/SuperAdminDashboard/SuperAdminSkillMatrix';
import { SuperAdminSkillTemplateEdit } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateEdit';
import { SuperAdminSkillTemplateView } from '~/pages/SuperAdminDashboard/SuperAdminSkillTemplateView';
import SuperAdminTemplateReview from '~/pages/SuperAdminDashboard/SuperAdminTemplateReview';
import { SuperAdminViewJobTemplate } from '~/pages/SuperAdminDashboard/SuperAdminViewJobTemplate';

import redirects from './redirects';

import { ROLES, LANGUAGES } from '~/constants';
import routes from '~/constants/routes';
import {
  updateSelectedTeam,
  updateSelectedCompany,
  updateSelectedRole,
} from '~/store/selected/actions';
import catalogEn from '~/translations/en/messages.js';
import catalogNl from '~/translations/nl/messages.js';
import history from '~/utils/history';

import DevTools from '../../utils/DevTools';
import HomePage from '../Home';
import OpenRoute from '../OpenRoute';
import PrivateRoute from '../PrivateRoute';

const catalogs = { [LANGUAGES.NL]: catalogNl.messages, [LANGUAGES.EN]: catalogEn.messages };

i18n.load(catalogs);
map(LANGUAGES, (val) => {
  i18n.loadLocaleData(val, { plurals: plurals[val] });
});
i18n.activate(LANGUAGES.EN);

const toastComponents = { Toast: ToastAdapter, ToastContainer };

const SwitchWith404 = ({ children }) => {
  return (
    <Switch>
      {children}
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

const CompanyModules = ({ match: { path, params } }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSelectedCompany(params.companyId));
    // eslint-disable-next-line
  }, []);

  return (
    <SwitchWith404>
      <Route path={`${path}/admin`} component={AdminModules} />
      <Route path={`${path}/team/:teamId`} component={CoachModules} />
      <Route path={`${path}/:other`} component={UserModules} />
    </SwitchWith404>
  );
};

const AdminModules = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSelectedRole(ROLES.ADMIN));
    // eslint-disable-next-line
  }, []);

  return (
    <SwitchWith404>
      {ReviewsModule.routesPrivate.admin}
      {GoalsModule.routesPrivate.admin}
      {ConversationsModule.routesPrivate.admin}
      {LearningsModule.routesPrivate.admin}
      {DashboardsModule.routesPrivate.admin}
      {TeamsModule.routesPrivate.admin}
      {SettingsModule.routesPrivate.admin}
      {GeneralModule.routesPrivate.admin}
      {LogsModule.routesPrivate.admin}
      {SkillsModule.routesPrivate.admin}
      {JobProfilesModule.routesPrivate.admin}
      {MembersModule.routesPrivate.admin}
      {NineGridModule.routesPrivate.admin}
      {EngagementModule.routesPrivate.admin}
      {PathsModule.routesPrivate.admin}
      {UserPathsModule.routesPrivate.admin}
      {UserPublicModule.routesPrivate.admin}
      {ReportsModule.routesPrivate.admin}
      {ReviewTemplatesModule.routesPrivate.admin}
      {ReviewThemesModule.routesPrivate.admin}
      {ConversationTemplatesModule.routesPrivate.admin}
      {RTFeedbacksModule.routesPrivate.admin}
    </SwitchWith404>
  );
};

const CoachModules = ({ match: { params } }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateSelectedRole(ROLES.COACH));
    dispatch(updateSelectedTeam(params.teamId));
    // eslint-disable-next-line
  }, [params.teamId]);

  return (
    <SwitchWith404>
      {ReviewsModule.routesPrivate.coach}
      {GoalsModule.routesPrivate.coach}
      {ConversationsModule.routesPrivate.coach}
      {LearningsModule.routesPrivate.coach}
      {DashboardsModule.routesPrivate.coach}
      {TeamsModule.routesPrivate.coach}
      {PathsModule.routesPrivate.coach}
      {UserPathsModule.routesPrivate.coach}
      {UserPublicModule.routesPrivate.coach}
      {JobProfilesModule.routesPrivate.coach}
      {GeneralModule.routesPrivate.coach}
      {EngagementModule.routesPrivate.coach}
    </SwitchWith404>
  );
};

const UserModules = ({ match: { params } }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (params.other !== 'team' && params.other !== 'admin') {
      dispatch(updateSelectedRole(ROLES.USER));
    }
    // eslint-disable-next-line
  }, []);

  return (
    <SwitchWith404>
      {ReviewsModule.routesPrivate.user}
      {ReviewThemesModule.routesPrivate.user}
      {GoalsModule.routesPrivate.user}
      {ConversationsModule.routesPrivate.user}
      {LearningsModule.routesPrivate.user}
      {RTFeedbacksModule.routesPrivate.user}
      {DashboardsModule.routesPrivate.user}
      {GeneralModule.routesPrivate.user}
      {JobProfilesModule.routesPrivate.user}
      {InvitesModule.routesPrivate.user}
      {Notifications.routesPrivate.user}
      {PathsModule.routesPrivate.user}
      {UserPathsModule.routesPrivate.user}
      {UserPublicModule.routesPrivate.user}
      {NextStepsModule.routesPrivate.user}
      {GoalTasksModule.routesPrivate.user}
      {SurveysModule.routesPrivate.user}
      {ThemesModule.routesPrivate.user}
      {SurveyTemplatesModule.routesPrivate.user}
      {SurveyTasksModule.routesPrivate.user}
      {UserSurveysModule.routesPrivate.user}
      {ProfileModule.routesPrivate.user}
      {EngagementModule.routesPrivate.user}
      {ReviewTemplatesModule.routesPrivate.user}
    </SwitchWith404>
  );
};

const PublicModules = () => {
  return (
    <SwitchWith404>
      {ReviewsModule.routesPublic}
      {RTFeedbacksModule.routesPublic}
      {ConversationsModule.routesPublic}
      {UserPublicModule.routesPublic}
    </SwitchWith404>
  );
};

function App({ lang }) {
  moment.locale(lang);

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <ToastProvider components={toastComponents} autoDismiss>
        <Router history={history}>
          <CssColors />
          <ErrorHandler>
            <SupportCheck>
              <div className="app">
                <SwitchWith404>
                  <PrivateRoute exact path={routes.HOME} component={HomePage} isLayout={false} />
                  <Redirect exact from="/login" to={routes.HOME} />
                  {/* SUPER ADMIN DASHBOARD */}
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_COMPANY_DASHBOARD.url}
                    layout={MainLayout}
                    component={SuperAdminCompanies}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_TEMPLATE_REVIEW_CREATE.url}
                    layout={MainLayout}
                    component={ReviewTemplateSetup}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_TEMPLATE_REVIEW_UPDATE.url({})}
                    layout={MainLayout}
                    component={ReviewTemplateSetup}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_TEMPLATES_REVIEW.url}
                    layout={MainLayout}
                    component={SuperAdminTemplateReview}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_COMPANY_MEMBERS.url}
                    layout={MainLayout}
                    component={SuperAdminCompanyMembers}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_OVERVIEW.url}
                    layout={MainLayout}
                    component={SuperAdminOverview}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_REQUESTS.url}
                    layout={MainLayout}
                    component={SuperAdminRequests}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_SKILL_MATRIX.url}
                    layout={MainLayout}
                    component={SuperAdminSkillMatrix}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_SKILL_TEMPLATE_CREATE.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminCreateSkillTemplate}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_SKILL_TEMPLATE_EDIT.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminSkillTemplateEdit}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_SKILL_TEMPLATE_VIEW.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminSkillTemplateView}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_INDUSTRIES.url}
                    layout={MainLayout}
                    component={SuperAdminIndustries}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_JOB_LIBRARY.url}
                    layout={MainLayout}
                    component={SuperAdminJobLibrary}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_JOB_TEMPLATE_CREATE.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminCreateJobTemplate}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_JOB_TEMPLATE_EDIT.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminEditJobTemplate}
                  />
                  <PrivateRoute
                    exact
                    path={routes.SUPERADMIN_JOB_TEMPLATE_VIEW.routePath()}
                    layout={FocusLayout}
                    component={SuperAdminViewJobTemplate}
                  />
                  {/* Auth pages */}
                  {/* PUBLIC ROUTES*/}
                  <OpenRoute
                    exact
                    path={routes.LOGOUT}
                    component={LogoutPage}
                    layout={PublicLayout}
                    layoutImgStyle={{ height: 52 }}
                  />
                  <OpenRoute exact path={routes.NO_ACCOUNT} component={NoAccountPage} />
                  <OpenRoute exact path={routes.TOO_MANY_REQUESTS} component={TooManyRequests} />
                  <OpenRoute exact path={routes.DISABLED_ACCOUNT} component={DisabledAccountPage} />
                  <OpenRoute exact path={routes.REGISTER} component={RegisterWithCompanyPage} />
                  <OpenRoute
                    exact
                    path={routes.COMPANY_CREATE_SUCCESS}
                    layout={LoginLayout}
                    component={CongratulationsPageCompany}
                  />
                  <OpenRoute path={routes.NOT_FOUND} exact component={NotFound} />
                  <OpenRoute path={routes.ERROR} exact component={CommonError} />
                  <OpenRoute path={routes.MAINTENANCE_MODE} exact component={MaintenanceMode} />
                  <OpenRoute
                    path={routes.PERMISSION_DENIED}
                    exact
                    component={MyPermissionDeniedPage}
                  />
                  {/* Redirects*/}
                  {redirects()}
                  {/* PRIVATE MODULES */}
                  <PrivateRoute
                    path="/company/:companyId"
                    component={CompanyModules}
                    isLayout={false}
                  />
                  {/* PUBLIC MODULES */}
                  <Route
                    path={['/user-public', '/conversations', '/feedbacks', '/reviews']}
                    component={PublicModules}
                  />
                </SwitchWith404>
                {process.env.NODE_ENV === 'development' ? <DevTools /> : null}
                {<Intercom />}
                {<IntercomUpscope />}
                {process.env.REACT_APP_PROJECT_ID === 'learned-production' && <GoogleTagManager />}
                {process.env.REACT_APP_PROJECT_ID === 'learned-production' && <GoogleAnalytics />}
                {process.env.REACT_APP_PROJECT_ID === 'learned-production' && <FacebookPixel />}
              </div>
            </SupportCheck>
          </ErrorHandler>
        </Router>
      </ToastProvider>
    </I18nProvider>
  );
}

const mapStateToProps = (state) => ({
  lang: state.locale.lang,
  user: state.auth.user,
});

export default connect(mapStateToProps)(App);
