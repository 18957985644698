import React, { useEffect, useState } from 'react';

import { CONFIRMATION_MODAL_TYPE, ROLES } from '@learned/constants';
import { IReviewTemplateOld } from '@learned/types';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { ICONS } from '~/components/Icon';
import { confirm } from '~/components/Modals/ConfirmationModal/confirm';
import { TableList } from '~/components/TableList';
import { useToasts, TOAST_TYPES } from '~/components/Toast';
import type { IFilterType } from '~/pages/OnboardAndLearn/tabs/AllLearningsTab/types';

import { COLUMNS, SORT_OPTIONS } from './columns';

import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { LS_KEYS, useLocalStorage } from '~/hooks/useLocalStorage';
import { getUser } from '~/selectors/baseGetters';
import {
  deleteMultipleTemplates as deleteMultipleConversationTemplates,
  getTemplates as getConversationTemplates,
} from '~/services/conversationTemplates';

import type { IReviewOld } from '@learned/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 8px 8px 0 rgba(145, 157, 165, 0.12);
`;

const PAGE_SIZE = 10;
const DEFAULT_PAGINATION = { skip: 0, limit: PAGE_SIZE, index: 1 };
const LS_KEY = LS_KEYS.LS_MEETINGS_TEMPLATES;

const initialFilters = {
  search: '',
  sortBy: SORT_OPTIONS.NAME_A_Z,
  pagination: DEFAULT_PAGINATION,
};

const TemplatesTab = () => {
  const { i18n } = useLingui();
  const history = useHistory();
  const user = useSelector(getUser);
  const { addToast } = useToasts();
  const [items, setItems] = useState<IReviewOld[]>([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentFilters, setCurrentFilters] = useLocalStorage(LS_KEY, initialFilters);
  const [isLoading, setIsLoading] = useState(false);
  const debCurrentFilters = useDebounce(currentFilters, 300);

  // redirect if no permission
  useEffect(() => {
    if (!user.isAdmin) {
      history.push(routes.HOME);
    }

    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    setIsLoading(true);

    const {
      data: { companyTemplates: items, total },
    } = await getConversationTemplates({
      search: currentFilters.search || null,

      // options
      skip: currentFilters.pagination.skip,
      limit: currentFilters.pagination.limit,
      order: currentFilters.sortBy,
    });

    setItems(Object.values(items));
    setTotalCount(total);
    setIsLoading(false);
  };

  const forceFetch = fetchData;

  // change filters fetch
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, [JSON.stringify(debCurrentFilters)]);

  const actionButton = {
    label: t`Create template`,
    onClick: () => {
      history.push(
        routes.CONVERSATION_TEMPLATE_CREATE.build(
          {
            role: ROLES.ADMIN,
            companyId: undefined,
            teamId: undefined,
          },
          {
            isBackPath: true,
          },
        ),
      );
    },
  };

  const filters = {
    search: currentFilters.search,
    setSearch: (value: string) =>
      setCurrentFilters((prevState: IFilterType) => ({
        ...prevState,
        search: value,
        pagination: DEFAULT_PAGINATION, // reset pagination
      })),

    // @ts-ignore
    onChangeFilter: (key, value) =>
      setCurrentFilters({ ...currentFilters, pagination: DEFAULT_PAGINATION, [key]: value }),
    resetFilters: () => setCurrentFilters(initialFilters),
  };

  const onItemClick = {
    column: 'name',
    onClick: (item: any) => {
      const path = routes.CONVERSATION_TEMPLATE_UPDATE.build(
        { companyId: undefined, teamId: undefined, role: ROLES.ADMIN },
        // @ts-ignore
        { templateId: item.id, isBackPath: true },
      );
      history.push(path as string);
    },
  };

  const onDelete = async (ids: string[]) => {
    const isConfirmed = await confirm({
      type: CONFIRMATION_MODAL_TYPE.DELETE,
      title: i18n._(t`Are you sure want to delete template(s)`),
      description: i18n._(t`Deleting template(s) cannot be undone!`),
    });
    if (isConfirmed) {
      await deleteMultipleConversationTemplates(ids);

      // confirm toast
      addToast({
        title: i18n._(t`Template deleted!`),
        subtitle: i18n._(t`Your template has been deleted from your company templates`),
        type: TOAST_TYPES.INFO,
      });

      // re-fetch items
      await forceFetch();

      // uncheck selectedItems
      if (selectedItems.length > 0) {
        setSelectedItems([]);
      }
    }
  };

  const createMenuItems = (item: IReviewTemplateOld) => {
    return [
      {
        label: i18n._(t`Delete`),
        action: () => onDelete([item.id]),
        icon: ICONS.DELETE_BIN,
        isWarning: true,
      },
    ];
  };

  const onSelectItem = (selectedItemId: string) => {
    const isSelected = selectedItems.includes(selectedItemId);
    setSelectedItems(
      isSelected
        ? selectedItems.filter((id) => id !== selectedItemId)
        : [...selectedItems, selectedItemId],
    );
  };

  const isAllSelected = items.every((item) => {
    return selectedItems.includes(item.id);
  });

  const onSelectAll = () => {
    const itemsToSelect = isAllSelected ? [] : items.map((item) => item.id);
    setSelectedItems(itemsToSelect);
  };

  const multiSelect = {
    checkedCount: selectedItems.length,
    onCheckAll: onSelectAll,
    onSelectItem: (item: IReviewTemplateOld) => onSelectItem(item.id),
    isItemChecked: (item: IReviewTemplateOld) => selectedItems.includes(item.id),
    isAllChecked: isAllSelected,
    onDelete: () => onDelete(selectedItems),
  };

  return (
    <Wrapper>
      <TableList
        data={items}
        columns={COLUMNS}
        onColClick={onItemClick}
        sortProps={{
          sortBy: currentFilters.sortBy,
          setSortBy: (sortBy: SORT_OPTIONS) => setCurrentFilters({ ...currentFilters, sortBy }),
        }}
        menuProps={{
          createMenuItems,
          isMenuVisible: true,
        }}
        multiSelectProps={{
          isMultiSelectVisible: true,
          multiSelect,
        }}
        paginationProps={{
          pagination: currentFilters.pagination,
          changePagination: ({ skip, limit, index }) =>
            setCurrentFilters({
              ...currentFilters,
              pagination: { ...currentFilters.pagination, skip, limit, index },
            }),
          totalCount,
        }}
        isLoading={isLoading}
        placeholderProps={{
          noResultText: i18n._(t`No templates found`),
          emptyStateText: i18n._(t`No templates yet… Let’s create one!`),
        }}
        actionButton={actionButton}
        filtersProps={{
          filters,
          isFiltered: !!currentFilters.search.length,
          resetFilters: filters.resetFilters,
        }}
      />
    </Wrapper>
  );
};

export { TemplatesTab };
