import React from 'react';

import styled from 'styled-components';

import { COLORS } from '~/styles';

const Wrapper = styled.div`
  padding-top: 24px;

  &:first-child {
    padding-top: 0;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 14px;
  color: ${COLORS.SUBTEXT};
  padding: 0 0 0 36px;
`;

const Counter = styled.div`
  color: #c2c9dd; // TODO replace with variable
  margin-left: 5px;
`;

const ChildrenWrapper = styled.div`
  margin-top: 6px;
`;

interface SectionProps {
  title: string;
  counter?: number;
  isCounter?: boolean;
  children?: React.ReactNode;
}

const Block = ({ title, isCounter = false, counter, children }: SectionProps) => {
  return (
    <Wrapper>
      <TitleRow>
        <Title>{title}</Title>
        {isCounter && <Counter>{`(${counter})`}</Counter>}
      </TitleRow>
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </Wrapper>
  );
};

export { Block };
