import axios from 'axios';

import { SERVER_URI } from '~/constants/env';

/**
 * @typedef {{method: 'post'|'get'|'patch'|'put'|'delete', route: string, isErrorInToast?: boolean}} route
 * */

/**
 * @typedef {(...args) => route} functionRoute
 */

/**
 * @type {Object.<string, Object.<string, (route|functionRoute)>>}
 *  */
export const serverRoutes = {
  users: {
    getCompanyUsers: { method: 'post', route: 'getCompanyUsers' },
    switchUserDisabledSuperAdmin: { method: 'post', route: '/switchUserDisabledSuperAdmin' },
    removeUserFromCompanySuperAdmin: { method: 'post', route: '/removeUserFromCompanySuperAdmin' },
    getUsers: { method: 'get', route: '/getUsers' },
    getUserData: { method: 'get', route: '/getUserData' },
    getUserAvatar: { method: 'get', route: '/getUserAvatar' },
    getUserLocale: { method: 'get', route: '/getUserLocale' },
    updateUserProfile: { method: 'post', route: '/updateUserProfile', isErrorInToast: true },
    updateMembersProfiles: { method: 'patch', route: '/users', isErrorInToast: true },
    updateUserAvatar: { method: 'post', route: '/updateUserAvatar' },
    deleteUserAvatar: (userId) => ({
      method: 'delete',
      route: `/users/${userId}/avatar`,
    }),
    login: { method: 'post', route: '/login' },
    logout: { method: 'post', route: '/logout' },
    updateAdminStatus: { method: 'post', route: '/updateAdminStatus' },
    addSkillsToUser: { method: 'post', route: '/addSkillsToUser' },
    removeSkillsFromUser: { method: 'post', route: '/removeSkillsFromUser' },
    removeUserFromCompany: { method: 'post', route: '/removeUserFromCompany' },
    getUserConnection: { method: 'get', route: '/getUserConnection' },
    switchUserDisabled: { method: 'post', route: '/switchUserDisabled' },
    createUsersFromIntegration: { method: 'post', route: '/createUsersFromIntegration' },
    connectUserToIntegration: (userId, integrationId) => ({
      method: 'put',
      route: `/users/${userId}/integrations/${integrationId}`,
    }),
    disconnectUserFromIntegration: (userId) => ({
      method: 'delete',
      route: `/users/${userId}/integrations`,
    }),
    updateMFA: { method: 'post', route: '/updateMFA' },
    resetMFA: (userId) => ({
      method: 'delete',
      route: `/users/${userId}/mfa-reset`,
      isErrorInToast: true,
    }),
    updateSSOMulti: { method: 'put', route: '/updateSSOMulti' },
    deleteUsers: { method: 'delete', route: '/users' },
    changeOwnership: { method: 'put', route: '/users/roles/owner' },
    resendWelcomeApiEmail: (userId) => ({ method: 'get', route: `/users/${userId}/welcome-email` }),
    resendWelcomeApiEmailSuperAdmin: (userId, companyId) => ({
      method: 'get',
      route: `/super-admin/companies/${companyId}/users/${userId}/welcome-email`,
    }),
    getUsersSuperAdmin: (companyId) => ({
      method: 'get',
      route: `/super-admin/companies/${companyId}/users`,
    }),
    getSelectedUsersSuperAdmin: (companyId) => ({
      method: 'post',
      route: `/super-admin/companies/${companyId}/users`,
    }),
    addSuperAdminAsCompanyAdmin: (companyId) => ({
      method: 'post',
      route: `/super-admin/companies/${companyId}/admins`,
    }),
    downloadUsersCSVSuperAdmin: {
      method: 'get',
      route: '/super-admin/csv/users',
      responseType: 'blob',
    },
    findUserSuperAdmin: { method: 'get', route: '/super-admin/user' },
    downloadMembersCSV: {
      method: 'get',
      route: '/members/csv',
      responseType: 'blob',
    },
    getFilteredUsersTotalCount: { method: 'get', route: '/users/count' },
    getUserSkillsOrder: (userId) => ({
      method: 'get',
      route: `/users/${userId}/skills-order`,
    }),
    updateUserSkillsOrder: (userId) => ({
      method: 'patch',
      route: `/users/${userId}/skills-order`,
      isErrorInToast: true,
    }),
    getUserById: (userId) => ({
      method: 'get',
      route: `/users/${userId}`,
      isErrorInToast: true,
    }),
    updateTrialEndedProductsMessages: {
      method: 'patch',
      route: 'users/trial-ended-products',
    },
    updatePreferredLanguage: {
      method: 'patch',
      route: 'users/preferred-language',
    },
    updateNotificationSettings: {
      method: 'patch',
      route: 'users/notification-settings',
    },
    updatePrimaryRole: {
      method: 'patch',
      route: 'users/primary-role',
    },
  },
  companies: {
    getCompanySuperAdmin: { method: 'get', route: '/getCompanySuperAdmin' },
    getCompaniesSuperAdmin: { method: 'get', route: '/getCompaniesSuperAdmin' },
    downloadCompaniesCSVSuperAdmin: {
      method: 'get',
      route: '/downloadCompaniesCSVSuperAdmin',
      responseType: 'blob',
    },
    downloadCompanyModulesCSVSuperAdmin: {
      method: 'get',
      route: '/companies-csv/modules',
      responseType: 'blob',
    },
    setCompanyEnabledSuperAdmin: { method: 'put', route: '/setCompanyEnabledSuperAdmin' },
    getCompany: { method: 'get', route: '/getCompany' },
    getCompanies: { method: 'get', route: '/getCompanies' },
    getUserCompanies: { method: 'post', route: 'user-companies', isErrorInToast: true },
    getCompanyActiveConnections: { method: 'get', route: '/getCompanyActiveConnections' },
    updateCompany: { method: 'patch', route: '/updateCompany' },
    updateCompanyLogo: { method: 'patch', route: '/updateCompanyLogo' },
    removeCompanyLogo: { method: 'patch', route: '/removeCompanyLogo' },
    createCompany: { method: 'post', route: '/createCompany', isErrorInToast: true },
    switchCompanyModule: { method: 'patch', route: '/switchCompanyModule' },
    deleteCompanyAllData: { method: 'delete', route: '/deleteCompanyAllData' },
    updateForceMFA: { method: 'post', route: '/updateForceMFA', isErrorInToast: true },
    updateDefaultSSOMethod: {
      method: 'put',
      route: '/updateDefaultSSOMethod',
      isErrorInToast: true,
    },
    getCompanyOutsideOld: { method: 'get', route: '/getCompanyOutside' },
    getCompanyOutside: (companyId) => ({
      method: 'get',
      route: `getCompanyOutside?companyId=${companyId}`,
      isErrorInToast: true,
    }),
    addCompanyLanguages: (companyId) => ({
      method: 'patch',
      route: `/companies/${companyId}/languages`,
    }),
    removeCompanyLanguage: (companyId) => ({
      method: 'delete',
      route: `/companies/${companyId}/languages`,
    }),
    updateCompanyPrimaryLanguage: (companyId) => ({
      method: 'patch',
      route: `/companies/${companyId}/primaryLang`,
      isErrorInToast: true,
    }),
    productsUpgrade: (companyId) => ({
      method: 'post',
      route: `companies/${companyId}/products/upgrade`,
      isErrorInToast: true,
    }),
    productsUpdate: (companyId) => ({
      method: 'patch',
      route: `companies/${companyId}/products`,
      isErrorInToast: true,
    }),
    updateAddons: (companyId) => ({
      method: 'patch',
      route: `/companies/${companyId}/addons`,
    }),
    updateCompanyLogoEmail: (companyId) => ({
      method: 'patch',
      route: `/companies/${companyId}/logoEmailUrl`,
      isErrorInToast: true,
    }),
    updateSuperAdminProducts: (companyId) => ({
      method: 'patch',
      route: `/companies/${companyId}/products/superAdmin`,
    }),
  },
  comments: {
    getComment: { method: 'get', route: '/getComment' },
    createComment: { method: 'post', route: '/createComment' },
    deleteComment: { method: 'delete', route: '/deleteComment' },
    updateComment: { method: 'patch', route: '/updateComment' },
  },
  goals: {
    getGoals: { method: 'post', route: '/goals', isErrorInToast: true },
    getGoal: (id) => ({ method: 'get', route: `/goals/${id}`, isErrorInToast: true }),
    getUserGoalsBySkills: { method: 'get', route: '/getUserGoalsBySkills', isErrorInToast: true },
    getGoalsByCycles: { method: 'post', route: '/getGoalsByCycles', isErrorInToast: true },
    getGoalForReview: (id, reviewId) => ({
      method: 'get',
      route: `/goals/${id}/reviews/${reviewId}`,
      isErrorInToast: true,
    }),
    createGoal: { method: 'put', route: '/goals', isErrorInToast: true },
    updateGoal: (id) => ({ method: 'patch', route: `/goals/${id}`, isErrorInToast: true }),
    updateGoalPublished: (id) => ({
      method: 'patch',
      route: `/goals-published/${id}`,
      isErrorInToast: true,
    }),
    updateGoalProgress: (id) => ({
      method: 'patch',
      route: `/goals-progress/${id}`,
      isErrorInToast: true,
    }),
    deleteGoal: (id) => ({ method: 'delete', route: `/goals/${id}`, isErrorInToast: true }),
    downloadGoalCSV: {
      method: 'post',
      route: '/downloadGoalCSV',
      responseType: 'blob',
      isErrorInToast: true,
    },
    getGoalsForReviewImport: {
      method: 'get',
      route: '/getGoalsForReviewImport',
      isErrorInToast: true,
    },
    updateGoalCycles: (id) => ({
      method: 'patch',
      route: `/goals/${id}/goalCycles`,
      isErrorInToast: true,
    }),
    addGoalCycles: (id) => ({
      method: 'patch',
      route: `/goals/${id}/goalCycles/add`,
      isErrorInToast: true,
    }),
    getAvailableParentGoals: (id) => ({
      method: 'post',
      route: `/goals/${id}/alignment/available`,
      isErrorInToast: true,
    }),
    duplicateGoal: (id) => ({
      method: 'post',
      route: `/goals/${id}/duplicate`,
      isErrorInToast: true,
    }),
    copyGoal: (id) => ({ method: 'post', route: `/goals/${id}/copy`, isErrorInToast: true }),
    getGoalSuggestions: { method: 'post', route: '/goals/suggestions', isErrorInToast: true },
    archiveGoal: (id) => ({
      method: 'patch',
      route: `/goals/${id}/archive`,
      isErrorInToast: true,
    }),
    unarchiveGoal: (id) => ({
      method: 'patch',
      route: `/goals/${id}/unarchive`,
      isErrorInToast: true,
    }),
  },
  userActivities: {
    getUserActivities: { method: 'get', route: '/user-activities' },
    getPersonalUserActivities: {
      method: 'get',
      route: '/user-activities/personal',
      isErrorInToast: true,
    },
    getUserActivity: (id) => ({ method: 'get', route: `/user-activities/${id}` }),
    getUserActivityByParentId: (id) => ({ method: 'get', route: `/user-activities/parent/${id}` }),
    getSubLibraryActivities: (libraryActivityId) => ({
      method: 'get',
      route: `/activities-library/${libraryActivityId}/user-activities`,
    }),
    createUserActivityFromLibrary: (libraryActivityId) => ({
      method: 'post',
      route: `/activities-library/${libraryActivityId}/user-activities`,
    }),
    deleteUserActivity: (userActivityId) => ({
      method: 'delete',
      route: `/user-activities/${userActivityId}`,
    }),
    updateUserActivityProgress: (userActivityId) => ({
      method: 'patch',
      route: `/user-activities/${userActivityId}/progress`,
    }),
    removeUserActivityAttachment: (userActivityId) => ({
      method: 'patch',
      route: `/user-activities/${userActivityId}/attachment`,
      isErrorInToast: true,
    }),
    removeUserActivityGoals: (userActivityId) => ({
      method: 'patch',
      route: `/user-activities/${userActivityId}/goals`,
      isErrorInToast: true,
    }),
    removeUserActivityGoal: (userActivityId) => ({
      method: 'patch',
      route: `/user-activities/${userActivityId}/goal`,
      isErrorInToast: true,
    }),
  },
  activities: {
    getActivities: { method: 'get', route: '/activities' },
    getActivity: (id) => ({ method: 'get', route: `/activities/${id}`, isErrorInToast: true }),
    getFilteredActivities: {
      method: 'post',
      route: '/activities/filtered',
      isErrorInToast: true,
    },
    getLibraryItems: { method: 'post', route: '/library-items', isErrorInToast: true },
    getPersonalLibraryItems: {
      method: 'post',
      route: '/library-items-personal',
      isErrorInToast: true,
    },
    deleteLibraryItems: { method: 'post', route: '/library-items-delete', isErrorInToast: true },
    getUserLibraryActivitiesWithFilter: {
      method: 'post',
      route: '/activities-library-user-filter',
    }, // new
    getCompanyActivities: { method: 'post', route: '/activities-company' }, // new
    getUserLibraryActivities: {
      method: 'post',
      route: '/activities-library-user',
    },
    createOriginalLibraryActivity: {
      method: 'post',
      route: '/activities-library',
      isErrorInToast: true,
    },
    updateOriginalLibraryActivity: (id) => ({
      method: 'patch',
      route: `/activities-library/${id}`,
      isErrorInToast: true,
    }),
    removeActivityAttachment: (id) => ({
      method: 'patch',
      route: `activities/${id}/attachment`,
      isErrorInToast: true,
    }),
    deleteOriginalLibraryActivities: {
      method: 'delete',
      route: '/activities-library',
      isErrorInToast: true,
    },
    copyOriginalActivity: (id) => ({
      method: 'post',
      route: `activities-library/${id}/duplicate`,
      isErrorInToast: true,
    }),
    addActivityOwners: (id) => ({ method: 'patch', route: `/activities/${id}/owners` }),
    addActivityUsers: (id) => ({ method: 'patch', route: `/activities/${id}/users` }),
    deleteActivityUsers: (id) => ({ method: 'delete', route: `/activities/${id}/users` }),
    deleteActivityOwners: (id) => ({ method: 'delete', route: `/activities/${id}/owners` }),
    createOriginalCustomActivity: {
      method: 'post',
      route: '/activities-custom',
      isErrorInToast: true,
    },
    getLearningKPIs: {
      method: 'post',
      route: '/activities-kpis',
    },
    getLearningReportMembers: {
      method: 'post',
      route: '/library-items-report/members',
      isErrorInToast: true,
    },
    downloadLearningReportMembersCSV: {
      method: 'post',
      route: '/library-items-report/members-csv',
      responseType: 'blob',
      isErrorInToast: true,
    },
    getLearningReportActivities: {
      method: 'post',
      route: '/library-items-report/activities',
      isErrorInToast: true,
    },
    downloadLearningReportActivitiesCSV: {
      method: 'post',
      route: '/library-items-report/activities-csv',
      responseType: 'blob',
      isErrorInToast: true,
    },
    getLearningReportTeams: {
      method: 'post',
      route: '/library-items-report/teams',
      isErrorInToast: true,
    },
    downloadLearningReportTeamsCSV: {
      method: 'post',
      route: '/library-items-report/teams-csv',
      responseType: 'blob',
      isErrorInToast: true,
    },
    getLearningReportPaths: {
      method: 'post',
      route: '/library-items-report/paths',
      isErrorInToast: true,
    },
    downloadLearningReportPathsCSV: {
      method: 'post',
      route: '/library-items-report/paths-csv',
      responseType: 'blob',
      isErrorInToast: true,
    },
  },
  conversations: {
    createConversation: { method: 'post', route: '/conversations', isErrorInToast: true },
    updateConversation: (conversationId) => ({
      method: 'patch',
      route: `/conversations/${conversationId}`,
      isErrorInToast: true,
    }),
    updateConversationPublished: (conversationId) => ({
      method: 'patch',
      route: `/conversations/${conversationId}/published`,
      isErrorInToast: true,
    }),
    updateConversationParticipants: (conversationId) => ({
      method: 'patch',
      route: `/conversations/${conversationId}/participants`,
      isErrorInToast: true,
    }),
    updateConversationCreatedBy: (conversationId) => ({
      method: 'patch',
      route: `/conversations/${conversationId}/createdBy`,
      isErrorInToast: true,
    }),
    getConversation: (conversationId) => ({
      method: 'get',
      route: `/conversations/${conversationId}`,
    }),
    getConversationsFilter: { method: 'get', route: '/conversations-filter' },
    getConversations: { method: 'get', route: '/conversations' },
    deleteConversation: (conversationId) => ({
      method: 'delete',
      route: `/conversations/${conversationId}`,
    }),
    deleteConversationFutureEvents: (conversationId) => ({
      method: 'delete',
      route: `/conversations/${conversationId}/future-user-conversations`,
    }),
  },
  userConversations: {
    updateUserConversationStatus: {
      method: 'patch',
      route: '/updateUserConversationStatus',
    },
    getUserConversation: { method: 'get', route: '/getUserConversation' },
    getUserConversations: { method: 'get', route: '/getUserConversations' },
    getUserConversationsForGraph: { method: 'get', route: '/getUserConversationsForGraph' },
    getUserConversationsDataPerTeam: { method: 'get', route: '/getUserConversationsDataPerTeam' },
    getUsersConversationsByCoach: { method: 'get', route: '/getUsersConversationsByCoach' },
    getConversationsAndReviews: { method: 'get', route: '/getConversationsAndReviews' },
    getUserPublicConversations: { method: 'get', route: '/getUserPublicConversations' },
    getUserConversationsByTalkinPointTarget: {
      method: 'get',
      route: '/getUserConversationsByTalkinPointTarget',
    },
    deleteUserConversation: { method: 'delete', route: '/deleteUserConversation' },
    updateUserTalkingPointsCheck: {
      method: 'patch',
      route: '/updateUserTalkingPointsCheck',
    },
    updateUserConversationTPOrder: {
      method: 'patch',
      route: '/updateUserConversationTPOrder',
    },
    uploadConversationAttachment: {
      method: 'post',
      route: '/uploadConversationAttachment',
      isErrorInToast: true,
    },
    deleteConversationAttachment: {
      method: 'delete',
      route: '/deleteConversationAttachment',
      isErrorInToast: true,
    },
    downloadAdminConversationReportCSV: {
      method: 'get',
      route: '/downloadAdminConversationReportCSV',
      responseType: 'blob',
    },
    downloadCalendarICS: {
      method: 'get',
      route: '/downloadCalendarICS',
      responseType: 'blob',
    },
    updateUserConversationDate: {
      method: 'patch',
      route: '/updateUserConversationDate',
      isErrorInToast: true,
    },
  },
  talkingPoints: {
    getTalkingPoint: { method: 'get', route: '/getTalkingPoint' },
    getTalkingPoints: { method: 'get', route: '/getTalkingPoints' },
    deleteTalkingPoint: { method: 'delete', route: '/deleteTalkingPoint' },
    deleteTalkingPointByTargetAndConversation: {
      method: 'delete',
      route: '/deleteTalkingPointByTargetAndConversation',
    },
    createTalkingPoints: { method: 'post', route: '/createTalkingPoints' },
    copyTalkingPoint: (talkingPointId, conversationId) => ({
      method: 'post',
      route: `/talking-points/${talkingPointId}/copy-to/${conversationId}`,
      isErrorInToast: true,
    }),
  },
  events: {
    createEvent: { method: 'put', route: '/createEvent' },
  },
  activityCategories: {
    getCategories: { method: 'post', route: '/activity-categories', isErrorInToast: true },
    getCategory: (categoryId) => ({
      method: 'get',
      route: `/activity-categories/${categoryId}`,
      isErrorInToast: true,
    }),
    createCategory: { method: 'put', route: '/activity-categories', isErrorInToast: true },
    deleteCategory: (categoryId) => ({
      method: 'delete',
      route: `/activity-categories/${categoryId}`,
      isErrorInToast: true,
    }),
    updateCategory: (categoryId) => ({
      method: 'post',
      route: `/activity-categories/${categoryId}`,
      isErrorInToast: true,
    }),
    manageCategories: {
      method: 'post',
      route: '/activity-categories/manage',
      isErrorInToast: true,
    },
  },
  skills: {
    getSkill: { method: 'get', route: '/getSkill' },
    createSkill: { method: 'post', route: '/createSkill' },
    updateSkill: { method: 'post', route: '/updateSkill' },
    deleteSkills: { method: 'delete', route: '/deleteSkills' },
    getSkills: { method: 'get', route: '/getSkills' },
    getSkillsById: { method: 'get', route: '/getSkillsById' },
    getSkillsReport: { method: 'get', route: '/skills-report' },
    getSkillReport: (skillId) => ({
      method: 'get',
      route: `/skills-report/${skillId}`,
    }),
    downloadSkillReportCSV: {
      method: 'get',
      route: '/downloadSkillReportCSV',
      responseType: 'blob',
    },
    downloadSkillsReportCSV: {
      method: 'get',
      route: '/downloadSkillsReportCSV',
      responseType: 'blob',
    },
    downloadSkillsCSV: { method: 'post', route: '/skills/csv', responseType: 'blob' },
    generateSkill: { method: 'post', route: '/skills/auto-complete', isErrorInToast: true },
  },
  ratings: {
    getUserRatings: { method: 'get', route: '/getUserRatings' },
    getUserRatingsByCompany: { method: 'get', route: '/getUserRatingsByCompany' },
    setRatings: { method: 'post', route: '/setRatings', isErrorInToast: true },
    getSkillsRatings: { method: 'get', route: '/getSkillsRatings' },
    getSkillRatings: { method: 'get', route: '/ratings/skill' },
    getSkillCoverage: { method: 'get', route: '/getSkillCoverage' },
    getUserReviewRatings: (userId, reviewId) => ({
      method: 'get',
      route: `/ratings/reviews/${reviewId}/user/${userId}`,
    }),
  },
  reviews: {
    // reviews-3.0
    items: { method: 'post', route: '/reviews', isErrorInToast: true },
    createReview: { method: 'put', route: '/reviews', isErrorInToast: true },
    fetchReviewById: (reviewId) => ({
      method: 'post',
      route: `reviews/${reviewId}`,
      isErrorInToast: true,
    }),
    updateReviewById: (reviewId) => ({
      method: 'patch',
      route: `reviews/${reviewId}`,
      isErrorInToast: true,
    }),
    deleteById: (reviewId) => ({
      method: 'delete',
      route: `reviews/${reviewId}`,
      isErrorInToast: true,
    }),
    archiveById: (reviewId) => ({
      method: 'patch',
      route: `reviews/${reviewId}/archive`,
      isErrorInToast: true,
    }),
    unarchiveById: (reviewId) => ({
      method: 'patch',
      route: `reviews/${reviewId}/unarchive`,
      isErrorInToast: true,
    }),
    getReviewLogs: (reviewId) => ({
      method: 'post',
      route: `reviews/${reviewId}/logs`,
      isErrorInToast: true,
    }),
    getReviewParticipationTotal: (reviewId) => ({
      method: 'get',
      route: `reviews/${reviewId}/participation/total`,
      isErrorInToast: true,
    }),
    getReviewParticipationPerTeam: (reviewId) => ({
      method: 'post',
      route: `reviews/${reviewId}/participation/per-team`,
      isErrorInToast: true,
    }),
    shareCalibrationInput: (reviewId) => ({
      method: 'patch',
      route: `reviews/${reviewId}/calibration/share`,
      isErrorInToast: true,
    }),
    /**
     * @deprecated since version 3.0 (going to delete in the end)
     * still in use, no replacement yet
     */
    getReviews: { method: 'get', route: '/getReviews' },
    getUserReviews: { method: 'get', route: '/getUserReviews' },
    getReview: { method: 'get', route: '/getReview', handledErrors: [404] },
    getUserReviewForConversations: { method: 'get', route: '/getUserReviewForConversations' },
    getFilteredReview: { method: 'post', route: '/getReview', handledErrors: [404] },
    updateReview: { method: 'patch', route: '/updateReview', isErrorInToast: true },
    updatePublishedReview: { method: 'patch', route: '/updatePublishedReview' },
    deleteReview: { method: 'delete', route: '/deleteReview' },
    setSelfReviewStatus: {
      method: 'patch',
      route: '/setSelfReviewStatus',
      isErrorInToast: true,
    },
    nominatePeopleForReview: {
      method: 'post',
      route: '/nominatePeopleForReview',
      isErrorInToast: true,
    },
    addUsersToReview: { method: 'post', route: '/addUsersToReview', isErrorInToast: true },
    deleteUsersFromReview: {
      method: 'post',
      route: '/deleteUsersFromReview',
      isErrorInToast: true,
    },
    lockReviewStage: { method: 'post', route: '/lockReviewStage' },
    prepareUserReview: { method: 'post', route: '/prepareUserReview' },
    downloadReviewCSV: {
      method: 'get',
      route: '/downloadReviewCSV',
      responseType: 'blob',
    },
    downloadUserReviewPDFReport: {
      method: 'get',
      route: '/downloadUserReviewPDFReport',
      responseType: 'blob',
    },
    getReviewsForDevelopmentCycle: {
      method: 'get',
      route: '/reviews-report/development-cycle',
      isErrorInToast: true,
    },
    sendReviewConversationReminder: (reviewId) => ({
      method: 'post',
      route: `/reviews/${reviewId}/reminders/date-of-conversation`,
      isErrorInToast: true,
    }),
    sendDigitalSignReminder: {
      method: 'post',
      route: '/sendReviewDigitalSignReminder',
    },
    getNineGridReviews: {
      method: 'get',
      route: '/getNineGridReviews',
    },
    getStatistics: (reviewId) => ({ method: 'post', route: `/reviews/${reviewId}/statistics` }),
    getTeamsStatistics: (reviewId) => ({
      method: 'post',
      route: `/reviews/${reviewId}/statistics/teams`,
    }),
    getTeamsInReview: (reviewId) => ({ method: 'get', route: `/reviews/${reviewId}/teams` }),
    getReviewsReport: { method: 'get', route: '/reviews/report' },
    getReviewsFilter: { method: 'get', route: '/reviews/filter' },
    downloadNinegridCSV: {
      method: 'get',
      route: '/reviews/downloadNinegridCSV',
      responseType: 'blob',
    },
    updateGoalCycleForUserReview: {
      method: 'get',
      route: '/updateGoalCycleForUserReview',
    },
    setDateOfConversation: (reviewId) => ({
      method: 'patch',
      route: `/reviews/${reviewId}/setDateOfConversation`,
      isErrorInToast: true,
    }),
    updateReviewConversationCoaches: {
      method: 'patch',
      route: '/reviews/updateReviewConversationCoaches',
    },
    addSignature: (reviewId) => ({
      method: 'post',
      route: `/reviews/${reviewId}/signatures`,
      isErrorInToast: true,
    }),
    deleteSignatures: (reviewId) => ({
      method: 'delete',
      route: `/reviews/${reviewId}/signatures`,
      isErrorInToast: true,
    }),
    uploadReviewAttachment: (reviewId) => ({
      method: 'post',
      route: `/reviews/${reviewId}/attachments`,
      isErrorInToast: true,
    }),
    deleteReviewAttachment: (reviewId) => ({
      method: 'delete',
      route: `/reviews/${reviewId}/attachments`,
      isErrorInToast: true,
    }),
    shareCoachesCalibratedReviews: (reviewId) => ({
      method: 'put',
      route: `/reviews/${reviewId}/calibrated/share`,
    }),
    getNextScheduledConversation: {
      method: 'get',
      route: 'reviews/next',
    },
  },
  reviewThemes: {
    getThemes: { method: 'post', route: 'review-themes', isErrorInToast: true },
    getTheme: (reviewThemeId) => ({
      method: 'post',
      route: `review-themes/${reviewThemeId}`,
      isErrorInToast: true,
    }),
    createTheme: { method: 'put', route: 'review-themes', isErrorInToast: true },
    updateTheme: (reviewThemeId) => ({
      method: 'patch',
      route: `/review-themes/${reviewThemeId}`,
      isErrorInToast: true,
    }),
    copyTheme: (reviewThemeId) => ({
      method: 'put',
      route: `/review-themes/${reviewThemeId}/copy`,
      isErrorInToast: true,
    }),
    deleteThemes: { method: 'post', route: 'review-themes/delete', isErrorInToast: true },
  },
  reviewQuestions: {
    createReviewQuestion: {
      method: 'put',
      route: 'review-questions',
      isErrorInToast: true,
    },
    updateReviewQuestion: (questionId) => ({
      method: 'patch',
      route: `/review-questions/${questionId}`,
      isErrorInToast: true,
    }),
    copyReviewQuestion: (questionId) => ({
      method: 'post',
      route: `/review-questions/${questionId}/copy`,
      isErrorInToast: true,
    }),
    deleteReviewQuestion: (questionId) => ({
      method: 'delete',
      route: `review-questions/${questionId}`,
      isErrorInToast: true,
    }),
  },
  reviewInvitationTemplates: {
    items: {
      method: 'post',
      route: 'review-invitation-templates',
      isErrorInToast: true,
    },
    createReviewInvitationTemplate: {
      method: 'put',
      route: 'review-invitation-templates',
    },
    editReviewInvitationTemplate: (reviewInvitationTemplateId) => ({
      method: 'patch',
      route: `review-invitation-templates/${reviewInvitationTemplateId}`,
    }),
    deleteReviewInvitationTemplate: (reviewInvitationTemplateId) => ({
      method: 'delete',
      route: `review-invitation-templates/${reviewInvitationTemplateId}`,
    }),
    updateReviewInvitationTemplate: (reviewInvitationTemplateId) => ({
      method: 'patch',
      route: `review-invitation-templates/${reviewInvitationTemplateId}`,
      isErrorInToast: true,
    }),
  },
  reviewRatings: {
    createOrUpdate: (isErrorHandledManually) => ({
      method: 'post',
      route: '/review-ratings',
      isErrorInToast: true,
      isErrorHandledManually,
    }),
    createOrUpdateOutside: (token, isErrorHandledManually) => ({
      method: 'put',
      route: `review-ratings/outside?token=${token}`,
      isErrorInToast: true,
      isErrorHandledManually,
    }),
  },
  reviewSummaries: {
    createOrUpdate: { method: 'put', route: '/review-summaries' },
  },
  invites: {
    getInvites: { method: 'get', route: '/getInvites' },
    getInvite: { method: 'get', route: '/getInvite' },
    acceptInvite: { method: 'get', route: '/acceptInvite' },
    cancelInvite: { method: 'post', route: '/cancelInvite' },
    sendInvites: { method: 'post', route: '/sendInvites' },
    resendInvite: { method: 'post', route: '/resendInvite' },
    updateInviteDetails: { method: 'post', route: '/updateInviteDetails' },
  },
  teams: {
    getTeam: (id) => ({ method: 'get', route: `/teams/${id}` }),
    getTeams: { method: 'get', route: '/teams' },
    getTeamsByIds: { method: 'post', route: '/teams/ids' },
    getUserTeams: (userId) => ({ method: 'get', route: `users/${userId}/teams` }),
    createTeams: { method: 'post', route: '/teams' },
    deleteTeams: { method: 'delete', route: '/teams' },
    updateTeam: (id) => ({ method: 'patch', route: `/teams/${id}` }),
    updateTeams: { method: 'patch', route: '/teams' },
    removeCoachFromTeam: (teamId, coachId) => ({
      method: 'post',
      route: `/teams/${teamId}/removeCoach/${coachId}`,
    }),
    removeMemberFromTeam: (teamId, memberId) => ({
      method: 'post',
      route: `/teams/${teamId}/removeMember/${memberId}`,
    }),
    addMembersToTeam: (id) => ({ method: 'post', route: `/teams/${id}/addMembers` }),
    addCoachesToTeam: (id) => ({ method: 'post', route: `/teams/${id}/addCoaches` }),
    inviteMembersToTeam: (id) => ({ method: 'post', route: `/teams/${id}/inviteMembers` }),
  },
  likes: {
    getLikes: { method: 'get', route: '/likes' },
    getUserLikes: (userId) => ({ method: 'get', route: `/users/${userId}/likes` }),
    createLikes: { method: 'post', route: '/likes' },
    getCompanyLikes: { method: 'get', route: '/likes/company' },
    retrieveLikesForUser: (userId) => ({ method: 'post', route: `/likes/user/${userId}` }),
    deleteLike: (likeId) => ({ method: 'delete', route: `/likes/${likeId}` }),
  },
  logs: {
    getCompanyLogs: { method: 'post', route: '/logs' },
    getLogsAdmins: { method: 'get', route: '/logs/admins' },
  },
  jobFamilies: {
    getJobFamilies: { method: 'get', route: '/job-families' },
    createJobFamily: { method: 'post', route: '/job-families' },
    updateJobFamily: (jobFamilyId) => ({ method: 'patch', route: `/job-families/${jobFamilyId}` }),
    deleteJobFamilies: {
      method: 'delete',
      route: '/job-families',
      isErrorInToast: true,
    },
    getJobFamily: (jobFamilyId) => ({ method: 'get', route: `/job-families/${jobFamilyId}` }),
    getJobFamiliesSuperAdmin: { method: 'get', route: 'super-admin/job-families' },
    createJobFamilySuperAdmin: { method: 'post', route: 'super-admin/job-families' },
    updateJobFamilySuperAdmin: (jobFamilyId) => ({
      method: 'patch',
      route: `super-admin/job-families/${jobFamilyId}`,
    }),
    deleteJobFamiliesSuperAdmin: {
      method: 'delete',
      route: 'super-admin/job-families',
      isErrorInToast: true,
    },
  },
  jobLevelGroups: {
    getJobLevelGroups: { method: 'get', route: '/job-level-groups' },
    getJobLevelGroup: (jobLevelGroupId) => ({
      method: 'get',
      route: `/job-level-groups/${jobLevelGroupId}`,
    }),
    updateJobLevelGroup: { method: 'patch', route: '/job-level-groups', isErrorInToast: true },
    createJobLevelGroup: { method: 'post', route: '/job-level-groups' },
    deleteJobLevelGroups: { method: 'delete', route: '/job-level-groups', isErrorInToast: true },
  },
  jobTemplates: {
    getSuperAdmin: (jobTemplateId) => ({
      method: 'get',
      route: `/super-admin/job-templates/${jobTemplateId}`,
      isErrorInToast: true,
    }),
    createSuperAdmin: {
      method: 'put',
      route: '/super-admin/job-templates',
      isErrorInToast: true,
    },
    updateSuperAdmin: (jobTemplateId) => ({
      method: 'patch',
      route: `/super-admin/job-templates/${jobTemplateId}`,
      isErrorInToast: true,
    }),
    addAliasSuperAdmin: (jobTemplateId) => ({
      method: 'patch',
      route: `/super-admin/job-templates/${jobTemplateId}/alias`,
      isErrorInToast: true,
    }),
    listSuperAdmin: {
      method: 'post',
      route: '/super-admin/job-templates',
      isErrorInToast: true,
    },
    archiveSuperAdmin: {
      method: 'patch',
      route: 'super-admin/job-templates/archive',
      isErrorInToast: true,
    },
    downloadJobTemplatesCSVSuperAdmin: {
      method: 'post',
      route: 'super-admin/job-templates-csv',
      responseType: 'blob',
    },
    getJobTemplateNames: {
      method: 'post',
      route: '/job-templates',
      isErrorInToast: true,
    },
    getJobTemplate: (jobTemplateId) => ({
      method: 'post',
      route: `job-templates/${jobTemplateId}`,
      isErrorInToast: true,
    }),
    deleteJobTemplateCoverImage: (jobTemplateId) => ({
      method: 'delete',
      route: `super-admin/job-templates/${jobTemplateId}/cover`,
      isErrorInToast: true,
    }),
    generateJobTemplate: {
      method: 'post',
      route: 'super-admin/job-templates/auto-complete',
      isErrorInToast: true,
    },
    generateSkillTempForJobTempCategory: (categoryId) => ({
      method: 'post',
      route: `super-admin/job-templates/skill-templates/category/${categoryId}/auto-complete`,
    }),
  },
  jobTemplatesPending: {
    listSuperAdmin: {
      method: 'post',
      route: '/super-admin/job-templates-pending',
      isErrorInToast: true,
    },
    deleteSuperAdmin: {
      method: 'delete',
      route: '/super-admin/job-templates-pending',
      isErrorInToast: true,
    },
    countSuperAdmin: {
      method: 'get',
      route: '/super-admin/job-templates-pending/count',
      isErrorInToast: true,
    },
    getSuperAdmin: (jobTemplateId) => ({
      method: 'get',
      route: `/super-admin/job-templates-pending/${jobTemplateId}`,
      isErrorInToast: true,
    }),
  },
  jobProfiles: {
    getJobProfiles: { method: 'post', route: '/job-profiles' },
    downloadJobProfilesCSV: { method: 'post', route: '/job-profiles/csv', responseType: 'blob' },
    updateJobProfile_: { method: 'post', route: '/updateJobProfile' },
    createJobProfiles: { method: 'post', route: '/createJobProfiles', isErrorInToast: true },
    getJobProfile: { method: 'get', route: '/getJobProfile' },
    deleteJobProfiles: { method: 'post', route: '/deleteJobProfiles' },
    updateJobProfiles: { method: 'patch', route: '/updateJobProfiles' },
    autoCompleteJobProfile: {
      method: 'post',
      route: '/job-profiles/auto-complete',
    },
    copyJobProfile: (jobProfileId) => ({
      method: 'post',
      route: `/job-profiles/${jobProfileId}/copy`,
    }),
    getJobProfileRelevanciesProgress: (jobProfileId, userId) => ({
      method: 'get',
      route: `/job-profiles/${jobProfileId}/users/${userId}/relevancies`,
    }),
    updateJobProfile: (jobProfileId) => ({
      method: 'patch',
      route: `job-profiles/${jobProfileId}`,
    }),
    deleteJobProfileCoverImage: (jobProfileId) => ({
      method: 'delete',
      route: `/job-profiles/${jobProfileId}/cover`,
      isErrorInToast: true,
    }),
    generateSkillsForJobProfileCategory: (categoryId) => ({
      method: 'post',
      route: `job-profiles/skills/category/${categoryId}/auto-complete`,
    }),
  },
  RTFeedbacks: {
    getRTFeedbacks: { method: 'get', route: '/getRTFeedbacks' },
    getAskedRTFeedbacks: { method: 'get', route: '/getAskedRTFeedbacks' },
    getProvidedRTFeedbacks: { method: 'get', route: '/getProvidedRTFeedbacks' },
    getRTFeedback: { method: 'get', route: '/getRTFeedback', isErrorInToast: true },
    getRTFeedbackForTalkingPoint: {
      method: 'get',
      route: '/getRTFeedbackForTalkingPoint',
      isErrorInToast: true,
    },
    getGoalRTFeedbacks: { method: 'get', route: '/getGoalRTFeedbacks', isErrorInToast: true },
    giveRTFeedback: { method: 'post', route: '/giveRTFeedback' },
    createRTFeedback: { method: 'post', route: '/createRTFeedback' },
    deleteRTFeedback: { method: 'delete', route: '/deleteRTFeedback' },
    updateRTFeedbackStatus: { method: 'patch', route: '/updateRTFeedbackStatus' },
    deleteRTFeedbackByInterviewee: {
      method: 'delete',
      route: '/deleteRTFeedbackByInterviewee',
    },
    getLearningReportRTFeedbacks: {
      method: 'get',
      route: '/rtfeedbacks-learning-report',
      isErrorInToast: true,
    },
  },
  requests: {
    getRequest: { method: 'get', route: '/getRequest', handledErrors: [404] },
    getRequests: { method: 'get', route: '/getRequests' },
    updateRequestStatus: { method: 'patch', route: '/updateRequestStatus' },
    acceptRequest: { method: 'post', route: '/acceptRequest', isErrorInToast: true },
    declineRequest: { method: 'post', route: '/declineRequest' },
    cancelRequest: { method: 'post', route: '/cancelRequest', isErrorInToast: true },
    acceptTeamRequest: { method: 'post', route: '/acceptTeamRequest' },
    declineTeamRequest: { method: 'post', route: '/declineTeamRequest' },
    deleteRequest: { method: 'post', route: '/deleteRequest' },
    getIncomingRequestsForOriginalReview: {
      method: 'get',
      route: '/getIncomingRequestsForOriginalReview',
    },
    sendRequestReminder: (requestId) => ({
      method: 'post',
      route: `requests/${requestId}/remind`,
    }),
  },
  notes: {
    getNote: (noteId) => ({ method: 'get', route: `/notes/${noteId}` }),
    getNotes: { method: 'get', route: '/notes' },
    createNote: (userId) => ({ method: 'post', route: `/users/${userId}/notes` }),
    updateNote: (noteId) => ({ method: 'patch', route: `/notes/${noteId}` }),
    removeNote: (noteId) => ({ method: 'delete', route: `/notes/${noteId}` }),
  },
  cvSettings: {
    saveCvSettings: { method: 'post', route: '/cv-settings', isErrorInToast: true },
    getCvSettingsByUserId: (userId) => ({
      method: 'get',
      route: `/cv-settings/user/${userId}`,
      isErrorInToast: true,
    }),
  },
  careerPlans: {
    getCareerPlans: { method: 'get', route: '/career-plans' },
    getCareerPlansCSV: { method: 'get', route: '/career-plans/csv/members', responseType: 'blob' },
    getCareerPlan: (id) => ({ method: 'get', route: `/career-plans/${id}` }),
    getUserCareerPlans: (userId) => ({ method: 'get', route: `/users/${userId}/career-plans` }),
    createCareerPlans: { method: 'post', route: '/career-plans' },
    deleteCareerPlans: { method: 'delete', route: '/career-plans' },
    createCareerPlansForUsers: {
      method: 'post',
      route: '/users/career-plans',
      isErrorInToast: true,
    },
    deleteCareerPlan: (id) => ({ method: 'delete', route: `/career-plans/${id}` }),
    removeCareerPlan: (id) => ({ method: 'delete', route: `/career-plans/delete/${id}` }),
    updateCareerPlan: (id) => ({ method: 'patch', route: `/career-plans/update/${id}` }),
    retrieveCareerPlans: (userId) => ({
      method: 'post',
      route: `/career-plans/retrieve/${userId}`,
    }),
    changePrimaryCareerPlanForUser: (careerPlanId) => ({
      method: 'patch',
      route: `/career-plans/${careerPlanId}/primary`,
    }),
    getCareerPlansFull: { method: 'get', route: '/career-plans/full' },
  },
  focusAreas: {
    getFocusArea: { method: 'get', route: '/getFocusArea' },
    getFocusAreas: { method: 'get', route: '/getFocusAreas' },
    createFocusArea: { method: 'post', route: '/createFocusArea' },
    deleteFocusArea: { method: 'post', route: '/deleteFocusArea' },
    getFocusAreasBySkillId: { method: 'get', route: '/getFocusAreasBySkillId' },
    getFocusAreasBySkillIds: { method: 'get', route: '/getFocusAreasBySkillIds' },
  },
  notifications: {
    getNotifications: { method: 'get', route: '/notifications' },
    readNotification: (id) => ({ method: 'patch', route: `/notifications/${id}` }),
    readAllNotifications: { method: 'patch', route: '/notifications' },
    deleteNotification: (id) => ({ method: 'delete', route: `/notifications/${id}` }),
    sendReviewFeedbackReminder: { method: 'post', route: '/reviews/feedback-reminder' },
    sendConversationReminder: { method: 'post', route: '/conversations/reminder' },
    sendUserPathReminder: { method: 'post', route: '/paths/user-reminder' },
  },
  superAdmin: {
    getSuperAdmins: { method: 'get', route: '/getSuperAdmins' },
    updateSuperAdminStatus: { method: 'put', route: '/updateSuperAdminStatus' },
    deleteCompany: (companyId) => ({
      method: 'delete',
      route: `super-admin/companies/${companyId}`,
      isErrorInToast: true,
    }),
  },
  files: {
    uploadSingle: { method: 'post', route: '/uploadSingle', isErrorInToast: true },
    uploadMultiple: { method: 'post', route: '/uploadMultiple' },
  },
  quickStarts: {
    getQuickStartByCompany: { method: 'get', route: 'quick-start', isErrorInToast: true },
    updateQuickStart: { method: 'patch', route: 'quick-start' },
    resetQuickStartProgress: { method: 'post', route: 'quick-start' },
    getQuickStartsSuperAdmin: (companyId) => ({
      method: 'get',
      route: `/super-admin/companies/${companyId}/quick-start`,
    }),
  },
  paths: {
    createPath: { method: 'post', route: '/paths', isErrorInToast: true },
    createUsersPath: (pathId) => ({ method: 'post', route: `/paths/${pathId}` }),
    getPath: (pathId) => ({ method: 'get', route: `/paths/${pathId}` }),
    updatePath: (pathId) => ({ method: 'patch', route: `/paths/${pathId}`, isErrorInToast: true }),
    updatePathPublished: (pathId) => ({
      method: 'patch',
      route: `/paths-published/${pathId}`,
      isErrorInToast: true,
    }),
    deletePath: (pathId) => ({ method: 'delete', route: `/paths/${pathId}`, isErrorInToast: true }),
    getSubLibraryPaths: (libraryPathId) => ({
      method: 'get',
      route: `/paths/${libraryPathId}/user-paths`,
    }),
    addPathUsers: (id) => ({ method: 'patch', route: `/paths/${id}/users` }),
    deletePathUsers: (id) => ({ method: 'delete', route: `/paths/${id}/users` }),
    addPathOwners: (id) => ({ method: 'patch', route: `/paths/${id}/owners` }),
    deletePathOwners: (id) => ({ method: 'delete', route: `/paths/${id}/owners` }),
    getPathsToAssign: (userId) => ({ method: 'get', route: `/users/${userId}/getPathsToAssign` }),
    downloadLearningPathCSV: (pathId) => ({
      method: 'get',
      route: `/paths/${pathId}/download-csv`,
      responseType: 'blob',
    }),
    copyPath: (pathId) => ({ method: 'post', route: `/paths/${pathId}/copy` }),
    getCoachDraftPaths: { method: 'get', route: '/getCoachDraftPaths' },
  },
  userPaths: {
    getUserPath: (pathId) => ({ method: 'get', route: `/user-paths/${pathId}` }),
    getUserPaths: { method: 'get', route: '/user-paths' },
  },
  educations: {
    createEducation: { method: 'post', route: '/educations' },
    getEducation: (educationId) => ({ method: 'get', route: `/educations/${educationId}` }),
    getUserEducations: (userId) => ({ method: 'get', route: `/users/${userId}/educations` }),
    updateEducation: (educationId) => ({ method: 'patch', route: `/educations/${educationId}` }),
    deleteEducation: (educationId) => ({ method: 'delete', route: `/educations/${educationId}` }),
  },
  certificates: {
    createCertificate: { method: 'post', route: '/certificates' },
    getCertificates: { method: 'get', route: '/certificates' },
    getUserCertificates: (userId) => ({ method: 'get', route: `/users/${userId}/certificates` }),
    getCertificate: (id) => ({ method: 'get', route: `/certificates/${id}` }),
    updateCertificate: (id) => ({ method: 'patch', route: `/certificates/${id}` }),
    deleteCertificate: (id) => ({ method: 'delete', route: `/certificates/${id}` }),
  },
  workExps: {
    createWorkExp: { method: 'post', route: '/workExperience' },
    getWorkExp: (id) => ({ method: 'get', route: `/workExperience/${id}` }),
    getUserWorkExps: { method: 'get', route: '/workExperience' },
    updateWorkExp: (id) => ({ method: 'patch', route: `/workExperience/${id}` }),
    deleteWorkExp: (id) => ({ method: 'delete', route: `/workExperience/${id}` }),
  },
  integrationSettings: {
    testIntegrationSettings: { method: 'post', route: '/integrationSettings/test' },
    createIntegrationSettings: { method: 'post', route: '/integrationSettings' },
    getIntegrationSettings: (id) => ({ method: 'get', route: `/integrationSettings/${id}` }),
    getCompanyIntegrationSettings: { method: 'get', route: '/integrationSettings' },
    updateIntegrationSettings: (id) => ({
      method: 'patch',
      route: `/integrationSettings/${id}`,
    }),
    deleteIntegrationSettings: (id) => ({
      method: 'delete',
      route: `/integrationSettings/${id}`,
    }),
  },
  integrations: {
    getUsersFromIntegration: (id) => ({
      method: 'get',
      route: `/integration/${id}`,
      isErrorInToast: true,
    }),
    getTeamsFromIntegration: (id) => ({
      method: 'get',
      route: `/integration/teams/${id}`,
      isErrorInToast: true,
    }),
    getJobProfilesFromIntegration: (id) => ({
      method: 'get',
      route: `/integration/roles/${id}`,
      isErrorInToast: true,
    }),
    findUsersToDeleteFromCompanyIntegrations: {
      method: 'get',
      route: '/integrationDelete/',
      isErrorInToast: true,
    },
    findTeamsToDeleteFromCompanyIntegrations: () => ({
      method: 'get',
      route: '/integrationTeamsDelete',
      isErrorInToast: true,
    }),
    findRolesToDeleteFromCompanyIntegrations: () => ({
      method: 'get',
      route: '/integrationRolesDelete',
      isErrorInToast: true,
    }),
    findTeamsToSyncInCompanyIntegrations: () => ({
      method: 'get',
      route: '/integrationTeamsSync',
      isErrorInToast: true,
    }),
    findRolesToSyncInCompanyIntegrations: () => ({
      method: 'get',
      route: '/findRolesToSyncInCompanyIntegrations',
      isErrorInToast: true,
    }),
    findMembersToSyncInCompanyIntegrations: {
      method: 'get',
      route: '/integrationSyncUsers',
      isErrorInToast: true,
    },
    syncMembersFromCompanyIntegrations: {
      method: 'put',
      route: '/integrationSyncUsers',
    },
  },
  integrationsHRIS: {
    initialize: { method: 'get', route: '/integrations/kombo/init', isErrorInToast: true },
    activate: { method: 'post', route: '/integrations/kombo/activate', isErrorInToast: true },
    relink: (integrationSettingsId) => ({
      method: 'get',
      route: `/integrations/kombo/${integrationSettingsId}/relink`,
      isErrorInToast: true,
    }),
  },
  reviewTemplates: {
    getTemplatesDepricated: { method: 'get', route: '/review-templates', isErrorInToast: true },
    getTemplates: { method: 'get', route: '/v2/review-templates', isErrorInToast: true },
    getTemplatesNew: { method: 'post', route: '/review-templates', isErrorInToast: true },
    createTemplateNew: { method: 'put', route: '/review-templates', isErrorInToast: true },
    getTemplate: (reviewTemplateId) => ({
      method: 'post',
      route: `/review-templates/${reviewTemplateId}`,
      // TODO if isErrorInToast: true -> in ReviewTemplateSetup we have infinite loop if reviewTemplateId does not exist,
      //  disabling the toast resolves the problem. Could not find explanation why toast is the reason.
      // isErrorInToast: true,
    }),
    createTemplate: { method: 'post', route: '/v2/review-templates', isErrorInToast: true },
    updateTemplate: (reviewTemplateId) => ({
      method: 'patch',
      route: `/review-templates/${reviewTemplateId}`,
      isErrorInToast: true,
    }),
    deleteTemplate: (id) => ({
      method: 'delete',
      route: `/review-templates/${id}`,
      isErrorInToast: true,
    }),
    createTemplateSuperAdmin: {
      method: 'post',
      route: '/review-templates-super-admin',
      isErrorInToast: true,
    },
    getTemplatesSuperAdmin: {
      method: 'get',
      route: '/review-templates-super-admin',
      isErrorInToast: true,
    },
    deleteMultipleTemplates: {
      method: 'delete',
      route: '/v2/review-templates',
      isErrorInToast: true,
    },
    getTemplateSuperAdmin: (id) => ({
      method: 'get',
      route: `/review-templates-super-admin/${id}`,
      isErrorInToast: true,
    }),
    deleteTemplateSuperAdmin: (id) => ({
      method: 'delete',
      route: `/review-templates-super-admin/${id}`,
      isErrorInToast: true,
    }),
    updateTemplateSuperAdmin: (id) => ({
      method: 'patch',
      route: `/review-templates-super-admin/${id}`,
      isErrorInToast: true,
    }),
    copyTemplate: (reviewTemplateId) => ({
      method: 'put',
      route: `/review-templates/${reviewTemplateId}/copy`,
      isErrorInToast: true,
    }),
    importQuestions: (id) => ({
      method: 'post',
      route: `/review-templates/${id}/questions`,
      isErrorInToast: true,
    }),
  },
  conversationTemplates: {
    getTemplatesDepricated: { method: 'get', route: '/conversation-templates' },
    getTemplates: { method: 'get', route: '/v2/conversation-templates' },
    getTemplate: (id) => ({ method: 'get', route: `/conversation-templates/${id}` }),
    createTemplate: { method: 'post', route: '/v2/conversation-templates' },
    updateTemplate: (id) => ({ method: 'patch', route: `/conversation-templates/${id}` }),
    deleteTemplate: (id) => ({ method: 'delete', route: `/conversation-templates/${id}` }),
    deleteMultipleTemplates: {
      method: 'delete',
      route: '/v2/conversation-templates',
      isErrorInToast: true,
    },
  },
  companySettings: {
    getCompanySkillLabels: (companyId) => ({
      method: 'get',
      route: `/company-settings/${companyId}/skillLabels`,
    }),
    getCompanyGoalUpdates: (companyId) => ({
      method: 'get',
      route: `/company-settings/${companyId}/goalUpdates`,
      isErrorInToast: true,
    }),
    updateCompanyGoalUpdates: (companyId) => ({
      method: 'patch',
      route: `/company-settings/${companyId}/goalUpdates`,
      isErrorInToast: true,
    }),
    getCompanyFeedbackCycle: (companyId) => ({
      method: 'get',
      route: `/company-settings/${companyId}/feedbackCycle`,
      isErrorInToast: true,
    }),
    updateCompanyFeedbackCycle: (companyId) => ({
      method: 'patch',
      route: `/company-settings/${companyId}/feedbackCycle`,
      isErrorInToast: true,
    }),
    updateRoleRelevancies: (companyId) => ({
      method: 'patch',
      route: `/company-settings/${companyId}/roleRelevancies`,
      isErrorInToast: true,
    }),
    updateCompanySkillLabels: (companyId) => ({
      method: 'post',
      route: `/company-settings/${companyId}/skillLabels`,
    }),
    updateCompanySkillCategories: (companyId) => ({
      method: 'patch',
      route: `/company-settings/${companyId}/skillCategories`,
    }),
    getCompanyUserNotifications: (companyId) => ({
      method: 'get',
      route: `/company-settings/${companyId}/user-notifications`,
    }),
    updateCompanyUserNotifications: (companyId) => ({
      method: 'patch',
      route: `/company-settings/${companyId}/user-notifications`,
    }),
    getCompanySettings: () => ({
      method: 'get',
      route: '/company-settings',
    }),
    updateCompanySettings: () => ({
      method: 'patch',
      route: '/company-settings',
      isErrorInToast: true,
    }),
  },
  tasks: {
    getTasks: { method: 'get', route: '/tasks' },
    getReviewTask: (id) => ({
      method: 'get',
      route: `/tasks/${id}/review`,
    }),
    getReviewTaskOutside: (token) => ({
      method: 'get',
      route: `tasks/review-outside?token=${token}`,
      isErrorInToast: true,
    }),
    createTasks: { method: 'put', route: '/tasks' },
    updateStatus: (id) => ({
      method: 'patch',
      route: `/tasks/${id}/status`,
      isErrorInToast: true,
    }),
    updateStatusOutside: (token) => ({
      method: 'patch',
      route: `tasks/status-outside?token=${token}`,
      isErrorInToast: true,
    }),
  },
  userIntegrations: {
    addIntegration: { method: 'post', route: '/user-integrations', isErrorInToast: true },
    deleteIntegration: (id) => ({
      method: 'delete',
      route: `/user-integrations/${id}`,
      isErrorInToast: true,
    }),
    getIntegration: (id) => ({
      method: 'get',
      route: `/user-integrations/${id}`,
      isErrorInToast: true,
    }),
    syncIntegration: (id) => ({
      method: 'post',
      route: `/user-integrations/${id}/sync`,
      isErrorInToast: true,
    }),
  },
  generatePDF: {
    getReviewPDF: (reviewId) => ({
      method: 'get',
      route: `/pdf/review/${reviewId}`,
      isErrorInToast: true,
    }),
    getConversationPDF: (conversationId) => ({
      method: 'get',
      route: `/pdf/conversation/${conversationId}`,
      isErrorInToast: true,
    }),
    getUserProfileCvPDF: (userId) => ({
      method: 'get',
      route: `/pdf/user-public/${userId}`,
      isErrorInToast: true,
    }),
  },
  skillCategories: {
    getSkillCategories: { method: 'get', route: '/skill-categories' },
    updateSkillCategory: (id) => ({ method: 'patch', route: `/skill-categories/${id}` }),
    listSkillCategoriesSuperAdmin: { method: 'get', route: '/super-admin/skill-categories' },
    updateSkillCategoriesSuperAdmin: { method: 'patch', route: '/super-admin/skill-categories' },
    createSkillCategory: { method: 'post', route: '/skill-categories-create' },
    deleteSkillCategory: (id) => ({ method: 'delete', route: `/skill-categories/${id}` }),
  },
  goalCycles: {
    getGoalCycles: { method: 'get', route: '/goal-cycles' },
    createGoalCycle: { method: 'post', route: '/goal-cycles' },
    getGoalCyclesByIds: { method: 'post', route: '/goal-cycles/ids' },
    updateGoalCycle: (id) => ({ method: 'patch', route: `/goal-cycles/${id}` }),
    getGoalCycle: (id) => ({ method: 'get', route: `/goal-cycles/${id}` }),
    deleteGoalCycle: (id) => ({ method: 'delete', route: `/goal-cycles/${id}` }),
  },
  goalTasks: {
    getGoalTask: (id) => ({ method: 'get', route: `/goal-tasks/${id}`, isErrorInToast: true }),
    updateGoalTask: (id) => ({ method: 'patch', route: `/goal-tasks/${id}`, isErrorInToast: true }),
  },
  nextSteps: {
    getRelevantSteps: (targetId) => ({
      method: 'get',
      route: `/next-steps/target/${targetId}`,
      isErrorInToast: true,
    }),
    createStepForConversation: (conversationId) => ({
      method: 'post',
      route: `/next-steps/user-conversations/${conversationId}`,
    }),
    createStep: {
      method: 'put',
      route: '/next-steps',
    },
    switchCompletedState: (stepId) => ({
      method: 'patch',
      route: `/next-steps/${stepId}`,
    }),
    copyToNextConversations: (stepId) => ({ method: 'post', route: `/next-steps/${stepId}` }),
    deleteStep: (stepId) => ({
      method: 'delete',
      route: `/next-steps/${stepId}`,
    }),
    fetchNextSteps: {
      method: 'post',
      route: '/next-steps',
    },
    updateStep: (stepId) => ({
      method: 'put',
      route: `/next-steps/${stepId}`,
    }),
  },
  goalsUpdates: {
    getGoalsUpdates: { method: 'get', route: '/goals-updates', isErrorInToast: true },
    getGoalsUpdate: (id) => ({
      method: 'get',
      route: `/goals-updates/${id}`,
      isErrorInToast: true,
    }),
    updateGoalsUpdate: (id) => ({
      method: 'patch',
      route: `/goals-updates/${id}`,
      isErrorInToast: true,
    }),
  },
  companyDeleteRequests: {
    getCompanyDeleteRequests: {
      method: 'post',
      route: '/company-delete-requests',
      isErrorInToast: true,
    },
    createCompanyDeleteRequest: {
      method: 'put',
      route: '/company-delete-requests',
      isErrorInToast: true,
    },
    handleCompanyDeleteRequest: (id) => ({
      method: 'patch',
      route: `/company-delete-requests/${id}`,
      isErrorInToast: true,
    }),
  },
  roleRelevancies: {
    getRoleRelevancies: {
      method: 'post',
      route: 'role-relevancies',
      isErrorInToast: true,
    },
    getRoleRelevancy: (relevancyId) => ({
      method: 'get',
      route: `role-relevancies/${relevancyId}`,
      isErrorInToast: true,
    }),
  },
  kpis: {
    getKpis: { method: 'post', route: 'kpis', isErrorInToast: true },
    saveKpis: { method: 'patch', route: 'kpis', isErrorInToast: true },
  },
  calendarEvents: {
    meetingLink: (id) => ({
      method: 'patch',
      route: `/calendar-events/${id}/meeting-link`,
      isErrorInToast: true,
    }),
    getEvent: (id) => ({ method: 'get', route: `/calendar-events/${id}` }),
  },
  surveyThemes: {
    getThemes: { method: 'post', route: 'survey-themes', isErrorInToast: true },
    getTheme: (surveyThemeId) => ({
      method: 'post',
      route: `survey-themes/${surveyThemeId}`,
      isErrorInToast: true,
    }),
    createTheme: { method: 'put', route: 'survey-themes', isErrorInToast: true },
    updateTheme: (surveyThemeId) => ({
      method: 'patch',
      route: `/survey-themes/${surveyThemeId}`,
      isErrorInToast: true,
    }),
    copyTheme: (surveyThemeId) => ({
      method: 'put',
      route: `/survey-themes/${surveyThemeId}/copy`,
      isErrorInToast: true,
    }),
    deleteThemes: { method: 'post', route: 'survey-themes-delete', isErrorInToast: true },
  },
  surveyTemplates: {
    getSurveyTemplates: { method: 'post', route: 'survey-templates', isErrorInToast: true },
    getSurveyTemplate: (SurveyTemplateId) => ({
      method: 'post',
      route: `survey-templates/${SurveyTemplateId}`,
      isErrorInToast: true,
    }),
    createSurveyTemplate: { method: 'put', route: 'survey-templates', isErrorInToast: true },
    updateSurveyTemplate: (SurveyTemplateId) => ({
      method: 'patch',
      route: `survey-templates/${SurveyTemplateId}`,
      isErrorInToast: true,
    }),
    deleteSurveyTemplates: {
      method: 'post',
      route: 'survey-templates-delete',
      isErrorInToast: true,
    },
    importQuestions: (SurveyTemplateId) => ({
      method: 'patch',
      route: `survey-templates/${SurveyTemplateId}/questions`,
      isErrorInToast: true,
    }),
  },
  surveys: {
    createSurvey: { method: 'put', route: 'surveys', isErrorInToast: true },
    updateSurvey: (surveyId) => ({
      method: 'patch',
      route: `surveys/${surveyId}`,
      isErrorInToast: true,
    }),
    getSurveys: { method: 'post', route: 'surveys', isErrorInToast: true },
    getSurvey: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}`,
      isErrorInToast: true,
    }),
    deleteSurveys: { method: 'post', route: 'surveys-delete', isErrorInToast: true },
    getSurveyParticipation: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/participation`,
      isErrorInToast: true,
    }),
    getSurveyResultsTeams: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-teams`,
      isErrorInToast: true,
    }),
    getSurveyMembers: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/members`,
      isErrorInToast: true,
    }),
    getSurveyMembersCSV: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/members-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    downloadSurveyParticipationCSV: (surveyId) => ({
      method: 'get',
      route: `surveys/${surveyId}/participation-csv`,
      responseType: 'blob',
    }),
    getSurveyResultsQuestions: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-questions`,
      isErrorInToast: true,
    }),
    downloadSurveyResultsQuestionsCSV: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-questions-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    resultsTeamsCSV: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-teams-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    getSurveyResultsThemes: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-themes`,
      isErrorInToast: true,
    }),
    getSurveyResultsThemesCSV: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/results-themes-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    shareResults: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/share-results`,
      isErrorInToast: true,
    }),
    archiveSurvey: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/archive`,
      isErrorInToast: true,
    }),
    unarchiveSurvey: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/unarchive`,
      isErrorInToast: true,
    }),
    getSurveyParticipationChart: (surveyId) => ({
      method: 'get',
      route: `surveys/${surveyId}/participation-chart`,
      isErrorInToast: true,
    }),
    getSurveyResultsThemesChart: (surveyId) => ({
      method: 'get',
      route: `surveys/${surveyId}/results-themes-chart`,
      isErrorInToast: true,
    }),
  },
  surveyRatings: {
    setSurveyRatings: { method: 'post', route: 'survey-ratings', isErrorInToast: true },
  },
  surveyEvents: {
    getSurveyEventsBySurveyId: (surveyId) => ({
      method: 'post',
      route: `surveys/${surveyId}/events`,
      isErrorInToast: true,
    }),
    getSurveyEventsByUserSurveyId: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/events`,
      isErrorInToast: true,
    }),
  },
  surveyTasks: {
    getSurveyTask: (surveyTaskId) => ({
      method: 'get',
      route: `survey-tasks/${surveyTaskId}`,
      isErrorInToast: true,
    }),
    changeSurveyTaskStatus: (surveyTaskId) => ({
      method: 'post',
      route: `survey-tasks/${surveyTaskId}/status`,
      isErrorInToast: true,
    }),
  },
  userSurveys: {
    getUserSurveys: { method: 'post', route: 'user-surveys', isErrorInToast: true },
    getUserSurvey: (userSurveyId) => ({
      method: 'get',
      route: `user-surveys/${userSurveyId}`,
      isErrorInToast: false,
    }),
    getUserSurveyResultsThemes: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-themes`,
      isErrorInToast: true,
    }),
    getUserSurveyResultsThemesCSV: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-themes-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    getUserSurveyResultsQuestions: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-questions`,
      isErrorInToast: true,
    }),
    downloadUserSurveyResultsQuestionsCSV: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-questions-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    getUserSurveyResultsTeams: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-teams`,
      isErrorInToast: true,
    }),
    resultsTeamsCSV: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-teams-csv`,
      responseType: 'blob',
      isErrorInToast: true,
    }),
    getUserSurveyResultsThemesChart: (userSurveyId) => ({
      method: 'post',
      route: `user-surveys/${userSurveyId}/results-themes-chart`,
      isErrorInToast: true,
    }),
  },
  surveyQuestions: {
    createSurveyQuestion: {
      method: 'put',
      route: 'survey-questions',
      isErrorInToast: true,
    },
    updateSurveyQuestion: (questionId) => ({
      method: 'patch',
      route: `/survey-questions/${questionId}`,
      isErrorInToast: true,
    }),
    copySurveyQuestion: (questionId) => ({
      method: 'post',
      route: `/survey-questions/${questionId}/copy`,
      isErrorInToast: true,
    }),
    deleteSurveyQuestion: (questionId) => ({
      method: 'delete',
      route: `survey-questions/${questionId}`,
      isErrorInToast: true,
    }),
  },
  skillTemplatesPending: {
    getSuperAdmin: (skillTemplatePendingId) => ({
      method: 'get',
      route: `super-admin/skill-templates-pending/${skillTemplatePendingId}`,
      isErrorInToast: true,
    }),
    listSuperAdmin: {
      method: 'post',
      route: 'super-admin/skill-templates-pending',
      isErrorInToast: true,
    },
    deleteSuperAdmin: {
      method: 'delete',
      route: 'super-admin/skill-templates-pending',
      isErrorInToast: true,
    },
    countSuperAdmin: {
      method: 'get',
      route: 'super-admin/skill-templates-pending/count',
      isErrorInToast: true,
    },
  },
  skillTemplates: {
    getSuperAdmin: (skillTemplateId) => ({
      method: 'get',
      route: `super-admin/skill-templates/${skillTemplateId}`,
      isErrorInToast: true,
    }),
    get: (skillTemplateId) => ({
      method: 'get',
      route: `skill-templates/${skillTemplateId}`,
      isErrorInToast: true,
    }),
    updateSuperAdmin: (skillTemplateId) => ({
      method: 'patch',
      route: `super-admin/skill-templates/${skillTemplateId}`,
      isErrorInToast: true,
    }),
    createSuperAdmin: {
      method: 'put',
      route: 'super-admin/skill-templates',
      isErrorInToast: true,
    },
    updateManySuperAdmin: {
      method: 'patch',
      route: 'super-admin/skill-templates',
      isErrorInToast: true,
    },
    list: {
      method: 'post',
      route: 'skill-templates',
      isErrorInToast: true,
    },
    listSuperAdmin: {
      method: 'post',
      route: 'super-admin/skill-templates',
      isErrorInToast: true,
    },
    getCSVSuperAdmin: {
      method: 'post',
      route: 'super-admin/skill-templates-csv',
      responseType: 'blob',
      isErrorInToast: true,
    },
    generateSkillTemplate: {
      method: 'post',
      route: 'super-admin/skill-templates/auto-complete',
      isErrorInToast: true,
    },
  },
  completions: {
    generateSkillDescription: {
      method: 'post',
      route: 'completions/skills/description',
      isErrorInToast: true,
    },
    generateSkillFocusAreas: {
      method: 'post',
      route: 'completions/skills/focus-areas',
      isErrorInToast: true,
    },
    generateJobDescription: {
      method: 'post',
      route: 'completions/job-description',
      isErrorInToast: true,
    },
    generateSkillDescriptionSuperAdmin: {
      method: 'post',
      route: 'super-admin/completions/skills/description',
      isErrorInToast: true,
    },
    generateSkillFocusAreasSuperAdmin: {
      method: 'post',
      route: 'super-admin/completions/skills/focus-areas',
      isErrorInToast: true,
    },
    generateJobDescriptionSuperAdmin: {
      method: 'post',
      route: 'super-admin/completions/job-description',
      isErrorInToast: true,
    },
    generateTextTranslationSuperAdmin: {
      method: 'post',
      route: 'super-admin/completions/text-translation',
      isErrorInToast: true,
    },
    generateTextTranslation: {
      method: 'post',
      route: 'completions/text-translation',
      isErrorInToast: true,
    },
  },
  industries: {
    searchSuperAdmin: {
      method: 'post',
      route: 'super-admin/industries/search',
      isErrorInToast: true,
    },
    deleteSuperAdmin: {
      method: 'post',
      route: 'super-admin/industries/delete',
      isErrorInToast: true,
    },
    insertSuperAdmin: {
      method: 'post',
      route: 'super-admin/industries',
      isErrorInToast: true,
    },
    updateSuperAdmin: {
      method: 'patch',
      route: 'super-admin/industries',
      isErrorInToast: true,
    },
    getIndustries: {
      method: 'get',
      route: '/industries',
      isErrorInToast: true,
    },
  },
  userConnections: {
    updateUserConnection: (connectionId) => ({
      method: 'patch',
      route: `/user-connections/${connectionId}`,
      isErrorInToast: true,
    }),
  },
  userReviews: {
    items: { method: 'post', route: '/user-reviews', isErrorInToast: true },
    item: (userReviewId) => ({
      method: 'post',
      route: `/user-reviews/${userReviewId}`,
      isErrorInToast: true,
    }),
    deleteById: (userReviewId) => ({
      method: 'delete',
      route: `/user-reviews/${userReviewId}`,
      isErrorInToast: true,
    }),
    archiveById: (userReviewId) => ({
      method: 'patch',
      route: `/user-reviews/${userReviewId}/archive`,
      isErrorInToast: true,
    }),
    unarchiveById: (userReviewId) => ({
      method: 'patch',
      route: `/user-reviews/${userReviewId}/unarchive`,
      isErrorInToast: true,
    }),
    getReviewSummaries: (userReviewId) => ({
      method: 'post',
      route: `/user-reviews/${userReviewId}/review-summaries/search`,
      isErrorInToast: true,
    }),
    updatePeers: (userReviewId) => ({
      method: 'patch',
      route: `/user-reviews/${userReviewId}/peers`,
      isErrorInToast: true,
    }),
    updateCoaches: (userReviewId) => ({
      method: 'patch',
      route: `/user-reviews/${userReviewId}/coaches`,
      isErrorInToast: true,
    }),
    updateGuests: (userReviewId) => ({
      method: 'patch',
      route: `/user-reviews/${userReviewId}/guests`,
      isErrorInToast: true,
    }),
    deleteUserReviewAttachment: (userReviewId) => ({
      method: 'delete',
      route: `/user-reviews/${userReviewId}/attachments`,
      isErrorInToast: true,
    }),
    uploadUserReviewAttachment: (userReviewId) => ({
      method: 'post',
      route: `/user-reviews/${userReviewId}/attachments`,
      isErrorInToast: true,
    }),
  },
  publicProfile: {
    items: (userId) => ({ method: 'post', route: `public-profile/${userId}/user-reviews` }),
  },
  reports: {
    getDetails: () => ({
      method: 'post',
      route: 'reports/engagement/matrix',
      isErrorInToast: true,
    }),
    getMatrixReport: () => ({
      method: 'post',
      route: '/reports/engagement/matrix-export',
      responseType: 'blob',
      isErrorInToast: false,
    }),
    getChartReport: () => ({
      method: 'post',
      route: '/reports/engagement/chart-export',
      responseType: 'blob',
      isErrorInToast: false,
    }),
    getChart: () => ({
      method: 'post',
      route: '/reports/engagement/charts',
      isErrorInToast: true,
    }),
    getTab: (reportType, reportId) => ({
      method: 'get',
      route: `/reports/customReports/${reportType}/${reportId}`,
      isErrorInToast: true,
    }),
    getCustomReports: () => ({
      method: 'get',
      route: '/reports/customReports',
      isErrorInToast: false,
    }),
    getFacets: () => ({
      method: 'post',
      route: 'reports/facets',
      isErrorInToast: false,
    }),
    deleteCustomReport: (id, reportType) => ({
      method: 'delete',
      route: `/reports/customReports/${reportType}/${id}/delete`,
      isErrorInToast: true,
    }),
    duplicateCustomReport: (id, reportType) => ({
      method: 'post',
      route: `/reports/customReports/${reportType}/${id}/duplicate`,
      isErrorInToast: true,
    }),
    createCustomReport: (id, reportType) => ({
      method: 'post',
      route: `/reports/customReports/${reportType}/${id}`,
      isErrorInToast: true,
    }),
    updateCustomReport: (id, reportType) => ({
      method: 'put',
      route: `/reports/customReports/${reportType}/${id}`,
      isErrorInToast: true,
    }),
    updateTabProperties: (id, reportType) => ({
      method: 'patch',
      route: `/reports/customReports/${reportType}/${id}`,
      isErrorInToast: true,
    }),
    getAIInsights: () => ({
      method: 'post',
      route: '/reports/ai-insights',
      isErrorInToast: true,
    }),
    deleteAIInsights: (id) => ({
      method: 'delete',
      route: `/reports/ai-insights/${id}`,
      isErrorInToast: false,
    }),
    getRatings: (reportType) => ({
      method: 'post',
      route: `/reports/${reportType}/ratings`,
      isErrorInToast: true,
    }),
    getDashboard: () => ({
      method: 'get',
      route: '/reports/dashboard/items',
      isErrorInToast: false,
    }),
    deleteFromDashboard: () => ({
      method: 'delete',
      route: '/reports/dashboard/items',
      isErrorInToast: true,
    }),
    addToDashboard: () => ({
      method: 'put',
      route: '/reports/dashboard/items',
      isErrorInToast: false,
    }),
    updateOrderOfDashboardReports: () => ({
      method: 'patch',
      route: '/reports/dashboard/items',
      isErrorInToast: false,
    }),
  },
  pexels: {
    searchPexels: {
      method: 'post',
      route: 'pexels',
      isErrorInToast: true,
    },
  },
};

export const axiosCloud = new axios.create({
  baseURL: SERVER_URI,
});
