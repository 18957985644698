import React, { ChangeEvent, useEffect, useState } from 'react';

import { ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { useHistory, useParams, useLocation } from 'react-router';

import InviteTeamModal from '~/components/Coach/InviteTeamModal';
import { ICONS, ICON_SIZES } from '~/components/Icon';

import {
  MenuCtr,
  Title,
  SearchCtr,
  SearchPlaceholder,
  SearchIcon,
  TitleInner,
  Collapse,
  CollapseIcon,
  MenuItemCtr,
  MenuItem,
  MenuItemWrapper,
  MemberCtr,
  MemberIconCtr,
  MemberNameCtr,
  MemberGrayIcon,
  ChevronIcon,
  InviteMemberCtr,
  PlusIcon,
} from './styledComponents';

import routes from '~/constants/routes';
import useDebounce from '~/hooks/useDebounce';
import { getCompanySettings } from '~/selectors/companySettings';

import type { IMenuItem, IMenuItemChild } from '../types';
import type { ITeam, IUser } from '@learned/types';

interface ISecondaryMenuProps {
  title: string;
  teams: ITeam[];
  users: IUser[];
  inactiveUsers: IUser[];
  menuItems: IMenuItem;
  isEngagementModuleEnabled: boolean;
}

const TeamMenu = ({
  title,
  teams,
  users,
  inactiveUsers,
  menuItems,
  isEngagementModuleEnabled,
}: ISecondaryMenuProps) => {
  const inactiveUsersArr = Object.values(inactiveUsers).map((user) => user.id);
  const teamsArr = menuItems?.children?.map((item) => (item as IMenuItemChild)?.team) || [];
  const filteredTeams = Object.values(teams).filter((team) => teamsArr.includes(team.id));
  const [openedMenuItems, setOpenedMenuItems] = useState(new Set<string>());
  const [openModal, setOpenModal] = useState(false);
  const { i18n } = useLingui();
  const params = useParams();
  const companyId = get(params, 'companyId');
  const teamId = get(params, 'teamId');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const memberId = searchParams.get('memberId');
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearch = useDebounce(searchValue, 1000);
  const companySettings = useSelector(getCompanySettings);
  const regex = new RegExp(debouncedSearch, 'i');
  const searchedUsers = Object.values(users).filter((user) =>
    regex.test(`${user.firstName} ${user.lastName}`),
  );

  const isAllowedToInviteMembers = companySettings?.coaches?.isAllowedToInviteMembers || false;
  const filteredUsers = (arr: ITeam[]) => {
    if (debouncedSearch.trim().length === 0) {
      return arr;
    }
    return arr
      .map((team) => {
        const members = team.members.filter((member) =>
          searchedUsers.some((user) => user.id === member),
        );
        return { ...team, members };
      })
      .filter((team) => team.members.length > 0);
  };
  const teamArr = filteredUsers(Object.values(filteredTeams))
    .map((team) => ({ team: team.name, members: team.members, id: team.id }))
    .sort((a, b) => {
      const nameA = a.team.toLowerCase();
      const nameB = b.team.toLowerCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }

      // names must be equal
      return 0;
    });

  const onHideInviteModal = () => {
    setOpenModal(false);
  };

  const onTeamButtonClicked = (newTeamId: string, newMemberId: string | null) => {
    history.push(
      routes.TEAM_COACH.build(
        { role: ROLES.COACH, teamId: newTeamId, companyId },
        { query: { ...(newMemberId && { memberId: newMemberId }) } },
      ),
    );
  };

  const isNotEmpty = (obj: any) => {
    return obj && Object.keys(obj).length > 0;
  };

  const saveOpenedItems = (items: Set<string>) => {
    setOpenedMenuItems(items);
    localStorage.setItem('OpenMenuItems', JSON.stringify(Array.from(items)));
  };

  const getMemberDetails = (mId: string) => {
    let selectedMember = Object.values(users).find((user) => user.id === mId);
    if (!isNotEmpty(selectedMember)) {
      selectedMember = Object.values(inactiveUsers).find((user) => user.id === mId);
      if (!isNotEmpty(selectedMember)) {
        return <></>;
      }
    }
    return (
      <>
        {inactiveUsersArr.includes(mId) ? (
          <MemberGrayIcon />
        ) : (
          <MemberIconCtr src={selectedMember?.avatarUrl} />
        )}
        {selectedMember?.firstName && selectedMember?.lastName ? (
          <MemberNameCtr>{`${selectedMember?.firstName} ${selectedMember?.lastName}`}</MemberNameCtr>
        ) : (
          <MemberNameCtr>{`${selectedMember?.email}`}</MemberNameCtr>
        )}
      </>
    );
  };

  useEffect(() => {
    if (debouncedSearch.trim().length > 0) {
      const newIdArr = Object.values(filteredTeams).filter((team) => team?.members.length > 0);
      saveOpenedItems(new Set<string>(newIdArr.map((team) => team.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  const handleMenuItemClick = (teamId: string, changeMenu: boolean) => {
    if (isEngagementModuleEnabled) {
      onTeamButtonClicked(teamId, null);
    }
    if (!changeMenu) {
      return;
    }
    const newList = new Set(openedMenuItems);
    if (newList.has(teamId)) {
      newList.delete(teamId);
    } else {
      newList.add(teamId);
    }
    saveOpenedItems(newList);
  };

  const getCollapseIcon = () => {
    if (openedMenuItems.size > 0) {
      saveOpenedItems(new Set<string>());
    } else {
      const newIdArr = Object.values(teams).filter((team) => team?.members.length > 0);
      saveOpenedItems(new Set<string>(newIdArr.map((team) => team.id)));
    }
  };

  useEffect(() => {
    if (memberId) {
      const filteredTeamArr = filteredTeams.filter((team) =>
        team?.members.includes(memberId as string),
      );
      saveOpenedItems(new Set<string>(filteredTeamArr.map((team) => team.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storageItems = localStorage.getItem('OpenMenuItems');
    const stArr = JSON.parse(storageItems as string) || [];
    if (stArr.length > 0) {
      const items = new Set(JSON.parse(`${storageItems}`));
      setOpenedMenuItems(items as Set<string>);
    } else {
      if (teamArr.length > 0) {
        setOpenedMenuItems(new Set<string>([teamArr[0].id]));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teams]);

  return (
    <>
      <MenuCtr>
        <Title>
          <TitleInner>{title}</TitleInner>
          <Collapse onClick={getCollapseIcon}>
            {openedMenuItems.size > 0 ? i18n._(t`Collapse all`) : i18n._(t`Open all`)}
            <CollapseIcon
              icon={ICONS.COLLAPSE_MENU}
              className={openedMenuItems.size > 0 ? 'active' : 'inactive'}
              size={ICON_SIZES.SMALL}
            />
          </Collapse>
        </Title>
        <SearchCtr>
          <SearchPlaceholder
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSearchValue(e.currentTarget.value);
            }}
            value={searchValue}
            placeholder={i18n._(t`Search...`)}
          />
          <SearchIcon icon={ICONS.SEARCH} size={ICON_SIZES.MEDIUM} />
        </SearchCtr>
        <MenuItemCtr>
          {teamArr.map((teamData, i) => {
            return (
              <MenuItemWrapper key={`item-${i + 1}`}>
                <MenuItem
                  isSelected={teamData.id === teamId}
                  className={
                    teamData.id === teamId && memberId && teamData.members.includes(memberId)
                      ? 'member'
                      : ''
                  }
                  onClick={() => handleMenuItemClick(teamData.id, true)}
                >
                  <span>{teamData.team}</span>
                  <ChevronIcon
                    icon={ICONS.DROPDOWN}
                    className={openedMenuItems.has(teamData.id) ? 'active' : 'inactive'}
                    size={ICON_SIZES.SMALL}
                  />
                </MenuItem>
                {openedMenuItems.has(teamData.id) && (
                  <>
                    {teamData.members.map((member, j) =>
                      !inactiveUsersArr.includes(member) ? (
                        <MemberCtr
                          isSelected={member === memberId}
                          key={`member-${j + 1}`}
                          onClick={() => onTeamButtonClicked(teamData.id, member)}
                        >
                          {getMemberDetails(member)}
                        </MemberCtr>
                      ) : null,
                    )}
                    {isAllowedToInviteMembers && (
                      <InviteMemberCtr
                        onClick={() => {
                          handleMenuItemClick(teamData.id, false);
                          setOpenModal(true);
                        }}
                      >
                        <PlusIcon icon={ICONS.ADD_PLUS} size={ICON_SIZES.SMALL} />
                        {i18n._(t`Invite member(s)`)}
                      </InviteMemberCtr>
                    )}
                  </>
                )}
              </MenuItemWrapper>
            );
          })}
        </MenuItemCtr>
      </MenuCtr>
      {openModal && <InviteTeamModal onClose={onHideInviteModal} />}
    </>
  );
};

export default TeamMenu;
