import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { NoDataPlaceholderCtr } from './NoDataPlaceholderStyles';

import noDataImg from '~/assets/empty-state.webp';

const NoDataPlaceholder = () => {
  const { i18n } = useLingui();
  return (
    <NoDataPlaceholderCtr>
      <div id="content">
        <img src={noDataImg} alt={i18n._(t`No data available`)} width="216px" />
        <span className="title">{i18n._(t`No data available`)}</span>
        <span className="text-regular">
          {i18n._(t`The combination of dimensions and filters did not return any results. Try changing the
          filters or check back again later`)}
        </span>
      </div>
    </NoDataPlaceholderCtr>
  );
};

export default NoDataPlaceholder;
