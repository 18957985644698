import React from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonVariant } from '~/components/Buttons';

import { COLORS } from '~/styles';

const Container = styled.div<{ height: number }>`
  width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height}px;
  gap: 10px;
`;

const Label = styled.div`
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: -0.16px;
  color: ${COLORS.PLACEHOLDERS};
`;

interface IProps {
  onCreateNew: () => void;
  isDashboard?: boolean;
}

export const EmptyGoalsPlaceholder = ({ onCreateNew, isDashboard }: IProps) => {
  const { i18n } = useLingui();
  return (
    <Container height={isDashboard ? 60 : 250}>
      <Label>
        <Trans>No goals planned yet</Trans>
      </Label>
      {!isDashboard && (
        <Button
          label={i18n._(t`Create new`)}
          variant={ButtonVariant.PRIMARY}
          onClick={onCreateNew}
        />
      )}
    </Container>
  );
};
