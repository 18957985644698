import styled from 'styled-components';

import { Dropdown } from '~/components/Dropdown';
import TextArea from '~/components/TextArea';

import { COLORS } from '~/styles';

export const Wrapper = styled.div`
  width: 750px;
  background-color: ${COLORS.WHITE};
  padding: 24px 32px 26px;
  border-radius: 10px;
`;

export const TextEditorWrapper = styled.form<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-color: ${({ isActive }) => (isActive ? COLORS.COMPANY : COLORS.BORDERS)};
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  background-color: ${COLORS.BG_LIST};
  box-shadow: 0 2px 4px 0 rgba(145, 157, 165, 0.12);

  & .assignWrapper {
    display: flex;
    align-items: end;
    gap: 24px;
    padding: 16px;

    & .assign {
      width: 100%;

      & .label {
        font-size: 12px;
        color: ${COLORS.TEXT_HOVER};
        margin-bottom: 5px;
      }
    }
  }

  & .username {
    max-width: 200px;
  }

  & button {
    width: 72px;
    min-width: unset;
  }
`;

export const StepTextArea = styled(TextArea)`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 16px;
  width: 100%;
  height: auto;
  border: none;
  overflow: hidden;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${COLORS.TEXT_HOVER};
  outline: none;

  &::placeholder {
    color: ${COLORS.PLACEHOLDERS};
  }

  &:hover {
    background-color: ${COLORS.HOVER};
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  align-self: center;
  padding: 7px 10px 7px 8px;
  border-radius: 6px;
  box-sizing: border-box;
  cursor: pointer;

  border: 1px solid ${COLORS.BORDERS};
  color: ${COLORS.PLACEHOLDERS};

  & .avatarWrapper {
    width: 80%;

    & span {
      font-size: 14px;
    }

    & .username {
      max-width: 120px;
    }

    & .avatar {
      width: 18px;
      height: 18px;
      min-width: 18px;
      min-height: 18px;
    }
  }

  & .placeholder {
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &:hover {
    height: 32px;
    background-color: ${COLORS.INFO_LIGHT};
  }
`;

export const StyledDropdown = styled(Dropdown<string>)`
  background-color: ${COLORS.WHITE};
`;
