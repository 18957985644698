import React from 'react';

import { TASKS_ACTIONS, TASK_ACTIONS_MAP, UPDATED_TASK_ACTIONS } from '@learned/types';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { ICON_SIZES, Icon } from '~/components/Icon';
import Label from '~/components/Label';
import Tooltip, { TOOLTIP_PLACEMENTS, TOOLTIP_SIZES } from '~/components/Tooltip';

import { STATUS_ITEMS } from './consts';

type LabelProps = {
  status: TASKS_ACTIONS;
};

const StyledDiv = styled(Label)`
  display: inline-flex;
  align-items: center;
  height: 15px;
  font-size: 12px;
  margin-right: 10px;
`;

const StyledIcon = styled(Icon)<{
  rotate?: number;
  color: string;
}>`
  box-sizing: border-box;
  margin-right: 5px;
  transform: rotate(${({ rotate }) => (rotate ? rotate : '')}deg);
`;

const StatusLabel = ({ status }: LabelProps) => {
  const newStatus = TASK_ACTIONS_MAP[status];
  const item = Object.values(STATUS_ITEMS).find((i) => i.key === newStatus) || ({} as any);
  const { i18n } = useLingui();

  const label = (
    <StyledDiv color={item.color} backgroundColor={item.backGroundColor}>
      <StyledIcon
        icon={item.icon}
        size={ICON_SIZES.SMALL}
        rotate={item.key === UPDATED_TASK_ACTIONS.TODO ? -90 : 0}
        color={item.color}
      />
      {item.text(i18n)}
    </StyledDiv>
  );

  return item.tooltip ? (
    <Tooltip
      tooltip={item.tooltip(i18n)}
      placement={TOOLTIP_PLACEMENTS.BOTTOM}
      maxWidth={'360px'}
      size={TOOLTIP_SIZES.BIG}
    >
      <div>{label}</div>
    </Tooltip>
  ) : (
    label
  );
};

export { StatusLabel };
