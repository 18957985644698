import { createReducer } from 'redux-act';

import { setGeneratedSkill } from './actions';

const skillsReducer = createReducer(
  (on) => {
    on(setGeneratedSkill, (state, payload) => ({
      ...state,
      skillGenerated: payload,
    }));
  },
  {
    // TODO: Create a Root state type and define a initial value
  },
);

export default skillsReducer;
