import React from 'react';

import { CAREER_PLAN_STATUSES } from '@learned/constants';
import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import { ICONS, ICON_SIZES, Icon } from '~/components/Icon';
import ProgressBar from '~/components/ProgressBar';
import RickTextView from '~/components/RickTextView';

import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import {
  Wrapper,
  InformationRow,
  JobImage,
  JobTitle,
  Title,
  Labels,
  RoleLabel,
  Description,
  SkillMatch,
  JobInfo,
  ProgressContainer,
  JobFamily,
  JobLevel,
  ImagePlaceholder,
} from '../design';

import type { ICareerPlan } from '@learned/types';

interface IJobCardProps {
  width?: number;
  jobTitle?: string;
  image?: string;
  status: ICareerPlan['status'];
  description?: string;
  performance?: { score: number; maxScore: number };
  jobFamily?: string; // this type can be changed in future
  jobLevel?: string; // this type can be changed in future
  companyColor?: string;
  onClickViewDetails?: () => void;
}

const RectangularJobCard = ({
  width = 864,
  jobTitle,
  image,
  status,
  description,
  performance,
  jobFamily,
  jobLevel,
  companyColor,
  onClickViewDetails,
}: IJobCardProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  return (
    <>
      <Wrapper width={width}>
        <InformationRow>
          <JobImage>
            {image ? (
              <img alt={jobTitle} src={image} />
            ) : (
              <ImagePlaceholder backgroundColor={companyColor} height="160px" />
            )}
          </JobImage>
          <div>
            <JobTitle>
              <Title>{jobTitle}</Title>
              <Labels>
                {status === CAREER_PLAN_STATUSES.CURRENT ? (
                  <RoleLabel color={COLORS.ACTIVE} backgroundColor={COLORS.INFO_LIGHT}>
                    <Trans>Active</Trans>
                  </RoleLabel>
                ) : (
                  <RoleLabel
                    color={COLORS.PREVIOUS}
                    backgroundColor={COLORS.PREVIOUS}
                    backgroundByPercentage={30}
                    opacity="0.56"
                  >
                    <Trans>Previous</Trans>
                  </RoleLabel>
                )}
              </Labels>
            </JobTitle>
            <Description>
              <RickTextView
                html={convertMarkdownStarsToStrong(
                  getMultiLangString(
                    (description && description.length > 300
                      ? `${description.substring(0, 300)}...`
                      : description) || '',
                  ),
                )}
                color={COLORS.ICONS_PRIMARY}
                isHtmlWithoutStyles={undefined}
                className={undefined}
              />
            </Description>
          </div>
          <SkillMatch>
            {performance?.score && (
              <ProgressContainer>
                <span>
                  <Trans>Performance</Trans>
                </span>
                <ProgressBar
                  styles={null}
                  isHideValue
                  value={performance.score}
                  maxValue={performance.maxScore}
                  height={6}
                  width={150}
                />
                <span className="percentage">
                  {performance.score}/{performance.maxScore}
                </span>
              </ProgressContainer>
            )}
          </SkillMatch>
          <JobInfo>
            <JobFamily>
              {jobFamily && (
                <>
                  <Icon icon={ICONS.JOB_FAMILY} size={ICON_SIZES.MEDIUM} /> {jobFamily}
                </>
              )}
            </JobFamily>
            <JobLevel>
              {jobLevel && (
                <>
                  <Icon icon={ICONS.JOB_LEVEL} size={ICON_SIZES.MEDIUM} /> {jobLevel}
                </>
              )}
            </JobLevel>
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.MEDIUM}
              label={i18n._(t`View details`)}
              onClick={onClickViewDetails}
            />
          </JobInfo>
        </InformationRow>
      </Wrapper>
    </>
  );
};

export { RectangularJobCard };
