import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import styled from 'styled-components';

import { Button, ButtonSize, ButtonVariant } from '~/components/Buttons';
import RickTextView from '~/components/RickTextView';
import { InvitationModal } from '~/pages/Reviews/DashboardUser/ReviewDashboardUserForm/sidebarLeft/sections/Details/components/InvitationModal';

import { COLORS } from '~/styles';

import { Block } from '../components/Block';
import { Text, RowWrapper } from '../design';

const Placeholder = styled.div`
  font-size: 14px;
  color: ${COLORS.PROGRESSBAR_BACKGROUND};
`;

interface IInvitationMessageBlockProps {
  message: string;
}

const InvitationMessageBlock = ({ message }: IInvitationMessageBlockProps) => {
  const { i18n } = useLingui();
  const [isInvitationModalOpen, setIsInvitationModalOpen] = React.useState(false);

  const allowedSize = 100; // 100 symbols -> is more - cut
  const isCut = message.length > allowedSize;
  const messageCut = message.slice(0, allowedSize);

  return (
    <Block title={i18n._(t`Invitation message`)}>
      <RowWrapper>
        {messageCut ? (
          <>
            <Text>
              <RickTextView html={`${messageCut}${isCut ? '...' : ''}`} />
            </Text>

            {isCut && (
              <Button
                label={i18n._(t`Read full`)}
                size={ButtonSize.MEDIUM}
                onClick={() => setIsInvitationModalOpen(true)}
                variant={ButtonVariant.TEXT_PRIMARY}
              />
            )}
          </>
        ) : (
          <Placeholder>{i18n._(t`None`)}</Placeholder>
        )}
      </RowWrapper>

      {isInvitationModalOpen && (
        <InvitationModal message={message} onClose={() => setIsInvitationModalOpen(false)} />
      )}
    </Block>
  );
};

export { InvitationMessageBlock };
