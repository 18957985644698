import { Locals_all } from '@learned/constants';

import { IBaseLanguageStateReturn, ILanguageStateReturn } from '~/hooks/useLanguageState';

export enum LOCALES_ARRAY {
  PRIMARY_FIRST = 'PRIMARY_FIRST',
}

export const getCompanyLocales = (
  languageState: ILanguageStateReturn,
  sort?: LOCALES_ARRAY,
): Locals_all[] => {
  const { companyLanguages, companyPrimaryLanguage } = languageState;

  switch (sort) {
    case LOCALES_ARRAY.PRIMARY_FIRST:
      return [
        companyPrimaryLanguage,
        ...companyLanguages.filter((language) => language.locale !== companyPrimaryLanguage.locale),
      ].map((item) => item.locale) as Locals_all[];
    default:
      return companyLanguages.map((item) => item.locale) as Locals_all[];
  }
};

export const getTemplateLocales = (
  languageState: IBaseLanguageStateReturn,
  sort?: LOCALES_ARRAY,
): Locals_all[] => {
  const { languages, primaryLanguage } = languageState;

  switch (sort) {
    case LOCALES_ARRAY.PRIMARY_FIRST:
      return [
        primaryLanguage,
        ...languages.filter((language) => language.locale !== primaryLanguage.locale),
      ].map((item) => item.locale) as Locals_all[];
    default:
      return languages.map((item) => item.locale) as Locals_all[];
  }
};
