import React, { ChangeEvent } from 'react';

import { REVIEW_QUESTION_TYPES } from '@learned/constants';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import uniq from 'lodash/uniq';
import { useParams } from 'react-router';

import { ButtonVariant } from '~/components/Buttons';
import { HeaderFocusMode } from '~/components/Headers/HeaderFocusMode';
import { HeaderIconButtons } from '~/components/Headers/HeaderFocusMode/types';
import { ICONS } from '~/components/Icon';
import { PlanGoalQuestion } from '~/pages/ReviewGiveFeedback/components/Questions/PlanGoalQuestion';
import { IQuestionDefaultData } from '~/pages/ReviewGiveFeedback/types';
import { NominatePeers } from '~/pages/Reviews/Modals/NominatePeers';
import { PlanCalendarEventModal } from '~/pages/Reviews/Modals/PlanCalendarEventModal';
import { SelectCoachesModal } from '~/pages/Reviews/Modals/SelectCoachesModal';
import { SelectGuestsModal } from '~/pages/Reviews/Modals/SelectGuestsModal';

import { Attachments } from './components/Attachments';
import { NextSteps } from './components/NextSteps';
import { NextStepsModal } from './components/NextStepsModal';
import { RatingAnswers } from './components/Questions/RatingAnswers';
import { TextAnswers } from './components/Questions/TextAnswers';
import { QuestionWrapper } from './components/QuestionWrapper';
import { Scores } from './components/Scores';
import { Signatures } from './components/Signatures';
import { StatusLabel } from './components/StatusLabel';
import { Summaries } from './components/Summaries';
import { SummaryModal } from './components/SummaryModal';
import { Divider, EndOfTheReport, QuestionListContainer, ReviewDashboardTitle } from './design';
import { useAttachments } from './hooks/useAttachments';
import { useNextSteps } from './hooks/useNextSteps';
import { useReviewRatings } from './hooks/useReviewRatings';
import { useSummary } from './hooks/useSummary';
import { useUserReview } from './hooks/useUserReview';
import { DetailsSection } from './sidebarLeft/sections/Details';
import { EmployeeTasksSidebarSection } from './sidebarLeft/sections/EmployeeTasks';
import { OthersTasksSidebarSection } from './sidebarLeft/sections/OthersTasks';
import { SignaturesSection } from './sidebarLeft/sections/Signatures';

import type { ILanguageStateReturn } from '~/hooks/useLanguageState';
import { useMultiLangString } from '~/hooks/useMultiLangString';
import { COLORS } from '~/styles';
import { turnArrayIntoMultiLang } from '~/utils/turnMultiLangIntoArray';

import { DashboardLayout } from '../../components/DashboardLayout';

import type { IReviewDashboardUserForm } from './types';
import type { IReviewQuestion } from '@learned/types';
import type { UseFormReturn } from 'react-hook-form';

interface ReviewCycleFormProps {
  formMethods: UseFormReturn<IReviewDashboardUserForm>;
  languageState: ILanguageStateReturn;
}

const ReviewDashboardUserForm = ({ formMethods }: ReviewCycleFormProps) => {
  const { i18n } = useLingui();
  const params: Record<string, string | undefined> = useParams();
  const userReviewId = params.userReviewId as string;

  const getMultiLangString = useMultiLangString();
  const { watch } = formMethods;

  const nominatePeersTask = watch('taskNominatePeers');
  const employee = watch('employee');

  const {
    userReview,
    isLoading,
    isEmployee,
    employeeFirstName,
    // delete
    isShowToDelete,
    isDisableToDelete,
    // edit
    isShowToEdit,
    isDisableToEdit,
    // archive
    isAllowToArchive,
    isAllowToUnarchive,
    // export PDF
    isAllowToExportPDF,
    // sign
    isShowToSign,
    isDisableToSign,
    isDigitalSign,
    goToReviews,
    onEdit,
    onDelete,
    onArchive,
    onUnarchive,
    onExportPDF,
    isAllowToEditSummaryOrNextSteps,
    isAllowToGiveSelfReview,
    isAllowToNominatePeers,
    isAllowToGiveCoachReview,
    isAllowToGivePeerReview,
    showNominatePeersModal,
    openNominatePeersModal,
    closeNominatePeersModal,
    onGiveSelfReview,
    onGiveCoachReview,
    isAllowToDeletePeers,
    isAllowToDeleteCoaches,
    isAllowToDeleteGuests,
    isAllowToAddPeers,
    isAllowToAddCoaches,
    isAllowToAddGuests,
    isAllowToPlanConversation,
    isAllowedToAccessMeetingLink,
    onDeleteCoach,
    onDeletePeer,
    onDeleteGuest,
    onAddCoaches,
    onAddGuests,
    showSelectCoachesModal,
    openSelectCoachesModal,
    closeSelectCoachesModal,
    showSelectGuestsModal,
    openSelectGuestsModal,
    closeSelectGuestsModal,
    showPlanCalendarEventModal,
    openPlanCalendarEventModal,
    closePlanCalendarEventModal,
    isAllowToSign,
    isAdmin,
  } = useUserReview({
    formMethods,
    userReviewId,
  });
  const { reviewRatings } = useReviewRatings({ userReview });

  const participants = uniq([watch('employee.id'), ...watch('coaches'), ...watch('guests')]);

  const summaryModal = useSummary({
    userReviewId,
    status: watch('status'),
  });

  const nextStepsModal = useNextSteps({ userReviewId, status: watch('status') });
  const attachmentHook = useAttachments({ userReviewId, formMethods });

  const nameMultiLang = turnArrayIntoMultiLang(watch('name'));

  const iconButtons = [
    isShowToEdit && {
      icon: ICONS.EDIT_PENCIL,
      onClick: (_e: ChangeEvent<HTMLInputElement>) => onEdit(),
      isLoading,
      disabled: isDisableToEdit,
      tooltip: isDisableToEdit
        ? i18n._(t`This review is part of a review cycle and can only be edited there`)
        : i18n._(t`Edit`),
    },
    isAllowToArchive && {
      icon: ICONS.ARCHIVE,
      onClick: onArchive,
      isLoading,
      tooltip: i18n._(t`Archive`),
    },
    isAllowToUnarchive && {
      icon: ICONS.UNARCHIVE,
      onClick: onUnarchive,
      isLoading,
      tooltip: i18n._(t`Unarchive`),
    },
    isShowToDelete && {
      icon: ICONS.DELETE_BIN,
      onClick: onDelete,
      isLoading,
      disabled: isDisableToDelete,
      tooltip: isDisableToDelete
        ? i18n._(
            t`This review is part of a review cycle. To delete the review, delete the employee there.`,
          )
        : i18n._(t`Delete`),
    },
    isAllowToExportPDF && {
      icon: ICONS.EXPORT,
      onClick: onExportPDF,
      isLoading,
      tooltip: i18n._(t`Export PDF`),
    },
  ].filter((i) => i) as unknown as HeaderIconButtons[];

  const sidebarConfig = {
    items: [
      {
        title: isEmployee ? i18n._(t`Your tasks`) : i18n._(t`${employeeFirstName}'s tasks`),
        icon: ICONS.CHECKBOX_2,
        content: (
          <EmployeeTasksSidebarSection
            tasks={watch('tasksSelf')}
            employeeFirstName={employeeFirstName}
            isEmployee={isEmployee}
            isAllowToGiveSelfReview={isAllowToGiveSelfReview}
            isAllowToNominatePeers={isAllowToNominatePeers}
            onGiveSelfReview={onGiveSelfReview}
            onNominatePeers={openNominatePeersModal}
          />
        ),
        isContentVisibleByDefault: true,
      },
      {
        title: i18n._(t`Input by others`),
        icon: ICONS.USER_CHECK,
        content: (
          <OthersTasksSidebarSection
            tasksPeers={watch('tasksReceivedPeers')}
            tasksCoaches={watch('tasksReceivedCoaches')}
            formMethods={formMethods}
            isAllowToDeletePeers={isAllowToDeletePeers}
            isAllowToDeleteCoaches={isAllowToDeleteCoaches}
            isAllowToAddPeers={isAllowToAddPeers}
            isAllowToAddCoaches={isAllowToAddCoaches}
            onDeletePeer={onDeletePeer}
            onDeleteCoach={onDeleteCoach}
            onAddPeers={openNominatePeersModal}
            onAddCoaches={openSelectCoachesModal}
            isAllowToGiveCoachReview={isAllowToGiveCoachReview}
            isAllowToGivePeerReview={isAllowToGivePeerReview}
            onGiveCoachReview={onGiveCoachReview}
          />
        ),
      },
      isDigitalSign
        ? {
            title: i18n._(t`Signatures`),
            icon: ICONS.SIGNATURES,
            content: (
              <SignaturesSection
                formMethods={formMethods}
                isAllowToSign={isAllowToSign}
                onSign={() => {}} // TODO
              />
            ),
          }
        : undefined,
      {
        title: i18n._(t`Review details`),
        icon: ICONS.DETAILS,
        content: (
          <DetailsSection
            formMethods={formMethods}
            onAddGuests={openSelectGuestsModal}
            onDeleteGuest={onDeleteGuest}
            isAllowToDeleteGuests={isAllowToDeleteGuests}
            isAllowToAddGuests={isAllowToAddGuests}
            isAllowToPlanConversation={isAllowToPlanConversation}
            isAllowedToAccessMeetingLink={isAllowedToAccessMeetingLink}
            onPlanConversation={openPlanCalendarEventModal}
          />
        ),
      },
    ].filter((i) => i),
    buttons: [
      {
        title: i18n._(t`Show next steps`),
        icon: ICONS.ARROW_DOUBLE_RIGHT,
        color: COLORS.SKY_BLUE,
        disabled: !isAllowToEditSummaryOrNextSteps,
        tooltip: !isAllowToEditSummaryOrNextSteps ? nextStepsModal.openNextStepsTooltip : '',
        onClick: () => {
          nextStepsModal.isVisible.on();
        },
      },
      {
        title: i18n._(t`Show summary`),
        icon: ICONS.SUMMARY,
        color: COLORS.CYAN,
        disabled: !isAllowToEditSummaryOrNextSteps,
        tooltip: !isAllowToEditSummaryOrNextSteps ? summaryModal.openMySummaryTooltip : '',
        onClick: () => {
          summaryModal.isVisible.on();
        },
      },
    ],
  };

  const getQuestion = (question: IReviewQuestion) => {
    switch (question.type) {
      case REVIEW_QUESTION_TYPES.TEXT:
        return <TextAnswers question={question as unknown as IQuestionDefaultData} />;
      case REVIEW_QUESTION_TYPES.RATING:
        return <RatingAnswers question={question as unknown as IQuestionDefaultData} />;
      case REVIEW_QUESTION_TYPES.GOAL_PLAN:
        return <PlanGoalQuestion userFrom={userReview?.tasks?.[0].userFrom} isDashboard />;
    }
  };

  return (
    <>
      <HeaderFocusMode
        title={i18n._(t`Title: ${getMultiLangString(nameMultiLang)}`)}
        goBack={goToReviews}
        iconButtons={iconButtons}
        status={<StatusLabel status={watch('status')} />}
        isFixed
        isBackIcon={true}
        submitButton={
          isShowToSign
            ? {
                title: i18n._(t`Sign`),
                type: ButtonVariant.PRIMARY,
                onClick: () => {}, // TODO
                disabled: isDisableToSign,
                tooltip: isDisableToSign
                  ? i18n._(t`You can sign the review after your coach has signed`)
                  : i18n._(t`Sign`),
              }
            : undefined
        }
      />
      <DashboardLayout
        isLoading={isLoading}
        // @ts-ignore
        sidebar={sidebarConfig}
        main={
          <>
            <ReviewDashboardTitle>
              <Trans>The report</Trans>
            </ReviewDashboardTitle>
            <QuestionListContainer>
              {reviewRatings.map((question, index) => {
                return (
                  <QuestionWrapper
                    key={question.id}
                    questionNumber={index + 1}
                    themeName="Theme name"
                    question={question}
                  >
                    {getQuestion(question)}
                  </QuestionWrapper>
                );
              })}
            </QuestionListContainer>
            <Divider />
            <ReviewDashboardTitle>
              <Trans>Conclusions</Trans>
            </ReviewDashboardTitle>
            <Scores />
            <Summaries
              reviewSummaries={summaryModal.reviewSummaries}
              onOpenMySummary={summaryModal.isVisible.on}
              isOpenMySummaryDisabled={!isAllowToEditSummaryOrNextSteps}
              openMySummaryTooltip={summaryModal.openMySummaryTooltip}
            />
            <NextSteps
              nextSteps={nextStepsModal.nextSteps}
              onOpenMySummary={nextStepsModal.isVisible.on}
              isOpenNextStepsDisabled={!isAllowToEditSummaryOrNextSteps}
              openNextStepsTooltip={nextStepsModal.openNextStepsTooltip}
              onCheck={nextStepsModal.onCheck}
            />
            <Attachments
              attachments={watch('attachments')}
              onDelete={attachmentHook.onDelete}
              onUpload={attachmentHook.onUpload}
              isAdmin={isAdmin}
            />
            <EndOfTheReport>
              <Divider />
              <div className="title">
                <Trans>End of the report</Trans>
              </div>
              <Divider />
            </EndOfTheReport>
            <Signatures />
          </>
        }
      />
      {summaryModal.isVisible.value && isAllowToEditSummaryOrNextSteps && (
        <SummaryModal
          formMethods={summaryModal.formMethods}
          onClose={() => {
            summaryModal.autoSaveState.flush();
            summaryModal.isVisible.off();
          }}
          autoSaveState={summaryModal.autoSaveState}
        />
      )}
      {nextStepsModal.isVisible.value && isAllowToEditSummaryOrNextSteps && (
        <NextStepsModal
          formMethods={nextStepsModal.formMethods}
          editorFormMethods={nextStepsModal.editorFormMethods}
          onClose={nextStepsModal.isVisible.off}
          participants={participants}
          onSubmit={nextStepsModal.onSubmit}
          onDelete={nextStepsModal.onDelete}
          onCheck={nextStepsModal.onCheck}
          nextSteps={nextStepsModal.nextSteps}
          editStepId={nextStepsModal.editStepId}
          setEditStepId={nextStepsModal.setEditStepId}
        />
      )}
      {showNominatePeersModal && nominatePeersTask && (
        <NominatePeers
          taskId={nominatePeersTask.id}
          closeModal={(isSubmit) => closeNominatePeersModal(isSubmit)}
        />
      )}
      {showSelectCoachesModal && (
        <SelectCoachesModal
          userId={employee?.id}
          onSave={onAddCoaches}
          onClose={closeSelectCoachesModal}
          usersToHide={[employee?.id, ...watch('coaches')].filter((i) => i)}
        />
      )}
      {showSelectGuestsModal && (
        <SelectGuestsModal
          onSave={onAddGuests}
          onClose={closeSelectGuestsModal}
          usersToHide={[employee?.id, ...watch('guests')].filter((i) => i)}
        />
      )}

      {showPlanCalendarEventModal && (
        <PlanCalendarEventModal userReviewId={userReviewId} onClose={closePlanCalendarEventModal} />
      )}
    </>
  );
};

export { ReviewDashboardUserForm };
