import type { ISkillCategory, ISkillTemplate, WithReplace } from '@learned/types';

export type PopulatedSkillTemplate = WithReplace<
  ISkillTemplate,
  { skillCategory?: ISkillCategory }
>;

export enum SKILL_CREATION_STEPS {
  SEARCH_SKILL_TEMPLATE = 'SEARCH_SKILL_TEMPLATE',
  CREATE_SKILL_WITH_LUCA = 'CREATE_SKILL_WITH_LUCA',
}
