import styled from 'styled-components';

import { COLORS } from '~/styles';

export const LastSavedWrapper = styled.div`
  position: absolute;
  padding: 3px 10px 6px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  z-index: 1000;
  background-color: ${COLORS.WHITE};
  border-radius: 0 0 6px 6px;
`;

export const ReviewDashboardTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: -0.24px;
  margin-bottom: 18px;
  color: ${COLORS.TEXT_HOVER};
`;

export const QuestionListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin-bottom: 46px;
  width: 750px;
  box-sizing: border-box;
`;

export const Divider = styled.div<{ width?: string; marginBottom?: string }>`
  width: ${({ width }) => width || '750px'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '44px'};
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${COLORS.BORDERS};
`;

export const EndOfTheReport = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;

  .title {
    display: flex;
    align-items: center;
    color: ${COLORS.DISABLED_COLOR};
    white-space: nowrap;
    font-size: 14px;
    margin-top: -44px;
  }
`;
