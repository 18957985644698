import React, { useEffect, useRef, useState } from 'react';

import { REPORT_CHART_TYPES, REPORT_TYPES, ROLES } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Loader } from '~/components/Buttons/components/Loader';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';

import routes from '~/constants/routes';
import { getSelectedRole, getSettingsRole, getCustomReports } from '~/selectors/baseGetters';
import { getEngagementCharts, TEngagementReportResponse } from '~/services/reports';
import { COLORS } from '~/styles';
import { toFixed } from '~/utils/math';
import { getDateForTimeFrame } from '~/utils/reports';

import type { IReportCustomReport } from '@learned/types';
import type { ChartData, ChartOptions } from 'chart.js';
const GRADIENTS_RANGE = [['rgba(255, 255, 255, 1)', 'rgba(104, 59, 246, 0)']];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, LineController);

export enum ETypes {
  WAVE_CHART = 'chart',
  BAR_CHART = 'bar',
}

export enum ETrend {
  UP = 'up',
  DOWN = 'down',
  NO_CHANGE = 'noChange',
}

export type TBarChart = {
  title: string;
  value: number | string;
  trend?: ETrend;
  deviation?: number;
};

export type TChartData = { key: string; value: number; deviation: number };

export type TScores = {
  bestScore: TBarChart[];
  lowestScore: TBarChart[];
};

type TProps = {
  memberId: string;
  teamId?: string;
};

const ChartContainer = styled.div`
  border-radius: 6px;
  display: flex;
  width: 180px;
  height: 80px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
`;

const WaveCtr = styled.div`
  position: relative;
  color: white;
`;

const LoaderCtr = styled.div`
  margin: auto;
  color: ${COLORS.PLACEHOLDERS};
`;

const ChartTextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  width: 100%;
`;

const createGradient = (
  canvas: HTMLCanvasElement,
  height: number,
  color1: string,
  color2: string,
) => {
  const ctx = canvas.getContext('2d');
  const gradient = ctx?.createLinearGradient(0, 0, 0, height);
  gradient?.addColorStop(0, color1);
  gradient?.addColorStop(1, color2);
  return gradient;
};

export const options: ChartOptions<'line'> = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      beginAtZero: true,
      min: 0,
      max: 100,
    },
  },
};

const PerformanceChart = ({ memberId, teamId }: TProps): JSX.Element => {
  const { i18n } = useLingui();
  const chartRef = useRef<ChartJS<'line'>>(null);
  const [chartDataSet, setChartDataSet] = useState<ChartData<'line'>>({ labels: [], datasets: [] });
  const [isLoading, setIsLoading] = useState(true);
  // role of user on the page
  const currentRole = useSelector(getSelectedRole);
  // actual role of user
  const userRole = useSelector(getSettingsRole);
  const [chartResponse, setChartResponse] = useState<TEngagementReportResponse>();
  const [reportTabId, setReportTabId] = useState<string | undefined>();
  const customReports = useSelector(getCustomReports) as IReportCustomReport[];
  const [latestCoachReviewScore, setLatestCoachReviewScore] = useState<{
    maxScore: string;
    score: string;
  }>();

  const getData = async () => {
    try {
      setIsLoading(true);
      const overtimePayload = {
        viewAs: currentRole,
        reportType: REPORT_TYPES.PERFORMANCE,
        chartType: REPORT_CHART_TYPES.BY_MONTH,
        dateRange: getDateForTimeFrame('24'),
        filters: {
          members: [memberId],
        },
      };

      const reviewScorePayload = {
        viewAs: currentRole,
        reportType: REPORT_TYPES.PERFORMANCE,
        dateRange: getDateForTimeFrame('24'),
        chartType: REPORT_CHART_TYPES.MEMBER_PERFORMANCE_SCORE,
        filters: {
          members: [memberId],
        },
      };

      const [overtimeResponse, reviewScoreResponse] = await Promise.all([
        getEngagementCharts(overtimePayload),
        getEngagementCharts(reviewScorePayload),
      ]);

      if (overtimeResponse.data) {
        setChartResponse(overtimeResponse.data);
      }
      if (reviewScoreResponse.data) {
        const memberScore = reviewScoreResponse.data.scores?.find((sc) => sc.memberId === memberId);

        setLatestCoachReviewScore({
          maxScore: memberScore?.maxScore || '0',
          score: memberScore?.score || '0',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderChart = () => {
    const chart = chartRef.current;
    if (!chart || !chartResponse) {
      return;
    }

    const chartData =
      chartResponse?.averageEngagement.series.map((item) => ({
        key: item.month,
        value: toFixed(item.value || 0, 2),
      })) || [];
    const labels = chartData.map((d) => d.value);
    const dataSets = [
      {
        data: labels,
        borderWidth: 0,
        tension: 0.5,
        pointRadius: 0,
      },
    ];

    const height = 80;
    const GRADIENTS = GRADIENTS_RANGE.map((color) =>
      createGradient(chart.canvas, height, color[0], color[1]),
    );

    const chartDataSet = {
      labels,
      datasets: dataSets.map((d, index) => ({
        ...d,
        fill: true,
        backgroundColor: GRADIENTS[index],
        borderColor: GRADIENTS_RANGE[index][0],
      })),
    };

    if (chartDataSet.datasets?.length && chartDataSet.labels?.length) {
      // @ts-ignore
      setChartDataSet(chartDataSet);
    }

    if (customReports) {
      const tabId = customReports
        .find((cr) => cr.reportType === REPORT_TYPES.PERFORMANCE)
        ?.tabs.find(
          (tab) => tab.isDefault && !tab.isPersonal && tab.name === 'Per theme over time',
        )?.tabId;
      if (tabId) {
        setReportTabId(tabId);
      }
    }
  };

  const onGoToReportClick = () => {
    if (userRole === ROLES.ADMIN) {
      routes.REPORTS_ADMIN_CUSTOM_REPORT.go(
        { role: ROLES.ADMIN },
        {
          reportType: REPORT_TYPES.PERFORMANCE,
          reportId: reportTabId,
          query: {
            member: memberId,
            ...(teamId ? { team: teamId } : {}),
          },
        },
      );
    } else if (userRole === ROLES.COACH) {
      routes.REPORTS_COACH_CUSTOM_REPORT.go(
        { role: ROLES.USER },
        {
          reportType: REPORT_TYPES.PERFORMANCE,
          reportId: reportTabId,
          query: {
            member: memberId,
            ...(teamId ? { team: teamId } : {}),
          },
        },
      );
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId]);

  useEffect(() => {
    renderChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <ChartContainer>
      {isLoading ? (
        <LoaderCtr>
          <Loader />
        </LoaderCtr>
      ) : (
        <>
          {!chartResponse || chartResponse?.total === 0 ? (
            <LoaderCtr>
              <></>
            </LoaderCtr>
          ) : (
            <WaveCtr>
              <Chart
                ref={chartRef}
                type="line"
                data={chartDataSet}
                options={options}
                width={'180px'}
              />
              <ChartTextContainer>
                {latestCoachReviewScore?.score && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: '19px' }}>{latestCoachReviewScore.score}</div>
                    <div style={{ fontSize: '12px' }}>/{latestCoachReviewScore.maxScore}</div>
                  </div>
                )}
                <div style={{ fontSize: '12px', width: '120px' }}>
                  {i18n._(t`Avg. Performance last 24 months`)}
                </div>
                {reportTabId && (
                  <div
                    style={{
                      fontSize: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      alignSelf: 'end',
                      cursor: 'pointer',
                    }}
                    aria-hidden="true"
                    onClick={onGoToReportClick}
                  >
                    {i18n._(t`Go to report`)}{' '}
                    <Icon icon={ICONS.NEXT} color={COLORS.WHITE} size={ICON_SIZES.MEDIUM} />
                  </div>
                )}
              </ChartTextContainer>
            </WaveCtr>
          )}
        </>
      )}
    </ChartContainer>
  );
};

export default PerformanceChart;
