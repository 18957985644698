import {
  ENGAGEMENT_REPORT_CHART_DIMENSIONS,
  REPORT_CHART_TYPES,
  REPORT_TYPES,
  REVIEW_RATING_TYPE,
  ROLES,
} from '@learned/constants';
import { AxiosResponse } from 'axios';
import FileSaver from 'file-saver';

import { TTotalGraphData } from '~/components/Graphs/types';

import type { ICreateServerRoute } from '~/@types/serverRoute';
import { serverRoutes } from '~/server_config';
import cloudRequest from '~/services/cloudRequest';

export type TSortingOrder = 'asc' | 'desc';

export type TGetEngagementDetailsPayload = {
  viewAs: ROLES;
  reportType: string;
  primaryDimension: string;
  secondaryDimension: string;
  additionalDimensions?: string[];
  chartType?: REPORT_CHART_TYPES;
  measure: string;
  dateRange: {
    start: string;
    end: string;
  };
  filters: {
    themes: string[];
    teams: string[];
    surveys: string[];
    search?: string;
    userId?: string;
    ageGroups?: string[];
    educationLevels?: string[];
    genders?: string[];
    jobGroups?: string[];
    jobs?: string[];
    reviews?: string[];
    skills?: string[];
    skillCategories?: string[];
    members?: string[];
  };
  sorting?: {
    order: TSortingOrder;
    orderBy: string;
  };
  options: Record<string, number | boolean | string>;
  pagination?: {
    limit: number;
    skip: number;
  };
};

export interface IChartReportRequest {
  limit?: number;
  skip?: number;
  viewAs: ROLES;
  reportType: REPORT_TYPES;
  chartType?: REPORT_CHART_TYPES;
  primaryDimension?: ENGAGEMENT_REPORT_CHART_DIMENSIONS;
  companyId?: string;
  options?: Record<string, string | boolean | number>;
  filters?: {
    userId?: string;
    themes?: string[];
    teams?: string[];
    surveys?: string[];
    ageGroups?: string[];
    educationLevels?: string[];
    genders?: string[];
    jobGroups?: string[];
    jobs?: string[];
    reviews?: string[];
    skills?: string[];
    skillCategories?: string[];
    members?: string[];
  };
  dateRange: {
    start: string;
    end: string;
  };
  pagination?: {
    limit: number;
    skip: number;
  };
  sorting?:
    | {
        order: TSortingOrder;
        orderBy: string;
      }
    | {};
}

export interface LucaModalFilters {
  primaryDimensionValue?: string;
  secondaryDimensionValue?: string;
  measureValue?: string;
  additionalDimensionValues?: string[];
}

export type TLucaModalData = {
  filters: LucaModalFilters;
  // for nested level dimensions primary/secondary dimension names are optional as dimension names are inclusive with the additional dimensions array
  primaryDimensionName?: string;
  secondaryDimensionName?: string;
  additionalDimensionNames?: string[];
  measureName?: string;
  team?: number | null;
  reviewer?: REVIEW_RATING_TYPE;
  // following attributes are deprecated as no longer need to show value properties in the information modal
  value: number | null; // @deprecated
  benchmark?: number | null; // @deprecated
  deviation?: number | null; // @deprecated
};

type TChartSeries = {
  benchmark: number;
  month: string;
  value: number;
  deviation: number;
  team: number;
  self: number;
  peer: number;
};

export type HeatmapResponse = {
  query: string;
  columns: { id: string | number; name: string | number }[];
  rows: {
    id: string;
    name?: string;
    secondaryName?: string;
    showPrimary?: boolean;
    cells: {
      measure: string;
      value: number;
    }[];
  }[];
  total: number;
};

export type TEngagementReportResponse = {
  chartType: string;
  averageEngagement: {
    average: number;
    deviation: number;
    series: TChartSeries[];
  };
  total: number;
  dimensionAverage: TTotalGraphData[];
  themeRank: {
    top: {
      name: string;
      value: number;
      trend: string;
      deviation: number;
    }[];
    bottom: {
      name: string;
      value: number;
      trend: string;
      deviation: number;
    }[];
  };
  scores?: {
    reviewId: string;
    memberId: string;
    jobProfileId: string;
    score: string;
    maxScore: string;
  }[];
};

export type TFilter = {
  isEnabled: boolean;
  value: string[];
  options: string[];
};

type TOptions = {
  isEnabled: boolean;
  value: boolean;
};

type TDimension = {
  isEnabled: boolean;
  value: string | null;
  options?: string[];
};

export type TVisualType = {
  isEnabled: boolean;
  selected: boolean;
};

export type ITabConfigurationResponse = {
  controls: {
    allowCSVExport: boolean;
    allowAdminDashboard: boolean;
    allowCoachDashboard: boolean;
    allowDelete: boolean;
    allowDuplicate: boolean;
    allowLuca: boolean;
    allowPDFExport: boolean;
    allowRename: boolean;
    allowSave: boolean;
    allowSaveAs: boolean;
  };
  filters: {
    timeFrame?: {
      isEnabled: boolean;
      value: string;
      options: string[];
    };
    themes?: TFilter;
    teams?: TFilter;
    surveys?: TFilter;
    jobs?: TFilter;
    jobGroups?: TFilter;
    ageGroups?: TFilter;
    educationLevels?: TFilter;
    genders?: TFilter;
    reviews?: TFilter;
    skills?: TFilter;
    skillCategories?: TFilter;
    performanceCategories?: TFilter;
    members?: TFilter;
  };
  isDefault: boolean;
  isPersonal: boolean;
  isOnAdminDashboard: boolean;
  isOnCoachDashboard: boolean;
  measure?: {
    isEnabled: boolean;
    value: string | null;
    options?: string[];
  };
  name: string;
  options?: {
    isHeatmapColored?: TOptions;
    includeCompanyAverage?: TOptions;
    includeBenchmark?: TOptions;
    includeTeamAverage?: TOptions;
    includePeerReviewAverage?: TOptions;
    includeSelfReviewAverage?: TOptions;
    exportOptions?: TOptions;
  };
  primaryDimension?: TDimension;
  secondaryDimension?: TDimension;
  additionalDimensions?: TDimension;
  tabId: string;
  viewType: REPORT_CHART_TYPES;
  visualTypes: {
    barHorizontal: TVisualType;
    barVertical: TVisualType;
    line: TVisualType;
    table: TVisualType;
    spider: TVisualType;
  };
  sorting?: {
    order: TSortingOrder;
    orderBy: string;
  };
};

export type TTabResponse = {
  company: string;
  reportType: REPORT_TYPES;
  name: {
    nl_NL: string;
    en_GB: string;
  };
  sourceReport: string;
  selectedTab: REPORT_CHART_TYPES;
  isDefault: true;
  isCustomizable: false;
  deleted: false;
  sortOrder: 8;
  tabs: ITabConfigurationResponse[];
  id: string;
};

export function getEngagementDetails(
  payload: TGetEngagementDetailsPayload,
): AxiosResponse<HeatmapResponse> {
  return cloudRequest(
    (serverRoutes.reports.getDetails as ICreateServerRoute)(),
    undefined,
    payload,
  ) as unknown as AxiosResponse<HeatmapResponse>;
}

export function getEngagementCharts(
  payload: IChartReportRequest,
): AxiosResponse<TEngagementReportResponse> {
  return cloudRequest(
    (serverRoutes.reports.getChart as ICreateServerRoute)(),
    undefined,
    payload,
  ) as unknown as AxiosResponse<TEngagementReportResponse>;
}

export function getTabDetails(
  id: string,
  reportType: string,
  viewAs: string,
): AxiosResponse<ITabConfigurationResponse> {
  return cloudRequest((serverRoutes.reports.getTab as ICreateServerRoute)(reportType, id), {
    viewAs,
  }) as unknown as AxiosResponse<ITabConfigurationResponse>;
}

export function downloadMatrixReport(
  payload: TGetEngagementDetailsPayload,
  type: 'csv' | 'pdf',
  fileName: string,
) {
  return cloudRequest(
    (serverRoutes.reports.getMatrixReport as ICreateServerRoute)(),
    { type },
    payload,
  ).then((response) => {
    FileSaver.saveAs(response, `engagement_${fileName}.${type}`);
  });
}

export function downloadChartReport(
  payload: IChartReportRequest,
  type: 'csv' | 'pdf',
  fileName: string,
) {
  return cloudRequest(
    (serverRoutes.reports.getChartReport as ICreateServerRoute)(),
    { type },
    payload,
  ).then((response) => {
    FileSaver.saveAs(response, `engagement_${fileName}.${type}`);
  });
}

export function getCustomReports() {
  return cloudRequest((serverRoutes.reports.getCustomReports as ICreateServerRoute)());
}

export function deleteCustomReport(id: string, reportType: string) {
  return cloudRequest(
    (serverRoutes.reports.deleteCustomReport as ICreateServerRoute)(id, reportType),
  );
}

export function duplicateCustomReport(id: string, reportType: string) {
  return cloudRequest(
    (serverRoutes.reports.duplicateCustomReport as ICreateServerRoute)(id, reportType),
  );
}

export function createCustomReport(
  payload: TGetEngagementDetailsPayload,
  id: string,
  reportType: string,
) {
  return cloudRequest(
    (serverRoutes.reports.createCustomReport as ICreateServerRoute)(id, reportType),
    undefined,
    payload,
  );
}

export function updateCustomReport(
  payload: TGetEngagementDetailsPayload,
  id: string,
  reportType: string,
) {
  return cloudRequest(
    (serverRoutes.reports.updateCustomReport as ICreateServerRoute)(id, reportType),
    undefined,
    payload,
  );
}

export function updateTabProperties(
  payload: TGetEngagementDetailsPayload,
  id: string,
  reportType: string,
) {
  return cloudRequest(
    (serverRoutes.reports.updateTabProperties as ICreateServerRoute)(id, reportType),
    undefined,
    payload,
  );
}

export function getFacets(reportType: string, include: string[], viewAs: string) {
  return cloudRequest((serverRoutes.reports.getFacets as ICreateServerRoute)(), undefined, {
    include,
    reportType,
    viewAs,
  });
}

export function getAIInsight(
  reportType: string,
  viewAs: string,
  insightTarget?: string,
  userPrompts?: string[],
) {
  return cloudRequest((serverRoutes.reports.getAIInsights as ICreateServerRoute)(), undefined, {
    reportType,
    userPrompts,
    insightTarget,
    viewAs,
  });
}

export function deleteAIInsight(
  reportType: string,
  viewAs: string,
  id: string,
  insightTarget?: string,
) {
  return cloudRequest(
    (serverRoutes.reports.deleteAIInsights as ICreateServerRoute)(id),
    undefined,
    {
      reportType,
      insightTarget,
      viewAs,
    },
  );
}

export function getRatings(reportType: string, payload: IChartReportRequest) {
  return cloudRequest(
    (serverRoutes.reports.getRatings as ICreateServerRoute)(reportType),
    undefined,
    payload,
  );
}

export function getDashboard(viewAs: ROLES) {
  return cloudRequest((serverRoutes.reports.getDashboard as ICreateServerRoute)(), { viewAs });
}

export function deleteFromDashboard(payload: {
  reportType: string;
  reportId: string;
  role: string;
}) {
  return cloudRequest(
    (serverRoutes.reports.deleteFromDashboard as ICreateServerRoute)(),
    undefined,
    payload,
  );
}

export function addToDashboard(payload: { reportType: string; reportId: string; role: ROLES }) {
  return cloudRequest(
    (serverRoutes.reports.addToDashboard as ICreateServerRoute)(),
    undefined,
    payload,
  );
}

export function updateOrderOfDashboardReports(
  payload: {
    reportType: string;
    reportId: string;
    role: ROLES;
  }[],
) {
  return cloudRequest(
    (serverRoutes.reports.updateOrderOfDashboardReports as ICreateServerRoute)(),
    undefined,
    payload,
  );
}
