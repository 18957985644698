import { TASK_STATUS } from '@learned/constants';
import { UPDATED_TASK_ACTIONS } from '@learned/types';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { transparentize } from 'polished';

import { ICONS } from '~/components/Icon';

import { COLORS } from '~/styles';

/**
 * @deprecated Should not be used anymore (use STATUS_ITEMS in constants/tasks.ts)
 */
export const STATUS_ITEMS = {
  [UPDATED_TASK_ACTIONS.IN_PROGRESS]: {
    key: UPDATED_TASK_ACTIONS.IN_PROGRESS,
    text: (i18n: I18n) => i18n._(t`In progress`),
    icon: ICONS.IN_PROGRESS,
    color: '#95a100',
    backGroundColor: '#f2f4d5',
  },
  [UPDATED_TASK_ACTIONS.PROBLEM]: {
    key: UPDATED_TASK_ACTIONS.PROBLEM,
    text: (i18n: I18n) => i18n._(t`Problem`),
    icon: ICONS.WARNING_2,
    color: COLORS.ERROR,
    backGroundColor: '#f6d0d0',
  },
  [UPDATED_TASK_ACTIONS.ARCHIVED]: {
    key: UPDATED_TASK_ACTIONS.ARCHIVED,
    text: (i18n: I18n) => i18n._(t`Archived`),
    icon: ICONS.ARCHIVE_2,
    color: COLORS.LIGHT_GRAY,
    backGroundColor: COLORS.ANY_OTHER_PRODUCTS,
  },
  [UPDATED_TASK_ACTIONS.UPCOMING]: {
    key: UPDATED_TASK_ACTIONS.UPCOMING,
    text: (i18n: I18n) => i18n._(t`Upcoming`),
    icon: null,
    color: COLORS.STATUS_UPCOMING,
    backGroundColor: transparentize(0.8, COLORS.STATUS_UPCOMING),
    tooltip: (i18n: I18n) => i18n._(t`This task will be available once the review has started.`),
  },
  [UPDATED_TASK_ACTIONS.TODO]: {
    key: UPDATED_TASK_ACTIONS.TODO,
    text: (i18n: I18n) => i18n._(t`To Do`),
    icon: ICONS.DROPDOWN,
    color: COLORS.TODO_BLUE,
    backGroundColor: transparentize(0.8, COLORS.TODO_BLUE),
  },
  [UPDATED_TASK_ACTIONS.ACTIVE]: {
    key: UPDATED_TASK_ACTIONS.ACTIVE,
    text: (i18n: I18n) => i18n._(t`In progress`),
    icon: ICONS.IN_PROGRESS,
    color: COLORS.STATUS_IN_PROGRESS,
    backGroundColor: transparentize(0.8, COLORS.STATUS_IN_PROGRESS),
  },
  [UPDATED_TASK_ACTIONS.COMPLETED]: {
    key: UPDATED_TASK_ACTIONS.COMPLETED,
    text: (i18n: I18n) => i18n._(t`Completed`),
    icon: ICONS.CHECKMARK,
    color: COLORS.STATUS_COMPLETED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_COMPLETED),
  },
  [UPDATED_TASK_ACTIONS.DECLINED]: {
    key: UPDATED_TASK_ACTIONS.DECLINED,
    text: (i18n: I18n) => i18n._(t`Declined`),
    icon: ICONS.DECLINED,
    color: COLORS.STATUS_DECLINED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_DECLINED),
    tooltip: (i18n: I18n) =>
      i18n._(t`This task is no longer available because it has been declined`),
  },
  [UPDATED_TASK_ACTIONS.EXPIRED]: {
    key: UPDATED_TASK_ACTIONS.EXPIRED,
    text: (i18n: I18n) => i18n._(t`Expired`),
    icon: ICONS.EXPIRED,
    color: COLORS.STATUS_EXPIRED,
    backGroundColor: transparentize(0.8, COLORS.STATUS_EXPIRED),
    tooltip: (i18n: I18n) => i18n._(t`This task is no longer available because it has expired`),
  },
  [TASK_STATUS.CALIBRATE]: {
    key: TASK_STATUS.CALIBRATE,
    text: (i18n: I18n) => i18n._(t`Calibrate`),
    icon: ICONS.CALIBRATE,
    color: COLORS.STATUS_CALIBRATE,
    backGroundColor: transparentize(0.8, COLORS.STATUS_CALIBRATE),
  },
};
