import React from 'react';

import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Controller, UseFormReturn } from 'react-hook-form';

import Editor from '~/components/Editor';
import { LastSaved } from '~/components/LastSaved';
import { DraggableModal } from '~/components/Modals/DraggableModal';

import { useAutoSaveFunc } from '~/hooks/useAutoSave';
import { convertMarkdownStarsToStrong } from '~/utils/markdown';

import { LastSavedWrapper } from '../design';

import type { IReviewSummaryForm } from '../types';

const SummaryModal = ({
  formMethods,
  autoSaveState,
  onClose,
}: {
  formMethods: UseFormReturn<IReviewSummaryForm>;
  autoSaveState: useAutoSaveFunc;
  onClose: () => void;
}) => {
  const { i18n } = useLingui();
  const { register, control } = formMethods;

  return (
    <DraggableModal
      title={i18n._(t`Summary`)}
      description={i18n._(t`Public summary visible at the end of the report.`)}
      onClose={onClose}
    >
      <Controller
        {...register('content')}
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <Editor
              onChange={onChange}
              onBlur={() => autoSaveState.run('reviewSummary')?.flush()}
              value={convertMarkdownStarsToStrong(value)}
              compact
              isFlexible
            />
            {autoSaveState.state && (
              <LastSavedWrapper>
                <LastSaved
                  time={autoSaveState.state.time}
                  status={autoSaveState.state.status}
                  errorMessage={autoSaveState.state.message}
                />
              </LastSavedWrapper>
            )}
          </>
        )}
      />
    </DraggableModal>
  );
};

export { SummaryModal };
