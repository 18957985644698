import React from 'react';

import { TASK_STATUS } from '@learned/constants';
import { t } from '@lingui/macro';
import { useLingui } from '@lingui/react';

import { DashboardHeader } from '~/components/DashboardHeader';
import { LastSaved } from '~/components/LastSaved';
import Tooltip, { TOOLTIP_SIZES } from '~/components/Tooltip';
import { ActionsWrap } from '~/pages/ReviewThemeView/design';

import { DisabledBadge, LastSavedWrapper, Wrapper } from './GiveReview';
import { ReturnTypeUseReviewRatingsState } from './GiveReview.hooks';

import { IPopulatedReviewTask, IPopulatedUserReview } from '../types';

interface IPeerReviewHeaderProps {
  companyLogo?: string;
  hideActions: boolean;
  userReview: IPopulatedUserReview;
  reviewTask: IPopulatedReviewTask;
  reviewRatingsState: ReturnTypeUseReviewRatingsState;
  useMultiLangString: () => (multiLangString: Record<string, string> | string) => string;
}

export const PeerReviewHeader = ({
  companyLogo,
  userReview,
  reviewTask,
  hideActions,
  reviewRatingsState,
  useMultiLangString,
}: IPeerReviewHeaderProps) => {
  const { i18n } = useLingui();
  const getMultiLangString = useMultiLangString();

  const { autoSaveState } = reviewRatingsState;

  return (
    <DashboardHeader
      logoUrl={companyLogo}
      isBackIcon
      title={i18n._(t`Provide input for: ${getMultiLangString(userReview.name)}`)}
      actions={
        !hideActions && (
          <ActionsWrap>
            {!reviewRatingsState.autosaveEnabled.value ? (
              <Tooltip
                size={TOOLTIP_SIZES.DEFAULT}
                tooltip={`${i18n._(t`Autosave is turned off, click here to turn it back on`)}`}
              >
                <Wrapper onClick={reviewRatingsState.autosaveEnabled.toggle}>
                  <DisabledBadge />
                  {i18n._(t`Autosave is disabled`)}
                </Wrapper>
              </Tooltip>
            ) : (
              <LastSavedWrapper
                onClick={() => {
                  reviewTask.status === TASK_STATUS.COMPLETED &&
                    reviewRatingsState.autosaveEnabled.toggle();
                }}
              >
                <LastSaved
                  time={autoSaveState.state.time}
                  status={autoSaveState.state.status}
                  errorMessage={autoSaveState.state.message}
                />
              </LastSavedWrapper>
            )}
          </ActionsWrap>
        )
      }
    />
  );
};
