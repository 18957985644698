import { persistState } from '@redux-devtools/core';
import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import { addons } from '~/store/addons/reducer';
import { app } from '~/store/app/reducer';
import { appTheme } from '~/store/appTheme/reducer';
import { auth } from '~/store/auth/reducer';
import { careerPlans } from '~/store/careerPlans/reducer';
import { companies } from '~/store/companies/reducer';
import companyConnections from '~/store/companyConnections/reducer';
import companySettings from '~/store/companySettings/reducer';
import currentConversation from '~/store/currentConversation/reducer';
import { currentGoal } from '~/store/currentGoal/reducer';
import { currentPath } from '~/store/currentPath/reducer';
import { currentRatings } from '~/store/currentRatings/reducer';
import { currentRequest } from '~/store/currentRequest/reducer';
import { currentReview } from '~/store/currentReview/reducer';
import { customReports } from '~/store/customReports/reducer';
import { invites } from '~/store/invites/reducer';
import jobFamilies from '~/store/jobFamilies/reducer';
import jobLevelGroups from '~/store/jobLevelGroups/reducer';
import jobProfiles from '~/store/jobProfiles/reducer';
import { locale } from '~/store/locale/reducer';
import { notifications } from '~/store/notifications/reducer';
import { pagination } from '~/store/pagination/reducer';
import { products } from '~/store/products/reducer';
import selected from '~/store/selected/reducer';
import skills from '~/store/skills/reducer';
import { teams } from '~/store/teams/reducer';
import { users } from '~/store/users/reducer';
import DevTools from '~/utils/DevTools';

let middlewares = [];
middlewares.push(thunk);

const reducer = combineReducers({
  app,
  auth,
  users,
  companies,
  teams,
  locale,
  jobProfiles,
  skills,
  jobLevelGroups,
  jobFamilies,
  currentReview,
  currentRequest,
  currentRatings,
  currentGoal,
  currentConversation,
  currentPath,
  appTheme,
  careerPlans,
  selected,
  companyConnections,
  companySettings,
  products,
  addons,
  invites,
  notifications,
  pagination,
  customReports,
});

let enhancer = compose(applyMiddleware(...middlewares));
if (process.env.NODE_ENV === 'development') {
  enhancer = compose(
    applyMiddleware(...middlewares),
    DevTools.instrument(),
    persistState(window.location.href.match(/[?&]debug_session=([^&#]+)\b/)),
  );
}
const store = createStore(reducer, enhancer);

export default store;
