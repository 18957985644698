import React, { ReactNode } from 'react';

import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { isEmpty } from 'lodash';

import { Button, ButtonVariant } from '~/components/Buttons';
import CheckBox from '~/components/CheckBox';
import { Icon, ICON_SIZES, ICONS } from '~/components/Icon';
import { UserAvatar } from '~/components/UserAvatar';

import {
  Content,
  Header,
  IconWrapper,
  NextStepCard,
  NextStepsWrapper,
  Placeholder,
  Title,
  Wrapper,
} from './design';

import { COLORS } from '~/styles';

import type { IReviewNextStep } from '../../types';

interface NextStepsProps {
  nextSteps: IReviewNextStep[];
  onOpenMySummary: () => void;
  isOpenNextStepsDisabled?: boolean;
  openNextStepsTooltip?: ReactNode;
  onCheck: (props: Pick<IReviewNextStep, 'id' | 'isCompleted'>) => void;
}

const NextSteps = ({
  nextSteps,
  onOpenMySummary,
  isOpenNextStepsDisabled,
  openNextStepsTooltip,
  onCheck,
}: NextStepsProps) => {
  const { i18n } = useLingui();

  return (
    <Wrapper>
      <Header>
        <Title>
          <IconWrapper>
            <Icon icon={ICONS.ARROW_DOUBLE_RIGHT} size={ICON_SIZES.SMALL} />
          </IconWrapper>
          <Trans>Next Steps</Trans>
          {!isEmpty(nextSteps) ? ` (${nextSteps.length})` : ''}
        </Title>
        <Button
          variant={isOpenNextStepsDisabled ? ButtonVariant.PRIMARY : ButtonVariant.SECONDARY}
          label={i18n._(t`Open next steps`)}
          onClick={onOpenMySummary}
          disabled={isOpenNextStepsDisabled}
          tooltip={isOpenNextStepsDisabled ? openNextStepsTooltip : ''}
        />
      </Header>
      {isEmpty(nextSteps) ? (
        <NextStepsWrapper>
          <Placeholder color={COLORS.PLACEHOLDERS}>
            <div className="arrow" />
            <Trans>No next steps created yet</Trans>
          </Placeholder>
        </NextStepsWrapper>
      ) : (
        <NextStepsWrapper>
          {nextSteps.map((item) => (
            <NextStepCard key={item.id}>
              <CheckBox
                size="20px"
                isSecondary
                checked={item.isCompleted}
                onChange={() => onCheck({ id: item.id, isCompleted: !item.isCompleted })}
              />
              <div className="main">
                <Content isCompleted={item.isCompleted}>{item.comment}</Content>
                <UserAvatar userId={item.createdFor} />
              </div>
            </NextStepCard>
          ))}
        </NextStepsWrapper>
      )}
    </Wrapper>
  );
};

export { NextSteps };
